import React, { useState } from 'react';
import { Box, Flex, Grid, Image, Link, Text, useBreakpointValue } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEventBySlug } from '../../../hooks/events/useEventBySlug';
import EventComments from './EventComments';
import PageTitleUpdater from '../../../routing/PageTitleUpdater';
import PageLayout from '../../../layout/PageLayout';
import mdToText from 'markdown-to-text';
import ParticipantsModal from './ParticipantsModal';
import { formatDateRange } from '../../../utils/dates';
import GameRole from './GameRole';
import HeaderEvent from '../HeaderEvent';
import { formatUri } from '../../../utils/api/helpers';
import { PROFILE_PATH } from '../../../routing/constants';
import { APP_URL, PUPPETEER_URL, S3_URL } from '../../../utils/constants';
import { capitalizeFirstLetter, getTypeString, removeMarkdown } from '../../../utils/helpers';
import UserStringAvatar from '../../../components/StringAvatar';
import ShareModalButton from '../../../components/ShareModalButton';
import JoinEventButton from '../JoinEventButton';

type ParamTypes = {
  slug: string;
};

const Event: React.FC = () => {
  const navigate = useNavigate();
  const { slug } = useParams<ParamTypes>();
  const { data: event, isLoading, isError, error } = useEventBySlug(slug || '');
  const [isOpen, setIsOpen] = useState(false);

  const isMobile = useBreakpointValue({ base: true, md: false });
  const gridTemplateColumns = isMobile ? '1fr' : ['1fr', '1fr', '2fr 1.5fr'];

  const getTruncatedText = (text: string) => {
    const isTruncated = text.length > 250;
    return isTruncated ? text.slice(0, 250) + '...' : text;
  };

  return (
    <PageLayout isLoading={isLoading} isError={isError} error={error}>
      {event && (
        <>
          <PageTitleUpdater additionalTitle={`Events - ${event.name}`} additionalDescription={removeMarkdown(event.description)} additionalOgImage={`${S3_URL}/events/${event.image}`} />
          <HeaderEvent event={event} />
          <Grid templateColumns={gridTemplateColumns} gap={4} px={2} mt={'-30px'} zIndex={1} mb={isMobile ? 6 : 0} position={'relative'}>
            <Box pr={isMobile ? 0 : 20}>
              {isMobile && (
                <>
                  <Flex mt={4}>
                    <Text color="white" fontSize="13px">
                      {getTruncatedText(event.description)}
                    </Text>
                  </Flex>
                  <Flex my={4}>{event.entityType !== 1 && <JoinEventButton event={event} />}</Flex>
                </>
              )}
              <Flex alignItems={'center'} mb={3} ml="-5px" cursor="pointer" onClick={() => navigate(formatUri({ username: event.userId.username }, PROFILE_PATH))}>
                <UserStringAvatar withBigBorder name={event.userId.username} media={event.userId.media} styles={{ size: 'xs' }} />
                <Text ml="2">{event.userId.username}</Text>
              </Flex>
              <Flex alignItems="center" mb={3} onClick={() => setIsOpen(true)} cursor="pointer">
                <Image src="/assets/icons/usersThree.svg" alt="Participants" width="27px" ml="-2px" />
                <Text ml={2} textDecoration="underline">{`${event.participants.length} participant${event.participants.length > 1 ? 's' : ''}`}</Text>
              </Flex>

              <Flex alignItems="center" mb={3}>
                <Image src="/assets/icons/calendar.svg" alt="Calendrier" width="27px" ml="-2px" />
                <Text ml={2}>{formatDateRange(event.scheduledStartTime, event.scheduledEndTime)}</Text>
              </Flex>
              {event.entityType === 3 && (
                <Flex alignItems="center" mb={3}>
                  <Image src="/assets/icons/mapPin.svg" alt="Localisation" width="27px" ml="-2px" />
                  {event.location && event.location.formattedAddress ? <Text ml={2}>{event.location.formattedAddress}</Text> : <Text ml={2}>N/A</Text>}
                </Flex>
              )}
              {event.game && event.entityType === 2 && <GameRole game={event.game} />}
              {event.entityType === 3 && (
                <Flex alignItems="center" mb={3}>
                  <Image src="/assets/icons/coins.svg" alt="Tarif" width="27px" ml="-2px" />
                  {event.amount ? <Text ml={2}>{event.amount}€</Text> : <Text ml={2}>N/A</Text>}
                </Flex>
              )}
              {event.entityType !== 2 && (
                <Flex alignItems="center" mb={3}>
                  <Image src="/assets/icons/globeHemisphereEast.svg" alt="Url" width="27px" ml="-2px" />
                  {event.url ? (
                    <Link href={event.url} isExternal ml={2} style={{ textDecoration: 'underline' }}>
                      {event.url}
                    </Link>
                  ) : (
                    <Text ml={2}>N/A</Text>
                  )}
                </Flex>
              )}
              <Flex alignItems="center" mb={3}>
                <ShareModalButton
                  type={'icon'}
                  shareFbUrl={`${PUPPETEER_URL}/${getTypeString(event.entityType)}/${event.slug}`}
                  shareTwitterUrl={`${APP_URL}/${getTypeString(event.entityType)}/${event.slug}`}
                  title={`[${capitalizeFirstLetter(getTypeString(event.entityType, true))}] ${event.name}`}
                  description={removeMarkdown(event.description)}
                />
              </Flex>

              <Box>
                <Text as="h1" mb={3} mt={4}>
                  Toujours plus d'infos
                </Text>

                <Text>{event.descriptionMore ? mdToText(event.descriptionMore) : 'Ah bas non :/'}</Text>
              </Box>
            </Box>

            <Box>
              <EventComments threadId={event.threadId} />
            </Box>
          </Grid>
          <ParticipantsModal isOpen={isOpen} eventParticipant={event.participants} onClose={() => setIsOpen(false)} />
        </>
      )}
    </PageLayout>
  );
};

export default Event;
