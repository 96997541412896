import React from 'react';
import { User } from '../../types/user';
import UserCover from '../../components/UserCover';
import { Box, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import UserAvatarProfile from '../../components/UserAvatarProfile';
import { customColors } from '../../theme';
import { formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';
import { getAge } from '../../utils/dates';
import UpdateHeaderAvatar from '../account/updateHead/UpdateHeaderAvatar';
import UpdateHeaderCover from '../account/updateHead/UpdateHeaderCover';

interface UserProfileHeaderProps {
  user: User;
  isEdit?: boolean;
}
const UserProfileHeader: React.FC<UserProfileHeaderProps> = ({ user, isEdit = false }) => {
  const [isLessThan1000] = useMediaQuery('(max-width: 1000px)');
  const [isLessThan768] = useMediaQuery('(max-width: 952px)');
  return (
    <UserCover height={'173px'} media={user.mediaCover} gradiant={'linear(90deg, rgba(43, 44, 44, 0.9) 40%, rgba(43, 44, 44, 0) 100%)'}>
      <Flex position="relative" height="100%" alignItems="center" ml={4} justifyContent="space-between">
        <Box py="4" my={4} mr={10}>
          <Flex align="left" alignItems="center">
            {isEdit ? <UpdateHeaderAvatar user={user} /> : <UserAvatarProfile withBorder username={user.username} media={user.media} styles={{ size: isLessThan1000 ? 'lg' : 'xl' }} />}

            <Box color={customColors.whiteWalter} ml={4}>
              <Text color="greyChupacabra" fontSize="11px">
                Inscrit depuis {formatDistanceToNow(new Date(user.inscriptionDate), { addSuffix: false, locale: fr }).replace('environ ', '').replace('plus de', '')}
              </Text>
              <Text fontSize={'21px'} fontWeight="bold">
                {user.username}
              </Text>
              <Text fontWeight="bold" pb={1}>
                {getAge(user.bornDate)} ans
              </Text>
              {user.location.city && <Text pb={1}>{user.location.city}</Text>}
              <Text>{user.location.country}</Text>
            </Box>
          </Flex>
        </Box>
        <Box mr={4}>
          {!isLessThan768 && (
            <>
              {isEdit && (
                <Box color={customColors.whiteWalter}>
                  <UpdateHeaderCover user={user} />
                </Box>
              )}
            </>
          )}
        </Box>
      </Flex>
    </UserCover>
  );
};

export default UserProfileHeader;
