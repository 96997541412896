import React from 'react';
import { User } from '../types/user';
import { Text, TextProps } from '@chakra-ui/react';

interface UsernameFormattedProps {
  user: User;
  styles?: TextProps;
}

const UsernameFormatted: React.FC<UsernameFormattedProps> = ({ user, styles }) => {
  const getColorByRole = () => {
    if (user.roles.includes('ROLE_ADMIN')) return 'red.500';
    if (user.roles.includes('ROLE_MODERATOR')) return 'green.500';
    if (user.roles.includes('ROLE_STREAM')) return 'yellow.500';
  };

  return (
    <Text color={getColorByRole()} {...styles}>
      {user.username}
    </Text>
  );
};

export default UsernameFormatted;
