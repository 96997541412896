import React, { ReactNode } from 'react';
import { useAppContext } from '../context/AppContext';
import { Box, Spinner } from '@chakra-ui/react';
import { customColors } from '../theme';

interface PublicRouteProps {
  children: ReactNode;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ children }) => {
  const { isAppLoading } = useAppContext();

  if (isAppLoading) {
    return (
      <Box w="100vw" bgColor={customColors.blackTegrite} h="100vh" display="flex" alignItems="center" justifyContent="center">
        <Spinner size="xl" color={customColors.yellowBob} />
      </Box>
    );
  }

  return <>{children}</>;
};

export default PublicRoute;
