import React from 'react';
import { SimpleGrid, Text, useBreakpointValue, useMediaQuery } from '@chakra-ui/react';

import PageLayout from '../../../layout/PageLayout';

import UserWithCoverCard from '../../../components/UserWithCoverCard';
import { useLastConnectedMessageMembers } from '../../../hooks/users/useLastConnectedMessageMembers';

const MessageEmpty: React.FC = () => {
  const { data, isLoading } = useLastConnectedMessageMembers();
  const [isLessThan1050] = useMediaQuery('(max-width: 1050px)');
  const isMobileView = useBreakpointValue({ base: true, md: false });

  return (
    <PageLayout isLoading={isLoading}>
      <Text as="h1" mt={isMobileView ? 0 : 20} mb={4}>
        Pas encore de messages
      </Text>
      <Text mb={4}>
        Hey, il semble que ton feed de discussions soit aussi vide qu'une salle de serveurs un vendredi soir ! Pas de souci, voici quelques super geeks avec qui tu pourrais débattre de la dernière
        technologie ou de l'épisode le plus récent de ta série préférée. Allez, plonge dans le multivers des discussions !
      </Text>
      <SimpleGrid columns={isLessThan1050 ? [1] : [1, 2]} spacingX={5} spacingY={4} mb={isLessThan1050 ? 6 : 0}>
        {data && data.slice(0, 8).map(user => <UserWithCoverCard user={user} key={user.id} />)}
      </SimpleGrid>
    </PageLayout>
  );
};

export default MessageEmpty;
