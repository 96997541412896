import { useQuery, UseQueryResult } from 'react-query';
import { User } from '../../types/user';
import api from '../../utils/api/api';
import { API_USERS_ME_URI } from '../../utils/api/constants';

export const fetchCurrentUser = async (): Promise<User> => {
  const response = await api.get(API_USERS_ME_URI);
  return response.data;
};

export const useFetchCurrentUser = (): UseQueryResult<User, unknown> => {
  return useQuery('user', fetchCurrentUser, {
    retry: 0,
  });
};

export const useReFetchCurrentUser = (): UseQueryResult<User, unknown> => {
  return useQuery('user', fetchCurrentUser, {
    retry: 0,
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  });
};
