import React from 'react';
import PageTitleUpdater from '../../../../routing/PageTitleUpdater';
import { useParams } from 'react-router-dom';
import { useDiscordRoleById } from '../../../../hooks/discord/useDiscordRoleById';
import PageLayout from '../../../../layout/PageLayout';
import DiscordRoleEditForm from './DiscordRoleEditForm';

type ParamTypes = {
  id: string;
};

const DiscordRoleEdit: React.FC = () => {
  const { id } = useParams<ParamTypes>();
  const { data, isLoading, isError, error } = useDiscordRoleById(id || '');

  return (
    <>
      <PageTitleUpdater additionalTitle="Admin Panel - Ajout role discord" />
      <PageLayout isLoading={isLoading} isError={isError} error={error}>
        {data && <DiscordRoleEditForm discordRole={data} />}
      </PageLayout>
    </>
  );
};

export default DiscordRoleEdit;
