import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Divider, Flex, Grid, Text, useBreakpointValue } from '@chakra-ui/react';
import { ContactFormSchema } from './yup/contacts';
import FormInput from '../../../../components/forms/FormInput';
import { useSendAuthContactMessage } from '../../../../hooks/users/useSendContactMessage';
import FormTextareaSimple from '../../../../components/forms/FormTextareaSimple';
import { UserPublic } from '../../../../types/user';
import UserAvatar from '../../../../components/UserAvatar';
import { customColors } from '../../../../theme';
import { useNavigate } from 'react-router-dom';
import { MESSAGES_CONVERSATIONS } from '../../../../routing/constants';
import { toast } from 'react-toastify';

interface ContactFormData {
  subject: string;
  message: string;
}

interface ContactFormProps {
  staff: UserPublic[];
}

export const ContactForm: React.FC<ContactFormProps> = ({ staff }) => {
  const isMobileView = useBreakpointValue({ base: true, md: false });
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm<ContactFormData>({
    resolver: yupResolver(ContactFormSchema),
    mode: 'onBlur',
  });

  const sendMessage = useSendAuthContactMessage();

  const onSubmit: SubmitHandler<ContactFormData> = async data => {
    try {
      await sendMessage.mutateAsync(data);
      reset();
      toast.success(`Votre message a bien été envoyé, nous vous répondrons dans les plus brefs délais.`, {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (error: any) {
      toast.error(`Erreur d'envoi : ${error.response.data.message}`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const gridTemplateColumns = isMobileView ? '1fr' : ' 1fr 2fr';

  return (
    <Box>
      <Grid templateColumns={gridTemplateColumns} mt={!isMobileView ? '150px' : '0'}>
        <Box>
          <Text as="h1">Le staff</Text>
          <Text my={4}>N'hésite pas à contacter un membre de l'équipe</Text>
          {staff.map((user, index) => (
            <Box cursor={'pointer'} key={user.id} mr={4} onClick={() => navigate(`${MESSAGES_CONVERSATIONS}?username=${user.username}`)}>
              <Flex align="center">
                <UserAvatar withBorder user={user} styles={{ size: 'md' }} />
                <Box w="100%" ml={2}>
                  <Flex align="center" justifyContent="space-between">
                    <Text fontWeight="bold">{user.username}</Text>
                  </Flex>
                  {user.isOnline ? <Text color={customColors.greenToxic}>En Ligne</Text> : <Text color={customColors.redLanterne}>Hors Ligne</Text>}
                </Box>
              </Flex>
              {index !== staff.length - 1 && <Divider my={4} />}
            </Box>
          ))}
        </Box>
        <Box mt={isMobileView ? 6 : '0'}>
          <Text as="h1">Ou envoi un message</Text>
          <Box as={'form'} onSubmit={handleSubmit(onSubmit)} mt={!isMobileView ? 10 : 4}>
            <Flex direction="column" minH="200px">
              <Flex align="center" my={4}>
                <FormInput variant="whiteBorder" type="text" placeholder="Sujet" name="subject" required register={register} errors={errors} />
              </Flex>

              <Flex align="center" my={4}>
                <FormTextareaSimple variant="whiteBorder" placeholder="Votre message" name="message" required errors={errors} register={register} getValues={getValues} setValue={setValue} />
              </Flex>
            </Flex>

            <Flex flexDir="row" justifyContent="flex-end">
              <Button ml={4} variant="navYellow" type="submit" isLoading={sendMessage.isLoading} isDisabled={!isValid}>
                Envoyer le message
              </Button>
            </Flex>
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};

export default ContactForm;
