import React from 'react';
import { Box, Button, Divider, Flex, FormLabel, SimpleGrid, Text, Textarea } from '@chakra-ui/react';
import PageTitleUpdater from '../../../../routing/PageTitleUpdater';
import { useNavigate } from 'react-router-dom';
import { useDrawContest } from '../../../../hooks/contests/useDrawContest';
import { Contest } from '../../../../types/Contests';
import { toast } from 'react-toastify';
import { ADMIN_CONTESTS_LIST_PATH } from '../../../../routing/constants';
import { S3_URL } from '../../../../utils/constants';
import { customColors } from '../../../../theme';

interface ContestDrawScriptProps {
  contest: Contest;
}
const ContestDrawScript: React.FC<ContestDrawScriptProps> = ({ contest }) => {
  const mutation = useDrawContest();
  const navigate = useNavigate();
  const [hideUsername, setHideUsername] = React.useState(true);
  const [winners, setWinners] = React.useState<string[]>([]);
  const [text, setText] = React.useState<string>('');

  const onSubmit = async () => {
    await mutation.mutate({ id: contest.id, winners, text });
    try {
      toast.success('Choix des gagnants enregistrés avec succès', {
        position: toast.POSITION.TOP_CENTER,
      });
      navigate(ADMIN_CONTESTS_LIST_PATH);
    } catch (e) {
      toast.error('Une erreur est survenue', {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const toggleWinner = (userId: string) => {
    setWinners(prevWinners => {
      if (prevWinners.includes(userId)) {
        return prevWinners.filter(winner => winner !== userId);
      } else {
        return [...prevWinners, userId];
      }
    });
  };

  const handleTextChange = (e: any) => {
    const newValue = e.target.value;
    setText(newValue);
  };

  return (
    <Box>
      <PageTitleUpdater additionalTitle="Admin Panel - Concours - Tirage au sort" />
      <Box>
        <Flex alignItems={'center'} verticalAlign="center" justifyContent={'space-between'}>
          <Text as={'h3'} fontWeight="bold" mb={2}>
            Participants
          </Text>
          <Box>
            <Button variant="navYellow" onClick={() => setHideUsername(prev => !prev)}>
              {hideUsername ? 'Afficher les noms' : 'Masquer les noms'}
            </Button>
          </Box>
        </Flex>
        <Divider my={2} />
        <SimpleGrid columns={[1, 2, 3]} spacing={5}>
          {contest.mediaSend.map(participant => (
            <Box key={participant.userId} textAlign="center">
              <Text as={'h1'} hidden={hideUsername}>
                {contest.participants.find(part => part.id === participant.userId)?.username}
              </Text>
              <Box as={'a'} href={`${S3_URL}/contests/${contest.slug}/${participant.media}`} target="_blank" rel="noopener noreferrer">
                <Text>{participant.title}</Text>
              </Box>
              <Button my={3} onClick={() => toggleWinner(participant.userId)} colorScheme={winners.includes(participant.userId) ? 'red' : 'green'}>
                {winners.includes(participant.userId) ? 'Retirer des gagnants' : 'Ajouter aux gagnants'}
              </Button>
            </Box>
          ))}
        </SimpleGrid>

        <Box>
          <FormLabel>Texte d'explication des gagnants</FormLabel>
          <Textarea
            sx={{ backgroundColor: customColors.blackTegrite, color: 'white', borderColor: 'white', border: '1px solid', '::placeholder': { color: customColors.greyChupacabra } }}
            height={'auto'}
            variant={'whiteBorder'}
            maxLength={500}
            onChange={handleTextChange}
          />
        </Box>

        <Flex flexDir="row" justifyContent="flex-end" mt={4}>
          <Button variant="navYellow" onClick={onSubmit}>
            Valider les gagnants
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};

export default ContestDrawScript;
