import React from 'react';
import { Box, Button, Flex, Image, Text, useBreakpointValue } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { actionButtonSets, actionButtonTextSets, textSets } from './constants';
import { customColors } from '../../../../theme';
import { INSCRIPTION_PATH } from '../../../../routing/constants';
import { useNavigate } from 'react-router-dom';

const MotionBox = motion(Box);
interface ActionButtonProps {
  currentIndex: number;
}

const ActionButton: React.FC<ActionButtonProps> = ({ currentIndex }) => {
  const navigate = useNavigate();
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <AnimatePresence>
      <Flex justifyContent="center" style={{ flexWrap: 'wrap', width: '100%' }}>
        <MotionBox
          key={`${textSets[currentIndex].length}-${currentIndex}`} // Clé unique combinant mot et indice de cycle
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -30 }} // Animation de sortie accélérée
          transition={{ type: 'spring', damping: 5, stiffness: 100, delay: 0.1 * textSets[currentIndex].length, duration: 0.4, exit: { dumpint: 20, duration: 0.1 } }}
          style={{
            marginRight: '10px',
            marginBottom: '15px',
            fontSize: '13px',
            lineHeight: '21px',
            fontFamily: 'Montserrat',
            color: 'white',
          }}
        >
          <Flex direction="column">
            <Button
              onClick={() => navigate(INSCRIPTION_PATH)}
              variant="navYellowTotal"
              style={{
                background: customColors.yellowBob,
                color: customColors.blackTegrite,
                width: isMobile ? '100%' : '400px',
                height: '51px',
                fontWeight: '700',
                fontSize: '24px',
                marginTop: '10px',
              }}
            >
              {actionButtonSets[currentIndex]}
            </Button>
            <Flex direction="row" mt={4} justifyContent="center">
              {currentIndex === 0 && <Image src={'/assets/homeNoAuth/pilule.svg'} alt="Geek" />}
              <Text mt={1} ml={currentIndex === 0 ? 2 : 0}>
                {actionButtonTextSets[currentIndex]}
              </Text>
            </Flex>
          </Flex>
        </MotionBox>
      </Flex>
    </AnimatePresence>
  );
};

export default React.memo(ActionButton);
