import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Profile from './Profile';
import { HOME_PATH } from '../../routing/constants';
import { useSocketContext } from '../../context/SocketContext';
import { useAppContext } from '../../context/AppContext';

type ParamTypes = {
  username: string;
};

const UserProfile: React.FC = () => {
  const { user } = useAppContext();
  const { username } = useParams<ParamTypes>();
  const navigate = useNavigate();
  const { recordVisit } = useSocketContext();

  if (!username) {
    navigate(HOME_PATH);
    return null;
  }

  useEffect(() => {
    if (user && user.username !== username) {
      recordVisit(username);
    }
  }, [username]);

  return <Profile usernameParam={username} />;
};

export default UserProfile;
