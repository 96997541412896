import React, { useEffect, useState } from 'react';
import { Button, Flex, Image, SimpleGrid, Text, useMediaQuery } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';
import { useUserVisits } from '../../hooks/visits/useUserVisits';
import { useSocketContext } from '../../context/SocketContext';
import PageLayout from '../../layout/PageLayout';
import { formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';
import { QueryContestParams } from '../contest/constantes';
import PageTitleUpdater from '../../routing/PageTitleUpdater';
import UserWithCoverCard from '../../components/UserWithCoverCard';
import Ariane from '../../components/Ariane';
import VisitesEmpty from './VisitesEmpty';

const Visites: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const skip = searchParams.get('skip') ? parseInt(searchParams.get('skip')!) : 0;
  const limit = 8;
  const [queryParams, setQueryParams] = useState<QueryContestParams>({
    skip,
    limit,
  });

  const [isLessThan1050] = useMediaQuery('(max-width: 1050px)');

  const { data: data, isLoading, refetch } = useUserVisits(queryParams);
  const { updateVisitsRead } = useSocketContext();

  const hasPrevious = queryParams.skip > 0;
  const hasNext = queryParams.skip + queryParams.limit < (data?.total || 0);

  useEffect(() => {
    const newQueryParams = {
      skip: searchParams.get('skip') ? parseInt(searchParams.get('skip') as string) : 0,
      limit,
    };
    if (JSON.stringify(newQueryParams) !== JSON.stringify(queryParams)) {
      setQueryParams(newQueryParams);
      refetch();
    }
  }, [searchParams, queryParams, refetch]);

  useEffect(() => {
    updateVisitsRead();
  }, []);

  const updateSkip = (skip: number) => {
    const newSearchParams = new URLSearchParams();
    newSearchParams.set('skip', skip.toString());
    setSearchParams(newSearchParams);
  };

  return (
    <PageLayout isLoading={isLoading}>
      <PageTitleUpdater additionalTitle={`Visites`} />
      <Ariane>
        <Flex alignItems="center">
          <Image src="/assets/icons/green-2.svg" alt="Membres" width="30px" />
          <Text as="h1" textTransform="uppercase" fontSize="19px" ml={4}>
            Vos visites
          </Text>
        </Flex>
      </Ariane>
      {data && data.total !== 0 && (
        <>
          <Flex alignItems="center" justifyContent="right" mr={2} mb={4} mt={isLessThan1050 ? '0' : '100px'}>
            <Button isDisabled={!hasPrevious} variant="navYellow" onClick={() => updateSkip(queryParams.skip - limit)} mr={2}>
              Précédent
            </Button>
            <Button isDisabled={!hasNext} variant="navYellow" onClick={() => updateSkip(queryParams.skip + limit)} mr={2}>
              Suivant
            </Button>
          </Flex>
          <SimpleGrid columns={isLessThan1050 ? [1] : [1, 2]} spacingX={5} spacingY={4} mb={isLessThan1050 ? 6 : 0}>
            {data.visits?.map(visit => (
              <UserWithCoverCard
                user={visit.visitorId}
                key={visit.visitorId.id}
                overideContent={
                  <Text fontSize="sm" fontWeight="bold">
                    Vu {formatDistanceToNow(new Date(visit.timestamp), { addSuffix: false, locale: fr })}
                  </Text>
                }
              />
            ))}
          </SimpleGrid>
        </>
      )}
      {data && data.total === 0 && <VisitesEmpty />}
    </PageLayout>
  );
};

export default Visites;
