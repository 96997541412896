import React from 'react';
import { User } from '../../types/user';
import { Image, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import UserAvatar from '../../components/UserAvatar';
import { isUrl } from '../../utils/helpers';
import { S3_URL } from '../../utils/constants';

interface AdminTableUserProps {
  user: User;
}

const AdminTableUser: React.FC<AdminTableUserProps> = ({ user }) => {
  console.log(user.mediaCover);
  return (
    <>
      <Table variant="simple">
        {user.mediaCover && (
          <>
            <Thead>
              <Tr>
                <Th>Cover</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>
                  <Image src={isUrl(user.mediaCover) ? user.mediaCover : `${S3_URL}/users/covers/${user.mediaCover}`} />
                </Td>
              </Tr>
            </Tbody>
          </>
        )}
        <Thead>
          <Tr>
            <Th>Avatar</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>
              <UserAvatar user={user} styles={{ size: 'xl' }} />
            </Td>
          </Tr>
        </Tbody>
        <Thead>
          <Tr>
            <Th>Email</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>{user.email}</Td>
          </Tr>
        </Tbody>
      </Table>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Présentation</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>{user.userProfile.presentation}</Td>
          </Tr>
        </Tbody>
      </Table>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>PSN</Th>
            <Th>Steam</Th>
            <Th>Nintendo</Th>
            <Th>Xbox</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>{user.userProfile.gamesId.psn}</Td>
            <Td>{user.userProfile.gamesId.steam}</Td>
            <Td>{user.userProfile.gamesId.nintendo}</Td>
            <Td>{user.userProfile.gamesId.xbox}</Td>
          </Tr>
        </Tbody>
      </Table>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Facebook</Th>
            <Th>Instagram</Th>
            <Th>Youtube</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>{user.userProfile.socialNetworks.facebook}</Td>
            <Td>{user.userProfile.socialNetworks.instagram}</Td>
            <Td>{user.userProfile.socialNetworks.youtube}</Td>
          </Tr>
        </Tbody>
      </Table>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Site</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>{user.userProfile.site}</Td>
          </Tr>
        </Tbody>
      </Table>
    </>
  );
};

export default AdminTableUser;
