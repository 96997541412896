import React from 'react';
import { Box, useBreakpointValue } from '@chakra-ui/react';
import { User } from '../../../types/user';
import AccountInfos from './AccountInfos';
import Password from './Password';
import DeleteAccount from './DeleteAccount';

interface ParameterTabProps {
  user: User;
}

const ParameterTab: React.FC<ParameterTabProps> = ({ user }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box w={'100%'} display="flex" flexDirection="column" mt={-4} h={isMobile ? 'auto' : 'calc(100vh - 500px)'} justifyContent="space-between">
      <Box>
        <AccountInfos user={user} />
        <Password />
        <DeleteAccount />
      </Box>
    </Box>
  );
};

export default ParameterTab;
