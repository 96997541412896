import React, { Dispatch, SetStateAction } from 'react';
import { Box, Button, Input, Text, Image, Flex, useBreakpointValue } from '@chakra-ui/react';
import { Contest } from '../../../../types/Contests';
import { useAppContext } from '../../../../context/AppContext';
import { formatUri } from '../../../../utils/api/helpers';
import { useNavigate } from 'react-router-dom';
import { CONTESTS_DETAILS_PATH } from '../../../../routing/constants';

interface SendMediaProps {
  contest: Contest;
  setSelectedImage: Dispatch<SetStateAction<File | null>>;
  selectedImage: File | null;
}
const SendMedia: React.FC<SendMediaProps> = ({ contest, setSelectedImage, selectedImage }) => {
  const navigate = useNavigate();
  const { user } = useAppContext();
  const isParticipant = contest.participants.some(participant => participant.id === user?.id);
  const isMobile = useBreakpointValue({ base: true, md: false });

  if (isParticipant) {
    navigate(formatUri({ slug: contest.slug }, CONTESTS_DETAILS_PATH));
  }

  // Gestionnaire pour le changement de fichier
  const handleImageChange = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setSelectedImage(file);
    }
  };

  return (
    <Flex justifyContent="center" h="calc(100% - 100px)" direction="column">
      <Box>
        <Text as="h1" fontSize="16px" mb={2} color="yellowBob">
          1. Prépare ton chef d'oeuvre
        </Text>
        <Text fontSize="13px">Assure-toi que ton image soit au format jpg/png.</Text>
        <Text fontSize="13px">Une fois ton image envoyée, ta participation devient définitive, alors soit sûr de ton choix !</Text>
      </Box>
      <Box mt={6} mb={4}>
        <Text as="h1" fontSize="16px" mb={2} color="yellowBob">
          2. Clique sur le bouton "Upload"
        </Text>
        <Text fontSize="13px">Etape final avant de partager ton talent !</Text>
      </Box>
      <Flex justifyContent="center" flexDirection={isMobile ? 'column' : 'row'} alignItems="center" verticalAlign="center" w={'100%'}>
        <Input type="file" accept="image/*" onChange={handleImageChange} hidden id="file-upload" />
        <label htmlFor="file-upload">
          <Button as="span" variant="navGreen" mr={isMobile ? 0 : 10} mb={isMobile ? 4 : 0} cursor="pointer">
            Upload
          </Button>
        </label>
        {selectedImage ? (
          <Box boxSize="sm" maxH={'150px'} display={isMobile ? 'contents' : 'relative'} mt={isMobile ? 4 : 0}>
            <Image src={URL.createObjectURL(selectedImage)} border="1px dashed white" maxH={'150px'} w="auto" alt="Ton image" />
          </Box>
        ) : (
          <Flex alignItems="center" justifyContent="center" textAlign="center" boxSize="sm" h={'150px'} w={'100px'} border="1px dashed white">
            Aperçu
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default SendMedia;
