import { Components } from 'react-markdown';
import { Link, useBreakpointValue } from '@chakra-ui/react';
import MarkdownIt from 'markdown-it';
import React from 'react';
import { CSSObject } from '@emotion/react';
import { customColors, eventsColors } from '../theme';
import { cleanMarkdown } from './removeMarkdown';

export const blockedChannelId = ['1183860634253791362', '1183860381664419860', '1183860447531765870'];

export const renderersMarkdown: Components = {
  a: ({ href, children }) => (
    <Link href={href as string} isExternal textDecoration="underline">
      {children}
    </Link>
  ),
};

export const isFirefox = () => {
  return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
};

export const removeAndExtractPrefix = (message: string) => {
  const regex = /\*\*Message créé sur Entre-Geeks par (.*?)\*\*\n/;
  const matched = message.match(regex);

  if (matched && matched[1]) {
    return {
      cleanMessage: message.replace(regex, ''),
      originalAuthor: matched[1],
    };
  }
  return { cleanMessage: message, originalAuthor: null };
};

export const customScrollBar: CSSObject = {
  // Styles for WebKit-based browsers
  '::-webkit-scrollbar': {
    width: '6px',
    backgroundColor: '#F5F5F5',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: '#888888',
    borderRadius: '4px',
  },
  '::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#555555',
  },
  // Styles for Firefox
  scrollbarWidth: 'thin',
  scrollbarColor: '#888888 #F5F5F5',
};

export function formatDuration(durationInMilliseconds: any) {
  const seconds = Math.floor(durationInMilliseconds / 1000);
  if (seconds < 60) return `${seconds}s`;
  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) return `${minutes}m`;
  const hours = Math.floor(minutes / 60);
  if (hours < 24) return `${hours}h`;
  const days = Math.floor(hours / 24);
  return `${days}j`;
}

export const useHideOnMobile = () => {
  return useBreakpointValue({ base: true, sm: true, md: false, lg: false, xl: false });
};

export function isUrl(str: string): boolean {
  try {
    const url = new URL(str);
    return ['http:', 'https:'].includes(url.protocol);
  } catch (e) {
    return false;
  }
}

export const getTypeString = (type: number, singular?: boolean) => {
  if (type === 1) return singular ? 'stream' : 'streams';
  if (type === 2) return singular ? 'game' : 'games';

  return singular ? 'sortie' : 'sorties';
};

export const getContestTypeString = (type: string) => {
  if (type === '1') return 'Loterie';
  if (type === '2') return 'Scribouillage';

  return 'Gribouillage';
};

export const getContestContestColor = (type: string) => {
  if (type === '1') return customColors.blueTardis;
  if (type === '2') return customColors.purpleThanos;

  return customColors.orangeMecanique;
};

export const getTypeEventColor = (type: number) => {
  if (type === 1) return eventsColors.stream;
  if (type === 2) return eventsColors.game;

  return eventsColors.outing;
};

export const removeEventPrefix = (name: string) => {
  return name.replace(/^\[(Game|Sortie|Stream)\]\s*/, '');
};

export function removeMarkdown(markdownText: string): string {
  const md = new MarkdownIt();
  const htmlText = md.render(markdownText);
  return htmlText.replace(/<\/?[^>]+(>|$)/g, '');
}

export const defaultImage = '/assets/default.png';
export const defaultImageLotterie = '/assets/buttons/lotterie-1.png';
export const defaultCoverImage = '/assets/defaultCover.png';

export function removeQueryParam(param: string): void {
  // Construire un nouvel objet URL à partir de l'URL actuelle (comme chaîne)
  const url = new URL(window.location.href);

  // Utiliser URLSearchParams pour travailler avec la chaîne de requête
  url.searchParams.delete(param);

  // Mettre à jour l'URL dans la barre d'adresse sans recharger la page
  window.history.pushState({}, '', url.toString());
}

export function extractRelevantPart(displayName: string) {
  const parts = displayName.split(',');
  if (parts.length >= 2) {
    // Récupérer les deux premières parties
    return parts[0].trim() + ', ' + parts[1].trim();
  }
  return displayName; // Retourne le displayName complet si moins de 2 parties
}

export function capitalizeFirstLetter(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function extractSocialMediaId(url: string) {
  // Regex pour extraire l'ID du profil social. Elle cherche les caractères après le dernier '/' et ignore les éventuels '/' de fin
  const regex = /\/([^/]+)\/?$/;
  const matches = url.match(regex);

  if (matches && matches[1]) {
    return matches[1];
  }

  return url;
}

export function formatTextWithLineBreaks(text: string) {
  if (!text) {
    return text;
  }
  return cleanMarkdown(text)
    .split('\n')
    .map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
}

export const smoothScrollToTop = (element: HTMLElement, duration: number) => {
  const totalScrollDistance = element.scrollTop;
  let scrollY = totalScrollDistance;
  let oldTimestamp: number | null = null;

  const step = (newTimestamp: number) => {
    if (oldTimestamp !== null) {
      // Calculez combien de temps s'est écoulé depuis le dernier appel
      const delta = newTimestamp - oldTimestamp;

      // Ajustez la position de défilement en fonction de la durée prévue
      const stepScroll = (totalScrollDistance * delta) / duration;
      scrollY = Math.max(0, scrollY - stepScroll);

      element.scrollTop = scrollY;
    }
    oldTimestamp = newTimestamp;

    if (scrollY > 0) {
      window.requestAnimationFrame(step);
    }
  };
  window.requestAnimationFrame(step);
};

export function formatDateLineBreak(dateStr: string) {
  // Séparer les nombres du reste du texte
  const numbers = dateStr.match(/\d+/g)?.join(' - ');
  const text = dateStr.replace(/\d+/g, '').replace(' - ', '').trim();

  return (
    <div>
      <div style={{ display: 'block', fontWeight: 'bold' }}>{numbers}</div>
      <div style={{ display: 'block' }}>{text}</div>
    </div>
  );
}

export const renderLinesDate = (text: string) => {
  if (!text) {
    return null; // Retourne null si le texte n'est pas défini
  }

  const lines = text.split('\n').map((line, index) => <div key={index}>{line}</div>);

  return <>{lines}</>;
};

/**
 * Génère un nombre aléatoire entre min (inclus) et max (exclus)
 * @param {number} min - Valeur minimale
 * @param {number} max - Valeur maximale
 * @return {number} Nombre aléatoire généré
 */
export function getRandomNumber(min: number, max: number) {
  return Math.floor(Math.random() * (max - min)) + min;
}

export const base64ToFile = (base64String: string, filename: string) => {
  // Split the base64 string into parts
  const parts = base64String.split(';base64,');
  const imageType = parts[0].split(':')[1];
  const decodedData = window.atob(parts[1]);
  const uInt8Array = new Uint8Array(decodedData.length);

  // Convert the base64 string to a byte array
  for (let i = 0; i < decodedData.length; ++i) {
    uInt8Array[i] = decodedData.charCodeAt(i);
  }

  // Create a Blob from the byte array
  const blob = new Blob([uInt8Array], { type: imageType });

  // Return a File object
  return new File([blob], filename, { type: imageType });
};

export const convertToBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = error => {
      reject(error);
    };
    fileReader.readAsDataURL(file);
  });
};
