import { useMutation, useQueryClient, UseMutationResult } from 'react-query';
import api from '../../utils/api/api';
import { API_EVENTS_CREATE } from '../../utils/api/constants';
import { Event } from '../../types/Events';
import { EventCreateForm } from '../../pages/events/create/constants';

export const useCreateEvent = (): UseMutationResult<Event, Error, EventCreateForm, unknown> => {
  const queryClient = useQueryClient();

  return useMutation<Event, Error, EventCreateForm>(
    (newEvent: EventCreateForm) => {
      return api
        .post(API_EVENTS_CREATE, newEvent, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res => res.data);
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries('event');
        queryClient.refetchQueries('events');
      },
    },
  );
};
