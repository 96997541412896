import React, { Dispatch, SetStateAction } from 'react';
import { Box, Button, Input, Text, Flex, Link, useMediaQuery } from '@chakra-ui/react';
import { Contest } from '../../../../types/Contests';
import { useAppContext } from '../../../../context/AppContext';
import { formatUri } from '../../../../utils/api/helpers';
import { useNavigate } from 'react-router-dom';
import { CONTESTS_DETAILS_PATH } from '../../../../routing/constants';

interface SendPDFProps {
  contest: Contest;
  selectedPDF: File | null;
  setTitle: Dispatch<SetStateAction<string | null>>;
  setSelectedPDF: Dispatch<SetStateAction<File | null>>;
}
const SendPDF: React.FC<SendPDFProps> = ({ contest, setSelectedPDF, setTitle, selectedPDF }) => {
  const navigate = useNavigate();
  const { user } = useAppContext();
  const isParticipant = contest.participants.some(participant => participant.id === user?.id);
  const [isLessThan1020] = useMediaQuery('(max-width: 1100px)');

  if (isParticipant) {
    navigate(formatUri({ slug: contest.slug }, CONTESTS_DETAILS_PATH));
  }

  const handlePDFChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setSelectedPDF(file);
    }
  };

  return (
    <Flex justifyContent="center" h={isLessThan1020 ? 'auto' : 'calc(100% - 100px)'} direction="column">
      <Box>
        <Text as="h1" fontSize="16px" mb={2} color="yellowBob">
          1. Prépare ton chef d'oeuvre
        </Text>
        <Text fontSize="13px">Assure-toi que ton fichier soit au format PDF.</Text>
        <Text fontSize="13px">Une fois ton fichier envoyé, ta participation devient définitive, alors soit sûr de ton choix !</Text>
      </Box>
      <Box mt={6} mb={4}>
        <Text as="h1" fontSize="16px" mb={2} color="yellowBob">
          2. Rentre ton titre et clique sur le bouton "Upload"
        </Text>
        <Text fontSize="13px">Etape final avant de partager ton talent !</Text>
      </Box>
      <Flex justifyContent="center" alignItems="center" verticalAlign="center" w={'100%'}>
        <Input mb={4} type="text" placeholder="Ton titre" variant="whiteBorder" w={isLessThan1020 ? 'auto' : '350px'} onChange={e => setTitle(e.target.value)} />
      </Flex>
      <Flex justifyContent="center" alignItems="center" verticalAlign="center" w={'100%'}>
        <Input type="file" accept=".pdf" onChange={handlePDFChange} hidden id="pdf-upload" />
        <label htmlFor="pdf-upload">
          <Button as="span" variant="navGreen" mr={10} cursor="pointer">
            Upload
          </Button>
        </label>
        {selectedPDF ? (
          <Flex
            alignItems="center"
            justifyContent="center" // Ajouté pour l'alignement horizontal
            textAlign="center"
            boxSize="sm"
            h={'100px'}
            w={'100px'}
            border="1px dashed white"
          >
            <Link href={URL.createObjectURL(selectedPDF)} isExternal>
              Voir le PDF
            </Link>
          </Flex>
        ) : (
          <Flex
            alignItems="center"
            justifyContent="center" // Ajouté pour l'alignement horizontal
            textAlign="center"
            boxSize="sm"
            h={'100px'}
            w={'100px'}
            border="1px dashed white"
          >
            Votre PDF
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default SendPDF;
