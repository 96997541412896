import { Global, css } from '@emotion/react';
import React from 'react';

const GlobalStyles: React.FC = () => {
  return (
    <Global
      styles={css`
        body {
          font-size: 16px; /* Taille par défaut pour les petits écrans */
          background-color: #ffcd29;

          @media (min-width: 1999px) {
            font-size: 16px; /* Taille pour les très grands écrans */
          }

          @media (min-width: 2000px) {
            font-size: 18px; /* Taille pour les très grands écrans */
          }
        }
      `}
    />
  );
};

export default GlobalStyles;
