import { UseQueryResult, useQuery } from 'react-query';
import api from '../../utils/api/api';
import { API_EVENTS_STATS } from '../../utils/api/constants';

interface Response {
  outingCreation: number;
  outingParticipant: number;
  gameCreation: number;
  gameParticipant: number;
}

export const useUserStats = (userId: string): UseQueryResult<Response, unknown> => {
  return useQuery<Response>(
    ['userStats', userId],
    async () => {
      const { data } = await api.get(`${API_EVENTS_STATS}/${userId}`);
      return data;
    },
    { retry: 0 },
  );
};
