import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { subTextSets, textSets } from './constants';

const MotionBox = motion(Box);
interface SubTextProps {
  currentIndex: number;
}

const SubTitle: React.FC<SubTextProps> = ({ currentIndex }) => {
  return (
    <AnimatePresence>
      <Flex justifyContent="flex-start" style={{ flexWrap: 'wrap', width: '100%' }}>
        {subTextSets[currentIndex].map((word, wordIndex) => (
          <MotionBox
            key={`${word}-${currentIndex}`} // Clé unique combinant mot et indice de cycle
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -30 }}
            transition={{ delay: 0.1 * (textSets[currentIndex].length + wordIndex), duration: 0.4, exit: { delay: 0, duration: 0.1 } }}
            style={{
              marginRight: '10px',
              marginBottom: '15px',
              fontSize: '13px',
              lineHeight: '21px',
              fontFamily: 'Montserrat',
              color: 'white',
            }}
          >
            {word}
          </MotionBox>
        ))}
      </Flex>
    </AnimatePresence>
  );
};

export default React.memo(SubTitle);
