import React, { useEffect, useState } from 'react';
import { Button, Flex, Text, Box, Image, Grid, GridItem, useBreakpointValue } from '@chakra-ui/react';
import { useNews } from '../../../hooks/news/useNews';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { formatUri } from '../../../utils/api/helpers';
import { S3_URL } from '../../../utils/constants';
import { formatingDate } from '../../../utils/dates';
import { NEWS_PATH } from '../../../routing/constants';
import PageLayout from '../../../layout/PageLayout';
import Ariane from '../../../components/Ariane';
import { NewsFilters, QueryNewsParams } from './constants';
import NewsFilter from './NewsFilter';
import { useLatestNews } from '../../../hooks/news/useLatestNews';
import PageTitleUpdater from '../../../routing/PageTitleUpdater';
import { removeMarkdown } from '../../../utils/helpers';
import ImageCard from '../../../components/card/ImageCard';
import UserCardStringAvatar from '../../../components/UserCardStringAvatar';

const NewsList: React.FC = () => {
  const { data: lastNews } = useLatestNews();
  const isMobileView = useBreakpointValue({ base: true, lg: false });
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const skip = searchParams.get('skip') ? parseInt(searchParams.get('skip')!) : 0;
  const title = searchParams.get('title') || '';
  const [queryParams, setQueryParams] = useState<QueryNewsParams>({
    skip: title ? 0 : skip,
    limit: 4,
    title,
  });
  const [total, setTotal] = useState<number>(0);
  const limit = 4;
  const { data, isLoading, refetch } = useNews(queryParams);

  const hasPrevious = queryParams.skip > 1;
  const hasNext = queryParams.skip + limit < total;

  useEffect(() => {
    const newSkip = parseInt(searchParams.get('skip') || '0');
    const newTitle = searchParams.get('title') || '';

    if (newSkip !== queryParams.skip || newTitle !== queryParams.title) {
      setQueryParams(prev => ({ ...prev, skip: newSkip, title: newTitle }));
      refetch();
    }
  }, [searchParams, queryParams.skip, queryParams.title, refetch]);

  useEffect(() => {
    if (data && data.total !== total) {
      setTotal(data.total);
    }
  }, [data]);

  const onSubmit = (data: NewsFilters) => {
    const newSearchParams = new URLSearchParams();
    newSearchParams.set('title', data.title || '');
    newSearchParams.set('skip', '0');
    setSearchParams(newSearchParams);
  };

  const updateSkip = (skip: number) => {
    const newSearchParams = new URLSearchParams();
    newSearchParams.set('skip', skip.toString());
    if (queryParams.title) newSearchParams.set('title', queryParams.title);
    setSearchParams(newSearchParams);
  };

  const getTruncateText = (text: string, truncat: number) => {
    const formattedText = removeMarkdown(text);
    const isTruncated = formattedText.length > truncat;
    return isTruncated ? formattedText.slice(0, truncat) + '...' : formattedText;
  };

  const getTruncateTitle = (text: string, truncat: number) => {
    const isTruncated = text.length > truncat;
    return isTruncated ? text.slice(0, truncat) + '...' : text;
  };

  return (
    <Box>
      <PageTitleUpdater additionalTitle={`Actualités`} />
      <Ariane>
        <Flex alignItems="center" justifyContent="space-between" w="100%">
          <Flex alignItems="center">
            <Image src="/assets/icons/purple.svg" alt="NEWS" width="30px" />
            <Text as="h1" textTransform="uppercase" fontSize="19px" ml={4}>
              Actualités
            </Text>
          </Flex>
          {!isMobileView && <NewsFilter onSubmit={onSubmit} />}
        </Flex>
      </Ariane>
      <Grid templateColumns={isMobileView ? 'repeat(1, 1fr)' : '2fr 3fr'} gap={7} h={isMobileView ? 'auto' : 'calc(100vh - 250px)'} maxH={isMobileView ? '100%' : '707px'}>
        <GridItem w="100%">
          <Flex
            direction="column" // Ajouté pour une disposition verticale
            justifyContent="center" // Alignement vertical au centre
            h="100%" // Hauteur complète
          >
            {lastNews && (
              <Box position="relative" onClick={() => navigate(formatUri({ slug: lastNews.slug }, NEWS_PATH))} cursor="pointer">
                <Text as="h1" fontSize="54px" fontWeight="bold" textTransform="uppercase" color="yellowBob" lineHeight={1}>
                  {lastNews.title}
                </Text>
                <Text mt={4} fontSize="11px" color="greyChupacabra">
                  {formatingDate(lastNews.createdAt)}
                </Text>
                <Text mt={2} fontSize="13px" lineHeight={'21px'}>
                  {getTruncateText(lastNews.text, 400)}
                </Text>
              </Box>
            )}
          </Flex>
        </GridItem>
        <GridItem w="100%" mt={isMobileView ? 4 : 16}>
          <Flex direction="column">
            {isMobileView && <NewsFilter onSubmit={onSubmit} />}
            {/* Conteneur pour les boutons, toujours en haut */}
            <Flex mt={isMobileView ? 4 : 0} w="100%" alignItems="center" justifyContent="flex-end" mr={2} mb={4}>
              {/* Étendez le Flex pour aligner les éléments à droite */}
              <Button isDisabled={!hasPrevious} variant="navYellow" onClick={() => updateSkip(queryParams.skip - limit)} mr={2}>
                Précédent
              </Button>
              <Button isDisabled={!hasNext} variant="navYellow" onClick={() => updateSkip(queryParams.skip + limit)} mr={2}>
                Suivant
              </Button>
              {total > 0 && (
                <Text>
                  {limit + skip} / {total} Actu{total > 1 ? 's' : ''}
                </Text>
              )}
            </Flex>
            <Box flex="1" overflow="auto">
              <PageLayout isLoading={isLoading}>
                {data && data.total === 0 && (
                  <Grid templateColumns="repeat(1, 1fr)" alignContent="space-around" gap={7} mt={2} w="100%" h={isMobileView ? 'auto' : 'calc(100vh - 375px)'}>
                    <Text textAlign="center" w="100%">
                      Aucune actualité trouvée
                    </Text>
                  </Grid>
                )}
                <Grid templateColumns={isMobileView ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)'} gap={7} mt={2} w="100%">
                  {data &&
                    data.news.map(news => {
                      return (
                        <Box
                          key={news.id}
                          position="relative"
                          onClick={() => navigate(formatUri({ slug: news.slug }, NEWS_PATH))}
                          cursor="pointer"
                          mb={7}
                          _hover={{ transform: 'translateY(-4px)', boxShadow: 'lg' }}
                          transition="transform 0.2s, box-shadow 0.2s"
                          bgColor="blackTegrite"
                          borderRadius={21}
                        >
                          <Box position="relative" w="100%" h={'120px'} boxShadow="md">
                            <ImageCard image={`${S3_URL}/news/${news.media}`} imageName={news.title} />
                            <Flex direction="column" position="absolute" bottom="0" left="0" w="100%" h="100%">
                              <Flex alignItems="bottom" h="100%">
                                <UserCardStringAvatar color={'blackTegrite'} withBorder username={news.userId.username} media={news.userId.media} styles={{ size: 'sm' }} />
                                <Box position={'absolute'} bottom={'-10.5px'} right={0} zIndex={10}>
                                  <Flex alignItems={'center'} justifyContent={'center'}>
                                    <Box bg="blackTegrite" ml={-1} borderRadius="21px 0 0 0" px={4}>
                                      <Text>{formatingDate(news.createdAt)}</Text>
                                    </Box>
                                  </Flex>
                                </Box>
                              </Flex>
                            </Flex>
                          </Box>
                          <Box mt={7} px={2} mb={2}>
                            <Text fontSize="13px" fontWeight="bold" lineHeight={'21px'} color="yellowBob">
                              {getTruncateTitle(news.title, 100)}
                            </Text>
                            <Text mt={2} fontSize="13px" lineHeight={'21px'}>
                              {getTruncateText(news.text, 100)}
                            </Text>
                          </Box>
                        </Box>
                      );
                    })}
                </Grid>
              </PageLayout>
            </Box>
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default NewsList;
