import React, { useMemo } from 'react';
import { Box, Text } from '@chakra-ui/react';

const randomTexts = ['Omelette du fromage...', "Vers l'infini et au-delà !", 'Mon precieuuuuuux !', 'Je suis ton père !', 'Je suis le roi du monde !', 'Hasta la vista, baby !'];

const RandomText: React.FC = () => {
  const randomElement = useMemo(() => {
    return randomTexts[Math.floor(Math.random() * randomTexts.length)];
  }, []);
  return (
    <Box mt={'-30px'} display={{ base: 'none', md: 'block' }} textAlign="right" mr="15%">
      <Text as="h4">{randomElement}</Text>
    </Box>
  );
};

export default RandomText;
