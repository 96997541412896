/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Image } from '@chakra-ui/react';
import { ContestCreateForm } from './constants';
import { toast } from 'react-toastify';
import { useCreateContest } from '../../../../hooks/contests/useCreateContest';
import { contestSchema } from './yup/contest';
import FormImageUpload from '../../../../components/forms/formImageUpload/FormImageUpload';
import FormInput from '../../../../components/forms/FormInput';
import FormDatePicker from '../../../../components/forms/FormDatePicker';
import { todayAddOneHour } from '../../../events/create/constants';
import { useNavigate } from 'react-router-dom';
import { formatUri } from '../../../../utils/api/helpers';
import { CONTESTS_DETAILS_PATH } from '../../../../routing/constants';
import PageTitleUpdater from '../../../../routing/PageTitleUpdater';
import FormTextareaSimple from '../../../../components/forms/FormTextareaSimple';
import FormSelect from '../../../../components/forms/FormSelect';
import InSavedChangesPrompt from '../../../../components/InSavedChangesPrompt';
import { convertToBase64 } from '../../../../utils/helpers';
import { Contest } from '../../../../types/Contests';

const text = {
  '1': {
    description:
      'Chaque semaine, deux jeux Steam à gagner par tirage au sort, comme trouver un trésor rare dans une quête ! Double tes chances en signalant ta participation ici et booste tes stats de chance en ajoutant un emote sur le post Discord.',
    judgmentCriterion: 'Participe directement sur le site et booste tes chances au niveau supérieur en ajoutant un emote à ce message Discord !',
    participationModalities: `Aucun !
    Seul le hasard entre en jeu, comme choisir la bonne porte dans un donjon.
    Que la force (et un peu de RNG en ta faveur) soit avec toi !
    Puisse le sort t'être favorable, jeune Padawan !`,
  },
  '2': {
    description: `Ecrire une nouvelle dans un temps imparti, en choisissant parmi les trois thèmes de nouvelles proposés. Et si vous n’arrivez pas à choisir, vous pouvez proposer plusieurs nouvelles en fonction des thèmes.`,
    judgmentCriterion: `La synthase est très importante. Nous notons le respect du thème, l’originalité du texte, le nombre de signe et la cohérence du récit. Un classement est établi en fonction des thèmes. Chaque thème a un gagnant. Un podium entre ces trois gagnants est réalisé. La communauté pourra voter pour sa nouvelle préférée sur discord.`,
    participationModalities: `Pour participer, les règles sont simples ! 
• 40 000 signes maximum 
• Attention aux fautes
• Utilisez votre tête plutôt que ChatGPT 
Votre nouvelle devra être déposée sur le site. Suivez les instructions et importez votre texte en PDF. N’oubliez pas de choisir un nom de plume si vous souhaitez signer votre nouvelle. Donnez aussi un titre à votre texte.`,
  },
  '3': {
    description: `Réalisez une œuvre (ou plusieurs si l'envie vous prenait de vouloir répondre à chacun des thèmes), sous la forme d'un dessin, d’un collage ou d'une peinture. Nous vous proposons trois thèmes.`,
    judgmentCriterion: `Le respect du médium, l’originalité, le respect du thème, le style graphique, la lumière, la composition, le cadrage, la technique et la cohérence générale.
Un classement est établi en fonction des thèmes. Chaque thème a un gagnant. Un podium entre ces trois gagnants est réalisé. La communauté pourra voter pour sa nouvelle préférée sur discord.`,
    participationModalities: `Suivez les instructions et déposez votre œuvre sous format Jpeg/Png. 
N’oubliez pas de choisir un nom d’artiste si vous souhaitez signer votre œuvre.`,
  },
};
export const ContestCreate: React.FC = () => {
  const [selectedType, setSelectedType] = React.useState<string | undefined>();
  const [currentAvatar, setCurrentAvatar] = React.useState<string | undefined>();
  const [contestCreated, setContestCreated] = React.useState<Contest | undefined>();
  const mutation = useCreateContest();
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    trigger,
    setValue,
    watch,
    getValues,
    formState: { errors, isValid, isDirty },
  } = useForm<ContestCreateForm>({
    resolver: yupResolver(contestSchema),
  });

  const onSubmit = async (data: ContestCreateForm) => {
    try {
      const contest = await mutation.mutateAsync(data);
      setContestCreated(contest);
      toast.success('Concours créé avec succès', {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (error: any) {
      toast.error(`Échec de la création du concours : ${error.response.data.message}`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  useEffect(() => {
    if (contestCreated) {
      navigate(formatUri({ slug: contestCreated.slug }, CONTESTS_DETAILS_PATH));
    }
  }, [contestCreated]);

  useEffect(() => {
    if (selectedType) {
      setValue('description', (text as any)[selectedType].description);
      setValue('judgmentCriterion', (text as any)[selectedType].judgmentCriterion);
      setValue('participationModalities', (text as any)[selectedType].participationModalities);
    }
  }, [selectedType]);

  const watchedMedia = watch('media');
  useEffect(() => {
    if (watchedMedia && watchedMedia instanceof File) {
      trigger('media');
      convertToBase64(watchedMedia).then(base64 => {
        setCurrentAvatar(base64 as string);
      });
    }
  }, [watchedMedia]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PageTitleUpdater additionalTitle="Admin Panel - Création concours" />
      {!selectedType ? (
        <>
          <Box mt={4}>
            <FormSelect
              variant="whiteBorder"
              label="Type"
              placeholder="Tous"
              name="type"
              register={register}
              options={[
                { key: '1', value: 'Tirage au sort' },
                { key: '2', value: "Concours d'écriture" },
                { key: '3', value: 'Concours de dessins' },
              ]}
            />
          </Box>
          <Box mt={4}>
            <Button colorScheme="blue" mr={3} onClick={() => setSelectedType(getValues('type'))}>
              Valider
            </Button>
          </Box>
        </>
      ) : (
        <>
          {currentAvatar && <Image src={currentAvatar} />}
          <Box mt={4}>
            <FormImageUpload variant="whiteBorderNoBg" placeholder="Image" name="media" required register={register} setValue={setValue} getValues={getValues} errors={errors} />
          </Box>
          <Box mt={4}>
            <FormInput variant="whiteBorderNoBg" label={'Titre'} placeholder="Titre" type="text" name="name" required register={register} errors={errors} />
          </Box>
          <Box mt={4}>
            <FormTextareaSimple
              variant="whiteBorderNoBg"
              label={'Description courte'}
              placeholder="Description courte"
              name="description"
              maxLength={300}
              required={true}
              errors={errors}
              register={register}
              getValues={getValues}
              setValue={setValue}
            />
          </Box>
          <Box mt={4}>
            <FormTextareaSimple
              variant="whiteBorderNoBg"
              label={'Critère de jugement'}
              placeholder="Critère de jugement"
              name="judgmentCriterion"
              maxLength={300}
              required={true}
              errors={errors}
              register={register}
              getValues={getValues}
              setValue={setValue}
            />
          </Box>
          <Box mt={4}>
            <FormTextareaSimple
              variant="whiteBorderNoBg"
              label={'Modalité de participation'}
              placeholder="Modalité de participation"
              name="participationModalities"
              maxLength={300}
              required={true}
              errors={errors}
              register={register}
              getValues={getValues}
              setValue={setValue}
            />
          </Box>
          <Box mt={4}>
            <FormTextareaSimple
              variant="whiteBorderNoBg"
              label={'Lot à gagner'}
              placeholder="Lot à gagner"
              name="toWin"
              maxLength={300}
              required={true}
              errors={errors}
              register={register}
              getValues={getValues}
              setValue={setValue}
            />
          </Box>
          <Box mt={4}>
            <FormDatePicker
              label={'Date de fin'}
              variant="whiteBorderNoBg"
              placeholder="Date de fin"
              name="endDate"
              minDate={todayAddOneHour}
              showTime={true}
              required={true}
              errors={errors}
              register={register}
              getValues={getValues}
              setValue={setValue}
            />
          </Box>
          <Box my={4} textAlign="right">
            <Button variant="navYellow" type="submit" isLoading={mutation.isLoading} isDisabled={!isValid}>
              Enregistrer
            </Button>
          </Box>
        </>
      )}
      {!contestCreated && <InSavedChangesPrompt isDirty={isDirty} />}
    </form>
  );
};
