import React from 'react';
import { Box, Text, Flex, Link } from '@chakra-ui/react';
import ReactMarkdown, { Components } from 'react-markdown';
import { Message } from '../../../types/Message';
import { convertUrlsToMarkdown } from '../../../utils/constants';
import { User } from '../../../types/user';
import UserAvatar from '../../../components/UserAvatar';
import { formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';

interface MessageCardProps {
  message: Message;
  currentUser: User;
  targetUser: User;
}

const renderers: Components = {
  a: ({ href, children }) => (
    <Link href={href as string} isExternal textDecoration="underline">
      {children}
    </Link>
  ),
};

const MessageCard: React.FC<MessageCardProps> = ({ message, currentUser, targetUser }) => {
  const removeAndExtractPrefix = (message: string) => {
    const regex = /\*\*Message créé sur Entre-Geeks par (.*?)\*\*\n/;
    const matched = message.match(regex);

    if (matched && matched[1]) {
      return {
        cleanMessage: message.replace(regex, ''),
        originalAuthor: matched[1],
      };
    }
    return { cleanMessage: message, originalAuthor: null };
  };

  const { cleanMessage } = removeAndExtractPrefix(message.content);

  const lines = cleanMessage.split('\n').map((line, index) => {
    const lineWithMarkdownLinks = convertUrlsToMarkdown(line);
    return (
      <Box key={index}>
        <ReactMarkdown components={renderers}>{lineWithMarkdownLinks}</ReactMarkdown>
      </Box>
    );
  });
  const getAuthor = (message: Message): User => {
    if (message.senderId.id !== currentUser.id) {
      return targetUser;
    }

    return currentUser;
  };

  return (
    <Box key={message.id} mb={6} mx={2}>
      <Flex align="start" justify="left" h="full" mt={1}>
        <UserAvatar withBorder user={getAuthor(message)} styles={{ size: 'md' }} />
        <Box ml={3}>
          <Text fontSize="11px">{getAuthor(message).username}</Text>
          <Text color="gray.500">{formatDistanceToNow(new Date(message.timestamp), { addSuffix: true, locale: fr })}</Text>
          <Text>{lines}</Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default MessageCard;
