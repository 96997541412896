import { useQuery, UseQueryResult } from 'react-query';
import { API_DISCORD_CHANNELS } from '../../../utils/api/constants';
import { DiscordChannel } from '../../../types/Discord';
import api from '../../../utils/api/api';
import { QueryDiscordChannelsParams } from '../../../pages/forum/channels/constants';

interface DiscordChannelsResponse {
  channels: DiscordChannel[];
  total: number;
}

export const useDiscordChannels = (options: QueryDiscordChannelsParams): UseQueryResult<DiscordChannelsResponse, unknown> => {
  // Destructure options with default values to ensure they are not undefined
  const { skip = 0, limit = 6 } = options;

  return useQuery(
    ['discordChannels', skip, limit],
    async () => {
      const queryParams = new URLSearchParams();

      if (skip) queryParams.append('skip', String(skip));
      if (limit) queryParams.append('limit', String(limit));

      const { data } = await api.get<DiscordChannelsResponse>(`${API_DISCORD_CHANNELS}?${queryParams.toString()}`);
      return data;
    },
    {
      retry: 0,
    },
  );
};
