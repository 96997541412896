import React from 'react';
import { FormControl, FormLabel, Checkbox, FormErrorMessage, Flex, Box } from '@chakra-ui/react';
import { UseFormRegister, FieldValues, DeepMap, FieldError } from 'react-hook-form';

interface FormCheckboxProps {
  label?: string;
  name: string;
  required?: boolean;
  register: UseFormRegister<any>;
  errors: DeepMap<FieldValues, FieldError>;
  variant?: any;
  children?: React.ReactNode;
  defaultValue?: boolean;
}

const FormCheckbox: React.FC<FormCheckboxProps> = ({ defaultValue = false, children, variant = 'violet', label, name, register, errors, required }) => {
  return (
    <FormControl isRequired={required} isInvalid={errors && !!errors[name]}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <Flex align="center">
        <Checkbox defaultChecked={defaultValue} variant={variant} id={name} {...(register ? register(name) : {})} />
        {children && <Box ml={2}>{children}</Box>}
      </Flex>
      <FormErrorMessage>{errors && errors[name] && errors[name].message}</FormErrorMessage>
    </FormControl>
  );
};

export default FormCheckbox;
