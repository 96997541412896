import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/react';

const DiscordRedirect: React.FC = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (code) {
      window.opener?.postMessage({ code }, window.location.origin);
      window.close();
    }
  }, []);

  return <Box>Redirection...</Box>;
};

export default DiscordRedirect;
