import React from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import FormInput from '../../../../components/forms/FormInput';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useResetPassword } from '../../../../hooks/users/useResetPassword';
import { CONNEXION_PATH } from '../../../../routing/constants';
import ResetPasswordSchema from './yup/ResetPasswordSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import PageTitleUpdater from '../../../../routing/PageTitleUpdater';
import { toast } from 'react-toastify';

type ResetPasswordFormInputProps = {
  password: string;
  confirmPassword: string;
};

interface ResetPasswordFormProps {
  token: string;
}

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({ token }) => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordFormInputProps>({
    resolver: yupResolver(ResetPasswordSchema),
  });
  const resetPassword = useResetPassword();

  const onSubmit: SubmitHandler<ResetPasswordFormInputProps> = data => {
    if (data.password !== data.confirmPassword) {
      toast.error(`Erreur d'envoi : Les mots de passe ne correspondent pas.`, {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    if (!token) {
      return;
    }

    resetPassword.mutate(
      { token, newPassword: data.password },
      {
        onSuccess: () => {
          toast.success(`Votre mot de passe a été réinitialisé.`, {
            position: toast.POSITION.TOP_CENTER,
          });
          navigate(CONNEXION_PATH);
        },
        onError: (error: any) => {
          toast.error(`Erreur de réinitialisation : ${error.response.data.message}`, {
            position: toast.POSITION.TOP_CENTER,
          });
        },
      },
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PageTitleUpdater additionalTitle={`Mot de passe oublié`} />
      <Box my={20} display="flex" flexDirection="column" alignItems="center">
        <Text fontSize="4xl" color="brand.white">
          Réinitialisez votre mot de passe
        </Text>
      </Box>
      <Flex align="center" my={4}>
        <FormInput variant="whiteBorderNoBg" type="password" placeholder="Nouveau mot de passe" name="password" register={register} errors={errors} />
      </Flex>
      <Flex align="center" my={4}>
        <FormInput variant="whiteBorderNoBg" type="password" placeholder="Confirmez le mot de passe" name="confirmPassword" register={register} errors={errors} />
      </Flex>
      <Button mt={4} variant="primary-dark" w="100%" fontSize="sm" colorScheme="teal" type="submit" isLoading={resetPassword.isLoading}>
        Réinitialiser
      </Button>
    </form>
  );
};

export default ResetPasswordForm;
