import React, { useEffect, useRef, useState } from 'react';
import { AvatarProps, Box, Image } from '@chakra-ui/react';
import { defaultImageLotterie } from '../utils/helpers';
import { PowerGlitch } from 'powerglitch';

interface ImageLoaderProps {
  src: string;
  imageName: string;
  styles?: AvatarProps;
}

const ImageLoaderWithGlitch: React.FC<ImageLoaderProps> = ({ src, imageName, styles }) => {
  const [opacity, setOpacity] = useState(0);
  const imgRef = useRef(null);

  useEffect(() => {
    if (imgRef.current) {
      PowerGlitch.glitch(imgRef.current, {
        playMode: 'hover',
        hideOverflow: true,
        timing: {
          duration: 2000,
        },
        glitchTimeSpan: {
          start: 0,
          end: 0.2,
        },
        shake: {
          velocity: 5,
          amplitudeX: 0.2,
          amplitudeY: 0.2,
        },
        slice: {
          count: 6,
          velocity: 15,
          minHeight: 0.02,
          maxHeight: 0.15,
          hueRotate: true,
        },
      });
    }
  }, []);

  const handleImageLoaded = () => {
    setOpacity(1);
  };

  const handleImageError = (e: any) => {
    e.target.src = defaultImageLotterie;
  };

  return (
    <Box
      className="glitch"
      style={{
        opacity: opacity,
        transition: 'opacity 0.3s ease-in-out',
      }}
    >
      <Image ref={imgRef} src={src} alt={imageName} onError={handleImageError} onLoad={handleImageLoaded} {...styles} />
    </Box>
  );
};

export default ImageLoaderWithGlitch;
