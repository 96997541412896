import React, { useState } from 'react';
import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import { textCGU } from '../noAuth/constants';
import Ariane from '../../../components/Ariane';
import { customColors } from '../../../theme';
import FaqContent from '../FaqContent';
import PageTitleUpdater from '../../../routing/PageTitleUpdater';
import { convertUrlsToMarkdown } from '../../../utils/constants';
import ReactMarkdown from 'react-markdown';
import { renderersMarkdown } from '../../../utils/helpers';

const CguFaq: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState('FAQ');

  const lines = textCGU.split('\n').map((line, index) => {
    const lineWithMarkdownLinks = convertUrlsToMarkdown(line);
    return (
      <Box key={`line-${index}`}>
        {!lineWithMarkdownLinks && <br />}
        <ReactMarkdown components={renderersMarkdown}>{lineWithMarkdownLinks}</ReactMarkdown>
      </Box>
    );
  });

  return (
    <Box>
      <PageTitleUpdater additionalTitle="Cgu - Faq" />
      <Ariane>
        <Flex alignItems="center">
          <Image src="/assets/icons/eg.svg" alt="CGU" width="30px" />
          <Text as="h1" textTransform="uppercase" fontSize="19px" ml={4}>
            FAQ - CGU
          </Text>
        </Flex>
      </Ariane>
      {/* Boutons pour changer d'onglet */}
      <Flex justifyContent="center" my={4}>
        <Button
          variant={'navYellow'}
          mr={2}
          onClick={() => setSelectedTab('FAQ')}
          color={selectedTab === 'FAQ' ? customColors.blackTegrite : undefined}
          bg={selectedTab === 'FAQ' ? customColors.yellowBob : undefined}
        >
          FAQ
        </Button>
        <Button
          variant={'navYellow'}
          onClick={() => setSelectedTab('CGU')}
          color={selectedTab === 'CGU' ? customColors.blackTegrite : undefined}
          bg={selectedTab === 'CGU' ? customColors.yellowBob : undefined}
        >
          CGU
        </Button>
      </Flex>

      {/* Affiche la Box en fonction de l'onglet sélectionné */}
      {selectedTab === 'CGU' && (
        <Box mb={4}>
          <Text as="h1" mb={4}>
            Conditions Générales d'Utilisation (CGU)
          </Text>
          {lines}
        </Box>
      )}
      {selectedTab === 'FAQ' && (
        <Box mb={4}>
          <FaqContent />
        </Box>
      )}
    </Box>
  );
};

export default CguFaq;
