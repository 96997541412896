import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppContext } from '../context/AppContext';
import { HOME_PATH } from './constants';
import { Box, Spinner } from '@chakra-ui/react';
import { customColors } from '../theme';

interface PrivateRouteProps {
  children: ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { user, isAppLoading } = useAppContext();

  if (isAppLoading) {
    return (
      <Box w="100vw" bgColor={customColors.yellowBob} h="100vh" display="flex" alignItems="center" justifyContent="center">
        <Spinner size="xl" />
      </Box>
    );
  }

  return user ? <>{children}</> : <Navigate to={HOME_PATH} />;
};

export default PrivateRoute;
