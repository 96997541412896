import React from 'react';
import ConnexionForm from './ConnexionForm';
import FormLayout from '../../layout/noAuth/wrappers/FormLayout';
import PageTitleUpdater from '../../routing/PageTitleUpdater';
import { getRandomNumber } from '../../utils/helpers';

const Connexion: React.FC = () => {
  return (
    <FormLayout media={`/assets/connexion/cover-${getRandomNumber(1, 7)}.png`}>
      <PageTitleUpdater additionalTitle="Connexion" />
      <ConnexionForm />
    </FormLayout>
  );
};

export default Connexion;
