import React from 'react';
import { Box, Container } from '@chakra-ui/react';
import Footer from '../../../pages/home/noAuth/footer/footer';
import CookiesPopup from '../../../pages/misc/CookiesPopup';
import { css, Global } from '@emotion/react';
import Menu from '../Menu';

const GlobalStyles: React.FC = () => {
  return (
    <Global
      styles={css`
        body {
          font-size: 16px;
          background-color: #2b2c2c;
        }
      `}
    />
  );
};

interface TemplateLayoutProps {
  children: React.ReactNode;
}

const PageLayout: React.FC<TemplateLayoutProps> = ({ children }) => {
  return (
    <>
      <GlobalStyles />
      <Menu />
      <Container maxW="container.xl">
        <Box minH={800} mt={'150px'} bgImage={`url(/assets/bg-template.png)`} bgPos="center" bgSize="contain" bgRepeat="no-repeat">
          <Container maxW="container.lg">{children}</Container>
        </Box>
      </Container>
      <Footer />
      <CookiesPopup />
    </>
  );
};

export default PageLayout;
