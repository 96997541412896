import { useQuery, UseQueryResult } from 'react-query';
import { Contest } from '../../types/Contests';
import api from '../../utils/api/api';
import { API_CONTESTS } from '../../utils/api/constants';
import { QueryContestParams } from '../../pages/contest/constantes';

export const useActiveContests = (): UseQueryResult<Contest[], unknown> => {
  return useQuery<Contest[]>('activeContest', async () => {
    const { data } = await api.get(`${API_CONTESTS}/all-active`);
    return data;
  });
};
