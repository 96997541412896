import React from 'react';
import Menu from './menu/Menu';
import Members from './Members';
import { Flex, useBreakpointValue } from '@chakra-ui/react';
import { useHideOnMobile } from '../../utils/helpers';
import DiscordStats from './DiscordStats';

const LeftBar: React.FC = () => {
  const isMobileView = useBreakpointValue({ base: true, md: false });
  return (
    <Flex flexDirection="column" flexGrow={1}>
      {!isMobileView && <Menu />}
      {!isMobileView && <DiscordStats />}
      {!useHideOnMobile() && <Members />}
    </Flex>
  );
};

export default LeftBar;
