import { useMutation, useQueryClient, UseMutationResult } from 'react-query';
import api from '../../utils/api/api';
import { API_EVENTS } from '../../utils/api/constants';

export const useDeleteEvent = (): UseMutationResult<void, Error, string, unknown> => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, string>((id: string) => api.delete(`${API_EVENTS}/${id}/delete`), {
    onSuccess: () => {
      queryClient.invalidateQueries('events');
      queryClient.refetchQueries('event');
    },
  });
};
