import { useQuery, UseQueryResult } from 'react-query';
import api from '../../utils/api/api';
import { API_DISCORD_ROLES_ADMIN } from '../../utils/api/constants';
import { DiscordRole } from '../../types/DiscordRole';

export const useDiscordRoleById = (id: string): UseQueryResult<DiscordRole, unknown> => {
  return useQuery(['discordRole', id], async () => {
    const { data } = await api.get(`${API_DISCORD_ROLES_ADMIN}/${id}`);
    return data;
  });
};
