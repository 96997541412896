import React from 'react';
import { Box, Text, useBreakpointValue } from '@chakra-ui/react';
import { S3_URL } from '../../../../utils/constants';

import { formatUri } from '../../../../utils/api/helpers';
import { NEWS_PATH } from '../../../../routing/constants';
import { useNavigate } from 'react-router-dom';
import { News as NewsType } from '../../../../types/News';
import CardImageWithBox from '../../../../components/card/CardImageWithBox';

interface EventCarouselProps {
  news: NewsType;
}

const News: React.FC<EventCarouselProps> = ({ news }) => {
  const navigate = useNavigate();
  const truncatedTitle = news.title.length > 30 ? news.title.slice(0, 30) + '...' : news.title;
  const isMobileView = useBreakpointValue({ base: true, md: false });

  return (
    <Box overflow="hidden" position="relative" mb={isMobileView ? 6 : '0'} onClick={() => navigate(formatUri({ slug: news.slug }, NEWS_PATH))} cursor="pointer">
      <Text as="h1" mb={2}>
        Dernière actualité
      </Text>
      <CardImageWithBox
        imageName={news.title}
        image={`${S3_URL}/news/${news.media}`}
        user={news.userId}
        contentFooter={
          <Text as="h2" fontSize={'13px'} mt={5}>
            {truncatedTitle}
          </Text>
        }
      />
    </Box>
  );
};

export default News;
