import { useQuery, UseQueryResult } from 'react-query';
import api from '../../utils/api/api';
import { API_EVENTS } from '../../utils/api/constants';
import { addDays } from 'date-fns';
import { Event } from '../../types/Events';

// Fonction pour obtenir les événements pour la semaine
const fetchEventsForWeek = async (startDate: Date): Promise<Event[]> => {
  const newStartDate = addDays(startDate, 1);
  const endDate = addDays(newStartDate, 6);
  return api.get(`${API_EVENTS}/range?start=${newStartDate.toISOString()}&end=${endDate.toISOString()}`).then(res => res.data);
};

export const useEventsForWeek = (startDate: Date): UseQueryResult<Event[], Error> => {
  return useQuery(['eventsForWeek', startDate], () => fetchEventsForWeek(startDate));
};
