/* eslint-disable max-len */
export const textCGU = `
## Objet 
Les présentes Conditions Générales ont pour objet de définir les modalités de mise à disposition des services du site Entre-geeks.com, ci-après nommé « le Service » et les conditions d'utilisation du Service par l'Utilisateur.

Tout accès et/ou Utilisation du site entre-geeks.com suppose l'acceptation et le respect de l'ensemble des termes des présentes Conditions et leur acceptation inconditionnelle. Elles constituent donc un contrat entre le Service et l'Utilisateur.

Dans le cas où l'Utilisateur ne souhaite pas accepter tout ou partie des présentes conditions générales, il lui est demandé de renoncer à utiliser ce Service.

## Mentions légales
Le site Entre-geeks.com est édité par : Maxime Champeval
Le site Entre-geeks.com est hébergé par : Digital Ocean
Toutes images uploads sur le site sont hébergées par : Amazon S3 

## Définitions
Utilisateur : L'Utilisateur est toute personne qui utilise le Site ou l'un des services proposés sur le Site.
Contenu Utilisateur : Le terme « Contenu Utilisateur » désigne les données transmises par l'Utilisateur dans les différentes rubriques du Site.
Membre : Le terme « Membre » désigne un utilisateur identifié sur le site.
Identifiant : Le terme « Identifiant » recouvre les informations nécessaires à l'identification d'un utilisateur sur le site pour accéder aux zones réservées aux membres.
Mot de passe : Le « Mot de passe » est une information confidentielle, dont l'Utilisateur doit garder le secret, lui permettant, utilisé conjointement avec son Identifiant, de prouver l'identité.
Staff : Equipe encadrante du Site.

## Accès au service
Le Service est accessible gratuitement à tout Utilisateur disposant d'un accès à internet. Tous les coûts afférents à l'accès au Service, que ce soient les frais matériels, logiciels ou d'accès à internet sont exclusivement à la charge de l'utilisateur. Il est seul responsable du bon fonctionnement de son équipement informatique ainsi que de son accès à internet.

Certaines sections du site sont réservées aux Membres après identification à l'aide de leur Identifiant et de leur Mot de passe.

Entre-geeks se réserve le droit de refuser l'accès au Service, unilatéralement et sans notification préalable, à tout Utilisateur ne respectant pas les présentes conditions d'utilisation.

«L’hébergeur du site » met en œuvre tous les moyens raisonnables à sa disposition pour assurer un accès de qualité au Service, mais n'est tenu à aucune obligation d'y parvenir. Ce dernier ne peut, en outre, être tenue responsable de tout dysfonctionnement du réseau ou des serveurs ou de tout autre événement échappant au contrôle raisonnable, qui empêcherait ou dégraderait l'accès au Service.

## Utilisateur
Lors de son Inscription en ligne, l'Utilisateur s'engage à fournir des informations vraies, exactes, à jour complètes sur son identité et son âge comme demandé dans le formulaire d'inscription aux Services, conformément à l'article 6-II de la loi n°2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique. Il s'engage notamment à ne pas créer une fausse identité de nature à induire Entre-geeks ou les tiers en erreur et à ne pas usurper l'identité d'une autre personne morale ou physique. L'Utilisateur s'engage à mettre immédiatement à jour, en cas de modification, les données qu'il a communiquées lors de son Inscription en ligne.

Dans l'hypothèse où l'Utilisateur fournirait des informations fausses, inexactes, périmées, incomplètes, trompeuses ou de nature à induire en erreur, Entre-geeks pourra, immédiatement sans préavis ni indemnité, suspendre ou résilier le Compte Personnel de l'Utilisateur et lui refuser l'accès, de façon temporaire ou définitive à tout ou partie du site.

### Pour les utilisateurs mineurs :
Il est rappelé aux parents et à toute personne exerçant l'autorité parentale, qu'il leur appartient, d'une part, de déterminer quel(s) Service(s) leur enfant mineur est autorisé à utiliser et, d'autre part, de surveiller l'utilisation que celui-ci fait de ce(s) Service(s).
Les utilisateurs mineurs de moins de 16 ans ne sont pas autorisés à s'inscrire. En revanche, les utilisateurs mineurs de 16 ans ou plus sont admis à s'inscrire, à la condition expresse qu'ils aient préalablement obtenu de leurs parents (ou de la personne exerçant l'autorité parentale) l'autorisation de le faire et de fournir les informations et adresses électroniques auxquelles toute communication peut leur être transmise.
Entre-geeks se réserve le droit d'en demander la justification écrite à tout moment de cette autorisation, et de procéder à toutes vérifications, ainsi que de supprimer tout Compte Personnel dont l’utilisateur mineur ne remettrait pas immédiatement ladite justification, ou éventuellement dans le délai qui lui serait imparti. Entre-geeks supprimera immédiatement tout Compte Personnel, dès la réception par ses services d'une demande parentale de fermeture du Compte Personnel et des Contenus y afférents.

## Propriété intellectuelle
L'Utilisateur est seul responsable du Contenu Utilisateur qu'il met en ligne via le Service, ainsi que des textes et/ou opinions qu'il formule. Il s'engage notamment à ce que ces données ne soient pas de nature à porter atteinte aux intérêts légitimes de tiers quels qu'ils soient. A ce titre, il garantit Entre-geeks contre tous recours, fondés directement ou indirectement sur ces propos et/ou données, susceptibles d'être intentés par quiconque à l'encontre de Entre-geeks. Il s'engage en particulier à prendre en charge le paiement des sommes, quelles qu'elles soient, résultant du recours d'un tiers à l'encontre d’Entre-geeks, y compris les honoraires d'avocat et frais de justice.

Le staff se réserve le droit de supprimer tout ou partie du Contenu Utilisateur, à tout moment et pour quelque raison que ce soit, sans avertissement ou justification préalable. L'Utilisateur ne pourra faire valoir aucune réclamation à ce titre.

## Données personnelles
Dans une logique de respect de la vie privée de ses Utilisateurs, Entre-geeks s'engage à ce que la collecte et le traitement d'informations personnelles, effectués au sein du présent site, soient effectués conformément à la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés, dite Loi « Informatique et Libertés ».

Conformément à l'article 34 de la loi « Informatique et Libertés », Entre-geeks garantit à l'Utilisateur un droit d'opposition, d'accès et de rectification sur les données nominatives le concernant.Lors de la suppression du compte utilisateur seul le pseudo est conservé afin déviter tout vol d'identité cela sans aucune utilisation commercial. Pour la suppression total, contacter l'administrateur par mail : webmaster@entre-geeks.com

L'Utilisateur a la possibilité d'exercer ce droit

en utilisant le formulaire de contact mis à sa disposition ;
en rentrant directement en contact par l'adresse suivante : webmaster@entre-geeks.com.

## Limites de responsabilité
Le site Entre-geeks.com est un site regroupant une communauté « geek ». Les informations diffusées, news, sur le site Entre-geeks.com proviennent de sources réputées fiables. Toutefois, Entre-geeks ne peut garantir l'exactitude ou la pertinence de ces données.

En outre, les informations mises à disposition sur ce site le sont uniquement à titre purement informatif et ne sauraient constituer en aucun cas un conseil ou une recommandation de quelque nature que ce soit.

En conséquence, l'Utilisation des informations et contenus disponibles sur l'ensemble du site, ne sauraient en aucun cas engager la responsabilité de Entre-geeks, à quelque titre que ce soit.
L'Utilisateur est seul maître de la bonne utilisation, avec discernement et esprit, des informations mises à sa disposition sur le Site.

Par ailleurs, l'Utilisateur s'engage à indemniser Entre-geeks de toutes conséquences dommageables liées directement ou indirectement à l'usage qu'il fait du Service.

L'accès à certaines sections du site Entre-geeks.com nécessite l'utilisation d'un Identifiant et d'un Mot de passe. Le Mot de passe, choisi par l'utilisateur, est personnel et confidentiel.
L'utilisateur s'engage à conserver secret son mot de passe et à ne pas le divulguer sous quelque forme que ce soit. L'utilisation de son Identifiant et de son Mot de passe à travers internet se fait aux risques et périls de l'Utilisateur. Il appartient à l'Utilisateur de prendre toutes les dispositions nécessaires permettant de protéger ses propres données contre toute atteinte.

Entre-geeks s'engage néanmoins à mettre en place tous les moyens nécessaires pour garantir la sécurité et la confidentialité des données transmises. L'Utilisateur est informé qu'un ou plusieurs cookies, ne contenant aucune information personnelle, pourront être placés sur son disque dur afin d'assurer son identification.

L'Utilisateur admet connaître les limitations et contraintes propres au réseau internet et, à ce titre, reconnaît notamment l'impossibilité d'une garantie totale de la sécurisation des échanges de données. Entre-geeks ne pourra pas être tenue responsable des préjudices découlant de la transmission de toute information, y compris de celle de son identifiant et/ou de son mot de passe, via le Service.

Entre-geeks ne pourra en aucun cas, dans la limite du droit applicable, être tenue responsable des dommages et/ou préjudices, directs ou indirects, matériels ou immatériels, ou de quelque nature que ce soit, résultant d'une indisponibilité du Service ou de toute Utilisation du Service. Le terme « Utilisation » doit être entendu au sens large, c'est-à-dire tout usage du site quel qu'il soit, licite ou non.

L'Utilisateur s'engage, d'une manière générale, à respecter l'ensemble de la réglementation en vigueur en France.

## Liens hypertextes
Entre-geeks.com propose des liens hypertextes vers des sites web édités et/ou gérés par des tiers.
Dans la mesure où aucun contrôle n'est exercé sur ces ressources externes, l'Utilisateur reconnaît que Entre-geeks.com n'assume aucune responsabilité relative à la mise à disposition de ces ressources, et ne peut être tenue responsable quant à leur contenu. Force majeure
La responsabilité de Entre-geeks.com ne pourra être engagée en cas de force majeure ou de faits indépendants de sa volonté.

## Les images uploadées via le site
Toutes les images uploadées sur le site via la fonction interne sont obligatoirement modérées par un modérateur.
Toutes images déplacées ou hors chartes se verront supprimées et des sanctions sur le compte seront appliquées.

## Evolution du présent contrat
Entre-geeks se réserve le droit de modifier les termes, conditions et mentions du présent contrat à tout moment. 
Il est ainsi obligatoire à l'Utilisateur de consulter régulièrement la dernière version des Conditions d'Utilisation disponible sur le site entre-geeks.com.

## Durée et résiliation
Le présent contrat est conclu pour une durée indéterminée à compter de l'Utilisation du Service par l'Utilisateur.

## Droit applicable et juridiction compétente
Les règles en matière de droit, applicables aux contenus et aux transmissions de données sur et autour du site, sont déterminées par la loi française.
En cas de litige, n'ayant pu faire l'objet d'un accord à l'amiable, seuls les tribunaux français du ressort de la cour d'appel de Paris sont compétents.

## Concours Entre Geeks
Les concours sur entre geeks sont sans obligation d'achat et n'entraine aucun engagement de votre part.
Pour participer à un concours il vous faut impérativement un compte valide sur entre-geeks.com

`;
