import { Box, Text, Badge, Image, VStack, HStack } from '@chakra-ui/react';
import { removeEventPrefix, removeMarkdown } from '../../../../utils/helpers';
import { S3_URL } from '../../../../utils/constants';
import { formatTimestamp } from '../../../../utils/dates';
import Markdown from 'react-markdown';
import React from 'react';
import { Event } from '../../../../types/Events';
import PageTitleUpdater from '../../../../routing/PageTitleUpdater';

interface PublicEventCardProps {
  event: Event;
}

const EventPublicCard: React.FC<PublicEventCardProps> = ({ event }) => {
  const { name, description, scheduledStartTime, scheduledEndTime, entityType, image, location } = event;

  const getEntityType = (entityType: number) => {
    if (entityType === 1) return 'Stream';
    if (entityType === 2) return 'Game';
    if (entityType === 3) return 'Sortie';
  };

  return (
    <Box borderWidth="1px" borderRadius="lg" p="4" w="100%" overflow="hidden" cursor="pointer">
      <PageTitleUpdater additionalTitle={`Evenement - ${name}`} additionalDescription={removeMarkdown(description)} additionalOgImage={`${S3_URL}/events/${image}`} />
      {image && <Image w="100%" objectFit="cover" src={`${S3_URL}/events/${image}`} alt={name} />}
      <VStack align="start" spacing={2}>
        <Text as="h1">{removeEventPrefix(name)}</Text>
        <Text>{`Le ${formatTimestamp(scheduledStartTime)} jusqu'à ${formatTimestamp(scheduledEndTime)}`}</Text>
        <Box>
          <Markdown className="markdown">{description}</Markdown>
        </Box>
        {location && <Text>Location: {location.formattedAddress}</Text>}
        <HStack spacing={2}>
          <Badge colorScheme="blue">{getEntityType(entityType)}</Badge>
        </HStack>
      </VStack>
    </Box>
  );
};

export default EventPublicCard;
