import React, { useRef } from 'react';
import { useAppContext } from '../../../context/AppContext';
import { Box, Button, Flex, Grid, SimpleGrid, Text, useBreakpointValue, useMediaQuery } from '@chakra-ui/react';
import { useUpdateUser } from '../../../hooks/users/useUpdateUser';
import { User } from '../../../types/user';
import { toast } from 'react-toastify';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UserUpdateGeneralYupSchema } from './yup/UserUpdateGeneralYupSchema';
import FormTextareaSimple from '../../../components/forms/FormTextareaSimple';
import FormInput from '../../../components/forms/FormInput';
import { customScrollBar } from '../../../utils/helpers';
import SliderInput from '../../../components/forms/SliderInput';
import { UserGeneralUpdateFormType } from '../constants';
import InSavedChangesPrompt from '../../../components/InSavedChangesPrompt';

interface GeneralTabProps {
  user: User;
}

const GeneralTab: React.FC<GeneralTabProps> = ({ user }) => {
  const [isLessThan768] = useMediaQuery('(max-width: 952px)');
  const [isLessThan550] = useMediaQuery('(max-width: 550px)');
  const updateUser = useUpdateUser();
  const { setUser } = useAppContext();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const lastMessageElementRef = useRef<HTMLDivElement | null>(null);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    setValue,
    getValues,
    reset,
  } = useForm<UserGeneralUpdateFormType>({
    resolver: yupResolver(UserUpdateGeneralYupSchema),
    defaultValues: {
      userProfile: {
        gamesId: user.userProfile.gamesId,
        socialNetworks: user.userProfile.socialNetworks,
        passions: user.userProfile.passions,
        presentation: user.userProfile.presentation,
      },
    },
  });

  const onSubmit: SubmitHandler<UserGeneralUpdateFormType> = async data => {
    try {
      const newUser = await updateUser.mutateAsync({ id: user.id, data });
      setUser(newUser);
      toast.success('Profile mis à jour avec succès', {
        position: toast.POSITION.TOP_CENTER,
      });
      reset({
        userProfile: {
          gamesId: newUser.userProfile.gamesId,
          socialNetworks: newUser.userProfile.socialNetworks,
          passions: newUser.userProfile.passions,
          presentation: newUser.userProfile.presentation,
        },
      });
      window.scrollTo(0, 0);
    } catch (error) {
      toast.error("Une erreur s'est produite lors de la mise à jour du profile", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const templateColumns = !isLessThan768 ? '1fr 3fr' : '1fr';
  const templateGridInput = !isLessThan550 ? 3 : 1;

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      <Box w={'100%'} display="flex" flexDirection="column" mt={-4} h={isMobile ? 'auto' : 'calc(100vh - 530px)'} justifyContent="space-between">
        <Box ref={lastMessageElementRef} css={customScrollBar} overflowY="auto" mb={4} pr={2}>
          <Grid templateColumns={templateColumns} gap={4} mt={4} flex={1}>
            <Box>
              <Text fontWeight="bold" color="yellowBob">
                Présentation
              </Text>
            </Box>
            <Box>
              <Text mb={4}>Portrait de ton profile</Text>
              <FormTextareaSimple
                variant="whiteBorderNoBg"
                height={200}
                placeholder="Présentation"
                name="userProfile.presentation"
                maxLength={2000}
                errors={errors}
                register={register}
                getValues={getValues}
                setValue={setValue}
              />
            </Box>
          </Grid>
          <Grid templateColumns={templateColumns} gap={4} mt={10} flex={1}>
            <Box>
              <Text fontWeight="bold" color="yellowBob">
                Réseaux Sociaux
              </Text>
            </Box>
            <Box>
              <Text mb={4}>Partage tes tags sociaux, d’aventurier des réseaux ! 🗡️🔮</Text>
              <SimpleGrid columns={templateGridInput} spacing={4}>
                <FormInput
                  variant="whiteBorderNoBg"
                  icon="assets/profile/facebook.svg"
                  placeholder="Facebook"
                  type="text"
                  name="userProfile.socialNetworks.facebook"
                  register={register}
                  errors={errors}
                />
                <FormInput
                  variant="whiteBorderNoBg"
                  icon="assets/profile/instagram.svg"
                  placeholder="Instagram"
                  type="text"
                  name="userProfile.socialNetworks.instagram"
                  register={register}
                  errors={errors}
                />
                <FormInput variant="whiteBorderNoBg" icon="assets/profile/x.svg" placeholder="X" type="text" name="userProfile.socialNetworks.x" register={register} errors={errors} />
                <FormInput
                  variant="whiteBorderNoBg"
                  icon="assets/profile/youtube.svg"
                  placeholder="Youtube"
                  type="text"
                  name="userProfile.socialNetworks.youtube"
                  register={register}
                  errors={errors}
                />
                <FormInput
                  variant="whiteBorderNoBg"
                  icon="assets/profile/pinterest.svg"
                  placeholder="Pinterest"
                  type="text"
                  name="userProfile.socialNetworks.pinterest"
                  register={register}
                  errors={errors}
                />
                <FormInput variant="whiteBorderNoBg" icon="assets/profile/tiktok.svg" placeholder="Tiktok" type="text" name="userProfile.socialNetworks.tiktok" register={register} errors={errors} />
              </SimpleGrid>
            </Box>
          </Grid>
          <Grid templateColumns={templateColumns} gap={4} mt={10} flex={1}>
            <Box>
              <Text fontWeight="bold" color="yellowBob">
                Games Id
              </Text>
            </Box>
            <Box>
              <Text mb={4}>Partage tes Games ID, trouve des coéquipiers ! 🗡️🔮</Text>
              <SimpleGrid columns={templateGridInput} spacing={4}>
                <FormInput variant="whiteBorderNoBg" icon="assets/profile/xbox.svg" placeholder="Xbox" type="text" name="userProfile.gamesId.xbox" register={register} errors={errors} />
                <FormInput variant="whiteBorderNoBg" icon="assets/profile/psn.svg" placeholder="Playstation" type="text" name="userProfile.gamesId.psn" register={register} errors={errors} />
                <FormInput variant="whiteBorderNoBg" icon="assets/profile/steam.svg" placeholder="Steam" type="text" name="userProfile.gamesId.steam" register={register} errors={errors} />
                <FormInput variant="whiteBorderNoBg" icon="assets/profile/nintendo.svg" placeholder="Nintendo" type="text" name="userProfile.gamesId.nintendo" register={register} errors={errors} />
                <FormInput variant="whiteBorderNoBg" icon="assets/profile/blizzard.svg" placeholder="Blizzard" type="text" name="userProfile.gamesId.blizzard" register={register} errors={errors} />
                <FormInput
                  variant="whiteBorderNoBg"
                  icon="assets/profile/leagueOfLegend.svg"
                  placeholder="Riot"
                  type="text"
                  name="userProfile.gamesId.leagueOfLegend"
                  register={register}
                  errors={errors}
                />
                <FormInput variant="whiteBorderNoBg" icon="assets/profile/origin.svg" placeholder="Origin" type="text" name="userProfile.gamesId.origin" register={register} errors={errors} />
              </SimpleGrid>
            </Box>
          </Grid>
          <Grid templateColumns={templateColumns} gap={4} mt={10} mr={2} flex={1}>
            <Box>
              <Text fontWeight="bold" color="yellowBob">
                Vos passions
              </Text>
            </Box>
            <Box>
              <Text mb={4}>Révèle ton niveau d'affinité geek</Text>
              <SimpleGrid columns={templateGridInput} spacing={4} spacingX="10">
                <SliderInput label="Mangas" placeholder="Mangas" name="userProfile.passions.mangas" getValues={getValues} setValue={setValue} register={register} errors={errors} />
                <SliderInput label="Scans" placeholder="Scans" name="userProfile.passions.scans" getValues={getValues} setValue={setValue} register={register} errors={errors} />
                <SliderInput label="Animes" placeholder="Animes" name="userProfile.passions.animes" getValues={getValues} setValue={setValue} register={register} errors={errors} />
                <SliderInput label="MEUPORG" placeholder="MEUPORG" name="userProfile.passions.mmo" getValues={getValues} setValue={setValue} register={register} errors={errors} />
                <SliderInput label="FPS" placeholder="FPS" name="userProfile.passions.fps" getValues={getValues} setValue={setValue} register={register} errors={errors} />
                <SliderInput label="Comics" placeholder="Comics" name="userProfile.passions.comics" getValues={getValues} setValue={setValue} register={register} errors={errors} />
                <SliderInput label="JDR Table" placeholder="JDR Table" name="userProfile.passions.jdrIrl" getValues={getValues} setValue={setValue} register={register} errors={errors} />
                <SliderInput label="JDR IRL" placeholder="JDR IRL" name="userProfile.passions.jdrTable" getValues={getValues} setValue={setValue} register={register} errors={errors} />
                <SliderInput label="Jeux console" placeholder="Jeux console" name="userProfile.passions.gameConsole" getValues={getValues} setValue={setValue} register={register} errors={errors} />
                <SliderInput label="Jeux PC" placeholder="Jeux PC" name="userProfile.passions.gamePc" getValues={getValues} setValue={setValue} register={register} errors={errors} />
                <SliderInput label="Jeux de carte" placeholder="Jeux de carte" name="userProfile.passions.gameCard" getValues={getValues} setValue={setValue} register={register} errors={errors} />
                <SliderInput
                  label="Culture Japonaise"
                  placeholder="Culture Japonaise"
                  name="userProfile.passions.japCulture"
                  getValues={getValues}
                  setValue={setValue}
                  register={register}
                  errors={errors}
                />
                <SliderInput
                  label="Programmation"
                  placeholder="Programmation"
                  name="userProfile.passions.programmation"
                  getValues={getValues}
                  setValue={setValue}
                  register={register}
                  errors={errors}
                />
                <SliderInput label="Cinéma" placeholder="Cinéma" name="userProfile.passions.cinema" getValues={getValues} setValue={setValue} register={register} errors={errors} />
              </SimpleGrid>
            </Box>
          </Grid>
        </Box>
      </Box>
      <Flex justifyContent="flex-end" pr={4}>
        <Button type="submit" isLoading={updateUser.isLoading} isDisabled={!isValid} variant="navGreen">
          Sauvegarder les changements
        </Button>
      </Flex>
      <InSavedChangesPrompt isDirty={isDirty} />
    </Box>
  );
};

export default GeneralTab;
