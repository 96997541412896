import api from '../../utils/api/api';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { API_ADMIN_VALID_ACCOUNT_URI } from '../../utils/api/constants';

export const useValidateAccounts = (): UseMutationResult<void, Error, string[], unknown> => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, string[]>(ids => api.post(API_ADMIN_VALID_ACCOUNT_URI, { ids }).then(res => res.data), {
    onSuccess: () => {
      queryClient.refetchQueries('invalidAccounts');
    },
  });
};
