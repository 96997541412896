import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import MessageCard from './MessageCard';
import { useMessages } from '../../../hooks/discord/forum/useMessages';
import { Box, Flex, Image, Text, useBreakpointValue } from '@chakra-ui/react';
import { Message } from '../../../types/Discord';
import SendDiscordMessage from '../../../components/sendDiscordMessage/SendDiscordMessage';
import PageTitleUpdater from '../../../routing/PageTitleUpdater';
import Ariane from '../../../components/Ariane';
import PageLayout from '../../../layout/PageLayout';
import { customScrollBar } from '../../../utils/helpers';

type ParamTypes = {
  channelId: string;
};

const Messages: React.FC = () => {
  const { channelId } = useParams<ParamTypes>();
  const { data, fetchNextPage, hasNextPage, isLoading, isError, error, refetch } = useMessages(channelId as string);
  const observer = useRef<IntersectionObserver | null>(null);
  const lastMessageElementRef = useRef<HTMLDivElement | null>(null);
  const [channelName, setChannelName] = useState<string>('');
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [lastActionTimestamp, setLastActionTimestamp] = useState<number>(Date.now());

  // Fonction pour observer le dernier message
  const observeLastMessage = useCallback(() => {
    if (observer.current) observer.current.disconnect();

    const lastMessage = lastMessageElementRef.current?.lastChild as Element;
    if (lastMessage) {
      (observer as any).current.observe(lastMessage);
    }
  }, []);

  useEffect(() => {
    if (isLoading) return;
    if (!hasNextPage) return;
    observer.current = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          fetchNextPage();
        }
      },
      { threshold: 0.1 },
    );

    observeLastMessage();
    return () => observer.current?.disconnect();
  }, [isLoading, hasNextPage, fetchNextPage, observeLastMessage]);

  useEffect(() => {
    if (!hasNextPage) return;
    if (data && data?.pages.length > 0) {
      observeLastMessage();
    }
  }, [data, observeLastMessage]);

  useEffect(() => {
    if (data && data.pages[0][0] && !channelName) {
      setChannelName(data.pages[0][0].channelName);
    }
  }, [data]);

  // Fonction pour mettre à jour le dernier timestamp d'action
  const updateLastActionTimestamp = useCallback(() => {
    setLastActionTimestamp(Date.now());
  }, []);

  // Fonction pour vérifier l'inactivité
  const checkInactivity = useCallback(() => {
    const now = Date.now();
    const diff = now - lastActionTimestamp;
    return diff < 600000;
  }, [lastActionTimestamp]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (checkInactivity()) {
        refetch();
      }
    }, 20000); // 20 secondes

    return () => clearInterval(interval);
  }, [checkInactivity, refetch]);

  return (
    <PageLayout isError={isError} error={error} isLoading={isLoading}>
      <Box w={'100%'} display="flex" flexDirection="column" height="100%">
        {data && <PageTitleUpdater additionalTitle={`Communauté - Channel - ${channelName}`} />}
        <Ariane>
          <Flex alignItems="center">
            <Image src="/assets/icons/blue.svg" alt="Communauté" width="30px" />
            <Text as="h1" textTransform="uppercase" fontSize="19px" ml={4}>
              Communauté {channelName && `- ${channelName}`}
            </Text>
          </Flex>
        </Ariane>
        {isMobile && <SendDiscordMessage refContainer={lastMessageElementRef} channelId={channelId as string} />}

        <Box ref={lastMessageElementRef} css={customScrollBar} flex="1" overflowY="auto" mb={4} pr={!isMobile ? 2 : 0}>
          {data?.pages.map((page, i) => (
            <Box key={`message-${i}`}>
              {page.map((message: Message) => (
                <MessageCard key={message.id} message={message} />
              ))}
            </Box>
          ))}
        </Box>
        {!isMobile && <SendDiscordMessage updateLastActionTimestamp={updateLastActionTimestamp} refContainer={lastMessageElementRef} channelId={channelId as string} />}
      </Box>
    </PageLayout>
  );
};

export default Messages;
