import { Box, Flex, Image, Text, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';

import { CHANNELS_PATH, CONTESTS_PATH, EVENTS_PATH, HOME_BOARD, NEWS_LIST_PATH, USERS_SEARCH_PATH } from '../../../routing/constants';
import Linker from '../../../components/Linker';
import { customColors } from '../../../theme';

interface MenuContentProps {
  toggleContent?: () => void;
}

const MenuContent: React.FC<MenuContentProps> = ({ toggleContent }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const handleClick = () => {
    const url = '//discord.com/invite/0VJbJI6szxQG87LO';

    window.open(url, '_blank');
  };
  return (
    <Box background={customColors.blackTegrite} zIndex={20} position={'relative'}>
      <Linker to={HOME_BOARD}>
        <Flex align="center" onClick={() => (toggleContent ? toggleContent() : undefined)}>
          <Image src={`/assets/icons/red.svg`} alt="Home" mr={2} width="32px" />
          <Text textTransform="uppercase" fontWeight="bold" ml={2}>
            Accueil
          </Text>
        </Flex>
      </Linker>
      <Linker to={EVENTS_PATH}>
        <Flex align="center" mt={2} onClick={() => (toggleContent ? toggleContent() : undefined)}>
          <Image src={`/assets/icons/green.svg`} alt="événements" mr={2} width="32px" />
          <Text textTransform="uppercase" fontWeight="bold" ml={2}>
            événements
          </Text>
        </Flex>
      </Linker>
      <Linker to={NEWS_LIST_PATH}>
        <Flex align="center" mt={2} onClick={() => (toggleContent ? toggleContent() : undefined)}>
          <Image src={`/assets/icons/purple.svg`} alt="Actualités" mr={2} width="32px" />
          <Text textTransform="uppercase" fontWeight="bold" ml={2}>
            Actualités
          </Text>
        </Flex>
      </Linker>
      <Linker to={CONTESTS_PATH}>
        <Flex align="center" mt={2} onClick={() => (toggleContent ? toggleContent() : undefined)}>
          <Image src={`/assets/icons/yellow.svg`} alt="Concours" mr={2} width="32px" />
          <Text textTransform="uppercase" fontWeight="bold" ml={2}>
            Concours
          </Text>
        </Flex>
      </Linker>
      <Linker to={CHANNELS_PATH}>
        <Flex align="center" mt={2} onClick={() => (toggleContent ? toggleContent() : undefined)}>
          <Image src={`/assets/icons/blue.svg`} alt="Communauté" mr={2} width="32px" />
          <Text textTransform="uppercase" fontWeight="bold" ml={2}>
            Communauté
          </Text>
        </Flex>
      </Linker>
      {isMobile && (
        <>
          <Linker to={USERS_SEARCH_PATH}>
            <Flex align="center" mt={2} onClick={() => (toggleContent ? toggleContent() : undefined)}>
              <Image src="/assets/icons/eg.svg" alt="Les membres" mr={2} width="32px" />
              <Text textTransform="uppercase" fontWeight="bold" ml={2}>
                Les membres
              </Text>
            </Flex>
          </Linker>
          <Flex align="center" mt={2} cursor="pointer" onClick={handleClick}>
            <Image src="/assets/icons/eg.svg" alt="Les membres" mr={2} width="32px" />
            <Text textTransform="uppercase" fontWeight="bold" ml={2}>
              Discord
            </Text>
          </Flex>
        </>
      )}
    </Box>
  );
};

export default MenuContent;
