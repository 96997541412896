import { Box, Text, HStack, VStack, Heading, Spinner, Spacer, SkeletonText, SkeletonCircle, Divider, Center, useBreakpointValue } from '@chakra-ui/react';
import { useLatestDiscussions } from '../../hooks/discord/fetchLatestDiscussions';
import React, { useEffect, useRef, useState } from 'react';
import { customScrollBar, formatDuration } from '../../utils/helpers';
import { LastThread } from '../../types/Discord';
import { formatUri } from '../../utils/api/helpers';
import { THREAD_MESSAGES_PATH } from '../../routing/constants';
import { useNavigate } from 'react-router-dom';

const SkeletonDiscussion = () => (
  <HStack spacing="4" mb="3" align="center">
    <SkeletonCircle size="40px" startColor="#3B3B3B" endColor="#4A4A4A" />
    <VStack align="start" spacing="1">
      <SkeletonText noOfLines={1} w="125px" startColor="#3B3B3B" endColor="#4A4A4A" />
      <SkeletonText noOfLines={1} w="200px" startColor="#3B3B3B" endColor="#4A4A4A" />
    </VStack>
    <Spacer />
    <SkeletonText noOfLines={1} w="50px" startColor="#3B3B3B" endColor="#4A4A4A" />
  </HStack>
);

const Actus: React.FC = () => {
  const { data, isLoading } = useLatestDiscussions();
  const boxRef2 = useRef(null);
  const [localData, setLocalData] = useState<LastThread[]>([]);
  const [boxHeight, setBoxHeight] = useState<number | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const storedData = localStorage.getItem('latestDiscussions');
    if (storedData) {
      setLocalData(JSON.parse(storedData));
    }
  }, []);

  useEffect(() => {
    if (data) {
      localStorage.setItem('latestDiscussions', JSON.stringify(data));
      setLocalData(data);
    }
  }, [data]);

  useEffect(() => {
    if (boxRef2 && boxRef2.current) {
      const current = (boxRef2 as any).current;
      if (current && current.offsetHeight) {
        setTimeout(() => {
          const initialHeight = current.offsetHeight;
          setBoxHeight(initialHeight);
        }, 500);
      }
    }
  }, []);

  useEffect(() => {
    const updateHeight = () => {
      if (boxRef2 && boxRef2.current) {
        const current = (boxRef2 as any).current;
        if (current && current.offsetHeight && boxHeight) {
          const currentHeight = current.offsetHeight;
          setBoxHeight(currentHeight);
        }
      }
    };
    updateHeight();
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, [boxHeight]);

  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box ref={boxRef2} color={'white'} p={4} bgColor="brand.block" borderRadius={21} flex={1}>
      <HStack mb="4">
        <Heading as="h2" size="md">
          POSTS POPULAIRES
        </Heading>
        {isLoading && localData.length === 0 && <Spinner />}
      </HStack>
      <Box borderBottom="1px solid white" mb="4" />
      {boxHeight && (
        <Box css={customScrollBar} height={isMobile ? 'auto' : boxHeight ? `${boxHeight - 100}px` : 'auto'} overflowY="auto">
          {isLoading && localData.length === 0 ? (
            <VStack spacing="4">
              {[...Array(6)].map((_, index) => (
                <SkeletonDiscussion key={index} />
              ))}
            </VStack>
          ) : (
            localData.map(discussion => (
              <Box key={discussion.topicId} mb="2">
                <HStack cursor="pointer" spacing="4" mb="2" align="center" onClick={() => navigate(formatUri({ channelId: discussion.topicId }, THREAD_MESSAGES_PATH))}>
                  <VStack align="start" spacing="0" pl={2}>
                    <Text textStyle={'lastForumDiscussion.headerGreyLight'}>{discussion?.author?.username}</Text>
                    <Text textStyle={'lastForumDiscussion.content'}>{discussion.topicName}</Text>
                  </VStack>
                  <Spacer />
                  <Text fontSize="sm" mr={2} color="greyChupacabra">
                    {formatDuration(new Date().getTime() - new Date(discussion.lastMessageTimestamp).getTime())}
                  </Text>
                </HStack>
                <Center>
                  <Divider borderColor="greyChupacabra" width="95%" />
                </Center>
              </Box>
            ))
          )}
        </Box>
      )}
    </Box>
  );
};

export default Actus;
