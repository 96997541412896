import React from 'react';
import { Contest } from '../../../../types/Contests';
import { Box, Flex, Grid, Image, SimpleGrid, Text, useMediaQuery } from '@chakra-ui/react';
import UserAvatarProfile from '../../../../components/UserAvatarProfile';
import { formatUri } from '../../../../utils/api/helpers';
import { PROFILE_PATH } from '../../../../routing/constants';
import { useNavigate } from 'react-router-dom';

interface WinnerMediaProps {
  contest: Contest;
}
const Winner: React.FC<WinnerMediaProps> = ({ contest }) => {
  const isPluriel = contest.winner && contest.winner.length > 1;
  const navigate = useNavigate();
  const [isLessThan1050] = useMediaQuery('(max-width: 1050px)');
  const gridTemplateColumns = isLessThan1050 ? '1fr' : '0.8fr 0.2fr 1fr';

  const getNumbersOfColumns = () => {
    if (contest.winner.length === 1) {
      return 1;
    }
    if (contest.winner.length === 2) {
      return 2;
    }
    return 3;
  };

  return (
    <Grid templateColumns={gridTemplateColumns} zIndex="popover">
      {!isLessThan1050 && (
        <>
          <Box>
            <Flex alignItems="center" textAlign="center" direction="row" justifyContent="center" zIndex="popover" mt={10}>
              <Text as="h1">
                Gagnant{isPluriel && 's'} LEGENDAIRRRE{isPluriel && 'S'}
              </Text>
              <Text color="yellowBob" ml={2} mt={-4} transform="rotate(-20deg)" zIndex="popover">
                Dorrré{isPluriel && 's'}{' '}
                <Text as="span" zIndex="popover">
                  !
                </Text>
              </Text>
            </Flex>
            <Box mt={6}>
              <Text>
                Bravo,{' '}
                {contest.winner.map((winner, index) => (
                  <Text key={winner.username} as="span" fontWeight="bold">
                    {winner.username}
                    {index < contest.winner.length - 1 ? ', ' : '!'}
                  </Text>
                ))}
              </Text>
              {isPluriel ? (
                <Text mt={4}>Vos exploits résonnent dans les échos du royaume ! Le concours s'achève, mais vos noms demeurent gravés dans la légende !</Text>
              ) : (
                <Text mt={4}>Votre exploit résonne dans les échos du royaume ! Le concours s'achève, mais votre nom demeure gravé dans la légende !</Text>
              )}
            </Box>
          </Box>
          <Box />
        </>
      )}
      <Box textAlign="center" mt={isLessThan1050 ? 0 : 10}>
        {isLessThan1050 && (
          <Flex alignItems="center" position="relative" textAlign="center" direction="row" justifyContent="center" zIndex="popover" mb={4}>
            <Text as="h1">
              Gagnant{isPluriel && 's'} LEGENDAIRRRE{isPluriel && 'S'}
            </Text>
            <Text color="yellowBob" ml={2} mt={-4} transform="rotate(-20deg)" zIndex="popover">
              Dorrré{isPluriel && 's'}{' '}
              <Text as="span" zIndex="popover">
                !
              </Text>
            </Text>
          </Flex>
        )}
        <SimpleGrid justifyItems="center" columns={getNumbersOfColumns()} spacing={10}>
          {contest.winner.map(winner => (
            <Box
              key={winner.username}
              textAlign="center"
              cursor="pointer"
              onClick={() => navigate(formatUri({ username: winner.username }, PROFILE_PATH))}
              maxW="250px"
              _hover={{ transform: 'translateY(-4px)' }}
              transition="transform 0.2s "
              position="relative"
            >
              <Box zIndex="popover" position="relative">
                <UserAvatarProfile withBorder username={winner.username} media={winner.media} styles={{ size: 'lg' }} />
                <Text fontWeight="bold" fontSize="20px">
                  {winner.username}
                </Text>
              </Box>
              {!isLessThan1050 && (
                <Box position="absolute" left="-62px" top="-8px">
                  <Image src={'/assets/icons/homme-etoile.svg'} alt="winner" />
                </Box>
              )}
            </Box>
          ))}
        </SimpleGrid>
      </Box>
    </Grid>
  );
};
export default Winner;
