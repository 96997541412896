/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface PageTitleUpdaterProps {
  additionalTitle?: string;
  additionalDescription?: string;
  additionalKeywords?: string[];
  additionalOgImage?: string;
}

const updateMetaTag = (attrName: string, attrValue: string, content: string) => {
  let metaTag = document.querySelector(`meta[${attrName}='${attrValue}']`);
  if (!metaTag) {
    metaTag = document.createElement('meta');
    metaTag.setAttribute(attrName, attrValue);
    document.head.appendChild(metaTag);
  }
  metaTag.setAttribute('content', content);
};

const PageTitleUpdater: React.FC<PageTitleUpdaterProps> = ({ additionalTitle, additionalDescription, additionalKeywords, additionalOgImage }) => {
  const location = useLocation();

  useEffect(() => {
    const title = additionalTitle ? `Entre Geeks - ${additionalTitle}` : 'Entre Geeks';

    // Update title
    document.title = title;

    // Update meta description
    updateMetaTag(
      'name',
      'description',
      additionalDescription ||
        "Rejoignez le premier site communautaire dédié aux geeks ! Découvrez des sorties passionnantes, participez à des parties en ligne, engagez-vous dans des discussions enrichissantes, et profitez de concours exclusifs. Avec des activités de jeux de rôles (JDR), un serveur Discord actif, et bien plus, c'est l'endroit idéal pour réunir les amateurs de culture geek et de gaming. Connectez-vous dès maintenant pour une expérience communautaire unique !",
    );

    // Update meta keywords
    updateMetaTag('name', 'keywords', additionalKeywords?.join(', ') || 'geeks,geeks,rencontre,sortie,minecraft,tchat,cinema,ordinateur,programmation,manga,cosplay,anime,film,cinema, forum');

    // Update Open Graph tags
    updateMetaTag('property', 'og:title', title);
    updateMetaTag('property', 'og:site_name', 'Entre Geeks');
    updateMetaTag('property', 'og:image', additionalOgImage || `https://entregeeks.s3.eu-west-3.amazonaws.com/assets/logo512.png`);
    updateMetaTag(
      'property',
      'og:description',
      additionalDescription ||
        "Rejoignez le premier site communautaire dédié aux geeks ! Découvrez des sorties passionnantes, participez à des parties en ligne, engagez-vous dans des discussions enrichissantes, et profitez de concours exclusifs. Avec des activités de jeux de rôles (JDR), un serveur Discord actif, et bien plus, c'est l'endroit idéal pour réunir les amateurs de culture geek et de gaming. Connectez-vous dès maintenant pour une expérience communautaire unique !",
    );
  }, [location.pathname, additionalTitle, additionalDescription, additionalKeywords, additionalOgImage]);

  return null;
};

export default PageTitleUpdater;
