import React from 'react';
import { SimpleGrid, Text, useBreakpointValue, useMediaQuery } from '@chakra-ui/react';
import PageLayout from '../../layout/PageLayout';
import UserWithCoverCard from '../../components/UserWithCoverCard';
import { useLastConnectedMessageMembers } from '../../hooks/users/useLastConnectedMessageMembers';

const VisitesEmpty: React.FC = () => {
  const { data, isLoading } = useLastConnectedMessageMembers();
  const [isLessThan1050] = useMediaQuery('(max-width: 1050px)');
  const isMobileView = useBreakpointValue({ base: true, md: false });

  return (
    <PageLayout isLoading={isLoading}>
      <Text as="h1" mt={isMobileView ? 0 : 20} mb={4}>
        Pas encore de visites
      </Text>
      <Text mb={4}>
        Ta page de visites est actuellement aussi vierge qu'un nouveau disque dur ! Mais ne t'inquiète pas, nous avons rassemblé quelques membres de la communauté, un peu comme des personnages de jeu
        vidéo prêts pour une quête coopérative. Fais équipe avec eux et transforme cette tranquillité en une aventure épique pleine d'interactions !
      </Text>
      <SimpleGrid columns={isLessThan1050 ? [1] : [1, 2]} spacingX={5} spacingY={4} mb={isLessThan1050 ? 6 : 0}>
        {data && data.slice(0, 8).map(user => <UserWithCoverCard user={user} key={user.id} />)}
      </SimpleGrid>
    </PageLayout>
  );
};

export default VisitesEmpty;
