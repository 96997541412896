import React from 'react';
import PageTitleUpdater from '../../routing/PageTitleUpdater';
import { Box, Text } from '@chakra-ui/react';

const Maintenance: React.FC = () => {
  return (
    <>
      <PageTitleUpdater additionalTitle="Maintenance" />
      <Box position="relative" height="100vh" width="100vw" display="flex" alignItems="center" justifyContent="center">
        <Box position="relative">
          <img src="/assets/bg-template.png" alt="logo" />
          <Text
            as="h1"
            position="absolute"
            top="50%"
            left="50%"
            fontSize="6xl"
            transform="translate(-50%, -50%)"
            color="black" // Ajustez selon la couleur de fond de l'image
          >
            Site en maintenance
          </Text>
        </Box>
      </Box>
    </>
  );
};

export default Maintenance;
