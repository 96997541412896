import React from 'react';
import { useAppContext } from '../context/AppContext';

import { Outlet, useNavigate } from 'react-router-dom';
import {
  ABOUT_US_PATH,
  CGU,
  COMMUNITY_RULES_PATH,
  CONNEXION_PATH,
  CONTACT_PATH,
  CONTESTS_DETAILS_PATH,
  COOKIES_POLICY_PATH,
  DATA_PROTECTION_PATH,
  EVENTS_DETAIL_PATH,
  FAQ_PATH,
  HOME_BOARD,
  HOME_PATH,
  INSCRIPTION_PATH,
  LEGAL_NOTICE_PATH,
  MAINTENANCE,
  NEWS_PATH,
  PRIVACY_POLICY_PATH,
  REDIRECT_DISCORD_URI,
  RESET_PASSWORD_PATH,
} from '../routing/constants';
import { formatUri } from '../utils/api/helpers';
import { IS_MAINTENANCE } from '../utils/constants';
import useWindowZoom from '../hooks/useWindowZoom';
import ScrollToTop from '../components/ScrollToTop';
import PageTitleUpdater from '../routing/PageTitleUpdater';

const isPublicPath = (path: string) => {
  const staticPublicPaths = [
    RESET_PASSWORD_PATH,
    COMMUNITY_RULES_PATH,
    CONTACT_PATH,
    HOME_PATH,
    INSCRIPTION_PATH,
    CONNEXION_PATH,
    REDIRECT_DISCORD_URI,
    CGU,
    PRIVACY_POLICY_PATH,
    DATA_PROTECTION_PATH,
    LEGAL_NOTICE_PATH,
    ABOUT_US_PATH,
    COOKIES_POLICY_PATH,
    FAQ_PATH,
    MAINTENANCE,
  ];

  // Vérifiez les chemins statiques
  if (staticPublicPaths.includes(path)) {
    return true;
  }

  // Vérifiez les chemins dynamiques
  return path.startsWith('/sorties/') || path.startsWith('/games/') || path.startsWith('/streams/') || path.startsWith('/news/') || path.startsWith('/public/');
};

const LayoutNoAuth: React.FC = () => {
  useWindowZoom(2000);
  const { user } = useAppContext();
  const isPublicRoute = isPublicPath(location.pathname);
  const navigate = useNavigate();

  if (location.pathname !== MAINTENANCE && IS_MAINTENANCE === 'true') {
    navigate(MAINTENANCE);
  }

  if (isPublicRoute && user && IS_MAINTENANCE === 'false') {
    const path = window.location.pathname;
    const slug = path.split('/').pop();

    if (location.pathname.startsWith('/sorties/')) {
      navigate(formatUri({ slug, type: 'sorties' }, EVENTS_DETAIL_PATH));
    } else if (location.pathname.startsWith('/games/')) {
      navigate(formatUri({ slug, type: 'games' }, EVENTS_DETAIL_PATH));
    } else if (location.pathname.startsWith('/streams/')) {
      navigate(formatUri({ slug, type: 'streams' }, EVENTS_DETAIL_PATH));
    } else if (location.pathname.startsWith('/news/')) {
      navigate(formatUri({ slug }, NEWS_PATH));
    } else if (location.pathname.startsWith('/public/concours')) {
      navigate(formatUri({ slug }, CONTESTS_DETAILS_PATH));
    } else {
      navigate(HOME_BOARD);
    }
  }

  return (
    <>
      <ScrollToTop />
      <PageTitleUpdater />
      <Outlet />
    </>
  );
};

export default LayoutNoAuth;
