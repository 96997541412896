export const HOME_PATH = '/';
export const HOME_BOARD = '/board';
export const INSCRIPTION_PATH = '/inscription';
export const REDIRECT_DISCORD_URI = '/redirect-discord';
export const CONNEXION_PATH = '/connexion';
export const ACCOUNT_PATH = '/compte';

export const CHANNELS_PATH = '/communaute';
export const THREAD_TAG_PATH = '/communaute/tag/:tagId';
export const THREAD_CREATE_PATH = '/communaute/create/:channelId';
export const THREAD_CHANNEL_PATH = '/communaute/channel/:channelId';
export const THREAD_MESSAGES_PATH = '/communaute/messages/:channelId';

export const EVENTS_PATH = '/evenements';
export const EVENTS_CREATE_PATH = '/evenements/creation';
export const EVENTS_UPDATE_PATH = '/evenements/update/:slug';
export const EVENTS_DETAIL_PATH = '/evenements/:type/:slug';

export const PROFILE_PATH = '/profile/:username';

export const USERS_SEARCH_PATH = '/membres/recherche';

export const CONTACT_AUTH = '/auth/contact';

export const CONTESTS_PATH = '/concours';
export const CONTESTS_DETAILS_PATH = '/concours/:slug';

export const ADMIN_PATH = '/admin-panel';
export const ADMIN_MEDIA_TO_VALIDATE = '/admin-panel/media-to-validate';
export const ADMIN_CREATE_NEWS_PATH = '/admin-panel/news/create';
export const ADMIN_GAME_ADD_PATH = '/admin-panel/game/add';
export const ADMIN_BOT_MESSAGE_PATH = '/admin-panel/bot/message';
export const ADMIN_DISCORD_ROLES_PATH = '/admin-panel/discord/roles';
export const ADMIN_DISCORD_ROLE_CREATE_PATH = '/admin-panel/discord/roles/create';
export const ADMIN_DISCORD_ROLE_EDIT_PATH = '/admin-panel/discord/roles/edit/:id';
export const ADMIN_UPDATE_NEWS_PATH = '/admin-panel/news/update/:slug';
export const ADMIN_USERS_PATH = '/admin-panel/users';
export const ADMIN_USERS_VALIDATE_ACCOUNT_PATH = '/admin-panel/validate-account';
export const ADMIN_USERS_VALIDATE_UPDATE_PATH = '/admin-panel/validate-update-account';
export const ADMIN_EVENTS_VALIDATE_PATH = '/admin-panel/validate-events';
export const ADMIN_CONTEST_CREATE_PATH = '/admin-panel/concours/creation';
export const ADMIN_CONTESTS_LIST_PATH = '/admin-panel/concours/liste';
export const ADMIN_CONTESTS_SET_WINNER_PATH = '/admin-panel/concours/winner/:slug';
export const ADMIN_CONTEST_UPDATE_PATH = '/admin-panel/concours/update/:slug';
export const ADMIN_PROFILE_UPDATE_PATH = '/admin-panel/profile/update/:username';
export const ADMIN_CONNECTED = '/admin-panel/connectes';

export const NEWS_LIST_PATH = '/actus';
export const NEWS_PATH = '/actus/:slug';

export const MESSAGES_CONVERSATIONS = '/messages/conversations';

export const VISITS = '/visites';

export const CGU_AUTH = '/auth/cgu-faq';

//public routes
export const PUBLIC_OUTING = '/sorties/:slug';
export const MAINTENANCE = '/maintenance';
export const PUBLIC_GAMES = '/games/:slug';
export const PUBLIC_CONTESTS = '/public/concours/:slug';
export const PUBLIC_STREAMS = '/streams/:slug';
export const PUBLIC_NEWS = '/news/:slug';
export const CGU = '/cgu';
export const DATA_PROTECTION_PATH = '/protection-donnees';
export const LEGAL_NOTICE_PATH = '/mentions-legales';
export const PRIVACY_POLICY_PATH = '/politique-confidentialite';
export const ABOUT_US_PATH = '/a-propos';
export const CONTACT_PATH = '/contact';
export const COMMUNITY_RULES_PATH = '/regles-communaute';
export const COOKIES_POLICY_PATH = '/politique-cookies';
export const FAQ_PATH = '/foire-aux-questions';
export const RESET_PASSWORD_PATH = '/reinitialisation-mot-de-passe';
