import { Box, useBreakpointValue } from '@chakra-ui/react';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { customScrollBar } from '../../utils/helpers';
import { customColors } from '../../theme';
import { useLocation } from 'react-router-dom';
import { ACCOUNT_PATH, CONTACT_AUTH, EVENTS_CREATE_PATH, HOME_BOARD, MESSAGES_CONVERSATIONS, NEWS_LIST_PATH, USERS_SEARCH_PATH, VISITS } from '../../routing/constants';

const PathWithGradiant = [HOME_BOARD, NEWS_LIST_PATH, MESSAGES_CONVERSATIONS, CONTACT_AUTH, VISITS, ACCOUNT_PATH, USERS_SEARCH_PATH, EVENTS_CREATE_PATH];

const isWithGradiant = (path: string) => {
  if (PathWithGradiant.includes(path)) {
    return true;
  }

  if (path.startsWith('/actus/')) {
    return false;
  }

  return path.startsWith('/profile/') || path.startsWith('/actus') || path.startsWith('/communaute');
};

const Content: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [boxHeight, setBoxHeight] = useState(null);
  const boxRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (boxRef.current) {
      setTimeout(() => {
        const initialHeight = (boxRef as any).current.offsetHeight;
        setBoxHeight(initialHeight);
      }, 500);
    }
  }, []);

  useEffect(() => {
    // Fonction pour mettre à jour la hauteur lors du redimensionnement
    const updateHeight = () => {
      if (boxRef.current && boxHeight) {
        const currentHeight = (boxRef as any).current.offsetHeight;
        setBoxHeight(currentHeight);
      }
    };

    // Appel initial
    updateHeight();

    // Ajout de l'écouteur d'événement
    window.addEventListener('resize', updateHeight);

    // Suppression de l'écouteur d'événement au nettoyage
    return () => window.removeEventListener('resize', updateHeight);
  }, [boxHeight]);

  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box
      mt={isMobile ? '72px' : 0}
      ref={boxRef}
      background={!isMobile && isWithGradiant(location.pathname) ? `linear-gradient(180deg, rgba(43, 43, 43, 0.69) 0%, ${customColors.blackTegrite} 41%)` : customColors.blackTegrite}
      color="brand.white"
      borderRadius={21}
      overflow="hidden"
      flexGrow={1}
    >
      {(boxHeight || isMobile) && (
        <Box
          position="relative"
          px={7}
          pt={5}
          width={'100%'}
          css={customScrollBar}
          minH={isMobile ? 'calc(100vh - 95px)' : 'auto'}
          height={isMobile ? 'auto' : boxHeight ? `${boxHeight - 10}px` : 'auto'}
          overflowY={isMobile ? 'visible' : 'auto'}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};

export default Content;
