import { Box } from '@chakra-ui/react';
import React from 'react';
import { User } from '../../types/user';
import UserMenuContent from './UserMenuContent';

interface UserMenuProps {
  user: User;
}

const UserMenu: React.FC<UserMenuProps> = ({ user }) => {
  return (
    <Box
      position="absolute"
      top="100%"
      right={0}
      zIndex={1000}
      bg="blackTegrite" // Couleur de fond du menu
      boxShadow="0 1px 12px 4px rgba(43, 44, 44,1)" // Ombre du menu
      borderRadius="md" // Bordures arrondies
      minWidth="200px" // Largeur minimale
    >
      <UserMenuContent user={user} />
    </Box>
  );
};

export default UserMenu;
