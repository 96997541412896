import * as yup from 'yup';

export const UserUpdateGeneralYupSchema = yup.object().shape({
  userProfile: yup.object().shape({
    presentation: yup.string(),
    socialNetworks: yup.object().shape({
      instagram: yup.string(),
      facebook: yup.string(),
      x: yup.string(),
      youtube: yup.string(),
      pinterest: yup.string(),
      tiktok: yup.string(),
    }),
    gamesId: yup.object().shape({
      xbox: yup.string(),
      psn: yup.string(),
      steam: yup.string(),
      nintendo: yup.string(),
      blizzard: yup.string(),
      leagueOfLegend: yup.string(),
      origin: yup.string(),
    }),
  }),
});
