import React from 'react';
import { GamesId, SocialNetworks } from '../../../types/userProfile';
import { Text, Flex, Box } from '@chakra-ui/react';
import TemplateGamesIdAndSocial from './TemplateGamesIdAndSocial';

interface SocialAndGamesIdComponentProps {
  gamesId: GamesId;
  socialNetwork: SocialNetworks;
}

const SocialAndGamesIdComponent: React.FC<SocialAndGamesIdComponentProps> = ({ gamesId, socialNetwork }) => {
  return (
    <Box>
      <Text as="h3" mb={2} mt={6} color="yellowBob">
        Réseaux sociaux
      </Text>
      <Flex flexWrap="wrap" justifyContent="flex-start">
        {gamesId.xbox && <TemplateGamesIdAndSocial keyName="xbox" name="Xbox" id={gamesId.xbox} />}
        {gamesId.psn && <TemplateGamesIdAndSocial keyName="playstation" name="Playstation" id={gamesId.psn} />}
        {gamesId.steam && <TemplateGamesIdAndSocial keyName="steam" name="Steam" id={gamesId.steam} />}
        {gamesId.nintendo && <TemplateGamesIdAndSocial keyName="nintendo" name="Nintendo" id={gamesId.nintendo} />}
        {gamesId.blizzard && <TemplateGamesIdAndSocial keyName="blizzard" name="Blizzard" id={gamesId.blizzard} />}
        {gamesId.leagueOfLegend && <TemplateGamesIdAndSocial keyName="leagueOfLegend" name="League of Legend" id={gamesId.leagueOfLegend} />}
        {gamesId.origin && <TemplateGamesIdAndSocial keyName="origin" name="Origin" id={gamesId.origin} />}

        {socialNetwork.facebook && <TemplateGamesIdAndSocial keyName="facebook" name="Facebook" id={socialNetwork.facebook} />}
        {socialNetwork.instagram && <TemplateGamesIdAndSocial keyName="instagram" name="Instagram" id={socialNetwork.instagram} />}
        {socialNetwork.x && <TemplateGamesIdAndSocial keyName="x" name="X" id={socialNetwork.x} />}
        {socialNetwork.youtube && <TemplateGamesIdAndSocial keyName="youtube" name="Youtube" id={socialNetwork.youtube} />}
        {socialNetwork.pinterest && <TemplateGamesIdAndSocial keyName="pinterest" name="Pinterest" id={socialNetwork.pinterest} />}
        {socialNetwork.tiktok && <TemplateGamesIdAndSocial keyName="tiktok" name="Tiktok" id={socialNetwork.tiktok} />}
      </Flex>
    </Box>
  );
};

export default SocialAndGamesIdComponent;
