import { useQuery, UseQueryResult } from 'react-query';
import { API_NEWS } from '../../utils/api/constants';
import api from '../../utils/api/api';
import { News } from '../../types/News';
import { QueryNewsParams } from '../../pages/news/newsList/constants';

export const useNews = (queryParams: QueryNewsParams): UseQueryResult<{ news: News[]; total: number }, unknown> => {
  return useQuery(['newList', queryParams.skip, queryParams.limit, queryParams.title], async () => {
    const params = new URLSearchParams();
    params.append('skip', queryParams.skip.toString());
    params.append('limit', queryParams.limit.toString());
    if (queryParams.title) params.append('title', queryParams.title);

    const { data } = await api.get(`${API_NEWS}?${params.toString()}`);
    return data;
  });
};
