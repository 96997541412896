import React, { useState } from 'react';
import { InscriptionForm } from './InscriptionForm';
import { AuthChoice } from './AuthChoice';
import FormLayout from '../../layout/noAuth/wrappers/FormLayout';
import PageTitleUpdater from '../../routing/PageTitleUpdater';
import { getRandomNumber } from '../../utils/helpers';

const Inscription: React.FC = () => {
  const [authChoice, setAuthChoice] = useState<boolean>(false);
  const [discordData, setDiscordData] = useState<any>(undefined);

  return (
    <FormLayout media={`/assets/connexion/cover-${getRandomNumber(1, 7)}.png`}>
      <PageTitleUpdater additionalTitle="Inscription" />
      {authChoice ? <InscriptionForm discordData={discordData} /> : <AuthChoice setAuthChoice={setAuthChoice} setDiscordData={setDiscordData} />}
    </FormLayout>
  );
};

export default Inscription;
