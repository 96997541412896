import React, { useEffect } from 'react';
import { Box, Flex, Image } from '@chakra-ui/react';
import { User } from '../../../types/user';
import { useUpdateUser } from '../../../hooks/users/useUpdateUser';
import { useAppContext } from '../../../context/AppContext';
import FormImageUpload from '../../../components/forms/formImageUpload/FormImageUpload';
import { useForm } from 'react-hook-form';
import { UserUpdateCoverFormType } from '../constants';
import { isImageDataUri } from '../../../utils/constants';
import { toast } from 'react-toastify';
import { useDeleteUserMedia } from '../../../hooks/users/useDeleteUserMedia';

interface UpdateHeaderProps {
  user: User;
}

const UpdateHeaderCover: React.FC<UpdateHeaderProps> = ({ user }) => {
  const updateUser = useUpdateUser();
  const { setUser } = useAppContext();
  const { mutate: deleteMedia } = useDeleteUserMedia();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useForm<UserUpdateCoverFormType>({
    defaultValues: {
      mediaCover: user.mediaCover,
    },
    mode: 'onChange',
  });

  const watchedMedia = watch('mediaCover');

  useEffect(() => {
    if (watchedMedia && isImageDataUri(watchedMedia)) {
      onSubmit();
    }
  }, [watchedMedia, handleSubmit]);

  const onSubmit = async () => {
    try {
      const newUser = await updateUser.mutateAsync({ id: user.id, data: { mediaCover: getValues('mediaCover') } });
      setUser(newUser);
      toast.success('Photo de couverture mise à jour avec succès', {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (error) {
      toast.error("Une erreur s'est produite lors de la mise à jour du profile", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const handleDeleteMedia = () => {
    deleteMedia({ id: user.id, type: 'cover' });
  };

  return (
    <Box textAlign="center">
      <FormImageUpload placeholder="Changer la photo de couverture" name="mediaCover" cropHeight={266} cropWidth={1077} register={register} setValue={setValue} getValues={getValues} errors={errors} />
      {user.mediaCover && (
        <Flex cursor="pointer" justifyContent="center" alignItems="center" onClick={handleDeleteMedia}>
          <Image src={`/assets/icons/delete.svg`} alt="Discord" width="30px" /> Supprimer la photo de couverture
        </Flex>
      )}
    </Box>
  );
};

export default UpdateHeaderCover;
