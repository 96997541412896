import React, { useState } from 'react';
import { FormControl, FormLabel, Menu, MenuButton, MenuList, MenuItem, Button, FormErrorMessage } from '@chakra-ui/react';
import { UseFormRegister, DeepMap, FieldError } from 'react-hook-form';
import { customColors } from '../../theme';

export interface FormSelectProps {
  label?: string;
  placeholder: string;
  name: string;
  register: UseFormRegister<any>;
  errors?: DeepMap<any, FieldError>;
  options?: { key: string; value: string }[];
  required?: boolean;
  multiple?: boolean;
  variant?: any;
  getValues?: any;
  sx?: any;
}

const FormSelect: React.FC<FormSelectProps> = ({ sx, variant = 'violet', label, getValues, placeholder, name, register, errors, options, required = false }) => {
  const [selected, setSelected] = useState(getValues && getValues(name) ? getValues(name).toString() : '');
  const menuStyles: any = {
    violet: {
      list: {
        border: 'none',
        bg: '#4D3650',
        color: 'white',
      },
      item: {
        _hover: {
          bg: '#939393',
          color: 'white',
        },
      },
    },
    whiteBorder: {
      button: {
        border: '1px solid',
        borderColor: customColors.greyChupacabra,
        bg: customColors.blackTegrite,
        color: 'white',
        width: '100%',
        textAlign: 'left',
        _hover: {
          bg: customColors.blackTegrite,
          color: 'white',
        },
      },
      list: {
        border: '1px solid',
        maxHeight: '160px',
        overflowY: 'auto',
        borderColor: customColors.greyChupacabra,
        bg: customColors.blackTegrite,
        color: 'white',
      },
      item: {
        bg: customColors.blackTegrite,
        _hover: {
          bg: customColors.yellowBob,
          color: 'white',
        },
      },
    },
  };

  const currentStyle = menuStyles[variant];
  const handleChange = (key: string) => {
    setSelected(key);
    if (register) {
      register(name).onChange({ target: { name, value: key } }); // Mise à jour du formulaire avec React Hook Form
    }
  };

  return (
    <FormControl sx={{ ...sx, width: '100%' }} isRequired={required} isInvalid={errors && !!errors[name]} mt={4}>
      {label && <FormLabel>{label}</FormLabel>}
      <Menu matchWidth>
        <MenuButton sx={{ ...currentStyle.button, width: '100%' }} as={Button}>
          {selected ? options?.find(option => option.key === selected)?.value : placeholder}
        </MenuButton>
        <MenuList sx={{ ...currentStyle.list, width: '100%' }}>
          {options?.map(({ key, value }) => (
            <MenuItem sx={currentStyle.item} key={key} onClick={() => handleChange(key)}>
              {value}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      <FormErrorMessage>{errors && errors[name] && errors[name].message}</FormErrorMessage>
    </FormControl>
  );
};

export default FormSelect;
