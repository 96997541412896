import { useQuery, UseQueryResult } from 'react-query';
import api from '../../utils/api/api';
import { API_MESSAGES_CONVERSATIONS_LAST_SEVEN } from '../../utils/api/constants';

export const fetchMessagesCountLastSevenDays = async (): Promise<any> => {
  const response = await api.get(API_MESSAGES_CONVERSATIONS_LAST_SEVEN);
  return response.data;
};

export const useGetMessagesCountLastSevenDays = (): UseQueryResult<any, unknown> => {
  return useQuery('messagesCountLastSevenDays', fetchMessagesCountLastSevenDays, {
    retry: 0,
  });
};
