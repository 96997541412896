import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import api from '../../utils/api/api';
import { API_USERS_ADMIN_DELETE, API_USERS_URI } from '../../utils/api/constants';

type DeleteUserResponse = {
  message: string;
};

type DeleteUserError = {
  message: string;
};

export const useDeleteUser = (): UseMutationResult<DeleteUserResponse, DeleteUserError, string, unknown> => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id: string) => {
      const { data } = await api.delete(`${API_USERS_ADMIN_DELETE}/${id}`);
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('adminUsers');
        queryClient.invalidateQueries('invalidAccounts');
      },
      onError: error => {
        console.error('Une erreur est survenue :', error);
      },
    },
  );
};
