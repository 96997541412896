import { useQuery, UseQueryResult } from 'react-query';
import { Contest } from '../../types/Contests';
import api from '../../utils/api/api';
import { API_CONTESTS } from '../../utils/api/constants';

export const useLatestContest = (): UseQueryResult<Contest | null, unknown> => {
  return useQuery<Contest | null>('latestContest', async () => {
    const { data } = await api.get(`${API_CONTESTS}/latest`);
    return data || null;
  });
};
