import React, { useEffect } from 'react';
import { Box, Image } from '@chakra-ui/react';
import { User } from '../../../types/user';
import { useUpdateUser } from '../../../hooks/users/useUpdateUser';
import { useAppContext } from '../../../context/AppContext';
import UserAvatarProfile from '../../../components/UserAvatarProfile';
import FormImageUpload from '../../../components/forms/formImageUpload/FormImageUpload';
import { useForm } from 'react-hook-form';
import { UserUpdateAvatarFormType } from '../constants';
import { isImageDataUri } from '../../../utils/constants';
import { toast } from 'react-toastify';
import { useDeleteUserMedia } from '../../../hooks/users/useDeleteUserMedia';

interface UpdateHeaderProps {
  user: User;
}

const UpdateHeaderAvatar: React.FC<UpdateHeaderProps> = ({ user }) => {
  const updateUser = useUpdateUser();
  const { setUser } = useAppContext();
  const { mutate: deleteMedia } = useDeleteUserMedia();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useForm<UserUpdateAvatarFormType>({
    defaultValues: {
      media: user.media,
    },
    mode: 'onChange',
  });

  const watchedMedia = watch('media');

  useEffect(() => {
    if (watchedMedia && isImageDataUri(watchedMedia)) {
      onSubmit();
    }
  }, [watchedMedia, handleSubmit]);

  const handleDeleteMedia = () => {
    deleteMedia({ id: user.id, type: 'media' });
  };

  const onSubmit = async () => {
    try {
      const newUser = await updateUser.mutateAsync({ id: user.id, data: { media: getValues('media') } });
      setUser(newUser);
      toast.success('Photo de profile mise à jour avec succès', {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (error) {
      toast.error("Une erreur s'est produite lors de la mise à jour du profile", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <Box textAlign="center">
      <UserAvatarProfile withBorder username={user.username} media={user.media} styles={{ size: 'xl' }} />
      {user.media && (
        <Box position="absolute" top="29px" left="104px">
          <Image cursor="pointer" src={`/assets/icons/delete.svg`} alt="Discord" width="30px" onClick={handleDeleteMedia} />
        </Box>
      )}
      <FormImageUpload withoutIcon placeholder="Modifier la photo" name="media" cropWidth={300} cropHeight={300} register={register} setValue={setValue} getValues={getValues} errors={errors} />
    </Box>
  );
};

export default UpdateHeaderAvatar;
