import React from 'react';
import Select from 'react-select';
import { FormControl, FormLabel, FormErrorMessage, useTheme } from '@chakra-ui/react';
import { UseFormRegister, DeepMap, FieldError } from 'react-hook-form';
import { customColors } from '../../theme';

interface AutoCompleteFormSelectProps {
  label?: string;
  name: string;
  placeholder: string;
  register: UseFormRegister<any>;
  errors?: DeepMap<any, FieldError>;
  options?: { label: string; value: string }[];
  required?: boolean;
  setValue: any;
  getValues: any;
  variant?: string;
}

const AutoCompleteFormSelect: React.FC<AutoCompleteFormSelectProps> = ({ setValue, getValues, placeholder, label, name, errors, options, required = false }) => {
  const theme = useTheme();

  const customStyles = {
    menu: (provided: any) => ({
      ...provided,
      border: `1px solid ${customColors.greyChupacabra}`,
      backgroundColor: customColors.blackTegrite,
    }),
    control: (provided: any) => ({
      ...provided,
      border: `1px solid ${customColors.greyChupacabra}`,
      backgroundColor: customColors.blackTegrite,
      cursor: 'pointer',
    }),
    option: (provided: any) => ({
      ...provided,
      backgroundColor: customColors.blackTegrite,
      color: theme.colors.brand.white,
      cursor: 'pointer',
    }),
    singleValue: (provided: any) => {
      return { ...provided, color: theme.colors.brand.white };
    },
  };

  const update = (option: any) => {
    setValue(name, option ? option.value : undefined, { shouldValidate: true, shouldDirty: true });
  };

  return (
    <FormControl isRequired={required} isInvalid={errors && !!errors[name]} mt={4}>
      {label && <FormLabel>{label}</FormLabel>}
      <Select
        placeholder={placeholder}
        value={options?.find(option => option.value === getValues(name)) || null}
        options={options}
        styles={customStyles}
        isMulti={false}
        isClearable
        onChange={option => update(option)}
      />
      <FormErrorMessage>{errors && errors[name] && errors[name].message}</FormErrorMessage>
    </FormControl>
  );
};

export default AutoCompleteFormSelect;
