import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import api from '../../utils/api/api';
import { API_EVENTS } from '../../utils/api/constants';

export const useJoinOrLeaveEvent = (): {
  joinEvent: UseMutationResult<void, Error, string>;
  leaveEvent: UseMutationResult<void, Error, string>;
} => {
  const queryClient = useQueryClient();

  const joinEvent = useMutation<void, Error, string>((contestId: string) => api.put(`${API_EVENTS}/${contestId}/join`).then(res => res.data), {
    onSuccess: () => {
      queryClient.refetchQueries('event');
      queryClient.refetchQueries('events');
    },
  });

  const leaveEvent = useMutation<void, Error, string>((contestId: string) => api.put(`${API_EVENTS}/${contestId}/leave`).then(res => res.data), {
    onSuccess: () => {
      queryClient.refetchQueries('event');
      queryClient.refetchQueries('events');
    },
  });

  return {
    joinEvent,
    leaveEvent,
  };
};
