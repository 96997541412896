import React, { useState } from 'react';
import { User } from '../types/user';
import { useBlockOrUnblockUser } from '../hooks/users/useBlockOrUnblockUser';
import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Textarea, useDisclosure } from '@chakra-ui/react';
import { useAppContext } from '../context/AppContext';

interface BlockUserButtonProps {
  user: User;
}

const BlockUserButton: React.FC<BlockUserButtonProps> = ({ user }) => {
  const { user: currentUser } = useAppContext();
  const { blockUser } = useBlockOrUnblockUser();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [reason, setReason] = useState('');
  const handleBlock = () => {
    onOpen();
  };

  const handleSubmit = () => {
    blockUser.mutate({ userId: user.id, reason: reason });
    onClose();
  };

  if (currentUser?.id === user.id) return null;

  return (
    <>
      <Button variant="navRedFull" ml={4} onClick={handleBlock} disabled={blockUser.isLoading}>
        Bloquer
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Bloquer et signaler l'utilisateur</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea placeholder="Raison du blocage" value={reason} onChange={e => setReason(e.target.value)} />
          </ModalBody>

          <ModalFooter>
            <Button variant="navRed" mr={3} onClick={onClose}>
              Fermer
            </Button>
            <Button variant="navYellow" onClick={handleSubmit}>
              Valider
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default BlockUserButton;
