import { useQuery, UseQueryResult } from 'react-query';
import { Contest } from '../../types/Contests';
import api from '../../utils/api/api';
import { API_CONTESTS_ADMIN } from '../../utils/api/constants';

export const useAdminContests = (): UseQueryResult<Contest[], unknown> => {
  return useQuery<Contest[]>('contests', async () => {
    const { data } = await api.get(API_CONTESTS_ADMIN);
    return data;
  });
};
