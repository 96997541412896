import React, { useEffect } from 'react';
import { useDiscordAuth } from '../../hooks/auth/useDiscordAuth';
import { Box, Flex, Image, Spinner, Text, useBreakpointValue } from '@chakra-ui/react';

interface authChoiceProps {
  setDiscordData: (data: any) => void;
  setAuthChoice: (choice: boolean) => void;
}

export const AuthChoice: React.FC<authChoiceProps> = ({ setAuthChoice, setDiscordData }) => {
  const { discordAuthMutation, openDiscordPopup } = useDiscordAuth();
  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    if (discordAuthMutation.isSuccess) {
      setDiscordData(discordAuthMutation.data);
      setAuthChoice(true);
    }
  }, [discordAuthMutation.isSuccess, discordAuthMutation.data, setAuthChoice]);

  if (discordAuthMutation.isLoading) {
    return <Spinner />;
  }

  return (
    <Box maxW="600px" mt={isMobile ? '30%' : 0} mx={isMobile ? 4 : 0}>
      <Box
        bg="#36393F"
        color="whiteWalter"
        px={4}
        py={10}
        borderRadius={10}
        boxShadow={'0 0 10px 0 rgba(0, 0, 0, 0.5)'}
        onClick={openDiscordPopup}
        _hover={{ transform: 'translateY(-4px)', boxShadow: 'lg' }}
        transition="transform 0.2s, box-shadow 0.2s"
        cursor="pointer"
      >
        <Flex textAlign="left" justifyContent="center" flexDirection={isMobile ? 'column' : 'row'} alignItems="center">
          <Image src="/assets/logos/discord-white.svg" alt="logo" maxH="41px" />
          <Text ml={4} mt={isMobile ? 4 : 0}>
            Inscris-toi avec ton compte Discord et bénéficie de toutes les fonctionnalités liées au site directement sur l'application !
          </Text>
        </Flex>
      </Box>
      <Flex align="center" my={10}>
        <Box flex="1" height="1px" bg="gray.300" alignSelf="center" />
        <Text mx={2} mb={1} fontSize="sm" color="white" px={20} alignSelf="center">
          ou
        </Text>
        <Box flex="1" height="1px" bg="gray.300" alignSelf="center" />
      </Flex>
      <Box
        bg="#36393F"
        color="whiteWalter"
        pl={2}
        pr={4}
        py={2}
        borderRadius={10}
        boxShadow={'0 0 10px 0 rgba(0, 0, 0, 0.5)'}
        onClick={() => setAuthChoice(true)}
        _hover={{ transform: 'translateY(-4px)', boxShadow: 'lg' }}
        transition="transform 0.2s, box-shadow 0.2s"
        cursor="pointer"
      >
        <Flex textAlign="left" justifyContent="center" alignItems="center" flexDirection={isMobile ? 'column' : 'row'}>
          <Image borderRadius={10} src="/assets/inscription/inscription.png" alt="logo" maxH="130px" />
          <Text ml={4} mt={isMobile ? 4 : 0}>
            Inscris-toi directement via le formulaire <br />
            d'Entre-Geeks, mais tu ne pourras pas bénéficier de toutes les fonctionnalités sur Discord 😔
          </Text>
        </Flex>
      </Box>
    </Box>
  );
};
