import api from '../../utils/api/api';
import { API_USERS_CHECK_EMAIL_URI, API_USERS_CHECK_USERNAME_URI } from '../../utils/api/constants';

export async function checkEmail(email: string): Promise<boolean> {
  try {
    const response = await api.post(API_USERS_CHECK_EMAIL_URI, { email });
    return response.data.isAvailable;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function checkUsername(username: string): Promise<boolean> {
  try {
    const response = await api.post(API_USERS_CHECK_USERNAME_URI, { username });
    return response.data.isAvailable;
  } catch (error) {
    console.error(error);
    return false;
  }
}
