import React, { useEffect, useState } from 'react';
import { QueryUserParams } from '../search/constants';
import { Box, Button, Flex, Image, SimpleGrid, Text, useDisclosure, useMediaQuery } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';
import { useSearchUsers } from '../../../hooks/users/useSearchUsers';
import PageLayout from '../../../layout/PageLayout';
import { SearchUsersModal } from '../search/SearchUsersModal';
import { customColors } from '../../../theme';
import { SearchIcon } from '@chakra-ui/icons';
import Ariane from '../../../components/Ariane';
import PageTitleUpdater from '../../../routing/PageTitleUpdater';
import UserWithCoverCard from '../../../components/UserWithCoverCard';

const UsersList: React.FC = () => {
  const [isLessThan1050] = useMediaQuery('(max-width: 1050px)');
  const [searchParams, setSearchParams] = useSearchParams();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const skip = searchParams.get('skip') ? parseInt(searchParams.get('skip')!) : 0;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const username = searchParams.get('username') || '';
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const location = {
    country: searchParams.get('location.country') || '',
    region: searchParams.get('location.region') || '',
    department: searchParams.get('location.department') || '',
  };
  const limit = 8;
  const [queryParams, setQueryParams] = useState<QueryUserParams>({
    skip,
    username,
    location,
    limit: limit,
  });
  const { data, isLoading, refetch } = useSearchUsers(queryParams);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [hover, setHover] = useState<boolean>(false);

  const hasPrevious = queryParams.skip > 0;
  const hasNext = queryParams.skip + limit < (data?.total || 0);

  const updateSkip = (skip: number) => {
    const newSearchParams = new URLSearchParams();
    newSearchParams.set('skip', skip.toString());
    if (queryParams.username) newSearchParams.set('username', queryParams.username.toString());
    if (queryParams.location?.country) newSearchParams.set('location.country', queryParams.location?.country.toString());
    if (queryParams.location?.department) newSearchParams.set('location.department', queryParams.location?.department.toString());
    setSearchParams(newSearchParams);
  };

  useEffect(() => {
    const newQueryParams = {
      username: searchParams.get('username') || undefined,
      location: {
        country: searchParams.get('location.country') || undefined,
        region: searchParams.get('location.region') || undefined,
        department: searchParams.get('location.department') || undefined,
      },
      skip: searchParams.get('skip') ? parseInt(searchParams.get('skip') as string) : 0,
    };

    if (JSON.stringify(newQueryParams) !== JSON.stringify(queryParams)) {
      setQueryParams(newQueryParams);
      refetch();
    }
  }, [searchParams, queryParams, refetch]);

  const getNestedValue = (obj: any, path: string[]): any => {
    return path.reduce((acc, key) => (acc && acc[key] ? acc[key] : null), obj);
  };

  const countFilterSearch = (queryParams: QueryUserParams) => {
    const queryKeys = [['username'], ['location', 'country'], ['location', 'department']];

    return queryKeys.reduce((count, path) => {
      const value = getNestedValue(queryParams, path);
      return value ? count + 1 : count;
    }, 0);
  };

  const onSubmit = (data: QueryUserParams) => {
    const newSearchParams = new URLSearchParams();
    if (data.username) newSearchParams.set('username', data.username.toString());
    if (data.location?.country) newSearchParams.set('location.country', data.location?.country.toString());
    if (data.location?.department) newSearchParams.set('location.department', data.location?.department.toString());
    setSearchParams(newSearchParams);
    onClose();
  };

  return (
    <Box>
      <PageTitleUpdater additionalTitle={`Membres`} />
      <Ariane>
        <Flex alignItems="center">
          <Image src="/assets/icons/blue-membre.svg" alt="Membres" width="30px" />
          <Text as="h1" textTransform="uppercase" fontSize="19px" ml={4}>
            Les membres
          </Text>
        </Flex>
      </Ariane>
      <Flex alignItems="center" justifyContent="right" mr={2} mb={4} mt={isLessThan1050 ? '0' : '100px'}>
        <Button
          mr={2}
          variant="navYellow"
          px={'7px'}
          color={isOpen ? customColors.blackTegrite : undefined}
          bg={isOpen ? customColors.yellowBob : undefined}
          onClick={onOpen}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <SearchIcon stroke={hover ? customColors.blackTegrite : customColors.yellowBob} width="20px" />
          {countFilterSearch(queryParams) > 0 && `+${countFilterSearch(queryParams)}`}
        </Button>
        <Button isDisabled={!hasPrevious} variant="navYellow" onClick={() => updateSkip(queryParams.skip - limit)} mr={2}>
          Précédent
        </Button>
        <Button isDisabled={!hasNext} variant="navYellow" onClick={() => updateSkip(queryParams.skip + limit)} mr={2}>
          Suivant
        </Button>
        <Text>
          {data?.total} Membre{data && data.total > 0 && 's'}
        </Text>
      </Flex>
      <PageLayout isLoading={isLoading}>
        <SimpleGrid columns={isLessThan1050 ? [1] : [1, 2]} spacingX={5} spacingY={4} mb={isLessThan1050 ? 6 : 0}>
          {data && data.users.map(user => <UserWithCoverCard user={user} key={user.id} />)}
        </SimpleGrid>
      </PageLayout>
      <SearchUsersModal isOpen={isOpen} onClose={onClose} onSubmit={onSubmit} />
    </Box>
  );
};

export default UsersList;
