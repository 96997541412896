/* eslint-disable max-len */
import React from 'react';
import MarkDownStyled from '../../../components/MarkDownStyled';
import PageLayout from '../../../layout/noAuth/wrappers/PageLayout';

const text = `# Politique de Confidentialité des Cookies

## Introduction
Notre site utilise des cookies pour améliorer l'expérience des utilisateurs et analyser le trafic sur notre site. Cette politique explique comment nous utilisons les cookies et vos choix concernant leur utilisation.

## Qu'est-ce qu'un Cookie ?
Un cookie est un petit fichier texte stocké sur votre appareil lorsque vous visitez un site web. Il permet au site de se souvenir de vos actions et préférences.

## Utilisation des Cookies par Notre Site
Nous utilisons exclusivement Google Analytics pour analyser le trafic sur notre site. Google Analytics utilise des cookies pour collecter des données sur le comportement des visiteurs de notre site. Ces données incluent des informations telles que le nombre de visiteurs, les pages visitées, et la durée des visites.

## Consentement pour l'Utilisation des Cookies
Votre consentement est requis pour l'utilisation des cookies de Google Analytics. Lors de votre première visite sur notre site, une bannière vous informera de notre utilisation des cookies et vous demandera votre consentement.

## Comment Gérer les Cookies ?
Vous avez le contrôle total sur les cookies et pouvez les gérer à travers les paramètres de votre navigateur. Vous pouvez choisir de bloquer ou de supprimer les cookies, mais cela peut affecter votre expérience sur notre site.

## Protection de Vos Données
Nous prenons la confidentialité des données très au sérieux. Les données collectées par Google Analytics sont anonymisées et ne permettent pas de vous identifier personnellement.

## Modifications de Notre Politique de Cookies
Nous pouvons modifier cette politique de cookies de temps en temps. Toutes les modifications seront publiées sur cette page.
`;
const CookiesPolicy: React.FC = () => {
  return (
    <PageLayout>
      <MarkDownStyled text={text} />
    </PageLayout>
  );
};

export default CookiesPolicy;
