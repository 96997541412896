import React, { useEffect, useState } from 'react';
import { Box, Image, useBreakpointValue, useMediaQuery } from '@chakra-ui/react';
import Footer from './footer/footer';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';

import Covers from './covers/Covers';
import LayoutHomeNoAuth from '../../../layout/noAuth/LayoutHomeNoAuth';
import MenuHomeNoAuth from '../../../layout/noAuth/MenuHomeNoAuth';
import Bottom from './Bottom';
import Mobile from './Mobile';

const HomeNoAuth: React.FC = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [currentIndex, setCurrentIndex] = useState<number | null>(0);
  const [localCurrentIndex, setLocalCurrentIndex] = useState<number | null>(null);
  const [opacity, setOpacity] = useState(1);
  const [isLessThan1718] = useMediaQuery('(max-width: 1718px)');
  const [isLessThan1532] = useMediaQuery('(max-width: 1532px)');
  const [isLessThan1400] = useMediaQuery('(max-width: 1400px)');
  const [isLessThan1335] = useMediaQuery('(max-width: 1335px)');
  const [isLessThan1244] = useMediaQuery('(max-width: 1244px)');

  useEffect(() => {
    if (localCurrentIndex !== null && currentIndex === null) {
      setOpacity(0);
      setTimeout(() => {
        setLocalCurrentIndex(null);
        setOpacity(1);
      }, 500);
      return;
    }
    if (localCurrentIndex === null && currentIndex === 0) return;
    setOpacity(0); // Commence par estomper

    setTimeout(() => {
      setLocalCurrentIndex(currentIndex);
      setOpacity(1);
    }, 500);
  }, [currentIndex]);

  const getImgSize = () => {
    if (isLessThan1335) {
      return '350px';
    }
    if (isLessThan1400) {
      return '450px';
    }
    if (isLessThan1532) {
      return '550px';
    }
    if (isLessThan1718) {
      return '650px';
    }
    if (isMobile) {
      return '100px';
    }
    return '850px';
  };

  return (
    <LayoutHomeNoAuth>
      <MenuHomeNoAuth currentIndex={localCurrentIndex} setCurrentIndex={setCurrentIndex} isMobile={isMobile} />
      <Covers currentIndex={localCurrentIndex === null ? 0 : localCurrentIndex} setCurrentIndex={setCurrentIndex} opacity={opacity} />

      <ParallaxProvider>
        <Box bg="yellowBob" color="whiteWalter" position="relative" data-nosnippet>
          <Mobile />
          <Bottom />
          {!isLessThan1244 && (
            <Parallax className="custom-parallax" speed={-50} style={{ position: 'absolute', bottom: 0, right: 0, zIndex: 0 }}>
              <Image src="/assets/homeNoAuth/skin.png" opacity="0.5" minW={getImgSize()} maxW={getImgSize()} />
            </Parallax>
          )}
        </Box>
      </ParallaxProvider>
      <Footer />
    </LayoutHomeNoAuth>
  );
};

export default HomeNoAuth;
