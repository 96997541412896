import React, { useEffect, useRef, useState } from 'react';
import { SketchPicker } from 'react-color';
import { Box, Button, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { useUpdateUser } from '../../hooks/users/useUpdateUser';
import { User } from '../../types/user';
import { customColors } from '../../theme';
import { useAppContext } from '../../context/AppContext';
import { toast } from 'react-toastify';

interface ColorPickerProps {
  user: User;
}

const ColorPicker: React.FC<ColorPickerProps> = ({ user }) => {
  const { setUser } = useAppContext();
  const [color, setColor] = useState<string>(user.bgColor ? user.bgColor : customColors.yellowBob);
  const [showPicker, setShowPicker] = useState(false);
  const pickerRef = useRef<HTMLDivElement | null>(null);
  const updateUser = useUpdateUser();

  const handleChangeComplete = (color: any) => {
    setColor(color.hex);
    document.body.style.backgroundColor = color.hex;
  };

  const handleInputClick = () => {
    setShowPicker(true);
  };

  useEffect(() => {
    // Fonction pour vérifier si le clic est en dehors du picker
    const handleClickOutside = (event: MouseEvent) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target as Node)) {
        setShowPicker(false);
      }
    };

    // Ajouter l'écouteur d'événements au document
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Nettoyage de l'écouteur
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const updateUserBgColor = async (colorNew: string) => {
    if (user) {
      const update = await updateUser.mutateAsync({ id: user.id, data: { bgColor: colorNew } });
      setUser(update);
      toast.success(`La couleur de fond a bien été mise à jour.`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const customPresetColors = [
    customColors.redRanger,
    customColors.greyChupacabra,
    customColors.greenFrog,
    customColors.orangeMecanique,
    customColors.blueTardis,
    customColors.purpleThanos,
    customColors.yellowBob,
  ];

  return (
    <Box position="relative" ref={pickerRef} maxW="150px">
      <InputGroup>
        <Input variant={'whiteBorder'} type="text" value={color} readOnly onClick={handleInputClick} style={{ cursor: 'pointer' }} />
        <InputRightElement>
          <Box w="20px" h="20px" bg={color} />
        </InputRightElement>
      </InputGroup>
      {showPicker && (
        <Box position="absolute" zIndex="10" bottom="100%" left="0">
          <SketchPicker color={color} onChangeComplete={handleChangeComplete} presetColors={customPresetColors} />
        </Box>
      )}
      <Box mt={2}>
        <Button variant="navGreen" onClick={() => updateUserBgColor(color)}>
          Valider
        </Button>
        <Button ml={2} variant="navYellow" onClick={() => updateUserBgColor(customColors.yellowBob)}>
          Reset
        </Button>
      </Box>
    </Box>
  );
};

export default ColorPicker;
