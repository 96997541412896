import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { newsSchema } from './yup/news';
import { NewsForm } from './constants';
import { Button, Flex, Image } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { useCreateNews } from '../../../hooks/news/useCreateNews';
import { useNavigate } from 'react-router-dom';
import { NEWS_PATH } from '../../../routing/constants';
import FormImageUpload from '../../../components/forms/formImageUpload/FormImageUpload';
import FormInput from '../../../components/forms/FormInput';
import FormTextarea from '../../../components/forms/FormTextarea';
import { formatUri } from '../../../utils/api/helpers';
import PageTitleUpdater from '../../../routing/PageTitleUpdater';
import InSavedChangesPrompt from '../../../components/InSavedChangesPrompt';
import { convertToBase64 } from '../../../utils/helpers';
import { News } from '../../../types/News';

const CreateNews: React.FC = () => {
  const mutation = useCreateNews();
  const navigate = useNavigate();
  const [currentAvatar, setCurrentAvatar] = React.useState<string | undefined>();
  const [newsCreated, setNewsCreated] = React.useState<News | undefined>();

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    watch,
    trigger,
    formState: { errors, isValid, isDirty },
  } = useForm<NewsForm>({
    resolver: yupResolver(newsSchema),
    mode: 'onBlur',
  });

  const onSubmit = async (data: NewsForm) => {
    try {
      const news = await mutation.mutateAsync(data);
      setNewsCreated(news);

      toast.success('News créé avec succès', {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (error: any) {
      toast.error(`Échec de la création de la news : ${error.response.data.message}`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  useEffect(() => {
    if (newsCreated) {
      navigate(formatUri({ slug: newsCreated.slug }, NEWS_PATH));
    }
  }, [newsCreated]);

  const watchedMedia = watch('media');
  useEffect(() => {
    if (watchedMedia && watchedMedia instanceof File) {
      trigger('media');
      convertToBase64(watchedMedia).then(base64 => {
        setCurrentAvatar(base64 as string);
      });
    }
  }, [watchedMedia]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PageTitleUpdater additionalTitle="Admin Panel - Création d'une news" />
      {currentAvatar && <Image src={currentAvatar} />}
      <FormImageUpload variant="whiteBorder" label="Couverture" placeholder="Couverture" name="media" required register={register} setValue={setValue} getValues={getValues} errors={errors} />
      <FormInput variant="whiteBorder" label="Titre" type="text" placeholder="Titre" name="title" required register={register} errors={errors} />
      <FormTextarea variant="whiteBorder" label="Text" placeholder="Text" name="text" required={true} errors={errors} register={register} getValues={getValues} setValue={setValue} />
      <FormInput variant="whiteBorder" label="Lien" type="text" placeholder="Lien" name="link" register={register} errors={errors} />
      <Flex justifyContent="flex-end" alignItems="center" mt={4} mb={4} w="100%">
        <Button variant="navYellow" mr={3} type="submit" isLoading={mutation.isLoading} isDisabled={!isValid}>
          Valider
        </Button>
      </Flex>
      {!newsCreated && <InSavedChangesPrompt isDirty={isDirty} />}
    </form>
  );
};

export default CreateNews;
