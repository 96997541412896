import React, { useEffect } from 'react';
import { OrderedList, ListItem, UnorderedList, Text, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, useDisclosure, ModalCloseButton } from '@chakra-ui/react';

import { useLocation } from 'react-router-dom';

const RegisterSuccessModal: React.FC = () => {
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const registerSuccess = location.state?.registerSuccess || false;

  useEffect(() => {
    if (registerSuccess) {
      onOpen();
    }
  }, [registerSuccess]);

  if (!registerSuccess) {
    return null;
  }

  return (
    <Modal isCentered size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text as="h1">Bienvenue sur Entre Geeks !</Text>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <Text>
            🎉 <strong>Bienvenue à bord d'entre-geeks</strong>, la galaxie ultime pour les passionnés de geek culture ! Nous sommes super excités de t'avoir parmi nous.
          </Text>
          <Text my={4}>Voici un rapide tour d'horizon de ce qui t'attend :</Text>
          <UnorderedList>
            <ListItem mt={2}>
              <Text>Des Sorties et Parties en Ligne : Rejoins ou organise des événements gaming, des rencontres IRL, et plus encore.</Text>
            </ListItem>
            <ListItem mt={2}>
              <Text>Des Streams Épiques : Découvre des streamers incroyables ou partage tes propres aventures en direct.</Text>
            </ListItem>
            <ListItem mt={2}>
              <Text>Un Chat sur Discord : Plonge dans des discussions animées et rencontre des membres de la communauté partageant les mêmes intérêts.</Text>
            </ListItem>
            <ListItem mt={2}>
              <Text>Des Concours et Loteries : Tente ta chance et gagne des jeux, des goodies, et plein d'autres surprises.</Text>
            </ListItem>
          </UnorderedList>
          <Text my={4} as="h1" fontSize="18px">
            Premiers pas
          </Text>
          <Text>Pour commencer, nous te recommandons de :</Text>
          <OrderedList>
            <ListItem mt={2}>
              <Text>Compléter ton Profil : Ajoute une photo, une bio sympa, et tes intérêts pour te faire connaître de la communauté.</Text>
            </ListItem>
            <ListItem mt={2}>
              <Text>Explorer le Discord : Jette un œil aux dernières discussions et partage tes pensées.</Text>
            </ListItem>
            <ListItem mt={2}>
              <Text>Participer à un Événement : Il y a toujours quelque chose de cool qui se prépare.</Text>
            </ListItem>
          </OrderedList>
          <Text mt={4}>Si tu as des questions ou besoin d'aide, notre équipe de support est là pour toi. N'hésite pas à nous contacter via le formulaire de contact !</Text>
          <Text mt={4}>Prêt(e) à plonger dans cette aventure épique ? L'univers geek d'entre-geeks n'attend plus que toi !</Text>
          <Text mt={4}>
            <strong>Amuse-toi bien !</strong>
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RegisterSuccessModal;
