import React from 'react';
import { Box } from '@chakra-ui/react';
import { useEvents } from '../../../hooks/events/useEvents';
import StreamData from './StreamData';
import { useCheckStreamStatus } from '../../../hooks/twitch/useCheckStreamStatus';
import { APP_URL_TWITCH } from '../../../utils/constants';

const Stream: React.FC = () => {
  const { data: events } = useEvents({ skip: 0, limit: 1, entityType: 1 });
  const { data } = useCheckStreamStatus();

  if (data) {
    return (
      <Box mb={4} borderRadius={21} width="100%" height="200px" position="relative" overflow="hidden">
        <iframe src={`https://player.twitch.tv/?channel=entregeeksgaming&parent=${APP_URL_TWITCH}`} height="100%" width="100%" allowFullScreen={true}></iframe>
      </Box>
    );
  }

  return <Box mb={4}>{events && <StreamData events={events.events} />}</Box>;
};

export default Stream;
