import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { useGetMessagesCountLastSevenDays } from '../../../hooks/message/useGetMessagesCountLastSevenDays';
import { Box, Divider, Text } from '@chakra-ui/react';

const MessagesCountChart: React.FC = () => {
  const { data, isLoading, isError } = useGetMessagesCountLastSevenDays();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError || !data) {
    return <div>Error loading data</div>;
  }

  return (
    <>
      <Box mb={4}>
        <Text fontSize="2xl" fontWeight="bold">
          Messages échangés au cours des 7 derniers jours
        </Text>
        <Divider mt={2} />
      </Box>
      <Box width="100%">
        <ResponsiveContainer width="100%" height={200}>
          <LineChart
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="_id" />
            <YAxis />
            <Tooltip />
            <Line type="monotone" dataKey="count" stroke="#8884d8" activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </>
  );
};

export default MessagesCountChart;
