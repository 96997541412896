import React from 'react';
import { Contest } from '../../../../types/Contests';
import { Box, Flex, Grid, Text, useBreakpointValue } from '@chakra-ui/react';
import UserAvatarProfile from '../../../../components/UserAvatarProfile';
import { formatUri } from '../../../../utils/api/helpers';
import { PROFILE_PATH } from '../../../../routing/constants';
import { useNavigate } from 'react-router-dom';
import { formatTextWithLineBreaks } from '../../../../utils/helpers';

interface WinnerLotterieProps {
  contest: Contest;
}
const WinnerLotterie: React.FC<WinnerLotterieProps> = ({ contest }) => {
  const navigate = useNavigate();
  const isPluriel = contest.winner && contest.winner.length > 1;
  const isMobile = useBreakpointValue({ base: true, md: false });
  const gridTemplateColumns = isMobile ? '1fr' : ['1.5fr 1fr 2fr'];
  return (
    <Grid templateColumns={gridTemplateColumns} gap={4} px={2} mt={'-30px'} h={isMobile ? 'auto' : 'calc(100vh - 450px)'} zIndex={1} position={'relative'}>
      <Box h={'100%'}>
        {!isMobile && (
          <Flex justifyContent="space-evenly" h="100%" direction="column">
            <Box>
              <Text as="h1" fontSize="16px" mb={2} color="yellowBob">
                Modalités de participation
              </Text>
              <Text fontSize="13px">{formatTextWithLineBreaks(contest.participationModalities)}</Text>
            </Box>
            <Box>
              <Text as="h1" fontSize="16px" mb={2} color="yellowBob">
                Critère de jugement
              </Text>
              <Text fontSize="13px">{formatTextWithLineBreaks(contest.judgmentCriterion)}</Text>
            </Box>
            <Box>
              <Text as="h1" fontSize="16px" mb={2} color="yellowBob">
                A gagner
              </Text>
              <Text fontSize="13px">{formatTextWithLineBreaks(contest.toWin)}</Text>
            </Box>
          </Flex>
        )}
      </Box>
      <Box />
      <Box>
        <Flex justifyContent="space-evenly" h="100%" direction="column">
          <Box>
            <Flex alignItems="center" pt={4} textAlign="center" direction="row" justifyContent="center">
              <Text as="h1">
                Gagnant{isPluriel && 's'} LEGENDAIRRRE{isPluriel && 'S'}
              </Text>
              <Text color="yellowBob" ml={2} mt={-4} transform="rotate(-20deg)">
                Dorrré{isPluriel && 's'} !
              </Text>
            </Flex>
            <Box mt={6}>
              <Text>
                Bravo,{' '}
                <Text as="span" fontWeight="bold">
                  {contest.winner && contest.winner.length !== 0 && contest.winner[0].username}
                  {contest.winnerDiscord && contest.winnerDiscord.length !== 0 && contest.winnerDiscord[0]}
                </Text>{' '}
                !
              </Text>
              <Text mt={4}>Vos exploits résonnent dans les échos du royaume ! Le concours s'achève, mais votre nom demeure gravé dans la légende</Text>
              <Text mt={4}>
                Aux autres valeureux participants, vos aventures ont marqué cette quête commune. Le rideau tombe sur ce concours, mais que vos sagas personnelles continuent de s'écrire avec grandeur
                et bravoure!
              </Text>
            </Box>
            <Box textAlign="center">
              {contest.winner && contest.winner.length !== 0 && (
                <Box mt={4}>
                  <Box onClick={() => navigate(formatUri({ username: (contest?.winner && contest.winner[0].username) || '' }, PROFILE_PATH))} cursor="pointer">
                    <UserAvatarProfile withBorder username={contest.winner[0].username} media={contest.winner[0].media} styles={{ size: 'xl' }} />
                    <Text fontWeight="bold" fontSize="20px" mt={2}>
                      {contest.winner[0].username}
                    </Text>
                  </Box>
                </Box>
              )}
              {contest.winnerDiscord && contest.winnerDiscord.length !== 0 && (
                <Box mt={4}>
                  <Box cursor="pointer">
                    <UserAvatarProfile withBorder username={contest.winnerDiscord[0]} media={'/assets/discord-avatar.png'} styles={{ size: 'xl' }} />
                    <Text fontWeight="bold" fontSize="20px" mt={2}>
                      {contest.winnerDiscord[0]}
                    </Text>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Flex>
      </Box>
    </Grid>
  );
};
export default WinnerLotterie;
