import { useQuery, UseQueryResult } from 'react-query';
import { API_USERS_REGISTER } from '../../utils/api/constants';
import { UserPublic } from '../../types/user';
import api from '../../utils/api/api';

const fetchLastRegisterMembers = async (): Promise<UserPublic[]> => {
  return api.get(API_USERS_REGISTER).then(res => res.data);
};

export const useLastRegisterMembers = (): UseQueryResult<UserPublic[], Error> => {
  return useQuery('lastRegisterMembers', fetchLastRegisterMembers);
};
