import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import api from '../../utils/api/api';
import { User } from '../../types/user';
import { API_USERS_URI } from '../../utils/api/constants';

// Type pour les paramètres de la mutation
type DeleteMediaParams = {
  id: string;
  type: 'media' | 'cover';
};

export const useDeleteUserMedia = (): UseMutationResult<User, Error, DeleteMediaParams, unknown> => {
  const queryClient = useQueryClient();

  return useMutation<User, Error, DeleteMediaParams>(({ id, type }) => api.patch(`${API_USERS_URI}/delete/media/${type}/${id}`).then(res => res.data), {
    onSuccess: () => {
      queryClient.refetchQueries();
    },
  });
};
