import { useQuery, UseQueryResult } from 'react-query';
import api from '../../utils/api/api';
import { API_CONTESTS } from '../../utils/api/constants';

import { Contest } from '../../types/Contests';

export const useContestBySlug = (slug: string): UseQueryResult<Contest | null, unknown> => {
  return useQuery(
    ['contest', slug],
    async () => {
      const { data } = await api.get(`${API_CONTESTS}/${slug}`);
      return data;
    },
    {
      enabled: slug !== '', // N'exécute la requête que si slug n'est pas une chaîne vide
    },
  );
};
