import React, { useEffect, useState } from 'react';
import { Avatar, AvatarProps, Box } from '@chakra-ui/react';
import { isImageDataUri, S3_URL } from '../utils/constants';
import { AiOutlineDown } from 'react-icons/ai';
import { customColors } from '../theme';

interface UserAvatarProps {
  username: string;
  media: string;
  styles?: AvatarProps;
  withBorder?: boolean;
  isDisplayIcon?: boolean;
}

const UserAvatarProfile: React.FC<UserAvatarProps> = ({ isDisplayIcon, username, media, styles, withBorder = false }) => {
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpacity(1);
    }, 50);

    return () => clearTimeout(timer);
  }, []);
  return (
    <Box
      style={{
        position: 'relative',
        opacity: opacity,
        transition: 'opacity 0.3s ease-in-out',
      }}
    >
      <Box
        p="12px" // Ajustez selon vos besoins
        borderRadius="full" // Pour garder la forme circulaire
        display="inline-block" // Pour empêcher le conteneur de prendre toute la largeur disponible
        border={withBorder ? `3px solid ${customColors.greyRoswel}` : 'none'}
      >
        <Avatar boxShadow="0 1px 12px 4px rgba(43, 44, 44,1)" name={username} src={media ? (isImageDataUri(media) ? media : `${S3_URL}/users/${media}`) : ''} {...styles} />
      </Box>
      {isDisplayIcon && (
        <Box
          style={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            border: '1px solid white',
            transform: 'translate( 0%, 20%)',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: customColors.blackTegrite,
            justifyContent: 'center',
            width: '15px',
            height: '15px',
          }}
        >
          <AiOutlineDown size={11} /> {/* L'icône utilisée */}
        </Box>
      )}
    </Box>
  );
};

export default UserAvatarProfile;
