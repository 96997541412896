import { QueryClient } from 'react-query';

export const defaultOptionsQueryClient = {
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 10 * 60 * 1000, // 10 minutes
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
    },
  },
};

export const queryClient = new QueryClient(defaultOptionsQueryClient);
