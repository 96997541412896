import { useQuery, UseQueryResult } from 'react-query';
import api from '../../utils/api/api';
import { API_DISCORD_ROLES } from '../../utils/api/constants';
import { DiscordRole } from '../../types/DiscordRole';

// Fonction pour obtenir les dernières discussions
const fetchDiscordRoles = async (): Promise<DiscordRole[]> => {
  return api.get(API_DISCORD_ROLES).then(res => res.data);
};

export const useDiscordRoles = (): UseQueryResult<DiscordRole[], Error> => {
  return useQuery('discordRoles', fetchDiscordRoles);
};
