import React, { useEffect, useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, Button, CheckboxGroup, Checkbox, Flex } from '@chakra-ui/react';
import { RoleType } from '../../../../../types/user';

interface UpdateRoleModalProps {
  isOpen: boolean;
  onClose: () => void;
  roles: RoleType[];
  onUpdateRoles: (newRoles: RoleType[]) => void;
}

const UpdateRoleModal: React.FC<UpdateRoleModalProps> = ({ isOpen, onClose, roles, onUpdateRoles }) => {
  const [localRoles, setLocalRoles] = useState<RoleType[]>(roles);

  const handleRolesChange = (newRoles: RoleType[]) => {
    setLocalRoles(newRoles);
  };

  const handleSubmit = () => {
    onUpdateRoles(localRoles);
    onClose();
  };

  useEffect(() => {
    setLocalRoles(roles);
  }, [roles]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Changer les rôles</ModalHeader>
        <ModalBody>
          <CheckboxGroup value={localRoles} onChange={handleRolesChange}>
            <Flex direction="column">
              <Checkbox value="ROLE_ADMIN">Admin</Checkbox>
              <Checkbox value="ROLE_MODERATOR">Moderateur</Checkbox>
              <Checkbox value="ROLE_STREAM">Stream</Checkbox>
              <Checkbox value="ROLE_NEWS">Newser</Checkbox>
              <Checkbox value="ROLE_USER">User</Checkbox>
            </Flex>
          </CheckboxGroup>
        </ModalBody>
        <ModalFooter>
          <Flex flexDir="row" justifyContent="flex-end" mt={4}>
            <Button ml={4} variant="navYellow" onClick={handleSubmit}>
              Valider
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UpdateRoleModal;
