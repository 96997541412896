import { Box, Text, HStack, VStack, Heading, useBreakpointValue } from '@chakra-ui/react';
import { useLastConnectedMembers } from '../../hooks/users/useLastConnectedMembers';
import React, { useEffect, useRef, useState } from 'react';
import UserAvatar from '../../components/UserAvatar';
import { customScrollBar } from '../../utils/helpers';
import { formatUri } from '../../utils/api/helpers';
import { PROFILE_PATH, USERS_SEARCH_PATH } from '../../routing/constants';
import { useNavigate } from 'react-router-dom';
import { SearchIcon } from '@chakra-ui/icons';
import { customColors } from '../../theme';

const Members: React.FC = () => {
  const { data } = useLastConnectedMembers();
  const boxRef = useRef(null);
  const [boxHeight, setBoxHeight] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (boxRef && boxRef.current) {
      const current = (boxRef as any).current;
      if (current && current.offsetHeight) {
        setTimeout(() => {
          const initialHeight = current.offsetHeight;
          setBoxHeight(initialHeight);
        }, 500);
      }
    }
  }, []);

  useEffect(() => {
    const updateHeight = () => {
      if (boxRef && boxRef.current) {
        const current = (boxRef as any).current;
        if (current && current.offsetHeight && boxHeight) {
          const currentHeight = current.offsetHeight;
          setBoxHeight(currentHeight);
        }
      }
    };
    updateHeight();
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, [boxHeight]);

  const isMobile = useBreakpointValue({ base: true, md: false }); // Modifier si besoin selon vos breakpoints

  return (
    <Box ref={boxRef} color={'white'} p={4} bgColor="brand.block" borderRadius={21} overflow="hidden" flex={1}>
      <HStack mb={2} justifyContent="space-between" alignItems="center">
        <Heading as="h2" size="md">
          MEMBRES
        </Heading>
        <SearchIcon stroke={customColors.whiteWalter} width="27px" onClick={() => navigate(USERS_SEARCH_PATH)} cursor="pointer" />
      </HStack>
      <Box borderBottom="1px solid white" mb="4" />
      {boxHeight && (
        <Box css={customScrollBar} height={isMobile ? 'auto' : boxHeight ? `${boxHeight - 100}px` : 'auto'} overflowY="auto">
          {data &&
            data.map(user => (
              <Box key={user.username} mb="4">
                <HStack cursor="pointer" spacing="4" mb="2" align="center" onClick={() => navigate(formatUri({ username: user.username }, PROFILE_PATH))}>
                  <UserAvatar withBorder user={user} styles={{ size: 'sm' }} />
                  <VStack align="start" spacing="1">
                    <Text fontWeight="bold">{user.username}</Text>
                    {user.location.city && <Text color="greyChupacabra">{user.location.city.length > 18 ? `${user.location.city.substring(0, 18)}...` : user.location.city}</Text>}
                    {!user.location.city && user.location.country && (
                      <Text color="greyChupacabra">{user.location.country.length > 18 ? `${user.location.country.substring(0, 18)}...` : user.location.country}</Text>
                    )}
                  </VStack>
                </HStack>
              </Box>
            ))}
        </Box>
      )}
    </Box>
  );
};

export default Members;
