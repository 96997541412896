import React from 'react';
import { Box } from '@chakra-ui/react';
import PageLayout from '../../../layout/noAuth/wrappers/PageLayout';
import FaqContent from '../FaqContent';
import PageTitleUpdater from '../../../routing/PageTitleUpdater';

const Faq: React.FC = () => {
  return (
    <PageLayout>
      <PageTitleUpdater additionalTitle="Faq" />
      <Box mb={20}>
        <FaqContent />
      </Box>
    </PageLayout>
  );
};

export default Faq;
