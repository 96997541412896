import { Center, Spinner } from '@chakra-ui/react';
import React from 'react';

const PageLoader: React.FC = () => {
  return (
    <Center h="100%" w="100%">
      <Spinner size="xl" />
    </Center>
  );
};

export default PageLoader;
