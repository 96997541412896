import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { API_DISCORD_SEND_DISCORD_MESSAGE } from '../../../utils/api/constants';
import api from '../../../utils/api/api';

interface SendDiscordMessagePayload {
  channelId: string;
  message: string;
}

export const useSendDiscordMessage = (): UseMutationResult<any, Error, SendDiscordMessagePayload, unknown> => {
  const queryClient = useQueryClient();

  return useMutation<any, Error, SendDiscordMessagePayload>((payload: SendDiscordMessagePayload) => api.post(API_DISCORD_SEND_DISCORD_MESSAGE, payload), {
    onSuccess: () => {
      queryClient.invalidateQueries('messages');
      queryClient.invalidateQueries('latestDiscussions');
    },
  });
};
