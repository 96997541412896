import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Button, Flex } from '@chakra-ui/react';
import FormInput from '../../../components/forms/FormInput';
import FormCheckbox from '../../../components/forms/FormCheckbox';
import FormSelect from '../../../components/forms/FormSelect';
import FormDatePicker from '../../../components/forms/FormDatePicker';
import { FormValues } from './EventsList';
import { useSearchParams } from 'react-router-dom';
import { getTypes } from '../create/constants';

interface EventFilterFormProps {
  onSubmit: (data: FormValues) => void;
}

const EventFilterForm: React.FC<EventFilterFormProps> = ({ onSubmit }) => {
  const [searchParams] = useSearchParams();
  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      name: searchParams.get('name') || undefined,
      old: searchParams.get('old') === 'true',
      entityType: searchParams.get('entityType') ? parseInt(searchParams.get('entityType')!) : undefined,
      date: searchParams.get('date') || undefined,
      type: searchParams.get('type') || undefined,
    },
  });

  const queryParamsFromUrl = useMemo(() => {
    return {
      name: searchParams.get('name') || undefined,
      old: searchParams.get('old') === 'true',
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      entityType: searchParams.get('entityType') ? parseInt(searchParams.get('entityType')!) : undefined,
      date: searchParams.get('date') || undefined,
      type: searchParams.get('type') || undefined,
    };
  }, [searchParams]);

  useEffect(() => {
    setValue('name', queryParamsFromUrl.name ? (queryParamsFromUrl.name as string) : undefined);
    setValue('old', queryParamsFromUrl.old);
    setValue('entityType', queryParamsFromUrl.entityType ? (queryParamsFromUrl.entityType as unknown as number) : undefined);
    setValue('date', queryParamsFromUrl.date || undefined);
    setValue('type', queryParamsFromUrl.type || undefined);
  }, []);

  const reset = () => {
    onSubmit({
      name: '',
      old: false,
      entityType: undefined,
      date: undefined,
      type: '',
    });
  };

  return (
    <Box p="4" mb={4} w="100%">
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInput variant="whiteBorder" label="Titre" type="text" placeholder="Titre" name="name" register={register} errors={errors} />
        <FormDatePicker
          variant="whiteBorder"
          isDateString
          defaultDate={queryParamsFromUrl.date}
          label="Date"
          placeholder="Date"
          name="date"
          errors={errors}
          register={register}
          getValues={getValues}
          setValue={setValue}
        />
        <FormSelect
          variant="whiteBorder"
          label="Genre"
          placeholder="Tous"
          name="entityType"
          getValues={getValues}
          register={register}
          options={[
            { key: '1', value: 'Stream' },
            { key: '2', value: 'Game' },
            { key: '3', value: 'Sortie' },
          ]}
        />
        <FormSelect label="Type" variant="whiteBorder" placeholder="Tous" name="type" register={register} errors={errors} getValues={getValues} options={getTypes()} />

        <Box mt={4}>
          <FormCheckbox variant="whiteBorder" defaultValue={queryParamsFromUrl.old} name="old" register={register} errors={errors}>
            Anciens events
          </FormCheckbox>
        </Box>

        <Flex flexDir="row" justifyContent="flex-end" mt={4}>
          <Button variant="navRed" type="button" onClick={reset}>
            Réinitialiser
          </Button>
          <Button ml={4} variant="navYellow" type="submit">
            Recherche
          </Button>
        </Flex>
      </form>
    </Box>
  );
};

export default EventFilterForm;
