import React from 'react';
import { Box, Flex, Text, Center } from '@chakra-ui/react';

interface AdminCardProps {
  title: string;
  alert?: boolean;
  icon: JSX.Element; // Vous pouvez utiliser des icônes de libraries comme FontAwesome, Material-UI, etc.
  onClick: () => void;
}

const AdminCard: React.FC<AdminCardProps> = ({ title, icon, onClick, alert }) => {
  return (
    <Box m={2} p={4} borderWidth="2px" borderRadius="lg" cursor="pointer" onClick={onClick} borderColor={alert ? 'red.500' : 'gray.500'} _hover={{ borderColor: alert ? 'red.500' : 'blue.500' }}>
      <Flex direction="column" h="100%" align="center" justify="center">
        <Center color={alert ? 'red.500' : 'gray.500'}>{icon}</Center>
        <Text fontSize="lg" mt={4} color={alert ? 'red.500' : 'gray.500'} textAlign="center">
          {title}
        </Text>
      </Flex>
    </Box>
  );
};

export default AdminCard;
