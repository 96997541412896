import { useMutation, useQueryClient, UseMutationResult } from 'react-query';
import { CreateThreadDataForm } from '../../../pages/forum/threads/create/constants';
import { API_DISCORD_THREAD_CREATE } from '../../../utils/api/constants';
import api from '../../../utils/api/api';

export const useCreateThread = (): UseMutationResult<{ threadId: string }, Error, { formData: CreateThreadDataForm; channelId: string }, unknown> => {
  const queryClient = useQueryClient();

  return useMutation<{ threadId: string }, Error, { formData: CreateThreadDataForm; channelId: string }>(
    ({ formData, channelId }) => api.post(`${API_DISCORD_THREAD_CREATE}/${channelId}`, formData).then(res => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('threads');
        queryClient.invalidateQueries('latestDiscussions');
      },
    },
  );
};
