import React, { useState } from 'react';
import { Box, Text, Flex, Image, Modal, ModalOverlay, ModalContent, ModalBody, useDisclosure } from '@chakra-ui/react';
import { Message } from '../../../types/Discord';
import ReactMarkdown from 'react-markdown';
import { convertUrlsToMarkdown, NAME_BOT } from '../../../utils/constants';
import { renderersMarkdown } from '../../../utils/helpers';
import UserStringAvatar from '../../../components/StringAvatar';
import { formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';
import { formatUri } from '../../../utils/api/helpers';
import { PROFILE_PATH } from '../../../routing/constants';
import { useNavigate } from 'react-router-dom';

interface MessageCardProps {
  message: Message;
}

const MessageCard: React.FC<MessageCardProps> = ({ message }) => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleImageClick = (url: string) => {
    setSelectedImage(url);
    onOpen();
  };

  const removeAndExtractPrefix = (message: string) => {
    const regex = /\*\*Message créé sur Entre-Geeks par (.*?)\*\*\n/;
    const matched = message.match(regex);

    if (matched && matched[1]) {
      return {
        cleanMessage: message.replace(regex, ''),
        originalAuthor: matched[1],
      };
    }
    return { cleanMessage: message, originalAuthor: null };
  };

  const { cleanMessage, originalAuthor } = removeAndExtractPrefix(message.content);

  const lines = cleanMessage.split('\n').map((line, index) => {
    const lineWithMarkdownLinks = convertUrlsToMarkdown(line);
    return (
      <Box key={`line-${index}`}>
        <ReactMarkdown components={renderersMarkdown}>{lineWithMarkdownLinks}</ReactMarkdown>
      </Box>
    );
  });

  return (
    <Box bg="whiteTegrite" overflow="hidden" p={4} w="100%" borderRadius={21} cursor="pointer" mb={4}>
      <Flex align="start" justify="left" h="full" mt={1}>
        <Box onClick={() => (originalAuthor && message.author.username === NAME_BOT ? navigate(formatUri({ username: message.author.username }, PROFILE_PATH)) : undefined)}></Box>
        <UserStringAvatar withBigBorder media={message.author.avatar || ''} name={message.author.username} styles={{ size: 'md' }} />
        <Box ml={3}>
          <Text fontSize="11px">{message.author.username}</Text>
          {originalAuthor && message.author.username === NAME_BOT && (
            <Text fontSize="11px" color="gray.500">
              Auteur original : {originalAuthor}
            </Text>
          )}
          <Text color="gray.500">{formatDistanceToNow(new Date(message.createdAt), { addSuffix: true, locale: fr })}</Text>
          <Box mt="2">{lines}</Box>
          {message.attachments && (
            <Flex mt="2">
              {message.attachments.map((url, index) => (
                <Box key={index} onClick={() => handleImageClick(url)}>
                  <Image src={url} alt="attachment" maxHeight="300px" cursor="pointer" />
                </Box>
              ))}
            </Flex>
          )}
        </Box>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Image src={selectedImage || ''} alt="selected-attachment" />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default MessageCard;
