import React, { useRef, useState } from 'react';
import { Box, Button, HStack, VStack, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay } from '@chakra-ui/react';
import UserAdminCard from './UserAdminCard';
import { useInvalidAccounts } from '../../../../hooks/users/useInvalidAccounts';
import { useDeleteUser } from '../../../../hooks/users/useDeleteUser';
import { useValidateAccounts } from '../../../../hooks/users/useValidateAccounts ';
import PageTitleUpdater from '../../../../routing/PageTitleUpdater';

const AccountValidation: React.FC = () => {
  const { data, isLoading } = useInvalidAccounts();
  const validateAccountsMutation = useValidateAccounts();
  const deleteUserMutation = useDeleteUser();

  const [isOpen, setIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const onClose = () => setIsOpen(false);
  const cancelRef = useRef(null);

  const handleValidateAll = () => {
    if (data) {
      const ids = data.map(user => user.id);
      validateAccountsMutation.mutate(ids);
    }
  };

  const handleValidate = (id: string) => {
    validateAccountsMutation.mutate([id]);
  };

  const handleDelete = (id: string) => {
    setDeleteId(id);
    setIsOpen(true);
  };

  const confirmDelete = () => {
    if (deleteId) {
      deleteUserMutation.mutateAsync(deleteId);
      setDeleteId(null);
    }
    onClose();
  };

  if (isLoading) {
    return <>Loading...</>;
  }

  return (
    <>
      <PageTitleUpdater additionalTitle="Admin Panel - Validation nouveaux membres" />
      <VStack spacing={4}>
        {data && data.length === 0 ? (
          <Box>Aucun utilisateur à valider</Box>
        ) : (
          <>
            <Box alignSelf="flex-end">
              <Button onClick={handleValidateAll}>Valider tous</Button>
            </Box>
            <Box width="100%">
              <HStack wrap="wrap" justify="center" alignItems="stretch">
                {data && data.map(user => <UserAdminCard key={user.id} user={user} onValidate={() => handleValidate(user.id)} onDelete={() => handleDelete(user.id)} />)}
              </HStack>
            </Box>
          </>
        )}
      </VStack>

      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Supprimer utilisateur
            </AlertDialogHeader>

            <AlertDialogBody>Êtes-vous sûr ? Cette action est irréversible.</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Annuler
              </Button>
              <Button colorScheme="red" onClick={confirmDelete} ml={3}>
                Supprimer
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default AccountValidation;
