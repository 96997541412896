import { useQuery, UseQueryResult } from 'react-query';
import api from '../../utils/api/api';
import { User } from '../../types/user';
import { API_STAFF_USERS_URI } from '../../utils/api/constants';

export const useStaffUser = (): UseQueryResult<User[], unknown> => {
  return useQuery<User[]>(
    ['staffUser'],
    async () => {
      const { data } = await api.get(API_STAFF_USERS_URI);
      return data;
    },
    { retry: 0 },
  );
};
