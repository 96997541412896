import React from 'react';
import { FormControl, FormLabel, FormErrorMessage, Slider, SliderTrack, SliderFilledTrack, SliderThumb, Box } from '@chakra-ui/react';
import { DeepMap, FieldError, UseFormRegister } from 'react-hook-form';

export interface SliderInputProps {
  label: string;
  placeholder: string;
  name: string;
  required?: boolean;
  defaultValue?: string;
  register: UseFormRegister<any>;
  errors: DeepMap<any, FieldError>;
  min?: number;
  max?: number;
  step?: number;
  setValue: any;
  getValues: any;
  variant?: any;
}

const SliderInput: React.FC<SliderInputProps> = ({ getValues, label, name, min = 1, max = 4, step = 1, setValue, errors, required }) => {
  const onChange = (val: number) => {
    if (setValue) {
      setValue(name, val, { shouldValidate: true });
    }
  };
  return (
    <FormControl isRequired={required} isInvalid={errors && !!errors[name]}>
      <FormLabel mb={-1}>{label}</FormLabel>
      <Slider colorScheme="greenToxic" value={getValues(name)} min={min} max={max} step={step} onChange={val => onChange(val)}>
        <SliderTrack>
          <SliderFilledTrack bg="greenToxic" />
        </SliderTrack>
        <SliderThumb boxSize={3}>
          <Box color="white" />
        </SliderThumb>
      </Slider>
      <FormErrorMessage>{errors && errors[name] && errors[name].message}</FormErrorMessage>
    </FormControl>
  );
};

export default SliderInput;
