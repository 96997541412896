import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton } from '@chakra-ui/react';

import EventFilterForm from '../eventsList/EventFilterForm';
import { FormValues } from '../eventsList/EventsList';

interface SearchEventsModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: FormValues) => void;
}

export const SearchEventsModal: React.FC<SearchEventsModalProps> = ({ isOpen, onClose, onSubmit }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Recherche</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <EventFilterForm onSubmit={onSubmit} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
