import { Box } from '@chakra-ui/react';
import React from 'react';

interface CardProps {
  children: React.ReactNode;
  isFixed?: boolean;
}

const Ariane: React.FC<CardProps> = ({ children, isFixed = false }) => {
  return (
    <Box pb={4} overflow="hidden" position={isFixed ? 'fixed' : 'initial'} zIndex="sticky">
      {children}
    </Box>
  );
};

export default Ariane;
