import * as yup from 'yup';

export const UserUpdateYupSchema = yup.object().shape({
  location: yup
    .object()
    .shape({
      country: yup.string().required('Le pays est requis'),
      city: yup.string(),
    })
    .required('La localisation est requise'),
  userProfile: yup.object().shape({
    presentation: yup.string(),
    socialNetworks: yup.object().shape({
      instagram: yup.string(),
      facebook: yup.string(),
      x: yup.string(),
      youtube: yup.string(),
    }),
    gamesId: yup.object().shape({
      xbox: yup.string(),
      psn: yup.string(),
      steam: yup.string(),
      nintendo: yup.string(),
    }),
  }),
});
