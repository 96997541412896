import { useMutation, useQueryClient, UseMutationResult } from 'react-query';
import api from '../../utils/api/api';
import { Contest } from '../../types/Contests';
import { API_CONTEST_UPDATE_ADMIN } from '../../utils/api/constants';
import { ContestUpdateFormType } from '../../pages/admin/contests/update/constants';

export const useUpdateContest = (): UseMutationResult<Contest, Error, { id: string; data: Partial<Contest> | ContestUpdateFormType }, unknown> => {
  const queryClient = useQueryClient();

  return useMutation<Contest, Error, { id: string; data: Partial<Contest> | ContestUpdateFormType }>(
    ({ id, data }) =>
      api
        .put(`${API_CONTEST_UPDATE_ADMIN}/${id}`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res => res.data),
    {
      onSuccess: () => {
        queryClient.refetchQueries('latestContest');
        queryClient.refetchQueries('contests');
        queryClient.refetchQueries('contest');
        queryClient.refetchQueries('activeContest');
      },
    },
  );
};
