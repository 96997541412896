import React, { useEffect, useRef, useState } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Image as ChakraImage,
  Text,
  Tooltip,
  useBreakpointValue,
  useDisclosure,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';

import { Event as EventType, Event } from '../../types/Events';
import { capitalizeFirstLetter, defaultImage, getTypeEventColor, getTypeString, isUrl, removeMarkdown } from '../../utils/helpers';
import { APP_URL, isStaff, PUPPETEER_URL, S3_URL } from '../../utils/constants';
import Ariane from '../../components/Ariane';
import UserStringAvatar from '../../components/StringAvatar';
import mdToText from 'markdown-to-text';
import { formatDateRange } from '../../utils/dates';
import GameRole from './event/GameRole';
import Linker from '../../components/Linker';
import { EVENTS_CREATE_PATH, EVENTS_DETAIL_PATH, EVENTS_PATH, EVENTS_UPDATE_PATH, HOME_BOARD, PROFILE_PATH } from '../../routing/constants';
import { formatUri } from '../../utils/api/helpers';
import JoinEventButton from './JoinEventButton';
import EventPresentationModal from './event/EventPresentationModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { customColors } from '../../theme';
import ShareModalButton from '../../components/ShareModalButton';
import { User } from '../../types/user';
import { useDeleteEvent } from '../../hooks/events/useDeleteEvent';
import { useAppContext } from '../../context/AppContext';

const UpdateButton = ({ event, user }: { event: EventType; user: User }) => {
  const currentDate = new Date();
  const endDateTime = new Date(event.scheduledEndTime);
  const isEnded = currentDate > endDateTime;

  if ((!isEnded && event.userId.id === user?.id) || isStaff(user)) {
    return (
      <Linker to={formatUri({ slug: event.slug }, EVENTS_UPDATE_PATH)} color="brand.blue">
        <Button variant="whiteButton">
          <Text>Modifier</Text>
        </Button>
      </Linker>
    );
  }

  return null;
};

const DeleteButton = ({ event }: { event: EventType }) => {
  const navigate = useNavigate();
  const deleteEventMutation = useDeleteEvent();
  const [isOpen, setIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>(null);

  const cancelRef = useRef(null);
  const onClose = () => setIsOpen(false);

  const handleDelete = (id: string) => {
    setDeleteId(id);
    setIsOpen(true);
  };

  const confirmDelete = async () => {
    if (deleteId) {
      await deleteEventMutation.mutateAsync(deleteId);
      setDeleteId(null);
      navigate(HOME_BOARD);
    }
    onClose();
  };

  return (
    <>
      <Button ml={2} variant="whiteButton" onClick={() => handleDelete(event.id)}>
        <Text>Supprimer</Text>
      </Button>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Supprimer l'event
            </AlertDialogHeader>

            <AlertDialogBody>Êtes-vous sûr ? Cette action est irréversible.</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Annuler
              </Button>
              <Button colorScheme="red" onClick={confirmDelete} ml={3}>
                Supprimer
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

interface HeaderEventProps {
  event: Event;
  isEventList?: boolean;
}

const HeaderEvent: React.FC<HeaderEventProps> = ({ event, isEventList }) => {
  const navigate = useNavigate();
  const { user } = useAppContext();
  const location = useLocation();
  const plainText = mdToText(event.description);
  const isTruncated = plainText.length > 250;
  const truncatedText = isTruncated ? plainText.slice(0, 250) + '...' : plainText;
  const [displayEvent, setDisplayEvent] = useState(event);
  const [isBlurred, setIsBlurred] = useState(false);
  const [isValidImage, setIsValidImage] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [isLessThan1556] = useMediaQuery('(max-width: 1556px)');

  useEffect(() => {
    if (event && event !== displayEvent) {
      setIsBlurred(true);
      setTimeout(() => {
        setDisplayEvent(event);
        setIsBlurred(false);
      }, 50);
    }
  }, [event, displayEvent]);

  useEffect(() => {
    const image = new Image();
    image.onload = () => setIsValidImage(true);
    image.onerror = () => setIsValidImage(false);

    // Ici, vous pouvez ajouter une logique supplémentaire pour savoir quelle URL vérifier
    image.src = isUrl(displayEvent.image) ? displayEvent.image : `${S3_URL}/events/${displayEvent.image}`;
  }, [displayEvent.image]);

  const redirectToEvent = () => {
    const previousPath = location.state?.from?.pathname || '/';
    if (previousPath.includes('events/')) {
      navigate(-1);
    } else {
      navigate(EVENTS_PATH);
    }
  };

  const isEnded = (event: Event) => {
    const currentDate = new Date();
    const endDateTime = new Date(event.scheduledEndTime);
    return currentDate > endDateTime;
  };

  return (
    <>
      <Box h={isMobile ? '230px' : '330px'}>
        <Box position="absolute" width="100%" height={isMobile ? '230px' : '338px'} ml={-7} mt={-5}>
          {/* Image comme fond d'une Box */}
          <Box
            as="div"
            bg={`url(${isValidImage ? (isUrl(displayEvent.image) ? displayEvent.image : `${S3_URL}/events/${displayEvent.image}`) : defaultImage}) center/cover no-repeat`}
            position="relative"
            width="100%"
            height="100%"
          >
            {/* Gradient */}
            <Box position="absolute" top="0" left="0" w="100%" h="100%" bgGradient={`linear(92deg, ${customColors.blackTegrite} 20%, rgba(43, 44, 44, 0) 100%)`} />

            {/* Contenu */}
            <Box position="relative" bottom="4" left="4" py="4" height="calc(100% - 8px)" mr={10}>
              <Ariane>
                <Flex justifyContent="space-between" alignItems="center" pt={4}>
                  <Flex alignItems="center">
                    <Flex alignItems="center" ml="4" onClick={() => (!isEventList ? redirectToEvent() : undefined)} cursor={!isEventList ? 'pointer' : 'default'}>
                      <ChakraImage src="/assets/icons/green.svg" alt="EVENEMENTS" width="30px" />
                      <Text as="h1" textTransform="uppercase" fontSize="19px" ml="4">
                        événements
                      </Text>
                    </Flex>
                    {!isEventList && (
                      <>
                        <Box ml={2}>
                          <ChakraImage src="/assets/icons/space.svg" alt="EVENEMENTS" width="25px" />
                        </Box>
                        <Box ml={2}>
                          <Text fontSize="13px">Infos</Text>
                        </Box>
                      </>
                    )}
                  </Flex>
                  {!isMobile && !isEventList && (
                    <Flex alignItems="center">
                      {!isEnded(event) && (event.userId.id === user?.id || isStaff(user)) && (
                        <Flex alignItems="center" bg={customColors.redRanger} borderRadius="4" p={2}>
                          {!isLessThan1556 && <Text mr={2}>Administration de l'événement</Text>}
                          {user && <UpdateButton event={event} user={user} />}
                          {user && isStaff(user) && <DeleteButton event={event} />}
                        </Flex>
                      )}
                      <Button variant="createEvent" ml={2} onClick={() => navigate(EVENTS_CREATE_PATH)}>
                        Créer un événement
                      </Button>
                    </Flex>
                  )}
                  {!isMobile && isEventList && (
                    <Flex alignItems="center">
                      <Button variant="createEvent" ml={2} onClick={() => navigate(EVENTS_CREATE_PATH)}>
                        Créer un événement
                      </Button>
                    </Flex>
                  )}
                </Flex>
              </Ariane>
              <Box ml="6" mt="-1" className={isBlurred ? 'blur' : 'no-blur-animation'}>
                <Flex alignItems={'center'}>
                  <Text color={getTypeEventColor(displayEvent.entityType)}>{capitalizeFirstLetter(getTypeString(displayEvent.entityType, true))}</Text>
                  {displayEvent.type && <Text ml={1}>- {displayEvent.type}</Text>}
                </Flex>
                <Flex alignItems={'center'} mt="-2">
                  <Text as="h1" fontSize="34px" letterSpacing="1.70px" textTransform="uppercase" fontWeight="bold" color="white">
                    {displayEvent.name}
                  </Text>
                </Flex>
              </Box>
              <VStack
                zIndex={2}
                position="relative"
                justifyContent="space-between"
                alignItems="flex-start"
                ml="-1"
                className={isBlurred && isEventList ? 'blur' : 'no-blur-animation'}
                w={isMobile ? 'auto' : '500px'}
              >
                {!isMobile && (
                  <VStack alignItems="start" spacing="2">
                    <Box ml={isMobile ? 0 : 8}>
                      <Text color="white" fontSize="13px">
                        {truncatedText}
                        {!isEventList && isTruncated && (
                          <Text as="span" variant="whiteButton" textDecoration="underline" cursor="pointer" onClick={onOpen} ml={2}>
                            Lire plus
                          </Text>
                        )}
                      </Text>
                      <Flex>
                        <Box mt={4}>
                          {event.entityType !== 1 && <>{!isEventList && <JoinEventButton event={displayEvent} />}</>}
                          {isEventList && (
                            <Linker to={formatUri({ slug: displayEvent.slug, type: getTypeString(displayEvent.entityType) }, EVENTS_DETAIL_PATH)}>
                              <Button variant="navYellowTotal" sx={{ _hover: { bg: customColors.yellowBob, color: customColors.blackTegrite } }} mt={4} cursor="pointer">
                                Plus d'informations
                              </Button>
                            </Linker>
                          )}
                        </Box>
                      </Flex>
                    </Box>
                  </VStack>
                )}
              </VStack>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box position="absolute" ml={-7} w="100%" top={isMobile ? '238px' : '228px'} zIndex={1}>
        <Box position="relative" bottom="0" left="0" w="100%" h={'110px'} bgGradient="linear(180deg, rgba(43, 44, 44, 0) 0%, rgba(43, 44, 44, 1) 73%)">
          <Box pt={'45px'} px={8}>
            <Flex justifyContent="space-between" alignItems="center" width="100%">
              {isEventList && (
                <Box w={'100%'} className={isBlurred ? 'blur' : 'no-blur-animation'}>
                  <Flex justifyContent="right" alignItems="center" width="100%" gap={10}>
                    <Flex cursor="pointer" alignItems="center" mb={1} onClick={() => navigate(formatUri({ username: displayEvent.userId.username }, PROFILE_PATH))}>
                      <UserStringAvatar withBigBorder name={displayEvent.userId.username} media={displayEvent.userId.media} styles={{ size: 'xs' }} />
                      <Text ml="2">{displayEvent.userId.username}</Text>
                    </Flex>
                    <Flex alignItems="center" mb={1}>
                      <ChakraImage src="/assets/icons/calendar.svg" alt="Calendrier" width="27px" ml="-2px" />
                      <Text ml={2}>{formatDateRange(displayEvent.scheduledStartTime, displayEvent.scheduledEndTime)}</Text>
                    </Flex>
                    <Tooltip
                      label={displayEvent.participants.map(participant => participant.username).join(', ')}
                      sx={{
                        bg: customColors.yellowBob,
                        color: customColors.blackTegrite,
                      }}
                    >
                      <Flex alignItems="center" mb={1}>
                        <ChakraImage src="/assets/icons/usersThree.svg" alt="Participants" width="27px" ml="-2px" />
                        <Text ml={2}>+{displayEvent.participants.length}</Text>
                      </Flex>
                    </Tooltip>
                    {!isMobile && (
                      <>
                        {displayEvent.amount && displayEvent.entityType === 3 && (
                          <Flex alignItems="center" mb={1}>
                            <ChakraImage src="/assets/icons/coins.svg" alt="Tarif" width="27px" ml="-2px" />
                            <Text ml={2}>{displayEvent.amount}€</Text>
                          </Flex>
                        )}
                        {displayEvent.game && displayEvent.entityType === 2 && <GameRole game={displayEvent.game} />}
                        {displayEvent.location && displayEvent.location.city && displayEvent.entityType === 3 && (
                          <Flex alignItems="center" mb={1}>
                            <ChakraImage src="/assets/icons/mapPin.svg" alt="Localisation" width="27px" ml="-2px" />
                            <Text ml={2}>{displayEvent.location.city}</Text>
                          </Flex>
                        )}
                      </>
                    )}
                    <Flex alignItems="center" mb={1}>
                      <ShareModalButton
                        type={'icon'}
                        shareFbUrl={`${PUPPETEER_URL}/${getTypeString(displayEvent.entityType)}/${displayEvent.slug}`}
                        shareTwitterUrl={`${APP_URL}/${getTypeString(displayEvent.entityType)}/${displayEvent.slug}`}
                        title={`[${capitalizeFirstLetter(getTypeString(displayEvent.entityType, true))}] ${displayEvent.name}`}
                        description={removeMarkdown(displayEvent.description)}
                      />
                    </Flex>
                  </Flex>
                </Box>
              )}
            </Flex>
          </Box>
        </Box>
      </Box>
      {!isEventList && isTruncated && <EventPresentationModal text={displayEvent.description} isOpen={isOpen} onClose={onClose} />}
    </>
  );
};

export default HeaderEvent;
