import { useForm } from 'react-hook-form';
import { NewsFilters } from './constants';
import FormInput from '../../../components/forms/FormInput';
import React, { useEffect } from 'react';
import { Button, Flex } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';
import { customColors } from '../../../theme';
import { SearchIcon } from '@chakra-ui/icons';

interface NewsFilterProps {
  onSubmit: (data: NewsFilters) => void;
}

const NewsFilter = ({ onSubmit }: NewsFilterProps) => {
  const [searchParams] = useSearchParams();
  const {
    setValue,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<NewsFilters>({ mode: 'onChange' });

  useEffect(() => {
    setValue('title', searchParams.get('title') || '');
  }, [searchParams]);

  const searchButton = () => {
    return (
      <Button type="submit" variant="unstyled" padding={0}>
        <SearchIcon stroke={customColors.whiteWalter} width="27px" mb={1} />
      </Button>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex alignItems="center">
        <FormInput iconActionRight={searchButton()} variant="whiteBorder" type="text" placeholder="Filtrer par titre" name="title" register={register} errors={errors} />
      </Flex>
    </form>
  );
};

export default NewsFilter;
