import { useMutation, UseMutationResult } from 'react-query';
import api from '../../utils/api/api';
import { API_AUTH_CONTACT_SEND, API_CONTACT_SEND } from '../../utils/api/constants';

type ContactFormParams = {
  name: string;
  email: string;
  message: string;
};

type ContactFormAuthParams = {
  subject: string;
  message: string;
};

export const useSendContactMessage = (): UseMutationResult<void, Error, ContactFormParams, unknown> => {
  return useMutation<void, Error, ContactFormParams>(({ name, email, message }) => api.post(API_CONTACT_SEND, { name, email, message }).then(res => res.data));
};

export const useSendAuthContactMessage = (): UseMutationResult<void, Error, ContactFormAuthParams, unknown> => {
  return useMutation<void, Error, ContactFormAuthParams>(({ subject, message }) => api.post(API_AUTH_CONTACT_SEND, { subject, message }).then(res => res.data));
};
