import React, { useEffect, useState } from 'react';
import { Avatar, AvatarProps, Box, Tooltip } from '@chakra-ui/react';
import { S3_URL } from '../utils/constants';
import { isUrl } from '../utils/helpers';
import { customColors } from '../theme';

interface UserStringAvatarProps {
  name: string;
  media?: string;
  styles?: AvatarProps;
  withBorder?: boolean;
  withBigBorder?: boolean;
  withTooltip?: boolean; // New prop
}

const UserStringAvatar: React.FC<UserStringAvatarProps> = ({
  withBigBorder = false,
  name,
  media,
  styles,
  withBorder = false,
  withTooltip = false, // New prop
}) => {
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpacity(1);
    }, 50);

    return () => clearTimeout(timer);
  }, []);

  const getFormattedMedia = () => {
    if (!media) return '';

    if (isUrl(media)) return media;

    return `${S3_URL}/users/${media}`;
  };

  const renderAvatar = () => <Avatar name={name} src={getFormattedMedia()} border={withBorder ? '2px solid white' : 'none'} {...styles} />;

  const avatarWithTooltip = (
    <Tooltip
      label={name}
      sx={{
        bg: customColors.yellowBob,
        color: customColors.blackTegrite,
      }}
    >
      {renderAvatar()}
    </Tooltip>
  );

  const avatarComponent = withTooltip ? avatarWithTooltip : renderAvatar();

  if (withBigBorder) {
    return (
      <Box
        p="4px"
        borderRadius="full"
        display="inline-block"
        border={withBigBorder ? `2px solid ${customColors.greyRoswel}` : 'none'}
        style={{
          opacity: opacity,
          transition: 'opacity 0.3s ease-in-out',
        }}
      >
        {avatarComponent}
      </Box>
    );
  }

  return (
    <Box
      style={{
        opacity: opacity,
        transition: 'opacity 0.3s ease-in-out',
      }}
    >
      {avatarComponent}
    </Box>
  );
};

export default UserStringAvatar;
