import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { S3_URL } from '../../../utils/constants';
import { Event } from '../../../types/Events';
import CardImageWithBox from '../../../components/card/CardImageWithBox';
import { capitalizeFirstLetter, getTypeEventColor, getTypeString, isUrl } from '../../../utils/helpers';
import { formatDateRangeFormatted } from '../../../utils/dates';
import { formatUri } from '../../../utils/api/helpers';
import { EVENTS_DETAIL_PATH } from '../../../routing/constants';
import { useNavigate } from 'react-router-dom';

interface EventCarouselProps {
  events: Event[];
}

const StreamData: React.FC<EventCarouselProps> = ({ events }) => {
  const filteredEvents = events.filter(event => event.entityType === 1);
  const navigate = useNavigate();

  if (!filteredEvents[0]) {
    return null;
  }

  return (
    <Box width="100%" height="100%" position="relative" onClick={() => navigate(formatUri({ slug: filteredEvents[0].slug, type: getTypeString(filteredEvents[0].entityType) }, EVENTS_DETAIL_PATH))}>
      <CardImageWithBox
        height={144.5}
        imageName={filteredEvents[0].name}
        image={isUrl(filteredEvents[0].image) ? filteredEvents[0].image : `${S3_URL}/events/${filteredEvents[0].image}`}
        user={filteredEvents[0].userId}
        contentInCard={<Box color="whiteWalter">{formatDateRangeFormatted(filteredEvents[0].scheduledStartTime, filteredEvents[0].scheduledEndTime, true)}</Box>}
        contentRight={
          <Text mr={-2} mt={1} color={getTypeEventColor(filteredEvents[0].entityType)}>
            {capitalizeFirstLetter(getTypeString(filteredEvents[0].entityType, true))}
          </Text>
        }
        contentFooter={
          <>
            <Flex justifyContent="space-between" flexDirection={'row'} alignItems="center" h="100%" mt={5}>
              <Text as="h2" fontSize={'13px'}>
                {filteredEvents[0].name}
              </Text>
            </Flex>
          </>
        }
      />
    </Box>
  );
};

export default StreamData;
