import React from 'react';
import { Box, Button, Text } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useSendDiscordMessage } from '../../hooks/discord/forum/useSendDiscordMessage';
import { DiscordMessageType } from './constants';
import MarkdownTextarea from '../forms/MarkdownTextarea';
import { smoothScrollToTop } from '../../utils/helpers';
import InSavedChangesPrompt from '../InSavedChangesPrompt';

interface SendMessageComponentProps {
  channelId: string;
  refContainer: any;
  updateLastActionTimestamp?: () => void;
}

const SendDiscordMessage: React.FC<SendMessageComponentProps> = ({ channelId, refContainer, updateLastActionTimestamp }) => {
  const sendMessage = useSendDiscordMessage();

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    getValues,
  } = useForm<DiscordMessageType>({
    mode: 'onChange',
  });

  const onSubmit: SubmitHandler<DiscordMessageType> = async data => {
    try {
      sendMessage.mutate({ channelId, message: data.message });
      reset();
      if (refContainer.current) {
        smoothScrollToTop(refContainer.current, 500); // 500ms pour le défilement
      }
    } catch (error: any) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    if (updateLastActionTimestamp) {
      updateLastActionTimestamp();
    }
  };

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)} mb={2}>
      <MarkdownTextarea errors={errors} required={true} placeholder="Entrez pour envoyez, Maj + Entrée pour ajouter une nouvelle ligne" name="message" getValues={getValues} setValue={setValue} />
      {/*<FormTextareaSimple placeholder="Message" name="message" required={true} errors={errors} register={register} getValues={getValues} setValue={setValue} />*/}
      <Button display={'none'} id={'submit-button'} type="submit" colorScheme="blue" my={4}>
        Envoyer
      </Button>
      <Text mx={2} mt={1}>
        Tes messages seront transmis sur Discord, avec ton pseudo brillamment affiché. C'est comme être un agent secret des chats, mais sans les gadgets !
      </Text>
      <InSavedChangesPrompt isDirty={!!(getValues('message') && getValues('message').length !== 0)} />
    </Box>
  );
};

export default SendDiscordMessage;
