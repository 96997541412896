import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import api from '../../utils/api/api';
import { API_DISCORD_ROLE_ADD_ADMIN } from '../../utils/api/constants';
import { DiscordRoleForm } from '../../pages/admin/discordRoles/constants';

export const useAddDiscordRole = (): UseMutationResult<any, Error, DiscordRoleForm, unknown> => {
  const queryClient = useQueryClient();

  return useMutation<any, Error, DiscordRoleForm>((discordRole: DiscordRoleForm) => api.post(API_DISCORD_ROLE_ADD_ADMIN, discordRole).then(res => res.data), {
    onSuccess: () => {
      queryClient.refetchQueries('adminDiscordRoles');
    },
  });
};
