import React from 'react';
import { Box, Flex, Grid, Image, Text } from '@chakra-ui/react';

interface HeaderStepProps {
  step: number;
}

const HeaderStep: React.FC<HeaderStepProps> = ({ step }) => {
  return (
    <Box position="relative">
      <Grid templateColumns={'1fr 1fr 1fr'} gap={4} zIndex={1} position={'relative'}>
        <Flex flexDirection="column" alignItems="center">
          <Image src="/assets/icons/tour-green.svg" alt="step1" />
          <Text>Règlement du Concours</Text>
        </Flex>
        <Flex flexDirection="column" alignItems="center">
          <Image src={`/assets/icons/tour${step > 1 ? '-green' : ''}.svg`} alt="step2" />
          <Text>Upload de l’oeuvre</Text>
        </Flex>
        <Flex flexDirection="column" alignItems="center">
          <Image src={`/assets/icons/tour${step > 2 ? '-green' : ''}.svg`} alt="step3" />
          <Text>Resumer et confirmation</Text>
        </Flex>
      </Grid>
      <Box position="absolute" bottom={35} left={'150px'}>
        <Image src="/assets/icons/line-contest.svg" alt="line" />
      </Box>
      <Box position="absolute" bottom={35} right={'150px'}>
        <Image src="/assets/icons/line-contest.svg" alt="line" />
      </Box>
    </Box>
  );
};

export default HeaderStep;
