import React from 'react';
import { CUIAutoComplete } from 'chakra-ui-autocomplete';
import { Flex, FormControl, FormErrorMessage, Text } from '@chakra-ui/react';
import { FieldValues, DeepMap, FieldError, UseFormSetValue } from 'react-hook-form';

export interface FormAutoCompleteProps {
  label: string;
  placeholder: string;
  name: string;
  setValue: UseFormSetValue<any>;
  getValues: any;
  errors: DeepMap<FieldValues, FieldError>;
  options?: { label: string; value: string }[];
  required?: boolean;
  variant?: any;
}

const FormAutoComplete: React.FC<FormAutoCompleteProps> = ({ getValues, label, placeholder, name, setValue, errors, options, required }) => {
  const customRender = (selected: any) => {
    return (
      <Flex flexDir="row" alignItems="center" cursor="pointer">
        <Text color="blackTegrite">{selected.label}</Text>
      </Flex>
    );
  };
  return (
    <FormControl isRequired={required} isInvalid={errors && !!errors[name]} mt={4}>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/*@ts-ignore*/}
      <CUIAutoComplete
        label={label}
        disableCreateItem
        placeholder={placeholder}
        selectedItems={getValues(name) || []}
        items={options || []}
        itemRenderer={customRender}
        onSelectedItemsChange={(e: any) => {
          setValue(name, e.selectedItems, { shouldValidate: true });
        }}
      />
      <FormErrorMessage>{errors && errors[name] && errors[name].message}</FormErrorMessage>
    </FormControl>
  );
};

export default FormAutoComplete;
