import React, { useState } from 'react';
import { AvatarProps, Box, Image } from '@chakra-ui/react';
import { defaultImage } from '../utils/helpers';
import 'powerglitch';

interface ImageLoaderProps {
  src: string;
  imageName: string;
  styles?: AvatarProps;
}

const ImageLoader: React.FC<ImageLoaderProps> = ({ src, imageName, styles }) => {
  const [opacity, setOpacity] = useState(0);

  const handleImageLoaded = () => {
    setOpacity(1);
  };

  const handleImageError = (e: any) => {
    e.target.src = defaultImage;
  };

  return (
    <Box
      className="glitch"
      style={{
        opacity: opacity,
        transition: 'opacity 0.3s ease-in-out',
      }}
    >
      <Image src={src} alt={imageName} onError={handleImageError} onLoad={handleImageLoaded} {...styles} />
    </Box>
  );
};

export default ImageLoader;
