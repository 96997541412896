import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, Flex, Text } from '@chakra-ui/react';
import Cropper from 'react-cropper';

interface ImageCropModalProps {
  isOpen: boolean;
  onClose: () => void;
  image: string | null;
  onCrop: (croppedImage: string) => void;
  cropWidth: number;
  cropHeight: number;
  cropperRef: React.RefObject<any>;
}

const ImageCropModal: React.FC<ImageCropModalProps> = ({ isOpen, onClose, image, onCrop, cropWidth, cropHeight, cropperRef }) => {
  const handleCropData = () => {
    if (cropperRef.current) {
      const cropperInstance = cropperRef.current.cropper;
      const croppedCanvas = cropperInstance.getCroppedCanvas();
      if (croppedCanvas) {
        const croppedImage = croppedCanvas.toDataURL();
        onCrop(croppedImage);
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Redimension de l'image</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Cropper ref={cropperRef} src={image as string} aspectRatio={cropWidth / cropHeight} guides={false} dragMode="move" modal={true} cropBoxResizable={false} />
          <Text mt={2}>Tu peu controller le zoom avec ta molette ainsi que bouger le cadre sur ton image.</Text>
        </ModalBody>
        <ModalFooter>
          <Flex flexDir="row" justifyContent="flex-end" mt={4}>
            <Button variant="navRed" border="none" color="white" textDecoration="underline" onClick={onClose}>
              Annuler
            </Button>
            <Button ml={4} variant="navYellow" onClick={handleCropData}>
              Valider
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ImageCropModal;
