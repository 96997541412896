import React from 'react';
import { useParams } from 'react-router-dom';
import CreateThreadForm from './CreateThreadForm';
import PageTitleUpdater from '../../../../routing/PageTitleUpdater';
import Ariane from '../../../../components/Ariane';
import { Flex, Image, Text } from '@chakra-ui/react';
import { capitalizeFirstLetter } from '../../../../utils/helpers';
import PageLayout from '../../../../layout/PageLayout';
import { useDiscordChannelById } from '../../../../hooks/discord/forum/useDiscordChannelById';

type ParamTypes = {
  channelId: string;
};

const CreateThread: React.FC = () => {
  const { channelId } = useParams<ParamTypes>();
  const { data: channel, isLoading } = useDiscordChannelById(channelId as string);

  const availableTags = channel ? channel.availableTags : null;

  const transformedTags = (availableTags || []).map(tag => ({
    value: tag.id,
    label: tag.name,
  }));

  return (
    <>
      <PageTitleUpdater additionalTitle={`Discussions - Channel - Création`} />
      <Ariane>
        <Flex alignItems="center">
          <Image src="/assets/icons/purple.svg" alt="Communauté" width="30px" />
          <Text as="h1" textTransform="uppercase" fontSize="19px" ml={4}>
            Communauté - Création - {channel && `${capitalizeFirstLetter(channel.channelName)}`}
          </Text>
        </Flex>
      </Ariane>
      <Text as="h1" mt={20}>
        Créer un thread
      </Text>
      <PageLayout isLoading={isLoading}>{channel && <CreateThreadForm tags={transformedTags} channel={channel} />}</PageLayout>
    </>
  );
};

export default CreateThread;
