import React from 'react';
import { Center, Flex, Spinner, Text } from '@chakra-ui/react';
import CookiesPopup from '../pages/misc/CookiesPopup';

interface PageLayoutProps {
  isLoading?: boolean;
  isError?: boolean;
  children?: React.ReactNode;
  margin?: number;
  error?: any;
}

const PageLayout: React.FC<PageLayoutProps> = ({ margin, isLoading, isError, error, children }) => {
  return (
    <>
      {isLoading && (
        <Center h={margin ? `calc(100% - ${margin}px)` : '100%'} w="100%">
          <Spinner size="xl" />
        </Center>
      )}
      {isError && (
        <Center h={margin ? `calc(100% - ${margin}px)` : '100%'} w="100%">
          <Flex flexDirection="column" alignItems="center" justifyContent="center">
            <Text as="h1">Oups ! Une erreur c'est produite :/</Text>
            <Text>{error?.response?.data?.message}</Text>
          </Flex>
        </Center>
      )}
      {!isLoading && !isError && children}
      <CookiesPopup />
    </>
  );
};

export default PageLayout;
