import { Discord, User } from '../../../types/user';
import React, { useEffect } from 'react';
import { Button, Flex, Text, Image } from '@chakra-ui/react';
import { useDiscordAuth } from '../../../hooks/auth/useDiscordAuth';
import { useUpdateUser } from '../../../hooks/users/useUpdateUser';
import { useAppContext } from '../../../context/AppContext';
import { toast } from 'react-toastify';

interface AffiliateDiscordProps {
  user: User;
  withoutSpacing?: boolean;
}

const AffiliateDiscord: React.FC<AffiliateDiscordProps> = ({ user, withoutSpacing = false }) => {
  const { setUser } = useAppContext();
  const updateUser = useUpdateUser();
  const { discordAuthMutation, openDiscordPopup } = useDiscordAuth();
  useEffect(() => {
    const updateUserData = async () => {
      if (discordAuthMutation.isSuccess && user && !user.discordData?.userId) {
        const discordData = {
          userId: discordAuthMutation.data.user.id,
          token: discordAuthMutation.data.token,
          refreshToken: discordAuthMutation.data.refreshToken,
        } as Discord;

        try {
          const update = await updateUser.mutateAsync({ id: user.id, data: { discordData } });
          setUser(update);
        } catch (error: any) {
          toast.error(`Erreur de modification: ${error.response.data.message}`, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      }
    };

    updateUserData();
  }, [discordAuthMutation.isSuccess, discordAuthMutation.data, user]);

  return (
    <>
      {!user.discordData?.userId ? (
        <Flex alignItems="center" flexDirection="row" py={withoutSpacing ? 0 : 3} px={withoutSpacing ? 0 : 4} bg="blackTegrite" borderRadius={21}>
          <Image src="/assets/icons/eg.svg" alt="Discord" width="34px" mr={2} />
          <Text>Associer mon compte Discord</Text>
          <Button ml={2} variant="navGreen" onClick={openDiscordPopup}>
            Associer
          </Button>
        </Flex>
      ) : (
        <Flex alignItems="center" flexDirection="row" py={withoutSpacing ? 0 : 3} px={withoutSpacing ? 0 : 4} bg="blackTegrite" borderRadius={21}>
          <Image src="/assets/icons/eg.svg" alt="Discord" width="34px" mr={2} />
          <Text>Votre compte est bien lié à Discord</Text>
        </Flex>
      )}
    </>
  );
};

export default AffiliateDiscord;
