import { capitalizeFirstLetter } from './helpers';

export const formatTimestamp = (timestamp: string): string => {
  const date = new Date(timestamp);
  return date.toLocaleDateString('fr-FR', { weekday: 'long', day: 'numeric', month: 'long' }) + ' à ' + date.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });
};

export function formatingDate(dateString: string): string {
  const months = ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'];
  const date = new Date(dateString);
  const currentYear = new Date().getFullYear();

  // Comparer l'année de la date avec l'année en cours
  if (date.getFullYear() === currentYear) {
    // Si l'année est la même, ne pas inclure l'année dans le formatage
    return `${date.getDate()} ${months[date.getMonth()]}`;
  } else {
    // Sinon, inclure l'année
    return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
  }
}

export function getAge(bornDate: string): number {
  const today = new Date();
  const birthDate = new Date(bornDate);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
}

export function formatDateRange(startDateStr: string, endDateStr: string, isShort = false): string {
  // Choix du format du mois selon isShort
  const monthFormat = isShort ? 'short' : 'long';
  const currentYear = new Date().getFullYear();

  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  // Définir les options avec ou sans l'année en fonction de l'année de la date
  const startYearOption = startDate.getFullYear() !== currentYear ? 'numeric' : undefined;
  const endYearOption = endDate.getFullYear() !== currentYear ? 'numeric' : undefined;

  const startOptions: Intl.DateTimeFormatOptions = {
    year: startYearOption,
    month: monthFormat,
    day: 'numeric',
  };

  const endOptions: Intl.DateTimeFormatOptions = {
    year: endYearOption,
    month: monthFormat,
    day: 'numeric',
  };

  const timeOptions: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', hour12: false };

  if (startDate.getFullYear() !== endDate.getFullYear()) {
    // Si l'année de début est différente de l'année de fin
    return `${startDate.toLocaleDateString('fr-FR', startOptions)} - ${endDate.toLocaleDateString('fr-FR', endOptions)}`;
  }

  if (startDate.getMonth() !== endDate.getMonth()) {
    // Si les mois sont différents
    return `${startDate.toLocaleDateString('fr-FR', { month: monthFormat, day: 'numeric' })} - ${endDate.toLocaleDateString('fr-FR', endOptions)}`;
  }

  if (startDate.getDate() === endDate.getDate()) {
    // Si les jours sont identiques
    const startTime = startDate.toLocaleTimeString('fr-FR', timeOptions);
    const endTime = endDate.toLocaleTimeString('fr-FR', timeOptions);
    return `${startDate.toLocaleDateString('fr-FR', startOptions)} de ${startTime} à ${endTime}`;
  }

  // Si les jours sont différents mais le mois et l'année sont les mêmes
  return `${startDate.toLocaleDateString('fr-FR', { day: 'numeric' })} - ${endDate.toLocaleDateString('fr-FR', endOptions)}`;
}

export function formatDateRangeFormatted(startDateStr: string, endDateStr: string, isShort = false): string {
  // Choix du format du mois selon isShort
  const monthFormat = isShort ? 'short' : 'long';
  const currentYear = new Date().getFullYear();

  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  // Définir les options avec ou sans l'année en fonction de l'année de la date
  const startYearOption = startDate.getFullYear() !== currentYear ? 'numeric' : undefined;
  const endYearOption = endDate.getFullYear() !== currentYear ? 'numeric' : undefined;

  const startOptions: Intl.DateTimeFormatOptions = {
    year: startYearOption,
    month: monthFormat,
  };

  const endOptions: Intl.DateTimeFormatOptions = {
    year: endYearOption,
    month: monthFormat,
  };

  if (startDate.getFullYear() !== endDate.getFullYear()) {
    // Si l'année de début est différente de l'année de fin
    return `${capitalizeFirstLetter(startDate.toLocaleDateString('fr-FR', { day: 'numeric' }))} ${capitalizeFirstLetter(startDate.toLocaleDateString('fr-FR', startOptions))} - ${capitalizeFirstLetter(
      endDate.toLocaleDateString('fr-FR', endOptions),
    )}`;
  }

  if (startDate.getMonth() !== endDate.getMonth()) {
    // Si les mois sont différents
    return `${startDate.toLocaleDateString('fr-FR', { month: monthFormat, day: 'numeric' })} 
       \n${endDate.toLocaleDateString('fr-FR', { month: monthFormat, day: 'numeric' })} `;
  }

  if (startDate.getDate() === endDate.getDate()) {
    return `${capitalizeFirstLetter(startDate.toLocaleDateString('fr-FR', { day: 'numeric' }))}\n${capitalizeFirstLetter(startDate.toLocaleDateString('fr-FR', startOptions))}`;
  }

  // Si les jours sont différents mais le mois et l'année sont les mêmes
  return `${startDate.toLocaleDateString('fr-FR', { day: 'numeric' })} - ${endDate.toLocaleDateString('fr-FR', { day: 'numeric' })}\n${capitalizeFirstLetter(
    endDate.toLocaleDateString('fr-FR', endOptions),
  )}`;
}
