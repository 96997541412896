import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody, Box, Image, Flex, Badge } from '@chakra-ui/react';
import { User } from '../types/user';
import MenuContent from '../layout/left/menu/MenuContent';
import UserMenuContent from '../layout/header/UserMenuContent';
import Linker from './Linker';
import { HOME_BOARD, MESSAGES_CONVERSATIONS, VISITS } from '../routing/constants';
import { AiOutlineMenu } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { isStaff } from '../utils/constants';
import AdminIcon from '../layout/header/admin/AdminIcon';

interface UnreadData {
  visits: number;
  messages: number;
}

interface MenuModalProps {
  user: User;
  toggleContent: () => void;
  isOpen: boolean;
  unreadData: UnreadData;
}

export const MenuModal: React.FC<MenuModalProps> = ({ user, toggleContent, isOpen, unreadData }) => {
  return (
    <Modal isOpen={isOpen} onClose={toggleContent} blockScrollOnMount={true} size="full">
      <ModalOverlay />
      <ModalContent>
        <Flex justifyContent="space-between" alignItems="center" m={4}>
          <Box ml={6}>
            <Image src="/assets/logos/logo-white-alone.svg" align="center" maxHeight="30px" />
          </Box>
          <Box mr={3}>
            <AiOutlineMenu onClick={toggleContent} size={25} cursor="pointer" />
          </Box>
        </Flex>
        <ModalBody>
          <Box my={4}>
            <MenuContent toggleContent={toggleContent} />
          </Box>
          <Box textAlign="right">
            <Flex>
              <Box mr={2}>
                <Link to={VISITS} onClick={() => (toggleContent ? toggleContent() : undefined)}>
                  <Flex>
                    <Image src="/assets/icons/eye.svg" width="30px" height="42px" mt={1} alt="Messages" display="inline-block" verticalAlign="bottom" />
                    {unreadData && unreadData.visits > 0 && (
                      <Box ml={'0px'} mt={-1}>
                        <Badge px={'5.4px'} borderRadius="full" bg="redRanger" color="whiteWalter">
                          {unreadData.visits}
                        </Badge>
                      </Box>
                    )}
                  </Flex>
                </Link>
              </Box>
              <Box>
                <Link to={MESSAGES_CONVERSATIONS} onClick={() => (toggleContent ? toggleContent() : undefined)}>
                  <Flex>
                    <Image src="/assets/icons/chat.svg" width="50px" height="50px" alt="Messages" display="inline-block" verticalAlign="bottom" />
                    {unreadData && unreadData.messages > 0 && (
                      <Box ml={'-8px'} mt={-1}>
                        <Badge px={'5.4px'} borderRadius="full" bg="redRanger" color="whiteWalter">
                          {unreadData.messages}
                        </Badge>
                      </Box>
                    )}
                  </Flex>
                </Link>
              </Box>
              {isStaff(user) && <AdminIcon toggleContent={toggleContent} />}
            </Flex>
          </Box>
          <Box my={4}>
            <UserMenuContent user={user} toggleContent={toggleContent} />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
