import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { Event } from '../../../../types/Events';
import { formatDateRangeFormatted } from '../../../../utils/dates';
import { capitalizeFirstLetter, getTypeEventColor, getTypeString, isUrl } from '../../../../utils/helpers';
import { formatUri } from '../../../../utils/api/helpers';
import { EVENTS_DETAIL_PATH } from '../../../../routing/constants';
import { useNavigate } from 'react-router-dom';
import CardImageWithBox from '../../../../components/card/CardImageWithBox';
import { S3_URL } from '../../../../utils/constants';

interface EventCarouselProps {
  events: Event[];
}

const EventsMobile: React.FC<EventCarouselProps> = ({ events }) => {
  const navigate = useNavigate();
  return (
    <Box>
      <Text as="h1" mb={2} color="yellowBob">
        Prochains événements
      </Text>
      <Box>
        {events.slice(0, 2).map(event => {
          return (
            <Box key={event.id} onClick={() => navigate(formatUri({ slug: event.slug, type: getTypeString(event.entityType) }, EVENTS_DETAIL_PATH))} mb={6}>
              <CardImageWithBox
                height={130}
                imageName={event.name}
                image={isUrl(event.image) ? event.image : `${S3_URL}/events/${event.image}`}
                user={event.userId}
                contentInCard={<>{formatDateRangeFormatted(event.scheduledStartTime, event.scheduledEndTime, true)}</>}
                contentRight={
                  <Text mr={-2} mt={1} color={getTypeEventColor(event.entityType)}>
                    {capitalizeFirstLetter(getTypeString(event.entityType, true))}
                  </Text>
                }
                contentFooter={
                  <>
                    <Flex justifyContent="space-between" flexDirection={'row'} alignItems="center" h="100%" mt={5}>
                      <Text as="h2" fontSize={'13px'}>
                        {event.name}
                      </Text>
                    </Flex>
                  </>
                }
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default EventsMobile;
