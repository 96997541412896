import React from 'react';
import { Box, Button, Flex, Grid, SimpleGrid, Text, useMediaQuery } from '@chakra-ui/react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UserUpdatePasswordParameterYupSchema } from './yup/UserUpdateGeneralYupSchema';
import FormInput from '../../../components/forms/FormInput';
import { ResetPasswordFormInputProps } from '../constants';
import { useResetProfilePassword } from '../../../hooks/users/useResetProfilePassword';
import { toast } from 'react-toastify';

const Password: React.FC = () => {
  const [isLessThan768] = useMediaQuery('(max-width: 952px)');
  const templateColumns = !isLessThan768 ? '1fr 3fr' : '1fr';
  const resetPassword = useResetProfilePassword();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<ResetPasswordFormInputProps>({
    resolver: yupResolver(UserUpdatePasswordParameterYupSchema),
    mode: 'onChange',
  });

  const onSubmit: SubmitHandler<ResetPasswordFormInputProps> = data => {
    resetPassword.mutate(
      { password: data.password, oldPassword: data.oldPassword },
      {
        onSuccess: () => {
          toast.success(`Votre mot de passe a été modifié.`, {
            position: toast.POSITION.TOP_CENTER,
          });
          reset();
        },
        onError: (error: any) => {
          toast.error(`Erreur de modification : ${error.response.data.message}`, {
            position: toast.POSITION.TOP_CENTER,
          });
        },
      },
    );
  };

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      <Grid templateColumns={templateColumns} gap={4} mt={10} flex={1}>
        <Box>
          <Text fontWeight="bold" color="yellowBob">
            Mot de passe
          </Text>
          <Text textStyle="subHeader" fontStyle="italic" pr={10}>
            Doit conternir 8 characteres
          </Text>
        </Box>
        <Box>
          <Text mb={4}>Voici un sortilège de modification d'accès, renouvelle ton sésame à volonté.</Text>
          <SimpleGrid columns={isLessThan768 ? 1 : 2} spacing={4} spacingX="10">
            <FormInput variant="whiteBorderNoBg" type="password" placeholder="Ancien mot de passe" name="oldPassword" register={register} errors={errors} />
            <FormInput variant="whiteBorderNoBg" type="password" placeholder="Nouveau mot de passe" name="password" register={register} errors={errors} />
          </SimpleGrid>
          <Flex justifyContent="flex-end" mt={4}>
            <Button type="submit" isLoading={resetPassword.isLoading} isDisabled={!isValid} variant="navGreen">
              Enregistrer le nouveau mot de passe
            </Button>
          </Flex>
        </Box>
      </Grid>
    </Box>
  );
};

export default Password;
