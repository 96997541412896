import React, { useRef, useEffect } from 'react';
import { Box, Flex, Image } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { imageSets, shaders } from './constants';
const MotionBox = motion(Flex);

interface MediaProps {
  currentIndex: number;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number | null>>;
}

const MediaLottie: React.FC<MediaProps> = ({ currentIndex, setCurrentIndex }) => {
  const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);

  const handleMouseEnter = (index: number) => {
    const video = videoRefs.current[index];
    if (video) video.play();
  };

  const handleMouseLeave = (index: number) => {
    const video = videoRefs.current[index];
    if (video) video.pause();
  };

  useEffect(() => {
    // Assurez-vous de réinitialiser les références lors de la modification de currentIndex
    videoRefs.current = videoRefs.current.slice(0, imageSets[currentIndex]?.length);
  }, [currentIndex]);

  return (
    <>
      {imageSets[currentIndex].map((fileName, index) => (
        <Box key={`${fileName}-${currentIndex}-${index}`} onClick={() => setCurrentIndex(index + 1)} cursor="pointer">
          <MotionBox
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 30, delay: 0, duration: 0.5 }}
            transition={{
              type: 'spring',
              damping: 10,
              stiffness: 100,
              delay: (currentIndex === 0 ? 0.3 : 0.7) * index,
              duration: 0.8,
            }}
          >
            {currentIndex === 0 ? (
              <Box mx={2} position="relative" onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={() => handleMouseLeave(index)}>
                <video ref={el => (videoRefs.current[index] = el)} loop muted playsInline style={{ maxHeight: '500px' }}>
                  <source src={`/assets/homeNoAuth/${fileName}`} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <Image src={shaders[index]} alt="shaders" position="absolute" bottom="0" left="0" />
              </Box>
            ) : (
              <Box key={`${fileName}-${currentIndex}-${index}`}>
                <video autoPlay loop muted playsInline style={{ maxHeight: '500px' }}>
                  <source src={`/assets/homeNoAuth/${fileName}`} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Box>
            )}
          </MotionBox>
        </Box>
      ))}
    </>
  );
};

export default React.memo(MediaLottie);
