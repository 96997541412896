import { Box } from '@chakra-ui/react';
import React from 'react';

import MenuContent from './MenuContent';

const Menu: React.FC = () => {
  return (
    <Box color={'white'} mb={4} p={4} bgColor="brand.block" borderRadius={21} overflow="hidden" minW={'182px'}>
      <MenuContent />
    </Box>
  );
};

export default Menu;
