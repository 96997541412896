import React from 'react';
import { toast } from 'react-toastify';
import { Text } from '@chakra-ui/react';

interface CopyToClipboardTextProps {
  text: string;
}

const CopyToClipboardText: React.FC<CopyToClipboardTextProps> = ({ text }) => {
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success(`Id Copié !`, {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (err) {
      console.error('Erreur lors de la copie :', err);
    }
  };

  return (
    <Text onClick={handleCopy} style={{ cursor: 'pointer' }}>
      {text}
    </Text>
  );
};

export default CopyToClipboardText;
