import React, { useEffect, useState } from 'react';
import { Button, Flex, Grid, GridItem, Image, Text } from '@chakra-ui/react';
import { useThreadsByTag } from '../../../hooks/discord/forum/useFetchThreadsByTag';

import { useSearchParams } from 'react-router-dom';
import PageTitleUpdater from '../../../routing/PageTitleUpdater';
import { QueryContestParams } from '../../contest/constantes';
import Ariane from '../../../components/Ariane';
import ThreadCard from './ThreadCard';
import { capitalizeFirstLetter } from '../../../utils/helpers';

interface ThreadByTagCardProps {
  tagId: string;
}

const ThreadByTagCard: React.FC<ThreadByTagCardProps> = ({ tagId }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [tagName, setTagName] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const skip = searchParams.get('skip') ? parseInt(searchParams.get('skip')!) : 0;
  const [queryParams, setQueryParams] = useState<QueryContestParams>({
    skip,
    limit: 10,
  });
  const { data, isLoading, refetch } = useThreadsByTag(tagId, queryParams);

  const hasPrevious = queryParams.skip > 0;
  const hasNext = queryParams.skip + queryParams.limit < (data?.total || 0);

  useEffect(() => {
    const newQueryParams = {
      skip: searchParams.get('skip') ? parseInt(searchParams.get('skip') as string) : 0,
      limit: 10,
    };

    setQueryParams(newQueryParams);
    refetch();
  }, [searchParams]);

  const updateSkip = (skip: number) => {
    const newSearchParams = new URLSearchParams();
    newSearchParams.set('skip', skip.toString());
    setSearchParams(newSearchParams);
  };

  useEffect(() => {
    if (data && data.threads.length > 0) {
      setTagName(data.threads[0].tagName as string);
    }
  }, [data]);

  return (
    <>
      {data && <PageTitleUpdater additionalTitle={`Communauté - Channel - ${capitalizeFirstLetter(tagName)}`} />}
      <Ariane>
        <Flex alignItems="center">
          <Image src="/assets/icons/blue.svg" alt="Communauté" width="30px" />
          <Text as="h1" textTransform="uppercase" fontSize="19px" ml={4}>
            Communauté {tagName && `- Tag - ${capitalizeFirstLetter(tagName)}`}
          </Text>
        </Flex>
      </Ariane>
      <Grid mb={4}>
        <GridItem colSpan={2}>
          <Flex alignItems="center" justifyContent="right">
            <Button isDisabled={!hasPrevious} variant="navYellow" onClick={() => updateSkip(queryParams.skip - queryParams.limit)} mr={2}>
              Précédent
            </Button>
            <Button isDisabled={!hasNext} variant="navYellow" onClick={() => updateSkip(queryParams.skip + queryParams.limit)} mr={2}>
              Suivant
            </Button>
          </Flex>
        </GridItem>
      </Grid>
      <ThreadCard threads={data?.threads || []} isLoading={isLoading} />
    </>
  );
};

export default ThreadByTagCard;
