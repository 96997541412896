import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
interface PassionsElementProps {
  name: string;
  value: number;
}

const GreenDot = () => <span style={{ height: '10px', width: '10px', backgroundColor: 'green', borderRadius: '50%', display: 'inline-block', margin: '2px' }}></span>;

const DotsDisplay = ({ value }: { value: number }) => {
  const renderDots = () => {
    const dots = [];
    for (let i = 0; i < value; i++) {
      dots.push(<GreenDot key={i} />);
    }
    return dots;
  };

  return <Box ml={2}>{renderDots()}</Box>;
};

const PassionsElement: React.FC<PassionsElementProps> = ({ name, value }) => {
  return (
    <>
      <Flex width={['100%', '50%']} alignItems="center" mb={1}>
        <Flex alignItems={'center'}>
          <Text>{name}</Text>
          <DotsDisplay value={value} />
        </Flex>
      </Flex>
    </>
  );
};

export default PassionsElement;
