import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Flex, Image } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { NewsForm } from '../constants';
import { useUpdateNews } from '../../../../hooks/news/useUpdateNews';
import { newsSchema } from '../yup/news';
import { NEWS_PATH } from '../../../../routing/constants';
import FormImageUpload from '../../../../components/forms/formImageUpload/FormImageUpload';
import FormInput from '../../../../components/forms/FormInput';
import FormTextarea from '../../../../components/forms/FormTextarea';
import { News } from '../../../../types/News';
import { convertToBase64, isUrl } from '../../../../utils/helpers';
import { S3_URL } from '../../../../utils/constants';
import { formatUri } from '../../../../utils/api/helpers';
import InSavedChangesPrompt from '../../../../components/InSavedChangesPrompt';

type UpdateNewsFormProps = {
  news: News;
};

const UpdateNewsForm: React.FC<UpdateNewsFormProps> = ({ news }) => {
  const [currentAvatar, setCurrentAvatar] = React.useState<string | undefined>(isUrl(news.media) ? news.media : `${S3_URL}/news/${news.media}`);
  const [newsUpdated, setNewsUpdated] = React.useState<News | undefined>();
  const mutation = useUpdateNews();
  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    trigger,
    watch,
    formState: { errors, isValid, isDirty },
  } = useForm<NewsForm>({
    defaultValues: {
      title: news.title,
      media: isUrl(news.media) ? news.media : `${S3_URL}/news/${news.media}`,
      text: news.text,
      link: news.link,
    },
    resolver: yupResolver(newsSchema),
    mode: 'onBlur',
  });

  const watchedMedia = watch('media');
  useEffect(() => {
    if (watchedMedia && watchedMedia instanceof File) {
      trigger('media');
      convertToBase64(watchedMedia).then(base64 => {
        setCurrentAvatar(base64 as string);
      });
    }
  }, [watchedMedia]);

  const onSubmit = async (data: NewsForm) => {
    if (!news) return;
    try {
      await mutation.mutateAsync({ newsId: news.id, data });
      setNewsUpdated(news);
      toast.success('Article update avec succès', {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (error: any) {
      toast.error(`Échec de la mise à jour de l'article : ${error.response.data.message}`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  useEffect(() => {
    if (newsUpdated) {
      navigate(formatUri({ slug: newsUpdated.slug }, NEWS_PATH));
    }
  }, [newsUpdated]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {currentAvatar && <Image src={currentAvatar} />}
      <Box mb={4}>
        <FormImageUpload variant="whiteBorder" label="Couverture" placeholder="Couverture" name="media" register={register} setValue={setValue} getValues={getValues} errors={errors} />
      </Box>
      <Box mb={4}>
        <FormInput variant="whiteBorder" label="Titre" type="text" placeholder="Titre" name="title" required register={register} errors={errors} />
      </Box>
      <Box mb={4}>
        <FormTextarea variant="whiteBorder" label="Text" placeholder="Text" name="text" required={true} errors={errors} register={register} getValues={getValues} setValue={setValue} />
      </Box>
      <Box mb={4}>
        <FormInput variant="whiteBorder" label="Lien" type="text" placeholder="Lien" name="link" register={register} errors={errors} />
      </Box>
      <Flex justifyContent="flex-end" alignItems="center" mt={4} mb={4} w="100%">
        <Button variant="navYellow" mr={3} type="submit" isLoading={mutation.isLoading} isDisabled={!isValid}>
          Valider
        </Button>
      </Flex>
      {!newsUpdated && <InSavedChangesPrompt isDirty={isDirty} />}
    </form>
  );
};

export default UpdateNewsForm;
