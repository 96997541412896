import { API_DISCORD_CHANNELS } from '../../../utils/api/constants';
import { DiscordChannel } from '../../../types/Discord';
import api from '../../../utils/api/api';
import { useQuery, UseQueryResult } from 'react-query';

export const useDiscordChannelById = (channelId: string): UseQueryResult<DiscordChannel, unknown> => {
  return useQuery<DiscordChannel, unknown>(['discordChannel', channelId], async ({ queryKey }) => {
    // Le deuxième élément de queryKey est l'ID du canal
    const [, channelId] = queryKey;
    const { data } = await api.get(`${API_DISCORD_CHANNELS}/${channelId}`);
    return data;
  });
};
