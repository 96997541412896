import React from 'react';
import { useMediaToValidate } from '../../../hooks/media/useMediaToValidate';

import { Box, Button, Image, SimpleGrid } from '@chakra-ui/react';
import { S3_URL } from '../../../utils/constants';
import PageLayout from '../../../layout/PageLayout';
import { useMediaValidation } from '../../../hooks/media/useMediaValidation';

const ValidateMedia: React.FC = () => {
  const { data: mediaToValidate, isLoading, isError, error } = useMediaToValidate();
  const { mutate: mediaValidation } = useMediaValidation();

  const rejectMedia = (id: number, url: string) => {
    mediaValidation({ medias: [{ id, url }], action: 'reject' });
  };

  const validateMedia = (id: number, url: string) => {
    mediaValidation({ medias: [{ id, url }], action: 'validate' });
  };

  const validateAllMedia = () => {
    if (mediaToValidate) {
      mediaValidation({
        medias: mediaToValidate.map(media => {
          return { id: media.id, url: media.url };
        }),
        action: 'validate',
      });
    }
  };

  return (
    <PageLayout isLoading={isLoading} isError={isError} error={error}>
      <Box textAlign="right">
        <Button variant="navGreen" onClick={() => validateAllMedia()}>
          Tout valider
        </Button>
      </Box>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
        {mediaToValidate &&
          mediaToValidate.length > 0 &&
          mediaToValidate.map(media => (
            <Box key={media.url}>
              <Button variant="navGreen" onClick={() => validateMedia(media.id, media.url)}>
                Valider
              </Button>
              <Button variant="navRed" onClick={() => rejectMedia(media.id, media.url)}>
                Supprimer
              </Button>
              <Image src={`${S3_URL}/${media.url}`} />
            </Box>
          ))}
      </SimpleGrid>
    </PageLayout>
  );
};

export default ValidateMedia;
