import React from 'react';
import { Box, Button, Flex, Text, useBreakpointValue } from '@chakra-ui/react';
import UserAvatar from '../../../components/UserAvatar';
import { User } from '../../../types/user';
import { useBlockOrUnblockUser } from '../../../hooks/users/useBlockOrUnblockUser';
import { getAge } from '../../../utils/dates';
import { formatUri } from '../../../utils/api/helpers';
import { PROFILE_PATH } from '../../../routing/constants';
import { useNavigate } from 'react-router-dom';
import BlockUserButton from '../../../components/BlockUserButton';
import UserCover from '../../../components/UserCover';

interface MessageInfosUserProps {
  targetUser: User;
  currentUser: User;
}

const MessageInfosUser: React.FC<MessageInfosUserProps> = ({ currentUser, targetUser }) => {
  const navigate = useNavigate();
  const isMobileView = useBreakpointValue({ base: true, md: false });
  const { unblockUser } = useBlockOrUnblockUser();
  const isCurrentUserBlocked = currentUser?.blockedUsers?.some(blockedUser => blockedUser.id === targetUser?.id) || false;
  const isUserBlocked = targetUser?.blockedUsers?.some(blockedUser => blockedUser.id === currentUser?.id) || false;

  const handleUnblock = () => {
    if (targetUser) {
      unblockUser.mutate(targetUser.id);
    }
  };

  if (!targetUser) return null;

  return (
    <Flex alignItems="center" pb={2} mb={6} w="100%" mt={isMobileView ? 4 : 0} flexDirection={isMobileView ? 'column' : 'row'}>
      <UserCover media={targetUser.mediaCover}>
        <Flex position="relative" height="100%" alignItems="center" ml={4} p={4}>
          <UserAvatar withBorder user={targetUser} styles={{ size: 'lg' }} />
          <Box ml={isMobileView ? 0 : 4} mt={isMobileView ? 4 : 0}>
            <Text fontSize={'20px'} fontWeight="bold">
              {targetUser.username}
            </Text>
            <Text fontWeight="bold">
              {getAge(targetUser.bornDate)} ans - {targetUser.location.country} {targetUser.location.city ? `, ${targetUser.location.city}` : null}
            </Text>
          </Box>
          {!isMobileView && (
            <Flex flexGrow={1} justifyContent="flex-end" my={isMobileView ? 4 : 0}>
              {isUserBlocked ? (
                <Flex alignItems="center">
                  <Text ml={4} textAlign="center">
                    Vous avez été bloqué par cet utilisateur.
                  </Text>
                  {!isCurrentUserBlocked ? (
                    <BlockUserButton user={targetUser} />
                  ) : (
                    <Button variant="navRedFull" ml={4} onClick={handleUnblock} disabled={unblockUser.isLoading}>
                      Débloquer
                    </Button>
                  )}
                </Flex>
              ) : (
                <>
                  {isCurrentUserBlocked ? (
                    <Flex alignItems="center">
                      <Text ml={4} textAlign="center">
                        Vous avez bloqué cet utilisateur.
                      </Text>
                      <Button variant="navRedFull" ml={4} onClick={handleUnblock} disabled={unblockUser.isLoading}>
                        Débloquer
                      </Button>
                    </Flex>
                  ) : (
                    <>
                      <Button variant="navYellowFull" ml={4} onClick={() => navigate(formatUri({ username: targetUser.username }, PROFILE_PATH))}>
                        Profile
                      </Button>
                      <BlockUserButton user={targetUser} />
                    </>
                  )}
                </>
              )}
            </Flex>
          )}
        </Flex>
        {isMobileView && (
          <Flex flexGrow={2} justifyContent="center" my={4}>
            {isUserBlocked ? (
              <Flex alignItems="center">
                <Text ml={4} textAlign="center">
                  Vous avez été bloqué par cet utilisateur.
                </Text>
                {!isCurrentUserBlocked ? (
                  <BlockUserButton user={targetUser} />
                ) : (
                  <Button variant="navRedFull" ml={4} onClick={handleUnblock} disabled={unblockUser.isLoading}>
                    Débloquer
                  </Button>
                )}
              </Flex>
            ) : (
              <>
                {isCurrentUserBlocked ? (
                  <Flex alignItems="center">
                    <Text ml={4} textAlign="center">
                      Vous avez bloqué cet utilisateur.
                    </Text>
                    <Button variant="navRedFull" ml={4} onClick={handleUnblock} disabled={unblockUser.isLoading}>
                      Débloquer
                    </Button>
                  </Flex>
                ) : (
                  <>
                    <Button variant="navYellowFull" ml={4} onClick={() => navigate(formatUri({ username: targetUser.username }, PROFILE_PATH))}>
                      Profile
                    </Button>
                    <BlockUserButton user={targetUser} />
                  </>
                )}
              </>
            )}
          </Flex>
        )}
      </UserCover>
    </Flex>
  );
};

export default MessageInfosUser;
