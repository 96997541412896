import React, { useRef, useState, useEffect } from 'react';
import { FormControl, FormLabel, Input, FormErrorMessage, VStack, Box, List, ListItem, ListIcon } from '@chakra-ui/react';
import { MdLocationOn } from 'react-icons/md';
import { customColors } from '../../theme';

export interface CityAutocompleteProps {
  register: any;
  setValue: any;
  label?: string;
  type?: string;
  placeholder: string;
  name: string;
  required?: boolean;
  errors: any;
  getValues: any;
  variant?: any;
  sx?: any;
}

interface Suggestion {
  place_id: number;
  display_name: string;
  lat: string;
  lon: string;
  addresstype: string;
  address: {
    house_number: string;
    village: string;
    county: string;
    amenity: string;
    postcode: string;
    country: string;
    municipality: string;
    state: string;
    region: string;
    road: string;
    city: string;
    town: string;
  };
}

const CityAutocomplete: React.FC<CityAutocompleteProps> = ({ sx, variant = 'violet', getValues, setValue, label, type = 'city', placeholder, name, required, errors }) => {
  const [inputValue, setInputValue] = useState(getValues(name) ? getValues(name).formattedAddress : '');
  const [suggestions, setSuggestions] = useState([]);
  const inputRef = useRef(null);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | number | null>(null);

  const fetchSuggestions = async (value: string) => {
    const url =
      type === 'city' ? `https://nominatim.openstreetmap.org/search?format=json&q=${value}&limit=5` : `https://nominatim.openstreetmap.org/search?format=json&q=${value}&addressdetails=1&limit=5`;

    const response = await fetch(url);
    const data = await response.json();
    setSuggestions(data);
  };

  const fetchDetailedAddress = async (lat: string, lon: string) => {
    const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}&addressdetails=1`;
    const response = await fetch(url);
    return await response.json();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Définit un nouveau timeout
    const newTimeoutId = setTimeout(() => {
      if (value.length > 2) {
        fetchSuggestions(value);
      } else {
        setSuggestions([]);
      }
    }, 500);

    setTimeoutId(newTimeoutId);
  };

  const handleSuggestionClick = async (suggestion: Suggestion) => {
    const parts = suggestion.display_name.split(', ').reverse();
    setInputValue(type === 'city' ? parts[parts.length - 1] : suggestion.display_name);
    setSuggestions([]);
    if (setValue) {
      const data = await extractAddressDetails(suggestion);
      setValue(name, data, { shouldValidate: true });
      setInputValue(data.formattedAddress);
    }
  };

  async function extractAddressDetails(suggestion: Suggestion) {
    const details = {
      formattedAddress: '',
      city: '',
      country: '',
      region: '',
      department: '',
    };
    if (type !== 'city') {
      details.country = suggestion.address.country || '';
      if (suggestion.address.country === 'France') {
        details.region = suggestion.address.region || '';
        details.department = suggestion.address.county || suggestion.address.state || '';
      }
      details.city = suggestion.address.town || suggestion.address.city || suggestion.address.municipality || '';
      if (suggestion.addresstype === 'neighbourhood' || suggestion.addresstype === 'city') {
        details.formattedAddress = suggestion.display_name;
      } else {
        details.formattedAddress = `${suggestion.address.house_number ? `${suggestion.address.house_number} ` : ''}${suggestion.address.amenity ? `${suggestion.address.amenity} ` : ''}${
          suggestion.address.road ? `${suggestion.address.road}, ` : ''
        }${suggestion.address.postcode ? `${suggestion.address.postcode} ` : ''}${details.city ? `${details.city} ` : ''}${suggestion.address.country !== 'France' ? suggestion.address.country : ''}`;
      }
    } else {
      const data = await fetchDetailedAddress(suggestion.lat, suggestion.lon);
      details.formattedAddress = data.address.village || data.address.town || data.address.city || data.address.municipality || '';
      details.country = data.address.country;
      if (data.address.country === 'France') {
        details.region = data.address.region || '';
        details.department = data.address.county || data.address.state || '';
      }
      details.city = data.address.town || data.address.city || data.address.municipality || '';
    }

    return details;
  }

  // Pour initialiser la valeur du champ si une valeur existe
  useEffect(() => {
    if (getValues(name)) {
      setInputValue(getValues(name).formattedAddress);
    }
  }, [getValues, name]);

  return (
    <VStack w="100%" sx={sx}>
      <Box position="relative" w="100%">
        <FormControl w="100%" isRequired={required} isInvalid={errors && !!errors[name]}>
          {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
          <Input id={name} variant={variant} ref={inputRef} type="text" placeholder={placeholder} value={inputValue} onChange={handleInputChange} />
          <FormErrorMessage>{errors && errors[name] && errors[name].message}</FormErrorMessage>
        </FormControl>
        {suggestions.length > 0 && (
          <List bg="blackTegrite" border="1px solid white" position="absolute" w="full" shadow="md" rounded="md" py={2} zIndex="popover">
            {suggestions.map((suggestion: Suggestion) => (
              <ListItem bg="blackTegrite" sx={{ _hover: { bg: customColors.yellowBob } }} key={suggestion.place_id} px={4} py={2} cursor="pointer" onClick={() => handleSuggestionClick(suggestion)}>
                <ListIcon as={MdLocationOn} color="green.500" />
                {suggestion.display_name}
              </ListItem>
            ))}
          </List>
        )}
      </Box>
    </VStack>
  );
};

export default CityAutocomplete;
