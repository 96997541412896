import { useQuery, UseQueryResult } from 'react-query';
import api from '../../utils/api/api';
import { User } from '../../types/user';
import { API_ADMIN_USERS_SEARCH_URI } from '../../utils/api/constants';
import { SearchUsersForm } from '../../pages/admin/users/search/constants';

export const useSearchAdminUsers = (form: SearchUsersForm | null, skip: number, limit: number): UseQueryResult<{ users: User[]; total: number }, unknown> => {
  return useQuery(
    ['adminUsers', form, skip, limit],
    async () => {
      if (!form) return [];
      const { data } = await api.get(API_ADMIN_USERS_SEARCH_URI, {
        params: { ...form, skip, limit },
      });
      return data;
    },
    {
      enabled: !!form, // La requête sera exécutée seulement si "form" est non-null
    },
  );
};
