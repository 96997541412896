import { Flex } from '@chakra-ui/react';
import React from 'react';
import Stream from './stream/Stream';
import Calendar from './Calendar';
import Actus from './Actus';

const RightBar: React.FC = () => {
  return (
    <Flex flexDirection="column" flexGrow={1}>
      <Stream />
      <Calendar />
      <Actus />
    </Flex>
  );
};

export default RightBar;
