import React from 'react';
import { Box, Button } from '@chakra-ui/react';
import PageTitleUpdater from '../../../../routing/PageTitleUpdater';
import FormInput from '../../../../components/forms/FormInput';
import FormTextarea from '../../../../components/forms/FormTextarea';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { DiscordRoleForm } from '../constants';
import { discordRoleCreateSchema } from '../yup/discordRoleCreateSchema';
import { useAddDiscordRole } from '../../../../hooks/discord/useAddDiscordRole';
import { useNavigate } from 'react-router-dom';
import { ADMIN_DISCORD_ROLES_PATH } from '../../../../routing/constants';

const DiscordRoleCreate: React.FC = () => {
  const mutation = useAddDiscordRole();
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm<DiscordRoleForm>({
    resolver: yupResolver(discordRoleCreateSchema),
  });

  const onSubmit = async (data: DiscordRoleForm) => {
    try {
      await mutation.mutateAsync(data);
      toast.success('Message envoyé avec succès', {
        position: toast.POSITION.TOP_CENTER,
      });
      navigate(ADMIN_DISCORD_ROLES_PATH);
    } catch (error: any) {
      toast.error(`Échec d'envoi du message : ${error.response.data.message}`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PageTitleUpdater additionalTitle="Admin Panel - Ajout role discord" />
      <Box mt={4}>
        <FormInput variant="whiteBorderNoBg" label={'Nom'} placeholder="Nom" type="text" name="name" required register={register} errors={errors} />
      </Box>
      <Box mt={4}>
        <FormTextarea variant="whiteBorderNoBg" label={'Emoji'} placeholder="Emoji" name="emoji" required register={register} errors={errors} getValues={getValues} setValue={setValue} />
      </Box>
      <Box my={4} textAlign="right">
        <Button variant="navYellow" type="submit" isLoading={mutation.isLoading} isDisabled={!isValid}>
          Enregistrer
        </Button>
      </Box>
    </form>
  );
};

export default DiscordRoleCreate;
