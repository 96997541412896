import React from 'react';
import { Box, Button, Divider, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import PageTitleUpdater from '../../../../routing/PageTitleUpdater';
import { useNavigate } from 'react-router-dom';
import { useDrawContest } from '../../../../hooks/contests/useDrawContest';
import { Contest } from '../../../../types/Contests';
import { toast } from 'react-toastify';
import { ADMIN_CONTESTS_LIST_PATH } from '../../../../routing/constants';

interface ContestDrawProps {
  contest: Contest;
}
const ContestDraw: React.FC<ContestDrawProps> = ({ contest }) => {
  const mutation = useDrawContest();
  const navigate = useNavigate();

  const onSubmit = async () => {
    try {
      await mutation.mutate({ id: contest.id, winners: [], text: '' });
      toast.success('Tirage au sort réalisé avec succès', {
        position: toast.POSITION.TOP_CENTER,
      });
      navigate(ADMIN_CONTESTS_LIST_PATH);
    } catch (e) {
      toast.error('Une erreur est survenue', {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <Box>
      <PageTitleUpdater additionalTitle="Admin Panel - Concours - Tirage au sort" />
      <Box>
        <Text as={'h3'} fontWeight="bold" mb={2}>
          Participants sur Discord
        </Text>
        <Divider />
        <SimpleGrid columns={4} spacing={10} mt={2}>
          {contest.discordParticipants.map(participant => (
            <Text key={participant}>{participant}</Text>
          ))}
        </SimpleGrid>
        <Text as={'h3'} fontWeight="bold" mb={2} mt={4}>
          Participants sur Entre-Geeks
        </Text>
        <Divider />
        <SimpleGrid columns={4} spacing={10} mt={2}>
          {contest.participants.map(participant => (
            <Text key={participant.id}>{participant.username}</Text>
          ))}
        </SimpleGrid>

        <Flex flexDir="row" justifyContent="flex-end" mt={4}>
          <Button variant="navYellow" onClick={onSubmit}>
            Lancer le tirage au sort
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};

export default ContestDraw;
