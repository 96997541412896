import React from 'react';
import { Box, Button, Divider, Flex, Text, useBreakpointValue, VStack } from '@chakra-ui/react';
import { APP_URL, PUPPETEER_URL, S3_URL } from '../../../utils/constants';
import { useNavigate, useParams } from 'react-router-dom';
import { useNewsBySlug } from '../../../hooks/news/useNewsBySlug';
import NewComments from './NewsComments';
import Markdown from 'react-markdown';
import PageLayout from '../../../layout/PageLayout';
import HeaderNews from './HeaderNews';
import { formatUri } from '../../../utils/api/helpers';
import { PROFILE_PATH } from '../../../routing/constants';
import PageTitleUpdater from '../../../routing/PageTitleUpdater';
import { removeMarkdown } from '../../../utils/helpers';
import UserCover from '../../../components/UserCover';
import UserAvatar from '../../../components/UserAvatar';
import { getAge } from '../../../utils/dates';
import ShareModalButton from '../../../components/ShareModalButton';

type ParamTypes = {
  slug: string;
};

const News: React.FC = () => {
  const navigate = useNavigate();
  const { slug } = useParams<ParamTypes>();
  const { data, isLoading, isError, error } = useNewsBySlug(slug || '');
  const isMobileView = useBreakpointValue({ base: true, md: false });

  return (
    <PageLayout isLoading={isLoading} isError={isError} error={error}>
      {data && (
        <>
          <PageTitleUpdater additionalTitle={`Actualités - ${data.title}`} additionalDescription={removeMarkdown(data.text)} additionalOgImage={`${S3_URL}/news/${data.media}`} />
          <HeaderNews news={data} />
          {isMobileView && (
            <Box mt={-5}>
              <ShareModalButton
                shareFbUrl={`${PUPPETEER_URL}/news/${data.slug}`}
                shareTwitterUrl={`${APP_URL}/news/${data.slug}`}
                title={`[News] ${data.title}`}
                description={removeMarkdown(data.text)}
              />
            </Box>
          )}
          <Box px={isMobileView ? 0 : '16'} w="100%" mt={4}>
            <VStack align="start" spacing={2}>
              <Box style={{ whiteSpace: 'pre-line' }} className="markdown">
                <Markdown>{data?.text}</Markdown>
              </Box>

              {data?.link && (
                <>
                  <Divider borderColor="whiteTegrite" />
                  <Text>
                    Link:
                    <a href={data?.link} target="_blank" rel="noreferrer">
                      {data?.link}
                    </a>
                  </Text>
                </>
              )}
              <Text fontSize="13px" ml={2} mt={4}>
                Article rédigé par
              </Text>
              <Flex alignItems="center" pb={2} mb={6} w="100%" mt={2} flexDirection={isMobileView ? 'column' : 'row'}>
                <UserCover media={data.userId.mediaCover}>
                  <Flex position="relative" height="100%" alignItems="center" ml={4} p={4}>
                    <UserAvatar withBorder user={data.userId} styles={{ size: 'lg' }} />
                    <Box ml={isMobileView ? 0 : 4} mt={isMobileView ? 4 : 0}>
                      <Text fontSize={'20px'} fontWeight="bold">
                        {data.userId.username}
                      </Text>
                      <Text fontWeight="bold">
                        {getAge(data.userId.bornDate)} ans - {data.userId.location.country} {data.userId.location.city ? `, ${data.userId.location.city}` : null}
                      </Text>
                    </Box>
                    <Flex flexGrow={1} justifyContent="flex-end" my={isMobileView ? 4 : 0}>
                      <Button variant="navYellowFull" ml={4} onClick={() => navigate(formatUri({ username: data.userId.username }, PROFILE_PATH))}>
                        Profile
                      </Button>
                    </Flex>
                  </Flex>
                </UserCover>
              </Flex>
            </VStack>

            {data.threadId && (
              <Box>
                <Text fontSize="13px" ml={2} mb={2}>
                  Commentaires
                </Text>
                <NewComments threadId={data.threadId} />
              </Box>
            )}
          </Box>
        </>
      )}
    </PageLayout>
  );
};

export default News;
