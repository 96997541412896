import { useQuery, UseQueryResult } from 'react-query';
import api from '../../utils/api/api';
import { Event } from '../../types/Events';
import { API_ADMIN_INVALID_EVENTS_URI } from '../../utils/api/constants';

export const useInvalidEvents = (): UseQueryResult<Event[], unknown> => {
  return useQuery<Event[]>(
    ['invalidEvents'],
    async () => {
      const { data } = await api.get(API_ADMIN_INVALID_EVENTS_URI);
      return data;
    },
    {
      refetchInterval: 5 * 60 * 1000,
    },
  );
};
