import { useQuery, UseQueryResult } from 'react-query';
import api from '../../utils/api/api';
import { API_EVENTS } from '../../utils/api/constants';
import { Event } from '../../types/Events';

export const useEventBySlug = (slug: string): UseQueryResult<Event, unknown> => {
  return useQuery(['event', slug], async () => {
    const { data } = await api.get(`${API_EVENTS}/${slug}`);
    return data;
  });
};
