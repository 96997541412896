import React from 'react';
import { useParams } from 'react-router-dom';
import PageTitleUpdater from '../../../../routing/PageTitleUpdater';
import UserUpdateForm from './UserUpdateForm';

type ParamTypes = {
  username: string;
};

const UserAdminUpdate: React.FC = () => {
  const { username } = useParams<ParamTypes>();

  return (
    <>
      <PageTitleUpdater additionalTitle={`Admin panel - update - ${username}`} /> {username ? <UserUpdateForm usernameParam={username} /> : <p>Loading or no username...</p>}
    </>
  );
};

export default UserAdminUpdate;
