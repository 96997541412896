import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Container, Flex, Image } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { CONNEXION_PATH } from '../../routing/constants';
import { useAnimation } from 'framer-motion';
import MenuHomeNoAuthContent from './MenuHomeContent';

interface MenuHomeNoAuthProps {
  currentIndex: number | null;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number | null>>;
  isMobile?: boolean;
}

const MenuHomeNoAuth: React.FC<MenuHomeNoAuthProps> = ({ currentIndex, setCurrentIndex, isMobile }) => {
  const navigate = useNavigate();
  const [headerBg, setHeaderBg] = useState('blackTegrite');
  const [videoEnd, setVideoEnd] = useState(isMobile ? 200 : window.innerHeight);
  const controls = useAnimation();
  const refs = useRef<any>([]);

  useEffect(() => {
    setVideoEnd(isMobile ? 200 : window.innerHeight);
  }, [isMobile]);

  useEffect(() => {
    setVideoEnd(isMobile ? 200 : window.innerHeight);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setHeaderBg(position > videoEnd ? 'yellowBob' : 'blackTegrite');
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [videoEnd, isMobile]);

  useEffect(() => {
    if (currentIndex !== null) {
      const currentRef = refs.current[currentIndex - 1];
      if (currentRef) {
        controls.start({
          left: currentRef.offsetLeft,
          width: currentRef.offsetWidth,
          opacity: 1, // Assurez-vous que la barre est visible lorsque l'élément est sélectionné
          transition: { duration: 0.3 }, // Ajustez selon vos besoins pour la fluidité
        });
      }
    } else {
      // Si currentIndex est null, faites disparaître la barre
      controls.start({
        opacity: 0, // Rend la barre invisible
        transition: { duration: 0.2 }, // Animation plus rapide pour la disparition
      });
    }
  }, [currentIndex, controls]);

  return (
    <Flex as="header" position="fixed" top="0" width="100%" zIndex="popover" background={headerBg} p="4" align="center" justify="space-between" transition="background 0.2s ease-in-out">
      <Container maxW="container.2xl" p={isMobile ? 0 : 4}>
        <Flex width="100%" align="center" justify="space-between" mt={2}>
          <Box onClick={() => setCurrentIndex(null)} cursor="pointer" mr={4}>
            <Image src={`/assets/logos/logo-white.svg`} align="center" maxHeight="50px" />
          </Box>
          <Flex alignItems="center" justifyContent="center" mr={isMobile ? 0 : 6}>
            {!isMobile && headerBg !== 'yellowBob' && <MenuHomeNoAuthContent currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />}
            <Button variant="loginHome" ml={20} onClick={() => navigate(CONNEXION_PATH)}>
              Connexion
            </Button>
          </Flex>
        </Flex>
      </Container>
    </Flex>
  );
};

export default MenuHomeNoAuth;
