import { Box, Flex, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import ImageCard from './ImageCard';
import UserCardStringAvatar from '../UserCardStringAvatar';

interface ImageWithBoxCardProps {
  image: string;
  imageName: string;
  user?: { id: string; username: string; media: string };
  contentHeader?: React.ReactNode;
  contentFooter?: React.ReactNode;
  contentRight?: React.ReactNode;
  height?: number;
  contentInCard?: React.ReactNode;
}

const CardImageWithBox: React.FC<ImageWithBoxCardProps> = ({ contentInCard, contentRight, height = 141, image, user, contentHeader, imageName, contentFooter }) => {
  const isMobileView = useBreakpointValue({ base: true, md: false });

  return (
    <Box cursor="pointer" _hover={{ transform: 'translateY(-4px)', boxShadow: 'lg' }} transition="transform 0.2s, box-shadow 0.2s">
      <Box position="relative" w="100%" h={`${height}px`} boxShadow="md">
        <ImageCard image={image} imageName={imageName} />
        {contentInCard && (
          <Box position="absolute" top="5" right="5" bg="rgba(43, 44, 44, 0.9)" borderRadius="21px" p={4} minWidth="75px">
            <Box fontWeight="bold" textAlign="center" fontSize="13px">
              {contentInCard}
            </Box>
          </Box>
        )}

        <Flex direction="column" position="absolute" bottom="0" left="0" w="100%" h="100%">
          {user && (
            <Flex alignItems="bottom" h="100%">
              <UserCardStringAvatar withBorder username={user.username} media={user.media} styles={{ size: 'sm' }} />
              {contentRight && !isMobileView && (
                <Box position={'absolute'} bottom={'-10.5px'} right={0} zIndex={10}>
                  <Flex alignItems={'center'} justifyContent={'center'}>
                    <Box bg="whiteTegrite" ml={-1} borderRadius="21px 0 0 0" px={4}>
                      {contentRight}
                    </Box>
                  </Flex>
                </Box>
              )}
            </Flex>
          )}
          {!user && contentRight && (
            <Flex alignItems="bottom" h="100%">
              {contentRight && !isMobileView && (
                <Box position={'absolute'} bottom={'-10.5px'} right={0} zIndex={10}>
                  <Flex alignItems={'center'} justifyContent={'center'}>
                    <Box bg="whiteTegrite" ml={-1} borderRadius="21px 0 0 0" px={4}>
                      {contentRight}
                    </Box>
                  </Flex>
                </Box>
              )}
            </Flex>
          )}
          {contentHeader}
        </Flex>
      </Box>

      <Box bgColor="whiteTegrite" borderRadius="0 0 21px 21px" py={2} px={4} overflow="hidden">
        {contentFooter}
      </Box>
    </Box>
  );
};

export default CardImageWithBox;
