import { Badge, Box, Flex, Image, useBreakpointValue } from '@chakra-ui/react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { HOME_BOARD, MESSAGES_CONVERSATIONS, VISITS } from '../../routing/constants';
import UserAvatar from '../../components/UserAvatar';
import { User } from '../../types/user';
import { AiOutlineMenu } from 'react-icons/ai';
import { useSocketContext } from '../../context/SocketContext';
import Linker from '../../components/Linker';
import UserMenu from './UserMenu';
import UserMenuContent from './UserMenuContent';
import { isStaff } from '../../utils/constants';
import AdminIcon from './admin/AdminIcon';
import { MenuModal } from '../../components/MenuModal';

interface UserCardProps {
  user: User;
}
const UserCard: React.FC<UserCardProps> = ({ user }) => {
  const [isContentVisible, setIsContentVisible] = React.useState(false);
  const isMobileView = useBreakpointValue({ base: true, md: false });
  const { unreadData } = useSocketContext();
  const [showMenu, setShowMenu] = useState(false);

  const toggleContent = () => {
    setIsContentVisible(!isContentVisible);
  };

  return (
    <Box zIndex={1}>
      {isMobileView && (
        <Flex justifyContent="space-between" alignItems="center">
          <Linker to={HOME_BOARD}>
            <Box ml={6}>
              <Image src="/assets/logos/logo-white-alone.svg" align="center" maxHeight="30px" />
            </Box>
          </Linker>
          <Box mr={3}>
            <AiOutlineMenu onClick={toggleContent} size={25} cursor="pointer" />
          </Box>
        </Flex>
      )}
      {(isContentVisible || !isMobileView) && (
        <Box>
          <Flex alignItems="center">
            <Box textAlign="right">
              <Flex>
                <Box mr={2}>
                  <Link to={VISITS} onClick={() => (toggleContent ? toggleContent() : undefined)}>
                    <Flex>
                      <Image src="/assets/icons/eye.svg" width="30px" height="42px" mt={1} alt="Messages" display="inline-block" verticalAlign="bottom" />
                      {unreadData && unreadData.visits > 0 && (
                        <Box ml={'0px'} mt={-1}>
                          <Badge px={'5.4px'} borderRadius="full" bg="redRanger" color="whiteWalter">
                            {unreadData.visits}
                          </Badge>
                        </Box>
                      )}
                    </Flex>
                  </Link>
                </Box>
                <Box>
                  <Link to={MESSAGES_CONVERSATIONS} onClick={() => (toggleContent ? toggleContent() : undefined)}>
                    <Flex>
                      <Image src="/assets/icons/chat.svg" width="50px" height="50px" alt="Messages" display="inline-block" verticalAlign="bottom" />
                      {unreadData && unreadData.messages > 0 && (
                        <Box ml={'-8px'} mt={-1}>
                          <Badge px={'5.4px'} borderRadius="full" bg="redRanger" color="whiteWalter">
                            {unreadData.messages}
                          </Badge>
                        </Box>
                      )}
                    </Flex>
                  </Link>
                </Box>
                {isStaff(user) && <AdminIcon toggleContent={toggleContent} />}
              </Flex>
            </Box>
            <Box position="relative" ml={2} mr={2} onMouseEnter={() => setShowMenu(true)} onMouseLeave={() => setShowMenu(false)}>
              <UserAvatar isDisplayIcon={!isMobileView} withBorder user={user} styles={{ size: 'sm' }} />
              {showMenu && !isMobileView && <UserMenu user={user} />}
            </Box>
          </Flex>
          {isMobileView && (
            <Box my={4}>
              <UserMenuContent user={user} toggleContent={toggleContent} />
            </Box>
          )}
        </Box>
      )}
      {isMobileView && <MenuModal unreadData={unreadData} user={user} isOpen={isContentVisible} toggleContent={toggleContent} />}
    </Box>
  );
};

export default UserCard;
