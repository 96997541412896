import React from 'react';
import { Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, ModalCloseButton, Box } from '@chakra-ui/react';

import Markdown from 'react-markdown';

interface EventPresentationModalProps {
  isOpen: boolean;
  onClose: () => void;
  text: string;
}

const EventPresentationModal: React.FC<EventPresentationModalProps> = ({ text, isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Description</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Box className="markdown">
            <Markdown>{text}</Markdown>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EventPresentationModal;
