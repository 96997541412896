import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import api from '../../utils/api/api';
import { API_CONTESTS } from '../../utils/api/constants';

export const useJoinOrLeaveContest = (): {
  joinContest: UseMutationResult<void, Error, string>;
  leaveContest: UseMutationResult<void, Error, string>;
} => {
  const queryClient = useQueryClient();

  const joinContest = useMutation<void, Error, string>((contestId: string) => api.post(`${API_CONTESTS}/${contestId}/join`).then(res => res.data), {
    onSuccess: () => {
      queryClient.invalidateQueries('contests');
      queryClient.invalidateQueries('contest');
      queryClient.invalidateQueries('activeContest');
    },
  });

  const leaveContest = useMutation<void, Error, string>((contestId: string) => api.post(`${API_CONTESTS}/${contestId}/leave`).then(res => res.data), {
    onSuccess: () => {
      queryClient.invalidateQueries('contests');
      queryClient.invalidateQueries('contest');
      queryClient.invalidateQueries('activeContest');
    },
  });

  return {
    joinContest,
    leaveContest,
  };
};
