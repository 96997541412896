import { useMutation, useQueryClient, UseMutationResult } from 'react-query';
import api from '../../utils/api/api';
import { API_NEWS_CREATE } from '../../utils/api/constants';
import { NewsForm } from '../../pages/admin/news/constants';
import { News } from '../../types/News';

export const useCreateNews = (): UseMutationResult<News, Error, NewsForm, unknown> => {
  const queryClient = useQueryClient();

  return useMutation<News, Error, NewsForm>(
    (newNews: NewsForm) =>
      api
        .post(API_NEWS_CREATE, newNews, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res => res.data),
    {
      onSuccess: () => {
        queryClient.refetchQueries('newsList');
        queryClient.refetchQueries('latestNews');
      },
    },
  );
};
