import { useQuery, UseQueryResult } from 'react-query';
import api from '../../utils/api/api';
import { API_EVENTS } from '../../utils/api/constants';

import { Event } from '../../types/Events';
import { QueryParams } from '../../pages/events/eventsList/EventsList';

interface EventResponse {
  events: Event[];
  total: number;
}

export const useEvents = (options: QueryParams): UseQueryResult<EventResponse, unknown> => {
  // Destructure options with default values to ensure they are not undefined
  const { name = '', type = '', old = false, skip = 0, limit = 10, entityType, date } = options;

  return useQuery(['events', name, old, skip, limit, entityType, date, type], async () => {
    const queryParams = new URLSearchParams();

    if (name) queryParams.append('name', name);
    if (type) queryParams.append('type', type);
    if (old) queryParams.append('old', String(old));
    if (skip) queryParams.append('skip', String(skip));
    if (limit) queryParams.append('limit', String(limit));
    if (entityType) queryParams.append('entityType', String(entityType));
    if (date) queryParams.append('date', date);

    const { data } = await api.get<EventResponse>(`${API_EVENTS}?${queryParams.toString()}`);
    return data;
  });
};
