import React, { useState } from 'react';
import { FormControl, FormLabel, Input, FormErrorMessage, InputGroup, InputRightElement, InputLeftElement, Image } from '@chakra-ui/react';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

interface FormInputProps {
  defaultValue?: string;
  label?: string;
  type?: string;
  placeholder?: string;
  name: string;
  register?: any;
  errors?: any;
  required?: boolean;
  variant?: any;
  sx?: any;
  icon?: any;
  iconActionRight?: any;
}

const FormInput: React.FC<FormInputProps> = ({ iconActionRight, icon, sx, defaultValue, variant = 'violet', label, type = 'text', placeholder, name, register, errors, required }) => {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  return (
    <FormControl sx={sx} isRequired={required} isInvalid={errors && !!errors[name]}>
      {label && <FormLabel>{label}</FormLabel>}
      <InputGroup size="md">
        {icon && (
          <InputLeftElement>
            <Image alt="logo" src={icon} boxSize="20px" />
          </InputLeftElement>
        )}
        <Input
          pl={icon ? 10 : undefined}
          variant={variant}
          defaultValue={defaultValue}
          type={type === 'password' ? (show ? 'text' : 'password') : type}
          placeholder={placeholder}
          {...(register ? register(name) : {})}
        />
        {iconActionRight && <InputRightElement>{iconActionRight}</InputRightElement>}
        {type === 'password' && (
          <InputRightElement width="4.5rem" onClick={handleClick}>
            {show ? <AiFillEye size={30} cursor="pointer" color="#939393" /> : <AiFillEyeInvisible size={30} cursor="pointer" color="#939393" />}
          </InputRightElement>
        )}
      </InputGroup>
      <FormErrorMessage>{errors && errors[name] && errors[name].message}</FormErrorMessage>
    </FormControl>
  );
};

export default FormInput;
