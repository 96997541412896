import * as yup from 'yup';
import moment from 'moment';
import { checkEmail, checkUsername } from '../../../yup/api/user';

const usernameCache: { [key: string]: boolean } = {};
const emailCache: { [key: string]: boolean } = {};

export const UserCreateYupSchema = yup.object().shape({
  username: yup
    .string()
    .min(3, 'Minimum 3 caractères')
    .max(20, 'Maximum 20 caractères')
    .matches(/^[A-Za-z0-9]+$/, "Le nom d'utilisateur ne doit contenir que des lettres et des chiffres")
    .required("Nom d'utilisateur est requis")
    .test('is-username-available', "Nom d'utilisateur déjà utilisé", async value => {
      if (usernameCache[value]) {
        return usernameCache[value];
      }
      const result = await checkUsername(value);
      usernameCache[value] = result;
      return result;
    }),
  email: yup
    .string()
    .required('Email est requis')
    .email('Email non valide')
    .test('is-email-available', 'Email déjà utilisé', async value => {
      if (emailCache[value]) {
        return emailCache[value];
      }
      const result = await checkEmail(value);
      emailCache[value] = result;
      return result;
    }),
  password: yup.string().test('password-validation', 'Minimum 8 caractères ou requis si pas de token Discord', function (value) {
    const { discordData } = this.parent;
    if (discordData) {
      return true;
    }
    if (!value || value.length < 8) {
      return new yup.ValidationError('Minimum 8 caractères requis', null, 'password');
    }
    return true;
  }),
  location: yup
    .object()
    .shape({
      country: yup.string().required('Le pays est requis'),
      city: yup.string(),
    })
    .required('La localisation est requise'),
  bornDate: yup
    .string()
    .required('Date de naissance est requise')
    .test('is-16-or-older', 'Vous devez avoir 16 ans ou plus', value => {
      const currentDate = moment();
      const selectedDate = moment(value);
      const age = currentDate.diff(selectedDate, 'years');
      return age >= 16;
    }),
  media: yup.mixed(),
  cgu: yup.boolean().required('Vous devez valider les CGU'),
});
