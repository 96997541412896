import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Flex, Grid, Image, Text, useBreakpointValue, useMediaQuery } from '@chakra-ui/react';
import { useUserByUsername } from '../../hooks/users/useUserByUsername';
import { useAppContext } from '../../context/AppContext';
import { isStaff } from '../../utils/constants';
import { useBlockOrUnblockUser } from '../../hooks/users/useBlockOrUnblockUser';
import BlockUserButton from '../../components/BlockUserButton';
import Ariane from '../../components/Ariane';
import SocialAndGamesIdComponent from './components/SocialAndGamesIdComponent';
import Stats from './components/Stats';
import { customScrollBar, formatTextWithLineBreaks } from '../../utils/helpers';

import UserProfileHeader from './UserProfileHeader';
import PageLayout from '../../layout/PageLayout';
import PassionsComponent from './components/PassionsComponent';
import { ACCOUNT_PATH, MESSAGES_CONVERSATIONS } from '../../routing/constants';
import { useNavigate } from 'react-router-dom';
import PageTitleUpdater from '../../routing/PageTitleUpdater';

interface ProfileProps {
  usernameParam: string;
}

const Profile: React.FC<ProfileProps> = ({ usernameParam }) => {
  const { user: currentUser } = useAppContext();
  const { data: user, isLoading, isError, error } = useUserByUsername(usernameParam);
  const { unblockUser } = useBlockOrUnblockUser();
  const [boxHeight, setBoxHeight] = useState(null);
  const boxRef = useRef(null);
  const navigate = useNavigate();
  const [isLessThan1480] = useMediaQuery('(max-width: 1480px)');
  const [isLessThan1075] = useMediaQuery('(max-width: 1075px)');
  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    if (boxRef && boxRef.current) {
      const current = (boxRef as any).current;
      if (current && current.offsetHeight) {
        setTimeout(() => {
          const initialHeight = current.offsetHeight;
          setBoxHeight(initialHeight);
        }, 500);
      }
    }
  });

  useEffect(() => {
    const updateHeight = () => {
      if (boxRef && boxRef.current) {
        const current = (boxRef as any).current;
        if (current && current.offsetHeight && boxHeight) {
          const currentHeight = current.offsetHeight;
          setBoxHeight(currentHeight);
        }
      }
    };
    updateHeight();
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, [boxHeight]);

  const handleUnblock = () => {
    if (user && currentUser && currentUser.id !== user.id) {
      unblockUser.mutate(user.id);
    }
  };

  const isCurrentUserBlocked = (currentUser && currentUser.blockedUsers?.some(blockedUser => blockedUser.id === user?.id) && !isStaff(currentUser)) || false;
  const isUserBlocked = (user?.blockedUsers?.some(blockedUser => blockedUser.id === currentUser?.id) && !isStaff(currentUser)) || false;

  if (isCurrentUserBlocked) {
    return (
      <PageLayout isLoading={isLoading} isError={isError} error={error}>
        <p>Vous avez bloqué cet utilisateur.</p>
        <button onClick={handleUnblock} disabled={unblockUser.isLoading}>
          Débloquer
        </button>
      </PageLayout>
    );
  }

  if (isUserBlocked) {
    return (
      <PageLayout isLoading={isLoading} isError={isError} error={error}>
        <p>Vous avez été bloqué par cet utilisateur.</p>
        {!isCurrentUserBlocked ? (
          <>{user && <BlockUserButton user={user} />}</>
        ) : (
          <button onClick={handleUnblock} disabled={unblockUser.isLoading}>
            Débloquer
          </button>
        )}
      </PageLayout>
    );
  }

  const templateColumns = isLessThan1075 ? '1f' : !isLessThan1480 ? '2fr 2fr 1fr' : '2fr 2fr';

  return (
    <PageLayout isLoading={isLoading} isError={isError} error={error}>
      {user && (
        <>
          <PageTitleUpdater additionalTitle={`Profile - ${user.username}`} />
          <Ariane>
            <Flex alignItems="center" justifyContent="space-between" w="100%">
              <Flex alignItems="center">
                <Image src="/assets/icons/green-2.svg" alt="Profile" width="30px" />
                <Text as="h1" textTransform="uppercase" fontSize="19px" ml={4}>
                  Profile
                </Text>
              </Flex>
              {!isMobile && (
                <>
                  {user && currentUser && currentUser.id !== user.id ? (
                    <Button variant="createEvent" onClick={() => navigate(`${MESSAGES_CONVERSATIONS}?username=${user.username}`)}>
                      Envoyer un message
                    </Button>
                  ) : (
                    <Button variant="createEvent" onClick={() => navigate(ACCOUNT_PATH)}>
                      Modifier mon profil
                    </Button>
                  )}
                </>
              )}
            </Flex>
          </Ariane>
          <UserProfileHeader user={user} />
          {isMobile && (
            <Box mt={4}>
              {user && currentUser && currentUser.id !== user.id ? (
                <Button variant="createEvent" onClick={() => navigate(`${MESSAGES_CONVERSATIONS}?username=${user.username}`)}>
                  Envoyer un message
                </Button>
              ) : (
                <Button variant="createEvent" onClick={() => navigate(ACCOUNT_PATH)}>
                  Modifier mon profil
                </Button>
              )}
            </Box>
          )}
          <Grid templateColumns={templateColumns} gap={4} mt={6} h={!isLessThan1480 ? 'calc(100vh - 420px)' : 'auto'} flex={1} ref={boxRef} mb={4}>
            <Box>
              <Box css={customScrollBar} height={isLessThan1075 ? 'auto' : boxHeight ? `${boxHeight}` : 'auto'} maxW={!isLessThan1075 ? '415px' : 'auto'} overflowY="auto">
                <Text as="h3" mb={2} color="yellowBob">
                  Présentation
                </Text>
                <Text pr={2}>{formatTextWithLineBreaks(user.userProfile.presentation)}</Text>
              </Box>
            </Box>
            <Box css={customScrollBar} height={isLessThan1075 ? 'auto' : boxHeight ? `${boxHeight}` : 'auto'} overflowY="auto">
              <Flex direction="column" ml={!isLessThan1075 ? 10 : 0}>
                <PassionsComponent passions={user.userProfile.passions} />
                <SocialAndGamesIdComponent gamesId={user.userProfile.gamesId} socialNetwork={user.userProfile.socialNetworks} />
              </Flex>
            </Box>
            {!isLessThan1480 && (
              <Box>
                <Stats userId={user.id} />
              </Box>
            )}
          </Grid>
        </>
      )}
    </PageLayout>
  );
};

export default Profile;
