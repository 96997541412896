import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import api from '../../utils/api/api';
import { API_DISCORD_ROLE_ADD_ADMIN, API_DISCORD_ROLES_ADMIN } from '../../utils/api/constants';
import { DiscordRoleForm } from '../../pages/admin/discordRoles/constants';

export const useEditDiscordRole = (): UseMutationResult<any, Error, { id: string; discordRole: DiscordRoleForm }, unknown> => {
  const queryClient = useQueryClient();

  return useMutation<any, Error, { id: string; discordRole: DiscordRoleForm }>(({ id, discordRole }) => api.put(`${API_DISCORD_ROLES_ADMIN}/${id}/update`, discordRole).then(res => res.data), {
    onSuccess: () => {
      queryClient.refetchQueries('adminDiscordRoles');
    },
  });
};
