/* eslint-disable max-len */
import React from 'react';
import MarkDownStyled from '../../../components/MarkDownStyled';
import PageLayout from '../../../layout/noAuth/wrappers/PageLayout';
import PageTitleUpdater from '../../../routing/PageTitleUpdater';

const text = `# Entre-Geeks est lancé depuis le 09 mai 2010.

Le concept du site est de réunir des gens fans de la culture geek afin de partager nos passions et en découvrir d'avantages.

La découverte se fait au cours de sorties organisées soit par les membres soit par l'équipe du site dans divers lieux.

Il est aussi possible d'organiser des parties en lignes sur nos jeux préférés afin de ne plus jouer seul !

**Contact du responsable du site**: webmaster\\[at\\]entre-geeks.com
`;
const AboutUs: React.FC = () => {
  return (
    <PageLayout>
      <PageTitleUpdater additionalTitle="A propos" />
      <MarkDownStyled text={text} />
    </PageLayout>
  );
};

export default AboutUs;
