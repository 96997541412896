import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { useAppContext } from '../../../context/AppContext';
import { isAccessGranted, isStaff } from '../../../utils/constants';
import { useParams } from 'react-router-dom';
import { useEventBySlug } from '../../../hooks/events/useEventBySlug';
import PageTitleUpdater from '../../../routing/PageTitleUpdater';
import { EventUpdateForm } from './EventUpdateForm';
import PageLayout from '../../../layout/PageLayout';

type ParamTypes = {
  slug: string;
};

export const EventUpdate: React.FC = () => {
  const { slug } = useParams<ParamTypes>();
  const { user } = useAppContext();

  const { data: event, isLoading } = useEventBySlug(slug || '');

  return (
    <PageLayout isLoading={isLoading}>
      <PageTitleUpdater additionalTitle={`Events`} />
      {user && event && isAccessGranted(user) && (user?.id === event?.userId.id || isStaff(user)) ? (
        <EventUpdateForm event={event} user={user} />
      ) : (
        <Box textAlign="center" mt={4}>
          <Text>Vous n'avez pas les droits pour modifier cet evenement !</Text>
        </Box>
      )}
    </PageLayout>
  );
};
