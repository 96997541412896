import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { HOME_PATH } from '../../../../routing/constants';
import FormLayout from '../../../../layout/noAuth/wrappers/FormLayout';
import ResetPasswordForm from './ResetPasswordForm';

const ResetPassword: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  const navigate = useNavigate();
  if (!token) {
    navigate(HOME_PATH);
  }
  return <FormLayout media={`/assets/connexion-cover.png`}>{token && <ResetPasswordForm token={token} />}</FormLayout>;
};

export default ResetPassword;
