import React from 'react';
import { Box, Flex, useBreakpointValue, useMediaQuery } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { textSets } from './constants';

const MotionBox = motion(Box);
interface TextProps {
  currentIndex: number;
}

const Title: React.FC<TextProps> = ({ currentIndex }) => {
  const [isLessThan1160] = useMediaQuery('(max-width: 1160px)');
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <AnimatePresence>
      <Flex direction="row" style={{ flexWrap: 'wrap' }}>
        {textSets[currentIndex].map((word, wordIndex) => (
          <MotionBox
            key={`${word}-${currentIndex}`} // Clé unique combinant mot et indice de cycle
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -30 }}
            transition={{ delay: 0.1 * wordIndex, duration: 0.3, exit: { delay: 0, duration: 0.1 } }}
            style={{
              marginRight: '15px',
              marginBottom: '15px',
              fontSize: isLessThan1160 ? '40px' : '55px',
              lineHeight: isMobile ? '40px' : '66px',
              letterSpacing: '0.1em',
              fontFamily: 'Barlow Condensed',
              fontWeight: '700',
              color: 'white',
              textTransform: 'uppercase',
              display: 'inline',
            }}
          >
            {word}
          </MotionBox>
        ))}
      </Flex>
    </AnimatePresence>
  );
};

export default React.memo(Title);
