import { useQuery, UseQueryResult } from 'react-query';
import api from '../../utils/api/api';
import { Message } from '../../types/Message';

export const fetchMessagesBetweenUsers = async (userId?: string): Promise<Message[]> => {
  const response = await api.get(`/messages/between-users?userId=${userId}`);
  return response.data;
};

export const useGetMessagesBetweenUsers = (userId1?: string): UseQueryResult<Message[], unknown> => {
  return useQuery(['messagesBetween', userId1], () => fetchMessagesBetweenUsers(userId1), {
    enabled: userId1 !== null && userId1 !== undefined,
    retry: 0,
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  });
};
