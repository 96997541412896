import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Image } from '@chakra-ui/react';
import { ContestUpdateFormType } from './constants';
import { toast } from 'react-toastify';
import { contestSchema } from './yup/contest';
import FormImageUpload from '../../../../components/forms/formImageUpload/FormImageUpload';
import FormInput from '../../../../components/forms/FormInput';
import FormDatePicker from '../../../../components/forms/FormDatePicker';
import { todayAddOneHour } from '../../../events/create/constants';
import { useNavigate } from 'react-router-dom';
import { formatUri } from '../../../../utils/api/helpers';
import { CONTESTS_DETAILS_PATH } from '../../../../routing/constants';
import { Contest } from '../../../../types/Contests';
import { useUpdateContest } from '../../../../hooks/contests/useUpdateContest';
import { convertToBase64, isUrl } from '../../../../utils/helpers';
import { S3_URL } from '../../../../utils/constants';
import PageTitleUpdater from '../../../../routing/PageTitleUpdater';
import FormTextareaSimple from '../../../../components/forms/FormTextareaSimple';
import InSavedChangesPrompt from '../../../../components/InSavedChangesPrompt';

interface ContestUpdateFormProps {
  contest: Contest;
}

export const ContestUpdateForm: React.FC<ContestUpdateFormProps> = ({ contest }) => {
  const [currentAvatar, setCurrentAvatar] = React.useState<string | undefined>();
  const mutation = useUpdateContest();
  const navigate = useNavigate();
  const [contestUpdated, setContestUpdated] = React.useState<Contest | undefined>();

  const {
    handleSubmit,
    register,
    trigger,
    setValue,
    watch,
    getValues,
    formState: { errors, isValid, isDirty },
  } = useForm<ContestUpdateFormType>({
    resolver: yupResolver(contestSchema),
    defaultValues: {
      name: contest.name,
      description: contest.description,
      endDate: contest.endDate,
      judgmentCriterion: contest.judgmentCriterion,
      participationModalities: contest.participationModalities,
      toWin: contest.toWin,
    },
  });

  const onSubmit = async (data: ContestUpdateFormType) => {
    try {
      const contestResult = await mutation.mutateAsync({ id: contest.id, data });
      setContestUpdated(contestResult);
      toast.success('Concours modifié avec succès', {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (error: any) {
      toast.error(`Échec de la modification du concours : ${error.response.data.message}`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  useEffect(() => {
    if (contestUpdated) {
      navigate(formatUri({ slug: contestUpdated.slug }, CONTESTS_DETAILS_PATH));
    }
  }, [contestUpdated]);

  const watchedMedia = watch('media');
  useEffect(() => {
    if (watchedMedia && watchedMedia instanceof File) {
      trigger('media');
      convertToBase64(watchedMedia).then(base64 => {
        setCurrentAvatar(base64 as string);
      });
    } else if (watchedMedia && isUrl(watchedMedia)) {
      setCurrentAvatar(watchedMedia);
    }
  }, [watchedMedia]);

  useEffect(() => {
    setValue('media', isUrl(contest.media) ? contest.media : `${S3_URL}/contests/${contest.media}`);
  }, [setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PageTitleUpdater additionalTitle="Admin Panel - Update concours" />
      {currentAvatar && <Image src={currentAvatar} />}
      <Box mt={4}>
        <FormImageUpload variant="whiteBorderNoBg" placeholder="Image" name="media" register={register} setValue={setValue} getValues={getValues} errors={errors} />
      </Box>
      <Box mt={4}>
        <FormInput variant="whiteBorderNoBg" placeholder="Nom" type="text" name="name" required register={register} errors={errors} />
      </Box>
      <Box mt={4}>
        <FormTextareaSimple
          variant="whiteBorderNoBg"
          label={'Description courte'}
          placeholder="Description courte"
          name="description"
          maxLength={300}
          required={true}
          errors={errors}
          register={register}
          getValues={getValues}
          setValue={setValue}
        />
      </Box>
      <Box mt={4}>
        <FormTextareaSimple
          variant="whiteBorderNoBg"
          label={'Critère de jugement'}
          placeholder="Critère de jugement"
          name="judgmentCriterion"
          maxLength={300}
          required={true}
          errors={errors}
          register={register}
          getValues={getValues}
          setValue={setValue}
        />
      </Box>
      <Box mt={4}>
        <FormTextareaSimple
          variant="whiteBorderNoBg"
          label={'Modalité de participation'}
          placeholder="Modalité de participation"
          name="participationModalities"
          maxLength={300}
          required={true}
          errors={errors}
          register={register}
          getValues={getValues}
          setValue={setValue}
        />
      </Box>
      <Box mt={4}>
        <FormTextareaSimple
          variant="whiteBorderNoBg"
          label={'Lot à gagner'}
          placeholder="Lot à gagner"
          name="toWin"
          maxLength={300}
          required={true}
          errors={errors}
          register={register}
          getValues={getValues}
          setValue={setValue}
        />
      </Box>
      <Box mt={4}>
        <FormDatePicker
          variant="whiteBorderNoBg"
          placeholder="Date de fin"
          name="endDate"
          minDate={todayAddOneHour}
          showTime={true}
          required={true}
          errors={errors}
          register={register}
          getValues={getValues}
          setValue={setValue}
          defaultDate={contest.endDate}
        />
      </Box>
      <Box my={4} textAlign="right">
        <Button variant="navYellow" type="submit" isLoading={mutation.isLoading} isDisabled={!isValid}>
          Enregistrer
        </Button>
      </Box>
      {!contestUpdated && <InSavedChangesPrompt isDirty={isDirty} />}
    </form>
  );
};
