import React from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';
import { Contest as ContestType } from '../../../../types/Contests';
import { S3_URL } from '../../../../utils/constants';
import { formatUri } from '../../../../utils/api/helpers';
import { CONTESTS_DETAILS_PATH } from '../../../../routing/constants';
import { useNavigate } from 'react-router-dom';
import CardImageWithBox from '../../../../components/card/CardImageWithBox';
import { formatDateRangeFormatted } from '../../../../utils/dates';
import { getContestContestColor, getContestTypeString, renderLinesDate } from '../../../../utils/helpers';

interface EventCarouselProps {
  contest: ContestType;
}

const Contest: React.FC<EventCarouselProps> = ({ contest }) => {
  const navigate = useNavigate();
  const truncatedTitle = contest.name.length > 30 ? contest.name.slice(0, 30) + '...' : contest.name;

  const getContentInCard = (contest: ContestType) => {
    const currentDate = new Date();
    const endDateTime = new Date(contest?.endDate || '');
    const isEnded = currentDate > endDateTime;
    if (!isEnded) {
      return <>{renderLinesDate(formatDateRangeFormatted(contest.createdAt, contest.endDate, true))}</>;
    }

    if (isEnded && (!contest.winner || contest.winner?.length === 0) && contest.winnerDiscord?.length === 0) {
      return (
        <Text as="h1" color="blueTardis" fontSize="16px">
          DELIBERATION
        </Text>
      );
    }

    return (
      <Text as="h1" color="greenToxic" fontSize="16px">
        TERMINER
      </Text>
    );
  };

  return (
    <>
      <Box overflow="hidden" position="relative" onClick={() => navigate(formatUri({ slug: contest.slug }, CONTESTS_DETAILS_PATH))} cursor="pointer">
        <Text as="h1" mb={2}>
          Concours
        </Text>
        <CardImageWithBox
          imageName={contest.name}
          image={`${S3_URL}/contests/${contest.media}`}
          contentInCard={getContentInCard(contest)}
          contentRight={
            <Text mr={-2} mt={1} color={getContestContestColor(contest.type)}>
              {getContestTypeString(contest.type)}
            </Text>
          }
          contentFooter={
            <>
              <Flex justifyContent="space-between" flexDirection={'row'} alignItems="center" h="100%" my={2}>
                <>
                  <Text as="h2" fontSize={'13px'}>
                    {truncatedTitle}
                  </Text>
                </>
              </Flex>
            </>
          }
        />
      </Box>
    </>
  );
};

export default Contest;
