/* eslint-disable max-len */
export const textSets = [
  ['EMBARQUE', 'DANS', 'LE', 'SANCTUAIRE', 'ULTIME', 'DES', 'GEEKS'],
  ['Domine', 'LES', 'CHAMPS', 'DE', 'BATAILLE'],
  ['L’Essence', 'de', 'l’Action', 'En Direct'],
  ['L’aventure', 'juste', 'entre-geeks'],
  ['Connecte-toi,', 'Rencontre,', 'Anime'],
];

export const subTextSets = [
  ['Le premier site communautaire Entre Geeks. Gratuit et sans pub !'],
  [
    'Que tu sois un casual ou un hardcore gamer, Entre-Geeks te donne les outils pour te plonger dans notre communauté et construire ton crew. Rejoins des sessions de jeu, améliore ton niveau, et vis des aventures épiques. Écrase la compétition avec nos outils de guerre, plonge dans notre Discord, assemble ta team et domine les champs de bataille !',
  ],
  [
    "Rejoins notre crew pour partager tes aventures ou tout simplement suivre les lives de nos streamers sur EntreGeeks ou Twitch. Si tu es perspicace, Entre-Geeks t'offre une solide base de followers pour débuter dans le streaming. Nos outils et le Discords EG te permettent d'être informé des prochaines sessions de streaming grâce l’agenda, visible par la communauté.",
  ],
  [
    "Prêt à plonger dans une aventure geek ? Explore les JDR papier en IRL ou en streaming, partage des soirées de jeu dans des bars spécialisés, et crée un cosplay éblouissant pour briller lors des conventions geek. EntreGeeks t'offre la plateforme idéale pour te connecter avec d'autres passionnés, avec des outils dédiés à la création d'événements.",
  ],
  [
    'Entre-Geeks place le social au cœur de son univers. Le profil devient la porte vers des rencontres uniques. Parcours les profils des autres membres, échange des messages privés, et crée des liens. Rejoins notre serveur Discord pour des discussions animées et plonge dans une communauté vibrante.',
  ],
];

export const actionButtonSets = ['Rejoins-nous', 'Rejoins la Partie', 'Rejoins le show', 'Connecte-toi et participe', 'Rejoins la communauté'];

export const actionButtonTextSets = ['GEEK AND CHILL', 'PLAY GEEK SMELL GEEK DO GEEK', 'PUMP UP THE GEEK', 'KEEP CALM AND GEEK ON', 'HAUT BAS GAUCHE DROITE START GEEK'];

export const imageSets = [['01.mp4', '02.mp4', '03.mp4', '04.mp4'], ['05.mp4'], ['06.mp4'], ['07.mp4'], ['08.mp4']];

export const shaders = ['./assets/homeNoAuth/header/gaming.svg', './assets/homeNoAuth/header/stream.svg', './assets/homeNoAuth/header/sortie.svg', './assets/homeNoAuth/header/communaute.svg'];

export const MenuItems = [
  { id: 1, label: 'Gaming' },
  { id: 2, label: 'Streaming' },
  { id: 3, label: 'Sorties' },
  { id: 4, label: 'Communauté' },
];
