import React, { useEffect, useRef, useState } from 'react';
import 'cropperjs/dist/cropper.css';
import { FormControl, FormLabel, FormErrorMessage, Input, useDisclosure, Text, Flex, Image } from '@chakra-ui/react';
import { UseFormRegister, FieldValues, DeepMap, FieldError } from 'react-hook-form';
import ImageCropModal from './ImageCropModal';
import { base64ToFile } from '../../../utils/helpers';

export interface FormImageUploadProps {
  label?: string;
  placeholder: string;
  name: string;
  required?: boolean;
  register: UseFormRegister<any>;
  errors?: DeepMap<FieldValues, FieldError>;
  setValue: any;
  getValues: any;
  cropWidth?: number;
  cropHeight?: number;
  variant?: any;
  withoutIcon?: boolean;
}

const FormImageUpload: React.FC<FormImageUploadProps> = ({
  withoutIcon = false,
  placeholder,
  variant = 'violet',
  getValues,
  cropWidth = 1100,
  cropHeight = 350,
  setValue,
  label,
  name,
  required,
  errors,
}) => {
  const [image, setImage] = useState<string | null>(null);
  const cropperRef = useRef<any>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleIconClick = () => {
    onOpen();
  };

  useEffect(() => {
    if (cropperRef.current) {
      const cropperInstance = cropperRef.current.cropper;
      cropperInstance.setAspectRatio(cropWidth / cropHeight);
    }
  }, [image, cropWidth, cropHeight]);

  useEffect(() => {
    if (getValues && getValues(name) && setValue) {
      setValue(name, getValues(name), { shouldValidate: true });
    }
  }, []);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (image) {
      onOpen();
    }
  }, [image]);

  const onCrop = (croppedImage: string) => {
    const file = base64ToFile(croppedImage, 'croppedImage.png');
    setValue(name, file, { shouldValidate: true });
    setImage(null);
    onClose();
  };

  return (
    <FormControl isRequired={required} isInvalid={errors && !!errors[name]}>
      {label && <FormLabel>{label}</FormLabel>}
      <Input variant={variant} id={name} type="file" accept="image/*" onChange={handleImageChange} style={{ display: 'none' }} />
      <label htmlFor={name} style={{ cursor: 'pointer', display: 'inline-block' }}>
        <Flex alignItems="center" justifyContent="flex-start">
          {!withoutIcon && <Image src="/assets/icons/photo.svg" w="35px" h="35px" onClick={handleIconClick} />}
          <Text ml={2} color="brand.white">
            {placeholder}
          </Text>
        </Flex>
      </label>

      <ImageCropModal isOpen={isOpen} onClose={onClose} image={image} onCrop={onCrop} cropWidth={cropWidth} cropHeight={cropHeight} cropperRef={cropperRef} />
      <FormErrorMessage>{errors && errors[name] && errors[name].message}</FormErrorMessage>
    </FormControl>
  );
};

export default FormImageUpload;
