export const formatUri = <UriParams extends object>(paramsUri: UriParams, uri: string): string => {
  let formattedUri = uri;
  if (!Object.keys(paramsUri).length) {
    throw new Error('Erreur dans les paramètres');
  }

  for (const [name, value] of Object.entries(paramsUri)) {
    const regex = new RegExp(`:${name}`, 'g');
    formattedUri = formattedUri.replace(regex, value);
  }

  return formattedUri;
};

export const truncateURLs = (text: string): string => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, url => {
    if (url.length > 30) {
      return url.slice(0, 27) + '...';
    }
    return url;
  });
};
