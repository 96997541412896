import { useQuery, UseQueryResult } from 'react-query';
import api from '../../utils/api/api';
import { API_USERS_LOCATIONS_URI } from '../../utils/api/constants';

interface Locations {
  countries: string[];
  regions: string[];
  departments: string[];
}

export const fetchLocations = async (): Promise<Locations> => {
  const response = await api.get(API_USERS_LOCATIONS_URI);
  return response.data;
};

export const useFetchLocations = (): UseQueryResult<Locations, unknown> => {
  return useQuery('locations', fetchLocations, {
    retry: 0,
  });
};
