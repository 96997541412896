import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import api from '../../utils/api/api';
import { API_CONTEST_SEND_MEDIA } from '../../utils/api/constants';

interface UploadFormData {
  file: File;
  contestId: string;
}

export const useUploadContestImage = (): UseMutationResult<void, Error, UploadFormData, unknown> => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, UploadFormData>(
    ({ file, contestId }) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('contestId', contestId);
      formData.append('title', '-');

      return api
        .post(API_CONTEST_SEND_MEDIA, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res => res.data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('contests');
        queryClient.invalidateQueries('contest');
        queryClient.invalidateQueries('activeContest');
      },
    },
  );
};
