import { useQuery, UseQueryResult } from 'react-query';
import api from '../../utils/api/api';
import { API_DISCORD_STATS } from '../../utils/api/constants';
import { LastThread } from '../../types/Discord';

interface DiscordStatsResponse {
  total: number;
  online: number;
}

const fetchDiscordStats = async (): Promise<DiscordStatsResponse> => {
  return api.get(API_DISCORD_STATS).then(res => res.data);
};

export const useDiscordStats = (): UseQueryResult<DiscordStatsResponse, Error> => {
  return useQuery('discordStats', fetchDiscordStats, {
    refetchInterval: 300000,
  });
};
