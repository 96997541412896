import { useQuery, UseQueryResult } from 'react-query';
import { API_USERS_ONLINE } from '../../utils/api/constants';
import { UserPublic } from '../../types/user';
import api from '../../utils/api/api';

const fetchLastConnectedMembers = async (): Promise<UserPublic[]> => {
  return api.get(API_USERS_ONLINE).then(res => res.data);
};

export const useLastConnectedMessageMembers = (): UseQueryResult<UserPublic[], Error> => {
  return useQuery('lastConnectedMembersMessage', fetchLastConnectedMembers, {
    refetchInterval: 900000, // 15 minutes
  });
};
