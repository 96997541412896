import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import { API_DISCORD_MESSAGES } from '../../../utils/api/constants';
import api from '../../../utils/api/api';
export const useMessages = (channelId?: string): UseInfiniteQueryResult<any, unknown> => {
  const isEnabled = Boolean(channelId);

  return useInfiniteQuery<any, unknown>(
    ['messages', channelId],
    async ({ pageParam = null }) => {
      if (!channelId) {
        throw new Error('Channel ID is undefined');
      }

      const { data } = await api.get(`${API_DISCORD_MESSAGES}/${channelId}`, {
        params: { before: pageParam },
      });

      return data;
    },
    {
      getNextPageParam: lastPage => {
        const lastMessage = lastPage?.[lastPage.length - 1];
        return lastMessage?.id || null;
      },
      staleTime: 0,
      retry: false,
      cacheTime: 0,
      enabled: isEnabled,
    },
  );
};
