import { Contest } from '../../../types/Contests';
import React, { Dispatch, SetStateAction } from 'react';
import { Box, Button, Flex, Image, Link, Text, useBreakpointValue } from '@chakra-ui/react';
import { useJoinOrLeaveContest } from '../../../hooks/contests/useJoinOrLeaveContest';
import { useAppContext } from '../../../context/AppContext';
import { S3_URL } from '../../../utils/constants';
import { getRandomNumber } from '../../../utils/helpers';
import ImageLoaderWithGlitch from '../../../components/ImageLoaderWithGlitch';

interface ContestLoterieButtonProps {
  contest: Contest;
  setParticipationClick: Dispatch<SetStateAction<boolean>>;
}
const ContestParticipationButton: React.FC<ContestLoterieButtonProps> = ({ contest, setParticipationClick }) => {
  const { user } = useAppContext();
  const { joinContest, leaveContest } = useJoinOrLeaveContest();
  const isParticipant = contest.participants.some(participant => participant.id === user?.id);
  const currentDate = new Date();
  const endDateTime = new Date(contest.endDate);
  const isEnded = currentDate > endDateTime;
  const isMobile = useBreakpointValue({ base: true, md: false });

  const getImageParticipation = () => {
    const mediaSend = contest.mediaSend.find(media => media.userId === user?.id);
    return `${S3_URL}/contests/${contest.slug}/${mediaSend?.media}`;
  };

  const handleJoinOrLeave = () => {
    if (!isEnded) {
      if (isParticipant) {
        leaveContest.mutate(contest.id);
      } else {
        joinContest.mutate(contest.id);
      }
    }
  };

  if (contest.type === '1') {
    return (
      <Flex flexWrap="wrap" alignContent="space-around" justifyContent="center" h={isMobile ? 'auto' : '100%'} direction="column">
        {isEnded ? (
          <>En attente du tirage au sort</>
        ) : (
          <>
            {' '}
            {isParticipant ? (
              <Button variant="whiteButton" onClick={handleJoinOrLeave}>
                {isParticipant ? 'Annuler ma participation' : 'Participer'}
              </Button>
            ) : (
              <Box onClick={handleJoinOrLeave} maxH={'400px'} cursor="pointer">
                <ImageLoaderWithGlitch src={`/assets/buttons/lotterie-${getRandomNumber(1, 4)}.png`} imageName="Participer" styles={{ maxH: '400px' }} />
              </Box>
            )}
          </>
        )}
      </Flex>
    );
  }

  return (
    <>
      {!isParticipant ? (
        <Flex flexWrap="wrap" alignContent="space-around" justifyContent="center" h={isMobile ? 'auto' : '100%'} direction="column">
          {isEnded ? (
            <>En attente de délibération</>
          ) : (
            <Box onClick={() => setParticipationClick(true)} maxH={'400px'} cursor="pointer">
              {contest.type === '2' && <ImageLoaderWithGlitch src={`/assets/buttons/concours-txt-${getRandomNumber(1, 3)}.png`} imageName="Participer" styles={{ maxH: '400px' }} />}
              {contest.type === '3' && <ImageLoaderWithGlitch src={`/assets/buttons/concours-img-${getRandomNumber(1, 3)}.png`} imageName="Participer" styles={{ maxH: '400px' }} />}
            </Box>
          )}
        </Flex>
      ) : (
        <Flex justifyContent="center" h={isMobile ? 'auto' : 'calc(100% - 100px)'} direction="column">
          <Text as="h1" fontSize="16px" mb={2} color="yellowBob">
            Félicitations, héros !
          </Text>
          <Text>Ton chef-d'œuvre est maintenant dans la course pour le concours</Text>
          <Text fontWeight="bold"> {contest.name}</Text>
          <Text mt={2}>
            Reste connecté pour les résultats, c'est là que la magie opère ! Ton {contest.type === '2' ? 'texte' : 'image'} a bien été partagé sur Discord mais ne t'inquiète pas, ça reste en anonyme,
            laisse planer le mystère jusqu'à la fin !
          </Text>
          <Flex justifyContent="center" alignItems="center" verticalAlign="center" w={'100%'} mt={6}>
            {contest.type === '2' ? (
              <Flex alignItems="center" justifyContent="center" textAlign="center" boxSize="sm" h={'100px'} w={'100px'} border="1px dashed white">
                <Link href={getImageParticipation()} isExternal>
                  Voir le PDF
                </Link>
              </Flex>
            ) : (
              <Box boxSize="sm" maxH={'150px'} w={'100%'} textAlign="center">
                <Link href={getImageParticipation()} isExternal>
                  <Image src={getImageParticipation()} border="1px dashed white" maxH={'150px'} w="auto" alt="Ton image" />
                </Link>
              </Box>
            )}
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default ContestParticipationButton;
