import api from '../../utils/api/api';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { API_USERS_URI } from '../../utils/api/constants';
import { User } from '../../types/user';
import { UserCreateForm } from '../../pages/inscription/constants';

export const useCreateUser = (): UseMutationResult<{ user: User; access_token: string }, Error, UserCreateForm, unknown> => {
  const queryClient = useQueryClient();

  return useMutation<{ user: User; access_token: string }, Error, UserCreateForm>(
    (newUser: UserCreateForm) =>
      api
        .post(API_USERS_URI, newUser, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users');
      },
    },
  );
};
