import React, { useEffect, useState } from 'react';
import { Box, Flex, Image as ChakraImage, Text, useBreakpointValue, VStack } from '@chakra-ui/react';

import { getContestContestColor, getContestTypeString, removeMarkdown } from '../../utils/helpers';
import { APP_URL, PUPPETEER_URL, S3_URL } from '../../utils/constants';
import Ariane from '../../components/Ariane';
import { formatDateRange } from '../../utils/dates';
import { customColors } from '../../theme';
import { Contest } from '../../types/Contests';
import CountdownTimer from '../../components/CountdownTimer';
import { useNavigate } from 'react-router-dom';
import { formatUri } from '../../utils/api/helpers';
import { CONTESTS_DETAILS_PATH } from '../../routing/constants';
import ShareModalButton from '../../components/ShareModalButton';

interface HeaderContestProps {
  contest: Contest;
  isList?: boolean;
}

const HeaderContest: React.FC<HeaderContestProps> = ({ contest, isList = false }) => {
  const navigate = useNavigate();
  const [isBlurred, setIsBlurred] = useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const currentDate = new Date();
  const endDateTime = new Date(contest.endDate);
  const isEnded = currentDate > endDateTime;

  useEffect(() => {
    setIsBlurred(true);
    setTimeout(() => {
      setIsBlurred(false);
    }, 50);
  }, [contest]);

  return (
    <>
      <Box h="330px">
        <Box position="absolute" width="100%" height="338px" ml={-7} mt={-5}>
          {/* Image comme fond d'une Box */}
          <Box as="div" bg={`url(${`${S3_URL}/contests/${contest.media}`}) center/cover no-repeat`} position="relative" width="100%" height="100%">
            {/* Gradient */}
            <Box position="absolute" top="0" left="0" w="100%" h="100%" bgGradient={`linear(92deg, ${customColors.blackTegrite} 20%, rgba(43, 44, 44, 0) 100%)`} />

            {/* Contenu */}
            <Box position="relative" bottom="4" left="4" py="4" height={isMobile ? 'auto' : 'calc(100% - 8px)'} mr={isMobile ? 0 : 10}>
              <Ariane>
                <Flex justifyContent="space-between" alignItems="center" pt={4}>
                  <Flex alignItems="center">
                    <Flex alignItems="center" ml={isMobile ? 0 : 4}>
                      <ChakraImage src="/assets/icons/yellow.svg" alt="Concours" width="30px" />
                      <Text as="h1" textTransform="uppercase" fontSize="19px" ml="4">
                        Concours
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Ariane>
              <Box ml={isMobile ? 0 : 6} mt="-1" className={isBlurred ? 'blur' : 'no-blur-animation'}>
                <Text color={getContestContestColor(contest.type)}>{getContestTypeString(contest.type)}</Text>
                <Flex alignItems={'center'} mt="-2">
                  <Text as="h1" fontSize={isMobile ? '21px' : '34px'} letterSpacing={isMobile ? 'auto' : '1.70px'} textTransform="uppercase" fontWeight="bold" color="white">
                    {contest.name}
                  </Text>
                </Flex>
              </Box>
              <VStack
                zIndex={2}
                position="relative"
                justifyContent="space-between"
                alignItems="flex-start"
                ml="-1"
                className={isBlurred ? 'blur' : 'no-blur-animation'}
                w={isMobile ? 'auto' : '500px'}
              >
                <VStack alignItems="start" spacing="2">
                  <Box ml={isMobile ? 0 : 8}>
                    <Text color="white" fontSize="13px" pr={isMobile ? '26px' : 0}>
                      {contest.description}
                    </Text>
                    {isList && (
                      <Text mt={4} textDecoration="underline" cursor="pointer" onClick={() => navigate(formatUri({ slug: contest.slug }, CONTESTS_DETAILS_PATH))}>
                        Voir le details du concours
                      </Text>
                    )}
                    {isEnded && (!contest.winner || contest.winner?.length === 0) && contest.winnerDiscord?.length === 0 && (
                      <Text as="h1" color="blueTardis" fontSize={isMobile ? '30px' : '64px'}>
                        DELIBERATION
                      </Text>
                    )}
                    {isEnded && ((contest.winner && contest.winner?.length !== 0) || contest.winnerDiscord?.length !== 0) && (
                      <Text as="h1" color="greenToxic" fontSize={isMobile ? '30px' : '64px'}>
                        TERMINER
                      </Text>
                    )}
                    {!isEnded && <CountdownTimer startDate={contest.createdAt} endDate={contest.endDate} />}
                  </Box>
                </VStack>
              </VStack>
            </Box>
          </Box>
        </Box>
      </Box>
      {!isMobile ? (
        <Box position="absolute" ml={-7} w="100%" top={!isMobile ? '238px' : '228px'} zIndex={1}>
          <Box position="relative" bottom="0" left="0" w="100%" h={'110px'} bgGradient="linear(180deg, rgba(43, 44, 44, 0) 0%, rgba(43, 44, 44, 1) 73%)">
            <Box pt={'45px'} px={8}>
              <Flex justifyContent="space-between" alignItems="center" width="100%">
                <Box w={'100%'} className={isBlurred ? 'blur' : 'no-blur-animation'}>
                  <Flex justifyContent="right" alignItems="center" width="100%" gap={10}>
                    <Flex alignItems="center" mb={1}>
                      <ChakraImage src="/assets/icons/calendar.svg" alt="Calendrier" width="27px" ml="-2px" />
                      <Text ml={2}>{formatDateRange(contest.createdAt, contest.endDate)}</Text>
                    </Flex>
                    <Flex alignItems="center" mb={1}>
                      <ChakraImage src="/assets/icons/usersThree.svg" alt="Participants" width="27px" ml="-2px" />
                      <Text ml={2}>+{contest.participants.length + contest.discordParticipants.length}</Text>
                    </Flex>
                    <Flex alignItems="center" mb={1} ml={2}>
                      <ShareModalButton
                        type={'icon'}
                        shareFbUrl={`${PUPPETEER_URL}/public/concours/${contest.slug}`}
                        shareTwitterUrl={`${APP_URL}/concours/${contest.slug}`}
                        title={`[Concours] ${contest.name}`}
                        description={removeMarkdown(contest.description)}
                      />
                    </Flex>
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box position="absolute" ml={-7} w="100%" top={isMobile ? '238px' : '228px'} zIndex={1}>
          <Box position="relative" bottom="0" left="0" w="100%" h={'110px'} bgGradient="linear(180deg, rgba(43, 44, 44, 0) 0%, rgba(43, 44, 44, 1) 73%)"></Box>
        </Box>
      )}
    </>
  );
};

export default HeaderContest;
