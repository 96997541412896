import React, { ReactNode } from 'react';
import CookiesPopup from '../../pages/misc/CookiesPopup';

const LayoutHomeNoAuth: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <>
      {children}
      <CookiesPopup />
    </>
  );
};

export default LayoutHomeNoAuth;
