import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { Contest } from '../../types/Contests';
import api from '../../utils/api/api';
import { API_CONTEST_CREATE_ADMIN } from '../../utils/api/constants';
import { ContestCreateForm } from '../../pages/admin/contests/create/constants';

export const useCreateContest = (): UseMutationResult<Contest, Error, ContestCreateForm, unknown> => {
  const queryClient = useQueryClient();

  return useMutation<Contest, Error, ContestCreateForm>(
    (newContest: ContestCreateForm) =>
      api
        .post(API_CONTEST_CREATE_ADMIN, newContest, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res => res.data),
    {
      onSuccess: () => {
        queryClient.refetchQueries('latestContest');
        queryClient.refetchQueries('contests');
        queryClient.refetchQueries('activeContest');
      },
    },
  );
};
