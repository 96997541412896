import React, { useEffect, useState } from 'react';
import { useGetUserConversations } from '../../../hooks/message/useGetUserConversations';
import { Badge, Box, Button, Divider, Flex, Grid, GridItem, Image, Text, useMediaQuery } from '@chakra-ui/react';
import UserAvatar from '../../../components/UserAvatar';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { QueryMessagesParams } from '../constants';
import PageLayout from '../../../layout/PageLayout';
import { useAppContext } from '../../../context/AppContext';
import { formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';
import { customColors } from '../../../theme';
import MessageComponent from '../message/MessageComponent';
import Ariane from '../../../components/Ariane';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { HOME_BOARD } from '../../../routing/constants';
import PageTitleUpdater from '../../../routing/PageTitleUpdater';
import MessageEmpty from './MessageEmpty';
import { FaArrowsLeftRightToLine } from 'react-icons/fa6';

const MessageList: React.FC = () => {
  const { user } = useAppContext();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const skip = searchParams.get('skip') ? parseInt(searchParams.get('skip')!) : 0;
  const username = searchParams.get('username') ? searchParams.get('username') : undefined;
  const [isLessThan1088] = useMediaQuery('(max-width: 1088px)');

  const [isBoxOpen, setIsBoxOpen] = useState(true);

  useEffect(() => {
    if (isLessThan1088) {
      setIsBoxOpen(false);
    } else {
      setIsBoxOpen(true);
    }
  }, [isLessThan1088]);

  const [queryParams, setQueryParams] = useState<QueryMessagesParams>({
    skip,
    limit: 6,
    username,
  });
  const { data: data, isLoading, isError, error } = useGetUserConversations(queryParams);
  const hasPrevious = queryParams.skip > 0;
  const hasNext = queryParams.skip + queryParams.limit < (data?.total || 0);

  useEffect(() => {
    if (!username && data && data.total > 0) {
      const newSearchParams = new URLSearchParams();
      if (queryParams.skip !== 0) newSearchParams.set('skip', queryParams.skip.toString());
      newSearchParams.set('username', data.conversations[0].otherUser.username);
      setSearchParams(newSearchParams);

      if (isLessThan1088) {
        setIsBoxOpen(false);
      }
    }
  }, [data]);

  useEffect(() => {
    const newQueryParams = {
      skip: searchParams.get('skip') ? parseInt(searchParams.get('skip') as string) : 0,
      username: searchParams.get('username') ? searchParams.get('username') : '',
      limit: 6,
    };

    setQueryParams(newQueryParams);
  }, [searchParams]);

  const updateSkip = (skip: number) => {
    const newSearchParams = new URLSearchParams();
    newSearchParams.set('skip', skip.toString());
    if (queryParams.username !== undefined && queryParams.username !== null) newSearchParams.set('username', queryParams.username);
    setSearchParams(newSearchParams);
  };

  useEffect(() => {
    if (queryParams.username && queryParams.username.toLowerCase() === user?.username.toLowerCase()) {
      navigate(HOME_BOARD);
    }
  }, [queryParams]);

  const handleNewUsername = (newUsername: string) => {
    const newSearchParams = new URLSearchParams();
    if (queryParams.skip !== 0) newSearchParams.set('skip', queryParams.skip.toString());
    newSearchParams.set('username', newUsername);
    setSearchParams(newSearchParams);

    if (isLessThan1088) {
      setIsBoxOpen(false);
    }
  };

  useEffect(() => {
    if (isLessThan1088) {
      setIsBoxOpen(queryParams.username === undefined);
    }
  }, [isLessThan1088]);

  const gridTemplateColumns = isLessThan1088 ? '1fr' : !isBoxOpen ? '1fr' : ' 1fr 2fr';
  return (
    <PageLayout isLoading={isLoading} isError={isError} error={error}>
      <PageTitleUpdater additionalTitle={`Message${username ? ` - ${username}` : ''}`} />
      <Ariane>
        <Flex alignItems="center">
          <Image src="/assets/icons/green-2.svg" alt="Communauté" width="30px" />
          <Text as="h1" textTransform="uppercase" fontSize="19px" ml={4}>
            Vos discussions
          </Text>
        </Flex>
      </Ariane>
      {((data && data.conversations.length > 0) || (data && queryParams.username)) && (
        <Grid templateColumns={gridTemplateColumns} mt={isLessThan1088 ? '0' : '63px'} h={isLessThan1088 ? 'auto' : 'calc(100% - 220px)'}>
          {!isLessThan1088 && !isBoxOpen && (
            <Box mb={2} mt="-36px">
              <Button variant="navYellow" onClick={() => setIsBoxOpen(!isBoxOpen)} mr={2}>
                <FaArrowsLeftRightToLine size={20} />
              </Button>
            </Box>
          )}
          <Box h="100%">
            {isLessThan1088 && !isBoxOpen && (
              <Flex alignItems="center" justifyContent="right" flexDirection={isLessThan1088 ? 'column' : 'row'}>
                <Button cursor="pointer" variant="navYellow" onClick={() => setIsBoxOpen(!isBoxOpen)}>
                  Voir les discussions
                  <Box ml={2}>
                    <IoIosArrowDown />
                  </Box>
                </Button>
              </Flex>
            )}
            {isBoxOpen && (
              <>
                <Box>
                  <Grid mb={4}>
                    <GridItem colSpan={2}>
                      <Flex alignItems="center" justifyContent="right" flexDirection={isLessThan1088 ? 'column' : 'row'}>
                        {isLessThan1088 && isBoxOpen && (
                          <GridItem mb={2}>
                            <Flex alignItems="center" justifyContent="right">
                              <Button cursor="pointer" variant="navYellow" onClick={() => setIsBoxOpen(!isBoxOpen)}>
                                Masquer les discussions
                                <Box ml={2}>
                                  <IoIosArrowUp />
                                </Box>
                              </Button>
                            </Flex>
                          </GridItem>
                        )}
                        <GridItem>
                          <Flex alignItems="center" justifyContent="right" mr={2}>
                            <Button isDisabled={!hasPrevious} variant="navYellow" onClick={() => updateSkip(queryParams.skip - queryParams.limit)} mr={2}>
                              Précédente
                            </Button>
                            <Button isDisabled={!hasNext} variant="navYellow" onClick={() => updateSkip(queryParams.skip + queryParams.limit)} mr={2}>
                              Suivante
                            </Button>
                            {username && (
                              <Button variant="navYellow" onClick={() => setIsBoxOpen(!isBoxOpen)} mr={2}>
                                <FaArrowsLeftRightToLine size={20} />
                              </Button>
                            )}
                          </Flex>
                        </GridItem>
                      </Flex>
                    </GridItem>
                  </Grid>
                </Box>
                <Box mb={isLessThan1088 ? 6 : 0} h="100%">
                  {data.conversations.length === 0 && (
                    <Flex alignItems="center" flexDirection="column" justifyContent="space-around">
                      <Text>Vous n'avez pas de discussions.</Text>
                    </Flex>
                  )}
                  {data &&
                    data.conversations?.map((conversation, index) => {
                      const isTruncated = conversation.lastMessage.content.length > 20;
                      const truncatedText = isTruncated ? conversation.lastMessage.content.slice(0, 20) + '...' : conversation.lastMessage.content;

                      return (
                        <Box cursor={'pointer'} key={conversation.id} mr={4} onClick={() => handleNewUsername(conversation.otherUser.username)}>
                          <Flex align="center">
                            <UserAvatar withBorder user={conversation.otherUser} styles={{ size: 'md' }} />
                            <Box w="100%" ml={2}>
                              <Flex align="center" justifyContent="space-between">
                                <Text fontWeight="bold">{conversation.otherUser.username}</Text>
                                <Text color="greyChupacabra">{formatDistanceToNow(new Date(conversation.lastMessage.timestamp), { addSuffix: false, locale: fr })}</Text>
                              </Flex>
                              <Flex align="center" justifyContent="space-between">
                                <Text color="greyChupacabra">{truncatedText}</Text>
                                <Text mt="2">
                                  {!conversation.lastMessage.isRead && conversation.lastMessage.senderId.id !== user?.id && <Badge bg={customColors.greenFrog} p={1} borderRadius="full" />}
                                </Text>
                              </Flex>
                            </Box>
                          </Flex>
                          {index !== data.conversations.length - 1 && <Divider my={4} />}
                        </Box>
                      );
                    })}
                </Box>
              </>
            )}
          </Box>
          {queryParams.username && user && <MessageComponent username={queryParams.username} currentUser={user} />}
          {!queryParams.username && data && data.conversations.length > 0 && (
            <Flex alignItems="center" flexDirection="column" h="100%" justifyContent="space-around">
              <Text>Selectionnez une discussion</Text>
            </Flex>
          )}
        </Grid>
      )}

      {!queryParams.username && data && data.conversations.length === 0 && <MessageEmpty />}
    </PageLayout>
  );
};

export default MessageList;
