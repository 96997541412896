import { useQuery, UseQueryResult } from 'react-query';
import api from '../../utils/api/api';
import { API_CHECK_STREAM_STATUS_URI } from '../../utils/api/constants';

// Fonction pour vérifier le statut du stream
const fetchStreamStatus = async (): Promise<boolean> => {
  return api.get(API_CHECK_STREAM_STATUS_URI).then(res => res.data.isOnline);
};

export const useCheckStreamStatus = (): UseQueryResult<boolean, Error> => {
  return useQuery('checkStreamStatus', fetchStreamStatus, {
    refetchInterval: 300000,
  });
};
