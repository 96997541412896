import React, { useEffect } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Text } from '@chakra-ui/react';
import ReactRouterPrompt from 'react-router-prompt';

const InSavedChangesPrompt: React.FC<{ isDirty: boolean }> = ({ isDirty }) => {
  const handleBeforeUnload = React.useCallback(
    (event: any) => {
      if (isDirty) {
        const message = 'Vous avez des modifications non enregistrées. Êtes-vous sûr de vouloir quitter ?';
        event.returnValue = message;
        return message;
      }
    },
    [isDirty],
  );

  useEffect(() => {
    if (isDirty) {
      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [isDirty, handleBeforeUnload]);

  return (
    <ReactRouterPrompt when={isDirty}>
      {({ isActive, onConfirm, onCancel }) => (
        <Modal isOpen={isActive} size="xl" onClose={onCancel}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Quitter l'édition</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <Text>Tu as des modifications non enregistrées. Es-tu sûr de vouloir quitter ?</Text>
            </ModalBody>
            <ModalFooter>
              <Button variant="navRed" border="none" color="white" textDecoration="underline" mr={3} onClick={onConfirm}>
                Je confirme et je quitte l'édition
              </Button>
              <Button variant="navYellow" onClick={onCancel}>
                Oh mon dieu non, je veux rester !
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </ReactRouterPrompt>
  );
};

export default InSavedChangesPrompt;
