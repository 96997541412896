import React from 'react';
import { Contest } from '../../../../types/Contests';
import { Box, Button, Checkbox, Flex, FormControl, FormLabel, Text, useMediaQuery } from '@chakra-ui/react';
import HeaderStep from './HeaderStep';
import { formatTextWithLineBreaks } from '../../../../utils/helpers';
import { CGU_AUTH } from '../../../../routing/constants';

interface Step1Props {
  contest: Contest;
  setParticipationClick: React.Dispatch<React.SetStateAction<boolean>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}
const Step1: React.FC<Step1Props> = ({ contest, setParticipationClick, setStep }) => {
  const [isCheck, setIsCheck] = React.useState(false);
  const [isLessThan1020] = useMediaQuery('(max-width: 1600px)');
  const [isLessThan837] = useMediaQuery('(max-width: 837px)');
  const handleCheckboxChange = (e: any) => {
    setIsCheck(e.target.checked);
  };

  return (
    <>
      {!isLessThan1020 && (
        <Flex justifyContent="center" alignItems="end">
          <Button variant="navRed" onClick={() => setParticipationClick(false)}>
            Renoncer à la gloire
          </Button>
        </Flex>
      )}

      <Box mt={4}>
        {!isLessThan837 && <HeaderStep step={1} />}
        <Flex justifyContent="center" h={isLessThan1020 ? 'auto' : 'calc(100% - 100px)'} direction="column">
          <Text as="h1" fontSize="16px" mb={2} color="yellowBob">
            Modalités de participation
          </Text>
          <Text fontSize="13px">{formatTextWithLineBreaks(contest.participationModalities)}</Text>

          <Box mt={20}>
            <FormControl isRequired={true}>
              <Flex align="center">
                <Checkbox
                  variant="whiteBorder"
                  id="cgu"
                  onChange={handleCheckboxChange}
                  isChecked={isCheck} // Assurez-vous que la checkbox reflète l'état de 'isCheck'
                />
                <Box ml={2} mt="6px">
                  <FormLabel htmlFor="cgu" color="greenFrog">
                    Je confirme avoir pris connaissance des{' '}
                    <Box as={'a'} href={CGU_AUTH} target="_blank" rel="noopener noreferrer" textDecoration="underline">
                      CGU relative aux concours
                    </Box>
                  </FormLabel>
                </Box>
              </Flex>
            </FormControl>
          </Box>
        </Flex>
      </Box>
      <Flex justifyContent={isLessThan1020 ? 'space-between' : 'center'} alignItems="end">
        {isLessThan1020 && (
          <Button variant="navRed" onClick={() => setParticipationClick(false)}>
            Renoncer à la gloire
          </Button>
        )}
        <Button variant="navYellow" isDisabled={!isCheck} onClick={() => setStep(2)}>
          Etape suivante
        </Button>
      </Flex>
    </>
  );
};
export default Step1;
