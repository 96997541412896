import React from 'react';
import FormLayout from '../../../../layout/noAuth/wrappers/FormLayout';
import ContactForm from './ContactForm';
import PageTitleUpdater from '../../../../routing/PageTitleUpdater';
import { getRandomNumber } from '../../../../utils/helpers';

const Contact: React.FC = () => {
  return (
    <FormLayout media={`/assets/connexion/cover-${getRandomNumber(1, 7)}.png`}>
      <PageTitleUpdater additionalTitle="Contact" />
      <ContactForm />
    </FormLayout>
  );
};

export default Contact;
