import api from '../../utils/api/api';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { API_USERS_URI } from '../../utils/api/constants';
import { User } from '../../types/user';
import {
  UserAdminUpdateFormType,
  UserUpdateBgColorType,
  UserUpdateDiscordType,
  UserUpdateFormType,
  UserUpdateNotificationMobile,
  UserUpdateSoundMessageType,
  UserUpdateSoundVisitType,
} from '../../pages/userProfile/update/constants';
import { UserGeneralUpdateFormType, UserParameterUpdateFormType, UserUpdateAvatarFormType, UserUpdateCoverFormType } from '../../pages/account/constants';

type FormType =
  | UserAdminUpdateFormType
  | UserUpdateCoverFormType
  | UserUpdateAvatarFormType
  | UserParameterUpdateFormType
  | UserGeneralUpdateFormType
  | UserUpdateBgColorType
  | UserUpdateFormType
  | UserUpdateDiscordType
  | UserUpdateSoundMessageType
  | UserUpdateNotificationMobile
  | UserUpdateSoundVisitType;

export const useUpdateUser = (): UseMutationResult<User, Error, { id: string; data: FormType }, unknown> => {
  const queryClient = useQueryClient();

  return useMutation<User, Error, { id: string; data: FormType }>(
    ({ id, data }) =>
      api
        .patch(`${API_USERS_URI}/${id}`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res => res.data),
    {
      onSuccess: () => {
        queryClient.refetchQueries();
      },
    },
  );
};
