import React from 'react';
import { Contest } from '../../../../types/Contests';
import { Box, Flex, Grid, Link, SimpleGrid, Text, useMediaQuery } from '@chakra-ui/react';
import UserAvatarProfile from '../../../../components/UserAvatarProfile';
import { S3_URL } from '../../../../utils/constants';

interface WinnerTextProps {
  contest: Contest;
}
const WinnerText: React.FC<WinnerTextProps> = ({ contest }) => {
  const isPluriel = contest.winner && contest.winner.length > 1;
  const [isLessThan1050] = useMediaQuery('(max-width: 1050px)');

  const gridTemplateColumns = isLessThan1050 ? 'repeat(auto-fill, minmax(100%, 1fr))' : '1fr 5fr 1fr';

  const getImageParticipation = (userId: string) => {
    const mediaSend = contest.mediaSend.find(media => media.userId === userId);
    return `${S3_URL}/contests/${contest.slug}/${mediaSend?.media}`;
  };

  const getNumbersOfColumns = () => {
    if (contest.winner.length === 1) {
      return 1;
    }
    if (contest.winner.length === 2) {
      return 2;
    }
    return 3;
  };

  return (
    <Grid templateColumns={gridTemplateColumns} gap={4} px={2} mt={'-30px'} h="calc(100vh - 450px)" zIndex={1} position={'relative'}>
      <Box />
      <Flex justifyContent="space-evenly" h="100%" direction="column">
        <Box>
          <Flex alignItems="center" pt={4} textAlign="center" direction="row" justifyContent="center">
            <Text as="h1">
              Gagnant{isPluriel && 's'} LEGENDAIRRRE{isPluriel && 'S'}
            </Text>
            <Text color="yellowBob" ml={2} mt={-4} transform="rotate(-20deg)">
              Dorrré{isPluriel && 's'} !
            </Text>
          </Flex>
          <Box mt={6}>
            <Text>
              Bravo,{' '}
              {contest.winner.map((winner, index) => (
                <Text key={winner.username} as="span" fontWeight="bold">
                  {winner.username}
                  {index < contest.winner.length - 1 ? ', ' : '!'}
                </Text>
              ))}
            </Text>
            {isPluriel ? (
              <Text mt={4}>Vos exploits résonnent dans les échos du royaume ! Le concours s'achève, mais vos noms demeurent gravés dans la légende</Text>
            ) : (
              <Text mt={4}>Votre exploit résonne dans les échos du royaume ! Le concours s'achève, mais votre nom demeure gravé dans la légende</Text>
            )}

            <Text mt={4}>
              Aux autres valeureux participants, vos aventures ont marqué cette quête commune. Le rideau tombe sur ce concours, mais que vos sagas personnelles continuent de s'écrire avec grandeur et
              bravoure!
            </Text>
          </Box>
          <Box textAlign="center" mb={'70px'} mt={6}>
            <SimpleGrid justifyItems="center" columns={getNumbersOfColumns()} spacing={10}>
              {contest.winner.map(winner => (
                <Box key={winner.username} textAlign="center" cursor="pointer" maxW="250px" _hover={{ transform: 'translateY(-4px)' }} transition="transform 0.2s">
                  <Box position="relative" w="100%" h="150px">
                    <Flex flexDirection="column" alignItems="center" justifyContent="center" textAlign="center">
                      <Link href={getImageParticipation(winner.id)} isExternal>
                        <Flex alignItems="center" justifyContent="center" textAlign="center" boxSize="sm" h={'100px'} w={'100px'} border="1px dashed white">
                          <Link href={getImageParticipation(winner.id)} isExternal>
                            Voir le text
                          </Link>
                        </Flex>
                      </Link>
                      <Box mt={2}>
                        <UserAvatarProfile withBorder username={winner.username} media={winner.media} styles={{ size: 'lg' }} />
                        <Text fontWeight="bold" fontSize="20px">
                          {winner.username}
                        </Text>
                      </Box>
                    </Flex>
                  </Box>
                </Box>
              ))}
            </SimpleGrid>
          </Box>
        </Box>
      </Flex>
      <Box />
    </Grid>
  );
};
export default WinnerText;
