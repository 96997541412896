import React, { useState } from 'react';
import { Box, Text, VStack, HStack, Divider, Spinner } from '@chakra-ui/react';
import { startOfWeek, addDays, format, addWeeks, subWeeks, isToday, isWithinInterval } from 'date-fns';
import { fr } from 'date-fns/locale';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { useEventsForWeek } from '../../hooks/events/useEventsForWeek';
import { EVENTS_PATH } from '../../routing/constants';
import { useNavigate } from 'react-router-dom';
import { getTypeEventColor } from '../../utils/helpers';

const MAX_EVENT_DOTS = 3;
const DOT_SIZE = 4;
const DOT_SPACING = 1;

const Calendar = () => {
  const [currentWeekStart, setCurrentWeekStart] = useState(startOfWeek(new Date(), { weekStartsOn: 1 }));
  const { data: events, isLoading } = useEventsForWeek(currentWeekStart);
  const navigate = useNavigate();

  const handlePreviousWeek = () => {
    setCurrentWeekStart(subWeeks(startOfWeek(currentWeekStart, { weekStartsOn: 1 }), 1));
  };

  const handleNextWeek = () => {
    setCurrentWeekStart(addWeeks(startOfWeek(currentWeekStart, { weekStartsOn: 1 }), 1));
  };

  const redirect = (date: string) => {
    navigate(`${EVENTS_PATH}?date=${date}`);
  };

  return (
    <Box color="brand.white" mb={4} p={4} bgColor="brand.block" borderRadius={21} overflow="hidden">
      <VStack spacing={3}>
        <HStack justifyContent="space-between" width="100%">
          <Text fontWeight="bold">
            {format(currentWeekStart, 'MMMM', { locale: fr }).slice(0, 3).toUpperCase()} {format(currentWeekStart, 'yyyy', { locale: fr })}
          </Text>
          {isLoading && <Spinner size="sm" marginLeft={2} />}
          <HStack>
            <Box onClick={handlePreviousWeek} cursor="pointer">
              <IoIosArrowBack />
            </Box>
            <Box onClick={handleNextWeek} cursor="pointer">
              <IoIosArrowForward />
            </Box>
          </HStack>
        </HStack>

        <Divider />

        <HStack spacing={1}>
          {Array.from({ length: 7 }).map((_, index) => {
            const currentDate = addDays(currentWeekStart, index);
            const bgColor = isToday(currentDate) ? 'brand.yellow' : 'brand.white';
            const eventsDay = events?.filter(event => {
              const eventStart = new Date(event.scheduledStartTime);
              const eventEnd = new Date(event.scheduledEndTime);

              // Normaliser les heures pour commencer à 00:00:00 du jour de début
              eventStart.setHours(0, 0, 0, 0);

              // Normaliser les heures pour finir à 23:59:59 du jour de fin
              eventEnd.setHours(23, 59, 59, 999);

              return isWithinInterval(currentDate, {
                start: eventStart,
                end: eventEnd,
              });
            });
            return (
              <Box
                onClick={() => (eventsDay && eventsDay.length > 0 ? redirect(format(currentDate, 'yyyy-MM-dd', { locale: fr })) : undefined)}
                cursor={eventsDay && eventsDay.length > 0 ? 'pointer' : 'default'}
                key={`calendar-day-${index}`}
                position="relative"
                color="brand.dark"
                minW="47px"
                p={3}
                bg={bgColor}
                borderRadius="md"
                textAlign="center"
              >
                <Text fontWeight="bold" color="blackTegrite">
                  {format(currentDate, 'EEE', { locale: fr }).slice(0, 2)}
                </Text>
                <Text color="blackTegrite">{format(currentDate, 'd')}</Text>

                {/* Affichage des bulles d'événements */}
                {eventsDay?.slice(0, MAX_EVENT_DOTS).map((event, eventIndex) => (
                  <Box
                    key={event.slug}
                    position="absolute"
                    bottom={-2}
                    left={`calc(50% + ${eventIndex * (DOT_SIZE + DOT_SPACING) - (MAX_EVENT_DOTS * DOT_SIZE + (MAX_EVENT_DOTS - 1) * DOT_SPACING) / 2}px)`} // Ajustement pour positionner côte à côte
                    mt={1}
                    w={4}
                    h={4}
                    bg={getTypeEventColor(event.entityType)}
                    borderRadius="full"
                    border={`1px solid ${bgColor === 'brand.yellow' ? '#FFCD29' : 'white'}`}
                  />
                ))}
              </Box>
            );
          })}
        </HStack>
      </VStack>
    </Box>
  );
};

export default Calendar;
