import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import api from '../../utils/api/api';
import { API_USERS_UPDATE_VALIDATE } from '../../utils/api/constants';

export const useValidateUpdatedUsers = (): UseMutationResult<void, Error, string[], unknown> => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, string[]>(ids => api.put(API_USERS_UPDATE_VALIDATE, { ids }).then(res => res.data), {
    onSuccess: () => {
      queryClient.refetchQueries('updatedUsers');
    },
  });
};
