import { Box, Flex, Image, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { User } from '../../types/user';
import UserCard from './UserCard';
import { HOME_BOARD } from '../../routing/constants';
import Linker from '../../components/Linker';
import { customColors } from '../../theme';

interface HeaderProps {
  user: User;
}

const Header: React.FC<HeaderProps> = ({ user }) => {
  const isMobileView = useBreakpointValue({ base: true, md: false });
  return (
    <Box
      zIndex={20}
      h={isMobileView ? '72px' : 'auto'}
      w={isMobileView ? '100%' : 'auto'}
      position={isMobileView ? 'fixed' : 'relative'}
      background={isMobileView ? (user.bgColor ? user.bgColor : customColors.yellowBob) : 'transparent'}
    >
      <Box
        position={isMobileView ? 'fixed' : 'relative'}
        color={'white'}
        w={isMobileView ? '98%' : 'auto'}
        zIndex={1}
        mx={isMobileView ? 1 : 4}
        mt={isMobileView ? 1 : 4}
        p={isMobileView ? 4 : 2}
        opacity={1}
        background={
          !isMobileView
            ? `linear-gradient(90deg, ${customColors.blackTegrite} 36%, rgba(43, 44, 44, 0) 100%), linear-gradient(270deg, ${customColors.blackTegrite} 10%, rgba(0, 0, 0, 0) 55%)`
            : customColors.blackTegrite
        }
        borderRadius={21}
      >
        <Flex justify="space-between" align="center" m="auto">
          {!isMobileView && (
            <Linker to={HOME_BOARD}>
              <Box ml={6}>
                <Image src="/assets/logos/logo-white-alone.svg" align="center" maxHeight="21px" />
              </Box>
            </Linker>
          )}
          <Box width={isMobileView ? '100%' : 'auto'}>
            <UserCard user={user} />
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default Header;
