import React, { useEffect, useState } from 'react';
import { Box, Text, VStack, HStack, Flex, Image, Button, GridItem, Grid, useBreakpointValue } from '@chakra-ui/react';
import { useDiscordChannels } from '../../../hooks/discord/forum/useDiscordChannels';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { THREAD_CHANNEL_PATH } from '../../../routing/constants';
import { formatUri } from '../../../utils/api/helpers';
import PageLayout from '../../../layout/PageLayout';
import Ariane from '../../../components/Ariane';
import { capitalizeFirstLetter } from '../../../utils/helpers';
import { QueryDiscordChannelsParams } from './constants';
import PageTitleUpdater from '../../../routing/PageTitleUpdater';

const ChannelCard: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const skip = searchParams.get('skip') ? parseInt(searchParams.get('skip')!) : 0;
  const [queryParams, setQueryParams] = useState<QueryDiscordChannelsParams>({
    skip,
    limit: 8,
  });

  const { data, isLoading, refetch, error, isError } = useDiscordChannels(queryParams);

  const hasPrevious = queryParams.skip > 0;
  const hasNext = queryParams.skip + queryParams.limit < (data?.total || 0);

  useEffect(() => {
    const newQueryParams = {
      skip: searchParams.get('skip') ? parseInt(searchParams.get('skip') as string) : 0,
      limit: 8,
    };

    if (JSON.stringify(newQueryParams) !== JSON.stringify(queryParams)) {
      setQueryParams(newQueryParams);
      refetch();
    }
  }, [searchParams, queryParams, refetch]);

  const updateSkip = (skip: number) => {
    const newSearchParams = new URLSearchParams();
    newSearchParams.set('skip', skip.toString());
    setSearchParams(newSearchParams);
  };

  const gridTemplateColumns = isMobile ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)';

  return (
    <PageLayout isLoading={isLoading} error={error} isError={isError}>
      <PageTitleUpdater additionalTitle={`Communauté`} />
      <Ariane>
        <Flex alignItems="center">
          <Image src="/assets/icons/blue.svg" alt="Communauté" width="30px" />
          <Text as="h1" textTransform="uppercase" fontSize="19px" ml={4}>
            Communauté
          </Text>
        </Flex>
      </Ariane>
      <Grid my={4} maxW={isMobile ? '100%' : '600px'}>
        <Text>Plongez dans notre espace Discord où chaque fil de discussion est une aventure épique.</Text>
        <Text mt={3}>
          Partagez astuces, théories, et blagues de geek, et explorez les sujets brûlants de notre communauté. C'est le coin parfait pour connecter avec des esprits curieux et passionnés comme vous.
        </Text>
        <Text mt={3}>Prêt pour un voyage intergalactique dans le monde du savoir geek ?</Text>
        <Text mt={3}>Embarquez dès maintenant !</Text>
      </Grid>
      <Grid mb={4}>
        <GridItem colSpan={2}>
          <Flex alignItems="center" justifyContent="right" mr={2}>
            <Button isDisabled={!hasPrevious} variant="navYellow" onClick={() => updateSkip(queryParams.skip - queryParams.limit)} mr={2}>
              Précédent
            </Button>
            <Button isDisabled={!hasNext} variant="navYellow" onClick={() => updateSkip(queryParams.skip + queryParams.limit)} mr={2}>
              Suivant
            </Button>
          </Flex>
        </GridItem>
      </Grid>
      <Box>
        <Grid templateColumns={gridTemplateColumns} gap={5} mb={isMobile ? 6 : 0}>
          {data &&
            data.channels.map(channel => {
              return (
                <GridItem
                  cursor="pointer"
                  key={channel.channelId}
                  w="100%"
                  onClick={() => navigate(formatUri({ channelId: channel.channelId }, THREAD_CHANNEL_PATH))}
                  _hover={{ transform: 'translateY(-4px)', boxShadow: 'lg' }}
                  transition="transform 0.2s, box-shadow 0.2s"
                  borderRadius={21}
                >
                  <Flex bg="whiteTegrite" overflow="hidden" p={4} w="100%" borderRadius={21} mb={0} align="center" justify="space-between">
                    <HStack spacing={4} w="100%">
                      <VStack align="start" w="100%">
                        <Flex p={4} justifyContent="space-between" w="100%" alignItems="center">
                          <Box>
                            <Text as="h2" fontSize={'16px'}>
                              {capitalizeFirstLetter(channel.channelName).replaceAll('-', ' ')}
                            </Text>
                            <Text color="gray.500">{channel.topic}</Text>
                          </Box>
                        </Flex>
                      </VStack>
                    </HStack>
                  </Flex>
                </GridItem>
              );
            })}
        </Grid>
      </Box>
    </PageLayout>
  );
};

export default ChannelCard;
