import React, { useEffect, useMemo } from 'react';
import AutoCompleteFormSelect from '../../../components/forms/AutoCompleteFormSelect';
import FormInput from '../../../components/forms/FormInput';
import { Box, Button, Flex } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { QueryUserParams } from './constants';
import { useFetchLocations } from '../../../hooks/users/useFetchLocations';
import { useSearchParams } from 'react-router-dom';

type SelectOption = { label: string; value: string };

const transformArrayToSelectOptions = (array: string[]): SelectOption[] => {
  return array.map(item => ({
    label: item,
    value: item,
  }));
};

type Props = {
  setSearchForm: (data: QueryUserParams) => void;
};

const UsersSearchForm: React.FC<Props> = ({ setSearchForm }) => {
  const { data: locations } = useFetchLocations();
  const [searchParams] = useSearchParams();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm<QueryUserParams>({ mode: 'onChange' });

  const onSubmit: SubmitHandler<QueryUserParams> = data => {
    console.log(data);
    setSearchForm(data);
  };

  const queryParamsFromUrl = useMemo(() => {
    return {
      username: searchParams.get('username') || undefined,
      location: {
        country: searchParams.get('location.country') || undefined,
        department: searchParams.get('location.department') || undefined,
      },
    };
  }, [searchParams]);

  useEffect(() => {
    setValue('username', queryParamsFromUrl.username ? (queryParamsFromUrl.username as string) : undefined);
    setValue('location.country', queryParamsFromUrl.location.country ? (queryParamsFromUrl.location.country as string) : undefined);
    setValue('location.department', queryParamsFromUrl.location.department ? (queryParamsFromUrl.location.department as string) : undefined);
  }, [searchParams]);

  const resetForm = () => {
    reset();
    setValue('username', undefined);
    setValue('location.country', undefined);
    setValue('location.department', undefined);
    onSubmit({
      skip: 0,
      username: '',
      location: {
        country: undefined,
        department: undefined,
      },
    });
  };

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      <AutoCompleteFormSelect
        label="Pays"
        variant="whiteBorder"
        setValue={setValue}
        getValues={getValues}
        placeholder="Sélectionner un pays"
        name="location.country"
        register={register}
        errors={errors}
        options={locations ? transformArrayToSelectOptions(locations.countries) : []}
      />
      <AutoCompleteFormSelect
        label="Département"
        variant="whiteBorder"
        getValues={getValues}
        setValue={setValue}
        placeholder="Sélectionner un departement"
        name="location.department"
        register={register}
        errors={errors}
        options={locations ? transformArrayToSelectOptions(locations.departments) : []}
      />
      <FormInput label="Pseudo" sx={{ mt: 4 }} variant="whiteBorder" type="text" placeholder="Pseudo" name="username" register={register} errors={errors} />

      <Flex flexDir="row" justifyContent="flex-end" mt={4}>
        <Button variant="navRed" type="button" onClick={resetForm}>
          Réinitialiser
        </Button>
        <Button ml={4} variant="navYellow" type="submit">
          Rechercher
        </Button>
      </Flex>
    </Box>
  );
};

export default UsersSearchForm;
