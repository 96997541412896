import React, { useState, useRef, useEffect } from 'react';
import { Textarea, Box, Flex, FormErrorMessage } from '@chakra-ui/react';
import { FaBold, FaItalic, FaStrikethrough } from 'react-icons/fa';
import { GoSmiley } from 'react-icons/go';
import Picker from '@emoji-mart/react';
import { ImAttachment } from 'react-icons/im';
import { customColors } from '../../theme';
import { DeepMap, FieldError } from 'react-hook-form';
import { useAddMedia } from '../../hooks/media/useAddMedia';

export interface MarkdownTextareaProps {
  placeholder: string;
  name: string;
  required?: boolean;
  getValues: any;
  setValue: any;
  errors?: DeepMap<any, FieldError>;
  maxLength?: number;
  variant?: any;
}

const MarkdownTextarea: React.FC<MarkdownTextareaProps> = ({ getValues, placeholder, name, errors, required, setValue }) => {
  const [localValue, setLocalValue] = useState(getValues(name) ? getValues(name) : '');
  const textareaRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const { mutateAsync: uploadImage } = useAddMedia();

  const pickerRef = useRef(null);
  const [outsideClicked, setOutsideClicked] = useState(false);

  const insertMarkdown = (syntax: any) => {
    const textarea: any = textareaRef.current;
    if (!textarea) return;
    const selectionStart = textarea.selectionStart;
    const selectionEnd = textarea.selectionEnd;
    const before = localValue.substring(0, selectionStart);
    const after = localValue.substring(selectionEnd);
    const selectedText = localValue.substring(selectionStart, selectionEnd);

    let markdownText;
    switch (syntax) {
      case 'bold':
        markdownText = `**${selectedText}**`;
        break;
      case 'italic':
        markdownText = `*${selectedText}*`;
        break;
      case 'underline':
        markdownText = `_${selectedText}_`;
        break;
      case 'strikethrough':
        markdownText = `~~${selectedText}~~`;
        break;
      default:
        return;
    }

    setLocalValue(before + markdownText + after);

    if (textarea) {
      textarea.focus();
      const cursorPosition = before.length + markdownText.length;
      textarea.setSelectionRange(cursorPosition, cursorPosition);
    }
  };

  const uploadToImgur = async () => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append('image', selectedFile);

    try {
      const link = await uploadImage({ file: selectedFile, url: 'medias/form/' });
      if (textareaRef.current) {
        const textarea: any = textareaRef.current;
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        const text = localValue;
        const before = text.substring(0, start);
        const after = text.substring(end, text.length);
        const markdownText = `![Image](${link})`;
        setLocalValue(`${before}${markdownText}${after}`);
      }
    } catch (error) {
      console.error('There was an error uploading the image', error);
    }
  };

  useEffect(() => {
    if (selectedFile) {
      uploadToImgur();
    }
  }, [selectedFile]);

  const handleClickOutside = (event: any) => {
    if (pickerRef.current && !(pickerRef as any).current.contains(event.target)) {
      setOutsideClicked(true);
    }
  };

  useEffect(() => {
    if (buttonClicked) {
      setShowEmojiPicker(prev => !prev);
      setButtonClicked(false);
    } else if (outsideClicked) {
      setShowEmojiPicker(false);
      setOutsideClicked(false);
    }

    if (showEmojiPicker) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [buttonClicked, outsideClicked, showEmojiPicker]);

  const addEmoji = (emoji: any) => {
    if (textareaRef.current) {
      const textarea: any = textareaRef.current;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const text = localValue;
      const before = text.substring(0, start);
      const after = text.substring(end, text.length);
      setLocalValue(`${before}${emoji.native}${after}`);

      // Pour mettre à jour la position du curseur après avoir inséré l'émoji
      setTimeout(() => {
        textarea.selectionStart = textarea.selectionEnd = start + emoji.native.length;
      }, 0);
    }
  };

  useEffect(() => {
    setValue(name, localValue, { shouldValidate: true });
  }, [localValue]);

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter' && !e.shiftKey && localValue !== '') {
      e.preventDefault();
      document.getElementById('submit-button')?.click();
      setTimeout(() => {
        setLocalValue('');
      }, 100);
    }
  };

  return (
    <Box position="relative">
      <Box position="absolute" right={0} bottom={'145px'} zIndex={0} ref={pickerRef}>
        {showEmojiPicker && <Picker onEmojiSelect={addEmoji as any} />}
      </Box>
      <Box position="relative" border={`1px solid ${customColors.greyChupacabra}`} borderRadius={21} padding={4}>
        <input type="file" id="fileInput" style={{ display: 'none' }} onChange={(e: any) => setSelectedFile(e.target.files ? e.target.files[0] : null)} />
        <Textarea
          onKeyDown={handleKeyDown}
          required={required}
          sx={{
            p: 0,
            '::placeholder': {
              color: customColors.greyChupacabra,
            },
            border: 'none',
            _focus: {
              border: 'none',
              boxShadow: 'none',
            },
          }}
          border={'none'}
          ref={textareaRef}
          value={localValue}
          onChange={e => setLocalValue(e.target.value)}
          placeholder={placeholder}
          size="lg"
          padding="20px"
        />
        <Flex justifyContent={'space-between'} mr={2}>
          <Flex alignItems="center" mt={2}>
            <Box>
              <FaBold onClick={() => insertMarkdown('bold')} color={customColors.greyChupacabra} aria-label="Bold" />
            </Box>
            <Box ml={2}>
              <FaItalic onClick={() => insertMarkdown('italic')} color={customColors.greyChupacabra} aria-label="Italic" />
            </Box>
            <Box ml={2}>
              <FaStrikethrough onClick={() => insertMarkdown('strikethrough')} color={customColors.greyChupacabra} aria-label="Strikethrough" />
            </Box>
          </Flex>
          <Flex alignItems="center" mt={2}>
            <Box ml={2}>
              <ImAttachment onClick={() => document.getElementById('fileInput')?.click()} color={customColors.greyChupacabra} />
            </Box>
            <Box ml={2}>
              <GoSmiley onClick={() => setButtonClicked(!buttonClicked)} color={customColors.greyChupacabra} />
            </Box>
          </Flex>
        </Flex>
        <FormErrorMessage>{errors && errors[name] && errors[name].message}</FormErrorMessage>
      </Box>
    </Box>
  );
};

export default MarkdownTextarea;
