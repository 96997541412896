import React, { useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Box, Button, Flex, Text, Link as ChakraLink, Image, useBreakpointValue } from '@chakra-ui/react';
import { ConnexionDirectForm } from '../../types/forms/connexion';
import useConnexion from '../../hooks/auth/useConnexion';
import { HOME_BOARD, INSCRIPTION_PATH } from '../../routing/constants';
import { useAppContext } from '../../context/AppContext';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDiscordAuth } from '../../hooks/auth/useDiscordAuth';
import { User } from '../../types/user';
import useDiscordLogin from '../../hooks/auth/useDiscordLogin';
import FormInput from '../../components/forms/FormInput';
import PasswordResetModal from './PasswordResetModal';
import { toast } from 'react-toastify';

const ConnexionForm: React.FC = () => {
  const { setUser } = useAppContext();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ConnexionDirectForm>();
  const discordLogin = useDiscordLogin();
  const connexion = useConnexion();
  const { discordAuthMutation, openDiscordPopup } = useDiscordAuth();
  const [isResetModalOpen, setIsResetModalOpen] = React.useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const onSubmit: SubmitHandler<ConnexionDirectForm> = data => {
    connexion.mutate(data, {
      onSuccess: user => {
        setUser(user);
        navigate(HOME_BOARD);
      },
      onError: (error: any) => {
        toast.error(`Erreur de connexion. ${error.response.data.message}`, {
          position: toast.POSITION.TOP_CENTER,
        });
      },
    });
  };

  useEffect(() => {
    if (discordAuthMutation.isSuccess) {
      discordLogin.mutate(
        {
          token: discordAuthMutation.data.token,
          refreshToken: discordAuthMutation.data.refreshToken,
          userId: discordAuthMutation.data.user.id,
        },
        {
          onSuccess: (user: User) => {
            setUser(user);
            navigate(HOME_BOARD);
          },
          onError: () => {
            toast.error(`Erreur de connexion. Ancun utilisateur discord n'existe avec cet identifiant.`, {
              position: toast.POSITION.TOP_CENTER,
            });
          },
        },
      );
    }
  }, [discordAuthMutation.isSuccess, discordAuthMutation.data]);

  return (
    <Box mt={isMobile ? 10 : 0} px={isMobile ? 4 : 0}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mb={20} display="flex" flexDirection="column" alignItems="center">
          <Text fontSize={isMobile ? '2xl' : '4xl'} color="#ffffff" textAlign="center">
            Connecte-toi à ton compte via
          </Text>
          <Box mt={20} onClick={openDiscordPopup} cursor="pointer" bg="darkStar" px={10} py={4} borderRadius={21}>
            <Image src={`/assets/logos/discord-simple.svg`} alt="Discord" />
          </Box>
        </Box>
        <Flex align="center">
          <Box flex="1" height="1px" bg="gray.300" alignSelf="center" />
          <Text mx={2} mb={1} fontSize="sm" color="white" px={2} alignSelf="center">
            ou avec ton compte EntreGeeks
          </Text>
          <Box flex="1" height="1px" bg="gray.300" alignSelf="center" />
        </Flex>
        <Flex align="center" my={4}>
          <FormInput variant="whiteBorderNoBg" type="text" placeholder="Nom d'utilisateur" name="username" register={register} errors={errors} />
        </Flex>
        <Flex align="center" my={4}>
          <FormInput variant="whiteBorderNoBg" type="password" placeholder="Mot de passe" name="password" register={register} errors={errors} />
        </Flex>
        <Flex justifyContent="flex-end" my={4}>
          <ChakraLink as={RouterLink} onClick={() => setIsResetModalOpen(true)}>
            <Text textDecoration="underline" color="whiteWalter">
              Mot de passe oublié ?
            </Text>
          </ChakraLink>
        </Flex>
        <Button mt={4} variant="primary-dark" w="100%" fontSize="sm" colorScheme="teal" type="submit" isLoading={connexion.isLoading}>
          Connexion
        </Button>
        <Flex align="center" justifyContent="center" my={4}>
          <Text fontSize="sm" color="white">
            Pas encore de compte ?{' '}
            <ChakraLink as={RouterLink} to={INSCRIPTION_PATH} color="yellowBob">
              Créer un compte
            </ChakraLink>
          </Text>
        </Flex>
      </form>
      <PasswordResetModal isOpen={isResetModalOpen} onClose={() => setIsResetModalOpen(false)} />
    </Box>
  );
};

export default ConnexionForm;
