import { useMutation, UseMutationResult } from 'react-query';
import api from '../../utils/api/api';
import { API_PASSWORD_PROFILE_RESET_VALID } from '../../utils/api/constants';

type ResetPasswordParams = {
  password: string;
  oldPassword: string;
};

export const useResetProfilePassword = (): UseMutationResult<void, Error, ResetPasswordParams, unknown> => {
  return useMutation<void, Error, ResetPasswordParams>(({ password, oldPassword }) => api.post(API_PASSWORD_PROFILE_RESET_VALID, { password, oldPassword }).then(res => res.data));
};
