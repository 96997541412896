import { useQuery, UseQueryResult } from 'react-query';
import api from '../../utils/api/api';
import { User } from '../../types/user';
import { API_USERS_UPDATED } from '../../utils/api/constants';

export const useUpdatedUsers = (): UseQueryResult<User[], unknown> => {
  return useQuery<User[]>(
    'updatedUsers',
    async () => {
      const { data } = await api.get(API_USERS_UPDATED);
      return data;
    },
    {
      refetchInterval: 5 * 60 * 1000,
    },
  );
};
