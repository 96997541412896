import React from 'react';
import useLogout from '../../hooks/auth/useLogout';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { HOME_PATH } from '../../routing/constants';
import { useAppContext } from '../../context/AppContext';
import { Flex, Text } from '@chakra-ui/react';
import { customColors } from '../../theme';

const LogoutButton = (): React.ReactElement => {
  const navigate = useNavigate();
  const { setUser } = useAppContext();
  const logout = useLogout(() => {
    document.body.style.backgroundColor = customColors.blackTegrite;
    setUser(null);
    navigate(HOME_PATH);
  });

  const handleLogout = (): void => {
    logout.mutate();
  };

  return (
    <Flex alignItems="center" onClick={handleLogout} p={2} cursor="pointer">
      <AiOutlineCloseCircle size={20} />
      <Text ml={2}>Déconnexion</Text>
    </Flex>
  );
};

export default LogoutButton;
