import React from 'react';
import { Box, Flex, Image } from '@chakra-ui/react';
import { extractSocialMediaId } from '../../../utils/helpers';
import CopyToClipboardText from '../../../components/CopyToClipboardText';

interface TemplateGamesIdAndSocialProps {
  keyName: string;
  name: string;
  id: string;
}

const TemplateGamesIdAndSocial: React.FC<TemplateGamesIdAndSocialProps> = ({ keyName, id }) => {
  return (
    <>
      <Flex width={['100%', '50%']} alignItems="center" mb={4}>
        <Box>
          <Image src={`/assets/profile/${keyName}.svg`} align="center" />
        </Box>
        <Box ml={4}>
          <CopyToClipboardText text={extractSocialMediaId(id)} />
        </Box>
      </Flex>
    </>
  );
};

export default TemplateGamesIdAndSocial;
