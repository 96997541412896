import React from 'react';
import { Button, Flex } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createThreadSchema } from './yup/createThreadSchema';
import FormInput from '../../../../components/forms/FormInput';
import FormAutocomplete from '../../../../components/forms/FormAutocomplete';
import { CreateThreadDataForm } from './constants';
import { useCreateThread } from '../../../../hooks/discord/forum/useCreateThread';
import { DiscordChannel } from '../../../../types/Discord';
import { useNavigate } from 'react-router-dom';
import { formatUri } from '../../../../utils/api/helpers';
import { THREAD_MESSAGES_PATH } from '../../../../routing/constants';
import FormTextareaSimple from '../../../../components/forms/FormTextareaSimple';

interface CreateThreadForm {
  tags: { label: string; value: string }[];
  channel: DiscordChannel;
}

const CreateThreadForm: React.FC<CreateThreadForm> = ({ tags, channel }) => {
  const createThreadMutation = useCreateThread();
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm<CreateThreadDataForm>({
    resolver: yupResolver(createThreadSchema),
    mode: 'onBlur',
  });

  const onSubmit = async (data: CreateThreadDataForm) => {
    const thread = await createThreadMutation.mutateAsync({ formData: data, channelId: channel.channelId });

    if (thread) {
      navigate(formatUri({ channelId: thread.threadId }, THREAD_MESSAGES_PATH));
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormInput variant="whiteBorder" label="Titre" type="text" placeholder="Titre" name="title" required={true} register={register} errors={errors} />
      <FormTextareaSimple
        variant="whiteBorderNoBg"
        label="Message"
        placeholder="Message"
        name="message"
        required={true}
        errors={errors}
        register={register}
        getValues={getValues}
        setValue={setValue}
      />
      <FormAutocomplete variant="whiteBorderNoBg" label="Tags" placeholder="Choissez des tags" name="tags" setValue={setValue} getValues={getValues} errors={errors} options={tags} />

      <Flex justifyContent="flex-end" alignItems="center" mt={4} mb={4} w="100%">
        <Button variant="navYellow" type="submit" isLoading={createThreadMutation.isLoading} isDisabled={!isValid}>
          Envoyer
        </Button>
      </Flex>
    </form>
  );
};

export default CreateThreadForm;
