// useUserByUsername.ts
import { useQuery, UseQueryResult } from 'react-query';
import api from '../../utils/api/api';
import { User } from '../../types/user';
import { API_USER_BY_USERNAME_URI } from '../../utils/api/constants';

export const useUserByUsername = (username: string): UseQueryResult<User, unknown> => {
  return useQuery<User>(
    ['user', username],
    async () => {
      const { data } = await api.get(`${API_USER_BY_USERNAME_URI}/${username}`);
      return data;
    },
    { retry: 0 },
  );
};
