import React, { useEffect } from 'react';
import { useReFetchCurrentUser } from '../../../hooks/users/useFetchCurrentUser';
import PageLayout from '../../../layout/PageLayout';
import { useAppContext } from '../../../context/AppContext';
import { Box, Text } from '@chakra-ui/react';

export const FetchInactiveUser: React.FC = () => {
  const { setUser } = useAppContext();
  const { data, isLoading } = useReFetchCurrentUser();

  useEffect(() => {
    if (data) {
      if (data.isAccountValid) {
        setUser(data);
      }
    }
  }, [data]);

  return (
    <PageLayout isLoading={isLoading}>
      <Box textAlign="center" mt={4}>
        <Text>Votre compte doit être validé.</Text>
        <Text>Rassurez vous cela sera rapide :)</Text>
      </Box>
    </PageLayout>
  );
};

export default FetchInactiveUser;
