import { useEffect } from 'react';

const useWindowZoom = (maxWidth: number) => {
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > maxWidth) {
        const scale = window.innerWidth > maxWidth ? window.innerWidth / maxWidth : 1;
        document.body.style.transform = `scale(${scale})`;
        document.body.style.transformOrigin = 'top left';
        document.body.style.width = `${maxWidth}px`; // Fixe la largeur du body pour éviter des problèmes de débordement
      } else {
        document.body.style.width = '100%';
        document.body.style.transform = 'none';
      }
    }

    window.addEventListener('resize', handleResize);

    // Ajuster le zoom au chargement initial
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      document.body.style.transform = 'none'; // Réinitialiser le zoom lors du démontage
    };
  }, [maxWidth]);
};

export default useWindowZoom;
