import { extendTheme } from '@chakra-ui/react';

export const customColors = {
  whiteWalter: '#F7F7F7',
  whiteTrue: '#FFFFFF',
  greyChupacabra: '#8E9297',
  greyRoswel: '#D9D9D9',
  blueTardis: '#5E8AB1',
  greenToxic: '#00BE62',
  orangeMecanique: '#F1902F',
  redRanger: '#FE4A51',
  redLanterne: '#F86E6F',
  lemonBritney: '#FFF452',
  yellowBob: '#FFCD29',
  yellowBobDark: '#E7B922',
  yellowShit: '#594A31',
  blackTegrite: '#2B2C2C',
  whiteTegrite: '#323233',
  darkStar: '#36393F',
  black: '#270D2A',
  greenFrog: '#2ECC71',
  purpleThanos: '#8A5AA6',
};

export const eventsColors = {
  outing: customColors.blueTardis,
  game: customColors.purpleThanos,
  stream: customColors.greenToxic,
};

const customTextStyles = {
  h1: {
    fontSize: '24px',
    fontFamily: 'Barlow Condensed',
    fontWeight: '800',
    color: customColors.whiteWalter,
  },
  h2: {
    fontSize: '21px',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    color: customColors.whiteWalter,
  },
  h3: {
    fontSize: '16px',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    color: customColors.whiteWalter,
  },
  h4: {
    fontSize: '45px',
    fontFamily: 'Barlow Condensed',
    fontWeight: 'bold',
    fontStyle: 'italic',
    color: customColors.whiteWalter,
  },
  p: {
    fontSize: '13px',
    fontFamily: 'Montserrat',
    fontWeight: 'medium',
    color: customColors.whiteWalter,
  },
  '.markdown a': {
    color: customColors.yellowBob,
    textDecoration: 'underline',
  },
  '.header_home': {
    fontSize: '55px',
    lineHeight: '66px',
    fontFamily: 'Barlow Condensed',
    fontWeight: '700',
    color: customColors.whiteWalter,
  },
  '.title_menu_home': {
    fontSize: '13px',
    letterSpacing: '0.2em',
    fontFamily: 'Barlow Condensed',
    fontWeight: '700',
    color: 'whiteWalter',
    textTransform: 'uppercase',
  },
};

const theme = extendTheme({
  styles: {
    global: {
      '--select-bg': customColors.blackTegrite,
      ...customTextStyles,
    },
  },
  textStyles: {
    barlowCondensed: {
      fontFamily: 'Barlow Condensed',
    },
    subHeader: {
      fontSize: '13px',
      fontFamily: 'Montserrat',
      fontWeight: 'medium',
      color: customColors.greyChupacabra,
    },
    lastForumDiscussion: {
      headerGreyLight: {
        fontSize: '13px',
        fontFamily: 'Montserrat',
        fontWeight: 'medium',
        color: customColors.greyChupacabra,
      },
      content: {
        color: customColors.whiteTrue,
        fontSize: 16,
        fontFamily: 'Barlow Condensed',
        fontWeight: 'bold',
      },
    },
  },
  colors: {
    ...customColors,
    brand: {
      yellow: '#FFCD29',
      dark: '#2B2B2B',
      block: customColors.blackTegrite,
      blue: '#5865F2',
      green: 'green',
      purple: '#270D2A',
      purpleWhite: '#C486B9',
      bgForm: '#4D3650',
      white: 'white',
    },
  },
  components: {
    Modal: {
      // Styles de base pour le Modal
      baseStyle: {
        dialog: {
          bg: customColors.blackTegrite, // couleur de fond
          color: 'white', // couleur du texte
          width: '150%', // largeur par défaut
        },
      },
    },
    Select: {
      variants: {
        violet: {
          field: {
            border: 'none',
            bg: '#4D3650',
            color: 'white',
            '::placeholder': {
              color: '#939393',
            },
          },
          icon: {
            color: '#939393',
          },
          select: {
            // Ici, on ajoute la configuration pour les éléments `<option>`
            option: {
              color: 'white', // couleur par défaut
              '&:first-child[value=""]': {
                color: '#939393', // couleur pour l'option placeholder
              },
            },
          },
        },
        whiteBorder: {
          field: {
            border: '1px solid',
            borderColor: customColors.greyChupacabra,
            bg: customColors.blackTegrite,
            color: 'white',
            '::placeholder': {
              color: customColors.greyChupacabra,
            },
          },
          icon: {
            color: customColors.greyChupacabra,
          },
          select: {
            option: {
              color: 'white',
              bg: customColors.blackTegrite,
              '&:hover': {
                bg: customColors.yellowBob,
              },
              '&:first-child[value=""]': {
                color: customColors.greyChupacabra,
              },
            },
          },
        },
      },
    },
    Slider: {
      variants: {
        violet: {
          track: {
            bg: 'white',
          },
          thumb: {
            border: 'none',
            bg: 'white',
          },
        },
      },
    },
    Textarea: {
      variants: {
        violet: {
          textarea: {
            border: 'none',
            bg: '#4D3650',
            color: 'white',
            '::placeholder': {
              color: '#939393',
            },
          },
        },
      },
    },
    Input: {
      variants: {
        violet: {
          field: {
            border: 'none',
            bg: '#4D3650',
            color: 'white',
            '::placeholder': {
              color: '#939393',
            },
          },
        },
        whiteBorder: {
          field: {
            border: '1px solid',
            borderColor: customColors.greyChupacabra,
            bg: customColors.blackTegrite,
            color: 'white',
            '::placeholder': {
              color: '#939393',
            },
          },
        },
        whiteBorderNoBg: {
          field: {
            border: '1px solid',
            borderColor: customColors.greyChupacabra,
            bg: 'none',
            _hover: {
              bg: customColors.blackTegrite,
            },
            color: 'white',
            '::placeholder': {
              color: '#939393',
            },
          },
        },
      },
    },
    Checkbox: {
      variants: {
        violet: {
          control: {
            border: 'none',
            bg: '#4D3650',
            color: 'white',
          },
        },
        whiteBorder: {
          control: {
            border: '1px solid',
            borderColor: customColors.greyChupacabra,
            bg: customColors.blackTegrite,
            color: 'white',
            // Styles pour l'état checked
            _checked: {
              border: '1px solid',
              borderColor: customColors.yellowBob,
              bg: customColors.yellowBob,
              '::placeholder': {
                color: '#939393',
              },
            },
            _active: {
              border: '1px solid',
              borderColor: customColors.yellowBob,
              bg: customColors.yellowBob,
            },
            _hover: {
              border: '1px solid',
              borderColor: customColors.yellowBob,
              bg: customColors.yellowBob,
            },
          },
        },
      },
    },
    Button: {
      variants: {
        typeEvent: {
          cursor: 'default',
          color: customColors.yellowBob,
          bg: customColors.yellowShit,
          fontFamily: 'Montserrat',
          fontSize: '11px',
          textTransform: 'uppercase',
          px: '10px',
          height: '27px',
          _hover: {
            color: customColors.yellowBob,
            bg: customColors.yellowShit,
          },
        },
        buttonCoverCard: {
          bg: customColors.yellowBob,
          px: '5px',
          borderRadius: '21px 0 0 0',
          height: '50%',
          zIndex: '1',
          _hover: { zIndex: 'popover', boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.5)', transform: 'scale(1.05)' },
        },
        navYellow: {
          borderColor: customColors.yellowBob,
          border: '1px solid',
          color: customColors.yellowBob,
          fontFamily: 'Montserrat',
          fontSize: '11px',
          textTransform: 'uppercase',
          px: '10px',
          height: '27px',
          _hover: {
            bg: customColors.yellowBob,
            color: customColors.blackTegrite,
          },
          _active: {
            bg: customColors.yellowBob,
            color: customColors.blackTegrite,
          },
        },
        navTegrite: {
          borderColor: customColors.blackTegrite,
          border: '1px solid',
          color: customColors.blackTegrite,
          fontFamily: 'Montserrat',
          fontSize: '11px',
          textTransform: 'uppercase',
          px: '10px',
          height: '27px',
          _hover: {
            bg: customColors.blackTegrite,
            color: customColors.yellowBob,
          },
          _active: {
            bg: customColors.blackTegrite,
            color: customColors.yellowBob,
          },
        },
        navYellowTotal: {
          border: `1px solid ${customColors.yellowBob}`,
          color: customColors.yellowBob,
          px: '10px',
          fontSize: '13px',
          fontFamily: 'Montserrat',
          fontWeight: 'medium',
          height: '27px',
          _hover: {
            boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.5)',
            bg: customColors.yellowBob,
            color: customColors.blackTegrite,
          },
          _active: {
            boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.5)',
            bg: customColors.yellowBob,
            color: customColors.blackTegrite,
          },
        },
        navPurple: {
          borderColor: customColors.yellowBob,
          border: '1px solid',
          color: customColors.purpleThanos,
          fontFamily: 'Montserrat',
          fontSize: '11px',
          textTransform: 'uppercase',
          px: '10px',
          height: '27px',
          _hover: {
            bg: customColors.purpleThanos,
            color: customColors.blackTegrite,
          },
          _active: {
            bg: customColors.purpleThanos,
            color: customColors.blackTegrite,
          },
        },
        navBlue: {
          borderColor: customColors.yellowBob,
          border: '1px solid',
          color: customColors.blueTardis,
          fontFamily: 'Montserrat',
          fontSize: '11px',
          textTransform: 'uppercase',
          px: '10px',
          height: '27px',
          _hover: {
            bg: customColors.blueTardis,
            color: customColors.blackTegrite,
          },
          _active: {
            bg: customColors.blueTardis,
            color: customColors.blackTegrite,
          },
        },
        navGrey: {
          borderColor: customColors.greyChupacabra,
          border: '1px solid',
          color: customColors.greyChupacabra,
          fontFamily: 'Montserrat',
          fontSize: '11px',
          textTransform: 'uppercase',
          lineHeight: '10px',
          height: '27px',
          _hover: {
            bg: customColors.yellowBob,
            color: customColors.blackTegrite,
          },
          _active: {
            bg: customColors.yellowBob,
            color: customColors.blackTegrite,
          },
        },
        navWhite: {
          borderColor: customColors.whiteTrue,
          border: '1px solid',
          color: customColors.whiteTrue,
          fontFamily: 'Montserrat',
          fontSize: '11px',
          textTransform: 'uppercase',
          px: '10px',
          height: '27px',
          _hover: {
            bg: customColors.whiteTrue,
            color: customColors.blackTegrite,
          },
          _active: {
            bg: customColors.whiteTrue,
            color: customColors.blackTegrite,
          },
        },
        navGreen: {
          borderColor: customColors.greenToxic,
          border: '1px solid',
          color: customColors.greenToxic,
          fontFamily: 'Montserrat',
          fontSize: '11px',
          textTransform: 'uppercase',
          px: '10px',
          height: '27px',
          _hover: {
            bg: customColors.greenToxic,
            color: customColors.blackTegrite,
          },
          _active: {
            bg: customColors.greenToxic,
            color: customColors.blackTegrite,
          },
        },
        navRed: {
          borderColor: customColors.redRanger,
          border: '1px solid',
          color: customColors.redRanger,
          fontFamily: 'Montserrat',
          fontSize: '11px',
          textTransform: 'uppercase',
          px: '10px',
          height: '27px',
          _hover: {
            bg: customColors.redRanger,
            color: customColors.blackTegrite,
          },
          _active: {
            bg: customColors.redRanger,
            color: customColors.blackTegrite,
          },
        },
        loginHome: {
          bg: customColors.darkStar,
          color: customColors.whiteWalter,
          fontFamily: 'Montserrat',
          fontSize: '13px',
          px: '25px',
          height: '35px',
          _hover: {
            bg: customColors.darkStar,
            color: customColors.yellowBob,
          },
          _active: {
            bg: customColors.darkStar,
            color: customColors.yellowBob,
          },
        },
        navYellowFull: {
          bg: customColors.blackTegrite,
          color: customColors.yellowBob,
          fontFamily: 'Montserrat',
          fontSize: '11px',
          textTransform: 'uppercase',
          px: '10px',
          height: '27px',
          _hover: {
            bg: customColors.blackTegrite,
            color: customColors.whiteTrue,
          },
          _active: {
            bg: customColors.blackTegrite,
            color: customColors.whiteTrue,
          },
        },
        navRedFull: {
          bg: customColors.blackTegrite,
          border: 'none',
          color: customColors.redRanger,
          fontFamily: 'Montserrat',
          fontSize: '11px',
          textTransform: 'uppercase',
          px: '10px',
          height: '27px',
          _hover: {
            bg: customColors.blackTegrite,
            color: customColors.whiteTrue,
          },
          _active: {
            bg: customColors.blackTegrite,
            color: customColors.whiteTrue,
          },
        },
        whiteButton: {
          borderColor: customColors.whiteTrue,
          bg: 'trabsparent',
          border: '1px solid',
          color: customColors.whiteTrue,
          fontFamily: 'Montserrat',
          fontSize: '12px',
          cursor: 'pointer',
          fontWeight: '500',
          px: '10px',
          height: '30px',
          _hover: {
            bg: customColors.blackTegrite,
            color: customColors.yellowBob,
            borderColor: customColors.yellowBob,
          },
        },
        createEvent: {
          borderColor: customColors.whiteTrue,
          bg: customColors.blackTegrite,
          border: '2px solid',
          color: customColors.whiteTrue,
          fontFamily: 'Montserrat',
          fontSize: '12px',
          textTransform: 'uppercase',
          px: '15px',
          _hover: {
            bg: customColors.blackTegrite,
            color: customColors.yellowBob,
            borderColor: customColors.yellowBob,
          },
        },
        ctaYellow: {
          bg: customColors.yellowBob,
          color: customColors.blackTegrite,
          px: '33px',
          py: '21px',
          _hover: {
            bg: customColors.yellowBob,
          },
        },
        'primary-yellow': {
          bg: '#FFCD29',
          color: 'white',
          _hover: {
            bg: '#e5b400',
          },
        },
        'primary-dark': {
          bg: 'darkStar',
          color: 'white',
          fontSize: '22px',
          fontWeight: '400',
          borderRadius: '15px',
          px: '33px',
          py: '17px',
        },
      },
    },
  },
});
export default theme;
