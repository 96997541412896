import React from 'react';
import { Box, Button, Flex, Image as ChakraImage, Text, useBreakpointValue } from '@chakra-ui/react';

import { News } from '../../../types/News';
import { removeMarkdown } from '../../../utils/helpers';
import { APP_URL, isStaff, PUPPETEER_URL, S3_URL } from '../../../utils/constants';
import Ariane from '../../../components/Ariane';
import { useNavigate } from 'react-router-dom';
import { ADMIN_UPDATE_NEWS_PATH } from '../../../routing/constants';
import ShareModalButton from '../../../components/ShareModalButton';
import { customColors } from '../../../theme';
import { formatUri } from '../../../utils/api/helpers';
import { useAppContext } from '../../../context/AppContext';

interface HeaderEventProps {
  news: News;
  isEventList?: boolean;
}

const HeaderNews: React.FC<HeaderEventProps> = ({ news }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const navigate = useNavigate();
  const { user } = useAppContext();

  return (
    <>
      <Box h="250px">
        <Box position="absolute" width="100%" height="250px" ml={-7} mt={-5}>
          {/* Image comme fond d'une Box */}
          <Box as="div" bg={`url(${`${S3_URL}/news/${news.media}`}) center/cover no-repeat`} position="relative" width="100%" height="100%">
            {/* Gradient */}
            <Box position="absolute" top="0" left="0" w="100%" h="100%" bgGradient={`linear(92deg, ${customColors.blackTegrite} 20%, rgba(43, 44, 44, 0) 100%)`} />

            {/* Contenu */}
            <Box position="relative" bottom="4" left="4" py="4" height={isMobile ? 'auto' : 'calc(100% - 8px)'} mr={isMobile ? 0 : 10}>
              <Ariane>
                <Flex justifyContent="space-between" alignItems="center" pt={4}>
                  <Flex alignItems="center">
                    <Flex alignItems="center" ml={isMobile ? 0 : 4}>
                      <ChakraImage src="/assets/icons/yellow.svg" alt="Concours" width="30px" />
                      <Text as="h1" textTransform="uppercase" fontSize="19px" ml="4">
                        Actualités
                      </Text>
                    </Flex>
                  </Flex>
                  {isStaff(user) && (
                    <Flex>
                      <Button variant="navYellow" onClick={() => navigate(formatUri({ slug: news.slug }, ADMIN_UPDATE_NEWS_PATH))}>
                        Modifier
                      </Button>
                    </Flex>
                  )}
                </Flex>
              </Ariane>
              <Box ml={isMobile ? 0 : 6} mt="-1" position={'relative'}>
                <Flex alignItems={'center'} mt="-2">
                  <Text as="h1" fontSize={isMobile ? '21px' : '34px'} letterSpacing={isMobile ? 'auto' : '1.70px'} textTransform="uppercase" fontWeight="bold" color="white">
                    {news.title}
                  </Text>
                </Flex>
                {!isMobile && (
                  <Box mt={4}>
                    <ShareModalButton
                      shareFbUrl={`${PUPPETEER_URL}/news/${news.slug}`}
                      shareTwitterUrl={`${APP_URL}/news/${news.slug}`}
                      title={`[News] ${news.title}`}
                      description={removeMarkdown(news.text)}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box position="absolute" ml={-7} w="100%" top={'140px'} zIndex={1}>
        <Box position="relative" bottom="0" left="0" w="100%" h={'110px'} bgGradient="linear(180deg, rgba(43, 44, 44, 0) 0%, rgba(43, 44, 44, 1) 73%)"></Box>
      </Box>
    </>
  );
};

export default HeaderNews;
