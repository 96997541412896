import React from 'react';
import { useEvents } from '../../../hooks/events/useEvents';
import { Box, Flex, Image, SimpleGrid, Text, useBreakpointValue } from '@chakra-ui/react';
import Ariane from '../../../components/Ariane';
import Events from './events/Events';
import { useLatestContest } from '../../../hooks/contests/useLatestContest';
import Contest from './contests/Contest';
import { useLatestNews } from '../../../hooks/news/useLatestNews';
import News from './news/News';
import { useLastRegisterMembers } from '../../../hooks/users/useLastRegisterMembers';
import LastRegister from './lastRegister/LastRegister';
import RandomText from './randomText/RandomText';
import PageLayout from '../../../layout/PageLayout';
import EventsMobile from './events/EventsMobile';

const HomeAuth: React.FC = () => {
  const { data: events, isLoading: isLoadingEvents } = useEvents({ skip: 0, limit: 4 });
  const { data: contest, isLoading: isLoadingContest } = useLatestContest();
  const { data: news, isLoading: isLoadingNews } = useLatestNews();
  const { data: lastRegister, isLoading: isLoadingRegister } = useLastRegisterMembers();

  const isMobileView = useBreakpointValue({ base: true, md: false });

  return (
    <PageLayout isLoading={isLoadingRegister || isLoadingEvents || isLoadingContest || isLoadingNews}>
      <Ariane>
        <Flex alignItems="center">
          <Image src="/assets/icons/red.svg" alt="Home" width="30px" />
          <Text as="h1" textTransform="uppercase" fontSize="19px" ml={4}>
            Accueil
          </Text>
        </Flex>
      </Ariane>
      <RandomText />
      {isMobileView ? <>{events && <EventsMobile events={events.events} />}</> : <>{events && <Events events={events.events} />}</>}

      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, xl: 3 }} // 1 colonne en base et md, 2 en lg, et 3 en xl et au-delà
        spacing={10}
        mt={isMobileView ? '30px' : '40px'}
        zIndex={1}
      >
        {contest && <Contest contest={contest} />}
        {news && <News news={news} />}
        {lastRegister && (
          <Box
            display={{ base: 'none', lg: 'block' }} // Affiche sur les tailles d'écran lg et plus
          >
            <LastRegister users={lastRegister} />
          </Box>
        )}
      </SimpleGrid>
    </PageLayout>
  );
};

export default HomeAuth;
