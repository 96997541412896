import { useQuery, UseQueryResult } from 'react-query';
import api from '../../utils/api/api';
import { News } from '../../types/News';
import { API_NEWS } from '../../utils/api/constants';

export const useNewsBySlug = (slug: string): UseQueryResult<News, unknown> => {
  return useQuery(['news', slug], async () => {
    const { data } = await api.get(`${API_NEWS}/${slug}`);
    return data;
  });
};
