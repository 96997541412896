import React, { useState } from 'react';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { Box, Button, Flex, Image, Input, InputGroup, InputRightElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure, Text } from '@chakra-ui/react';

interface ShareButtonProps {
  shareFbUrl: string;
  type?: 'icon' | 'button';
  shareTwitterUrl: string;
  title: string;
  description: string;
  hashtag?: string;
  twitterUsername?: string;
}

const ShareModalButton: React.FC<ShareButtonProps> = ({ type = 'button', shareFbUrl, shareTwitterUrl, title, hashtag, twitterUsername }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(shareTwitterUrl);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };
  return (
    <>
      {type === 'icon' && (
        <Flex alignItems="center" onClick={onOpen} cursor="pointer">
          <Image src="/assets/icons/shareIcon.svg" alt="partager" width="27px" height="27px" ml="-2px" />
          <Text ml={2} textDecoration="underline">
            Partager
          </Text>
        </Flex>
      )}
      {type === 'button' && (
        <Button variant="whiteButton" onClick={onOpen}>
          <Flex>
            <Image src="/assets/icons/share.svg" alt="partager" />
            <Text ml={1}>Partager</Text>
          </Flex>
        </Button>
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex alignItems="center" justifyContent="center" mb="4">
              <Box mr={4}>
                <FacebookShareButton url={shareFbUrl} quote={title} hashtag={hashtag}>
                  <Image src="/assets/icons/facebook.svg" w="50px" h="50px" cursor="pointer" />
                </FacebookShareButton>
              </Box>

              <TwitterShareButton url={shareTwitterUrl} title={title} via={twitterUsername}>
                <Image src="/assets/icons/x.svg" w="50px" h="50px" cursor="pointer" />
              </TwitterShareButton>
            </Flex>

            <InputGroup mb={4}>
              <Input value={shareTwitterUrl} isReadOnly />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleCopy}>
                  {isCopied ? 'Copié !' : 'Copier'}
                </Button>
              </InputRightElement>
            </InputGroup>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ShareModalButton;
