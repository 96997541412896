import * as yup from 'yup';

export const contestSchema = yup.object().shape({
  media: yup.mixed().required(),
  name: yup.string().required('Le nom est obligatoire'),
  description: yup.string().required('La description est obligatoire'),
  judgmentCriterion: yup.string().required('Le critère de jugement est obligatoire'),
  participationModalities: yup.string().required('Les modalités de participation sont obligatoire'),
  toWin: yup.string().required('Le lot à gagner est obligatoire'),
  endDate: yup.string().required('La date de début est obligatoire'),
});
