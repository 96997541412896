import React, { useRef, useState, useEffect } from 'react';
import { FormControl, FormLabel, FormErrorMessage, Text, Button, Box, Flex } from '@chakra-ui/react';
import { UseFormRegister, DeepMap, FieldError } from 'react-hook-form';
import ReactMarkdown from 'react-markdown';
import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import Picker from '@emoji-mart/react';
import { useAddMedia } from '../../hooks/media/useAddMedia';

export interface FormTextareaProps {
  label?: string;
  placeholder: string;
  name: string;
  required?: boolean;
  getValues: any;
  setValue: any;
  register: UseFormRegister<any>;
  errors?: DeepMap<any, FieldError>;
  maxLength?: number;
  variant?: any;
}

const FormTextarea: React.FC<FormTextareaProps> = ({ setValue, getValues, label, name, errors, required = false, maxLength }) => {
  const mdEditorRef = useRef(null);
  const pickerRef = useRef(null);
  const [charCount, setCharCount] = useState(0);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [outsideClicked, setOutsideClicked] = useState(false);
  const { mutateAsync: uploadImage } = useAddMedia();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const uploadToImgur = async () => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append('image', selectedFile);

    try {
      const link = await uploadImage({ file: selectedFile, url: 'medias/form/' });

      if (mdEditorRef.current) {
        const editor = mdEditorRef.current;
        const textarea: HTMLTextAreaElement = (editor as any).nodeMdText.current;
        if (textarea) {
          const start = textarea.selectionStart;
          const end = textarea.selectionEnd;
          const text = textarea.value;
          const before = text.substring(0, start);
          const after = text.substring(end, text.length);
          textarea.value = `${before}![Image](${link})${after}`;
          setValue(name, textarea.value, { shouldValidate: true });
        }
      }
    } catch (error) {
      console.error('There was an error uploading the image', error);
    }
  };

  useEffect(() => {
    const initialValue = getValues(name);
    if (initialValue) {
      setValue(name, initialValue, { shouldValidate: true });
    }
  }, [getValues, name, setValue]);

  useEffect(() => {
    if (buttonClicked) {
      setShowEmojiPicker(prev => !prev);
      setButtonClicked(false);
    } else if (outsideClicked) {
      setShowEmojiPicker(false);
      setOutsideClicked(false);
    }

    if (showEmojiPicker) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [buttonClicked, outsideClicked, showEmojiPicker]);

  const handleEditorChange = ({ text }: any) => {
    setCharCount(text.length);
    setValue(name, text, { shouldValidate: true });
  };

  const addEmoji = (emoji: any) => {
    if (mdEditorRef.current) {
      const editor = mdEditorRef.current;
      const textarea: HTMLTextAreaElement = (editor as any).nodeMdText.current;
      if (textarea) {
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        const text = textarea.value;
        const before = text.substring(0, start);
        const after = text.substring(end, text.length);
        textarea.value = before + emoji.native + after;
        textarea.selectionStart = textarea.selectionEnd = start + emoji.native.length;
        setValue(name, textarea.value, { shouldValidate: true });
      }
    }
  };

  const handleButtonClick = () => {
    setButtonClicked(true);
  };

  const handleClickOutside = (event: any) => {
    if (pickerRef.current && !(pickerRef as any).current.contains(event.target)) {
      setOutsideClicked(true);
    }
  };

  useEffect(() => {
    if (selectedFile) {
      uploadToImgur();
    }
  }, [selectedFile]);

  return (
    <FormControl isRequired={required} isInvalid={errors && !!errors[name]}>
      {label && <FormLabel>{label}</FormLabel>}
      <MdEditor
        style={{ height: '400px' }}
        ref={mdEditorRef}
        value={getValues(name) || ''}
        renderHTML={text => <ReactMarkdown>{text}</ReactMarkdown>}
        onChange={handleEditorChange}
        config={{
          view: {
            menu: true,
            md: true,
            html: false,
          },
        }}
      />
      {maxLength && (
        <Text fontSize="sm" textAlign="right" mt={2}>
          {charCount}/{maxLength} caractères
        </Text>
      )}
      <Flex justifyContent="flex-end" alignItems="center" mt={4} mb={4} w="100%">
        <Button variant="navGreen" onClick={handleButtonClick} mr={2}>
          Emoji
        </Button>
        <Box ref={pickerRef}>{showEmojiPicker && <Picker onEmojiSelect={addEmoji as any} />}</Box>
        <input type="file" id="fileInput" style={{ display: 'none' }} onChange={(e: any) => setSelectedFile(e.target.files ? e.target.files[0] : null)} />
        <Button type="button" variant="navGrey" onClick={() => document.getElementById('fileInput')?.click()}>
          Upload Image
        </Button>
      </Flex>

      <FormErrorMessage>{errors && errors[name] && errors[name].message}</FormErrorMessage>
    </FormControl>
  );
};

export default FormTextarea;
