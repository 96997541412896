import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { customScrollBar } from '../../../utils/helpers';

interface ScrollableBoxWithFadeProps {
  children: React.ReactNode;
}

const ScrollableBoxWithFadeMobile: React.FC<ScrollableBoxWithFadeProps> = ({ children }) => {
  const [scrollTop, setScrollTop] = useState(0);
  const scrollContainerRef = useRef<any>(null);

  const onScroll = () => {
    if (scrollContainerRef.current) {
      setScrollTop(scrollContainerRef.current.scrollTop);
    }
  };

  useEffect(() => {
    const scrollContainerCurrent = scrollContainerRef.current;
    if (scrollContainerCurrent) {
      setScrollTop(scrollContainerCurrent.scrollTop);
    }
  }, []);

  const fadeStyle = {
    transition: 'opacity 0.3s ease-in-out', // Transition douce pour l'opacité
  };

  return (
    <Box width="full" height="auto" position="relative" overflow="hidden">
      <Box ref={scrollContainerRef} onScroll={onScroll} css={customScrollBar} height="100%" overflowY="auto">
        {children}
      </Box>
      {/* Gradient flou en haut avec transition */}
      <Box
        position="absolute"
        top="0"
        left="0"
        right="0"
        height="30px"
        background="linear-gradient(to bottom, rgba(43, 44, 44, 0.8), transparent)"
        pointerEvents="none"
        opacity={scrollTop > 0 ? 1 : 0}
        style={fadeStyle} // Appliquer la transition ici
      />
      {/* Gradient flou en bas avec transition */}
      <Box
        position="absolute"
        bottom="0"
        left="0"
        right="0"
        height="30px"
        background="linear-gradient(to top, rgba(43, 44, 44, 0.8), transparent)"
        pointerEvents="none"
        opacity={scrollTop < scrollContainerRef.current?.scrollHeight - scrollContainerRef.current?.clientHeight ? 1 : 0}
        style={fadeStyle} // Appliquer la transition ici
      />
    </Box>
  );
};

export default ScrollableBoxWithFadeMobile;
