import React from 'react';
import { Box, Flex, Image, useBreakpointValue } from '@chakra-ui/react';
import { HOME_PATH } from '../../../routing/constants';
import Linker from '../../../components/Linker';
import CookiesPopup from '../../../pages/misc/CookiesPopup';
import { css, Global } from '@emotion/react';

const GlobalStyles: React.FC = () => {
  return (
    <Global
      styles={css`
        body {
          font-size: 16px;
          background-color: #2b2c2c;
        }
      `}
    />
  );
};

interface TemplateLayoutProps {
  children: React.ReactNode;
  media: string;
}

const FormLayout: React.FC<TemplateLayoutProps> = ({ children, media }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Box bg="blackTegrite" w="100%" h={isMobile ? 'auto' : '100vh'} justifyContent="center" alignItems="center">
      <GlobalStyles />
      <Box position={{ base: 'static', md: 'absolute' }} top={{ base: 'initial', md: '3' }} left={{ base: 'initial', md: '3' }}>
        <Linker to={HOME_PATH}>
          <Image src={`/assets/logos/logo-white.svg`} align="center" ml={6} mt={6} maxH="60px" />
        </Linker>
      </Box>
      <Flex h="100%" direction={{ base: 'column', md: 'row' }}>
        <Flex flex="8" direction="row" display={{ md: 'flex' }}>
          <Box flex="1" display={{ md: 'block' }} />
          <Flex flex="4" alignItems="center" justifyContent="center">
            {children}
          </Flex>
          <Box flex="1" display={{ md: 'block' }} />
        </Flex>
        <Box flex="4" display={{ base: 'none', md: 'block' }} position="relative">
          <Image src={media} alt="cover" objectFit="cover" w="100%" h="100%" position="absolute" top="0" left="0" />
        </Box>
      </Flex>
      <CookiesPopup />
    </Box>
  );
};

export default FormLayout;
