import { Box, Text, Flex, Image, Badge } from '@chakra-ui/react';
import React from 'react';
import { useDiscordStats } from '../../hooks/discord/useDiscordStats';
import { customColors } from '../../theme';

const DiscordStats: React.FC = () => {
  const { data } = useDiscordStats();
  const handleClick = () => {
    const url = '//discord.com/invite/0VJbJI6szxQG87LO';

    window.open(url, '_blank');
  };

  return (
    <Box color={'white'} p={4} bgColor="brand.block" borderRadius={21} overflow="hidden" mb={4} cursor="pointer" onClick={handleClick}>
      <Flex alignItems="center">
        <Image src={`/assets/icons/eg.svg`} alt="Discord" mr={2} width="50px" />
        <Box ml={2}>
          <Text fontSize="15px" fontWeight="bold">
            Discord Entre-Geeks
          </Text>
          <Box fontSize="14px" mt={-1}>
            <Badge bg={customColors.greenFrog} p={1} borderRadius="full" /> {data?.online} En ligne
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default DiscordStats;
