import { useQuery, UseQueryResult } from 'react-query';
import api from '../../utils/api/api';
import { MESSAGES_CONVERSATIONS } from '../../routing/constants';
import { Conversation } from '../../types/Message';
import { QueryMessagesParams } from '../../pages/messages/constants';

interface UserConversationsResponse {
  conversations: Conversation[];
  total: number;
}

export const useGetUserConversations = (options: QueryMessagesParams): UseQueryResult<UserConversationsResponse, unknown> => {
  // Destructure options with default values to ensure they are not undefined
  const { skip = 0, limit = 6 } = options;

  return useQuery(
    ['conversations', skip, limit],
    async () => {
      const queryParams = new URLSearchParams();

      queryParams.append('skip', String(skip));
      queryParams.append('limit', String(limit));

      const { data } = await api.get<UserConversationsResponse>(`${MESSAGES_CONVERSATIONS}?${queryParams.toString()}`);
      return data;
    },
    {
      refetchOnMount: true,
    },
  );
};
