import { useQuery, UseQueryResult } from 'react-query';
import api from '../../utils/api/api';
import { API_USERS_INSCRIPTION_LAST_7DAYS } from '../../utils/api/constants';

export const fetchInscriptionCountLastSevenDays = async (): Promise<any> => {
  const response = await api.get(API_USERS_INSCRIPTION_LAST_7DAYS);
  return response.data;
};

export const useInscriptionsLast7Days = (): UseQueryResult<any, unknown> => {
  return useQuery('inscriptionsLast7Days', fetchInscriptionCountLastSevenDays, {
    retry: 0,
  });
};
