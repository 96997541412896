import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Grid, GridItem, SimpleGrid, Text, useMediaQuery } from '@chakra-ui/react';
import { useContests } from '../../../hooks/contests/useContests';
import { formatUri } from '../../../utils/api/helpers';
import { CONTESTS_DETAILS_PATH } from '../../../routing/constants';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CardImageWithBox from '../../../components/card/CardImageWithBox';
import { S3_URL } from '../../../utils/constants';
import { formatDateRangeFormatted } from '../../../utils/dates';
import PageLayout from '../../../layout/PageLayout';
import { QueryContestParams } from '../constantes';
import PageTitleUpdater from '../../../routing/PageTitleUpdater';
import HeaderContest from '../HeaderContest';
import { useActiveContests } from '../../../hooks/contests/useActiveContests';
import { Contest } from '../../../types/Contests';
import { getContestContestColor, getContestTypeString, renderLinesDate } from '../../../utils/helpers';
import { useContestBySlug } from '../../../hooks/contests/useContestBySlug';
import Winner from './winner/Winner';
import WinnerLotterie from './winner/WinnerLotterie';

const ContestsList: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const skip = searchParams.get('skip') ? parseInt(searchParams.get('skip')!) : 0;
  const { data: dataSlug } = useContestBySlug(searchParams.get('name') || '');
  const [queryParams, setQueryParams] = useState<QueryContestParams>({
    skip,
    limit: 3,
  });
  const [headerContest, setHeaderContest] = useState<Contest | null>(null);
  const { data: contestsActive, isLoading: isLoadingHeader, isError: isErrorHeader, error: errorHeader } = useActiveContests();
  const { data, isLoading, refetch } = useContests(queryParams);

  const hasPrevious = queryParams.skip > 0;
  const hasNext = queryParams.skip + queryParams.limit < (data?.total || 0);

  useEffect(() => {
    const newQueryParams = {
      skip: searchParams.get('skip') ? parseInt(searchParams.get('skip') as string) : 0,
      limit: 3,
    };
    if (JSON.stringify(newQueryParams) !== JSON.stringify(queryParams)) {
      setQueryParams(newQueryParams);
      refetch();
    }
  }, [searchParams, queryParams, refetch]);

  const updateSkip = (skip: number) => {
    const newSearchParams = new URLSearchParams();
    newSearchParams.set('skip', skip.toString());
    setSearchParams(newSearchParams);
  };

  function getRandomContest(contests: Contest[]): void {
    if (contests.length === 0 && data && data.contests.length !== 0) {
      setHeaderContest(data.contests[0]);
    }

    const randomIndex = Math.floor(Math.random() * contests.length);
    setHeaderContest(contests[randomIndex]);
  }

  useEffect(() => {
    if (dataSlug) {
      setHeaderContest(dataSlug);
      return;
    }
    if (contestsActive && !headerContest) {
      getRandomContest(contestsActive);
    }
  }, [contestsActive, dataSlug]);

  const getContentInCard = (contest: Contest) => {
    const currentDate = new Date();
    const endDateTime = new Date(contest?.endDate || '');
    const isEnded = currentDate > endDateTime;
    if (!isEnded) {
      return <>{renderLinesDate(formatDateRangeFormatted(contest.createdAt, contest.endDate, true))}</>;
    }

    if (isEnded && (!contest.winner || contest.winner?.length === 0) && contest.winnerDiscord?.length === 0) {
      return (
        <Text as="h1" color="blueTardis" fontSize="16px">
          DELIBERATION
        </Text>
      );
    }

    return (
      <Text as="h1" color="greenToxic" fontSize="16px">
        TERMINER
      </Text>
    );
  };

  const currentDate = new Date();
  const endDateTime = new Date(headerContest?.endDate || '');
  const isEnded = currentDate > endDateTime;

  const [isLessThan1020] = useMediaQuery('(max-width: 1020px)');
  const gridTemplateColumns = isLessThan1020 ? '1fr' : '1fr 0.5fr 1fr';

  return (
    <Box>
      <PageTitleUpdater additionalTitle="Concours" />
      <PageLayout isLoading={isLoadingHeader} isError={isErrorHeader} error={errorHeader}>
        {headerContest && <HeaderContest contest={headerContest} isList />}
        <Box minH="222px" mt={-4} height={isLessThan1020 ? 'auto' : '222px'} zIndex="1">
          {isEnded && headerContest && (headerContest?.winner.length !== 0 || headerContest?.winnerDiscord.length !== 0) ? (
            <>{headerContest.type === '1' ? <WinnerLotterie contest={headerContest} /> : <Winner contest={headerContest} />}</>
          ) : (
            <Grid templateColumns={gridTemplateColumns} zIndex="1" position="relative">
              <Grid>
                <>
                  <Box>
                    <Text as="h1" fontSize="16px" mb={2} color="yellowBob">
                      Critère de jugement
                    </Text>
                    <Text fontSize="13px">{headerContest?.judgmentCriterion}</Text>
                  </Box>
                  <Box mt={4}>
                    <Text as="h1" fontSize="16px" mb={2} color="yellowBob">
                      A gagner
                    </Text>
                    <Text fontSize="13px">{headerContest?.toWin}</Text>
                  </Box>
                </>
              </Grid>
              <Grid />
              <Grid>
                <Box mt={isLessThan1020 ? 4 : 0}>
                  <Text as="h1" fontSize="16px" mb={2} color="yellowBob">
                    Modalités de participation
                  </Text>
                  <Text fontSize="13px">{headerContest?.participationModalities}</Text>
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>
      </PageLayout>
      <Grid mb={4} mt={2}>
        <GridItem colSpan={2}>
          <Flex alignItems="center" justifyContent="right" mr={2}>
            <Button isDisabled={!hasPrevious} variant="navYellow" onClick={() => updateSkip(queryParams.skip - queryParams.limit)} mr={2}>
              Précédent
            </Button>
            <Button isDisabled={!hasNext} variant="navYellow" onClick={() => updateSkip(queryParams.skip + queryParams.limit)} mr={2}>
              Suivant
            </Button>
          </Flex>
        </GridItem>
      </Grid>
      <PageLayout isLoading={isLoading}>
        <SimpleGrid columns={[1, 2, 3]} spacing={5}>
          {data &&
            data.contests.map(contest => {
              const truncatedTitle = contest.name.length > 30 ? contest.name.slice(0, 30) + '...' : contest.name;

              return (
                <Box mb={4} key={contest.id} position="relative" onClick={() => navigate(formatUri({ slug: contest.slug }, CONTESTS_DETAILS_PATH))} cursor="pointer">
                  <CardImageWithBox
                    imageName={contest.name}
                    image={`${S3_URL}/contests/${contest.media}`}
                    contentInCard={getContentInCard(contest)}
                    contentRight={
                      <Text mr={-2} mt={1} color={getContestContestColor(contest.type)}>
                        {getContestTypeString(contest.type)}
                      </Text>
                    }
                    contentFooter={
                      <>
                        <Flex justifyContent="space-between" flexDirection={'row'} alignItems="center" h="100%" my={1}>
                          <>
                            <Text as="h2" fontSize={'13px'}>
                              {truncatedTitle}
                            </Text>
                          </>
                        </Flex>
                      </>
                    }
                  />
                </Box>
              );
            })}
        </SimpleGrid>
      </PageLayout>
    </Box>
  );
};

export default ContestsList;
