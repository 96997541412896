import React from 'react';
import { Box, Container, Flex, useMediaQuery } from '@chakra-ui/react';
import Title from './Title';
import SubTitle from './SubTitle';
import ActionButton from './ActionButton';
import MediaLottie from './MediaLottie';
import Lottie from 'react-lottie';
import animationData from '../lottieFiles/arrow.json';
import MenuHomeNoAuthContent from '../../../../layout/noAuth/MenuHomeContent';

interface CoversProps {
  currentIndex: number;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number | null>>;
  opacity: number;
}

const Covers: React.FC<CoversProps> = ({ currentIndex, setCurrentIndex, opacity }) => {
  const [isLessThan1160] = useMediaQuery('(max-width: 1160px)');

  return (
    <Box zIndex="1" position="relative" bg="blackTegrite">
      <Container maxW="container.2xl">
        <Flex direction={isLessThan1160 ? 'column' : 'row'} height={isLessThan1160 ? 'auto' : '100vh'} justifyContent="center" alignItems="center" position="relative">
          {isLessThan1160 && (
            <Flex mt={'100px'}>
              <MenuHomeNoAuthContent currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />
            </Flex>
          )}
          {!isLessThan1160 && (
            <>
              <Box width="100px" height="2px" bgColor="yellowBob" position="absolute" top="20%" left={20} />
              <Box width="100px" height="2px" bgColor="yellowBob" position="absolute" bottom="20%" left={20} />
            </>
          )}
          <Flex
            mb={isLessThan1160 ? '70px' : 0}
            mt={isLessThan1160 ? '30px' : 0}
            flex="1"
            justifyContent="flex-start"
            alignItems="center"
            alignContent="center"
            maxW={isLessThan1160 ? 'auto' : '750px'} // Responsive max width
            position="relative"
            px={{ base: 4, md: 20 }} // Responsive padding
            style={{
              flexWrap: 'wrap',
              flexDirection: 'column',
              opacity: opacity,
              transition: 'opacity 0.5s ease-in-out',
            }}
          >
            <Title currentIndex={currentIndex} />
            <SubTitle currentIndex={currentIndex} />
            <ActionButton currentIndex={currentIndex} />
          </Flex>

          <Flex
            flex="1"
            justifyContent="center"
            alignItems="center"
            maxW={isLessThan1160 ? 'auto' : '750px'}
            mb={isLessThan1160 ? '70px' : 0}
            mt={isLessThan1160 ? '-20px' : 0}
            style={{
              opacity: opacity,
              transition: 'opacity 0.5s ease-in-out',
            }}
          >
            <MediaLottie currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />
          </Flex>
        </Flex>
        {!isLessThan1160 && (
          <Box position="absolute" bottom="0" left="50%" transform="translateX(-50%)" style={{ maxWidth: '100px', maxHeight: '100px' }}>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              isStopped={false}
              speed={0.8}
            />
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default Covers;
