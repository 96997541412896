import React from 'react';
import { Text, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, ModalCloseButton, Box, SimpleGrid, Divider, Flex } from '@chakra-ui/react';
import { EventParticipant } from '../../../types/Events';
import UserStringAvatar from '../../../components/StringAvatar';
import { formatUri } from '../../../utils/api/helpers';
import { PROFILE_PATH } from '../../../routing/constants';
import { useNavigate } from 'react-router-dom';

interface ParticipantsModalProps {
  isOpen: boolean;
  onClose: () => void;
  eventParticipant?: EventParticipant[];
}

const ParticipantsModal: React.FC<ParticipantsModalProps> = ({ eventParticipant, isOpen, onClose }) => {
  const navigate = useNavigate();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Participants</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          {eventParticipant && (
            <Box>
              <Divider />
              <SimpleGrid columns={2} spacing={10} mt={2}>
                {eventParticipant.map(participant => (
                  <Flex alignItems="center" key={participant.id} cursor="pointer" onClick={() => navigate(formatUri({ username: participant.username }, PROFILE_PATH))}>
                    <UserStringAvatar withBigBorder name={participant.username} media={participant.media} />
                    <Text ml="2">{participant.username}</Text>
                  </Flex>
                ))}
              </SimpleGrid>
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ParticipantsModal;
