import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import PageTitleUpdater from '../../../routing/PageTitleUpdater';
import { Box, Button } from '@chakra-ui/react';
import FormInput from '../../../components/forms/FormInput';
import { BotMessageForm } from './constants';
import FormTextarea from '../../../components/forms/FormTextarea';
import { botMessageSchema } from './yup/botMessage';
import { useBotMessage } from '../../../hooks/discord/useBotMessage';

export const BotMessage: React.FC = () => {
  const mutation = useBotMessage();
  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm<BotMessageForm>({
    resolver: yupResolver(botMessageSchema),
  });

  const onSubmit = async (data: BotMessageForm) => {
    try {
      await mutation.mutateAsync(data);
      toast.success('Message envoyé avec succès', {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (error: any) {
      toast.error(`Échec d'envoi du message : ${error.response.data.message}`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PageTitleUpdater additionalTitle="Admin Panel - Message bot discord" />
      <Box mt={4}>
        <FormInput variant="whiteBorderNoBg" label={'Channel Id'} placeholder="Channel Id" type="text" name="channelId" required register={register} errors={errors} />
      </Box>
      <Box mt={4}>
        <FormTextarea variant="whiteBorderNoBg" label={'Message'} placeholder="Message" name="message" required register={register} errors={errors} getValues={getValues} setValue={setValue} />
      </Box>
      <Box my={4} textAlign="right">
        <Button variant="navYellow" type="submit" isLoading={mutation.isLoading} isDisabled={!isValid}>
          Enregistrer
        </Button>
      </Box>
    </form>
  );
};
