import { useMutation, useQueryClient, UseMutationResult } from 'react-query';
import api from '../../utils/api/api';
import { API_EVENTS } from '../../utils/api/constants';
import { Event } from '../../types/Events';
import { EventCreateForm } from '../../pages/events/create/constants';

export const useUpdateEvent = (): UseMutationResult<Event, Error, { id: string; data: EventCreateForm }, unknown> => {
  const queryClient = useQueryClient();

  return useMutation<Event, Error, { id: string; data: EventCreateForm }>(
    ({ id, data }) =>
      api
        .put(`${API_EVENTS}/${id}/update`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res => res.data),
    {
      onSuccess: () => {
        queryClient.refetchQueries('event');
        queryClient.refetchQueries('events');
        queryClient.refetchQueries('invalidEvents');
      },
    },
  );
};
