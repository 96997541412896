import React from 'react';

import { useParams } from 'react-router-dom';
import PageTitleUpdater from '../../../routing/PageTitleUpdater';
import { useContestBySlug } from '../../../hooks/contests/useContestBySlug';
import { Box, Flex, Grid, Text, useBreakpointValue } from '@chakra-ui/react';
import HeaderContest from '../HeaderContest';
import ContestParticipationButton from '../list/ContestParticipationButton';
import { formatTextWithLineBreaks } from '../../../utils/helpers';
import Participation from './Participation/Participation';
import WinnerLotterie from './winner/WinnerLotterie';
import WinnerMedia from './winner/WinnerMedia';
import WinnerText from './winner/WinnerText';
import PageLayout from '../../../layout/PageLayout';

type ParamTypes = {
  slug: string;
};
const Contest: React.FC = () => {
  const { slug } = useParams<ParamTypes>();
  const { data, isLoading, isError, error } = useContestBySlug(slug || '');
  const [participationClick, setParticipationClick] = React.useState(false);

  const isMobile = useBreakpointValue({ base: true, md: false });
  const gridTemplateColumns = isMobile ? '1fr' : ['1.5fr 1fr 2fr'];

  const currentDate = new Date();
  const endDateTime = new Date(data?.endDate || '');
  const isEnded = currentDate > endDateTime;

  return (
    <PageLayout isLoading={isLoading} isError={isError} error={error}>
      {data && (
        <>
          <PageTitleUpdater additionalTitle={`Concours - ${data.name}`} />
          <HeaderContest contest={data} />

          {!participationClick ? (
            <>
              {isEnded && (data.winner.length !== 0 || data.winnerDiscord.length !== 0) ? (
                <Box>
                  {data.type === '1' && <WinnerLotterie contest={data} />}
                  {data.type === '2' && <WinnerText contest={data} />}
                  {data.type === '3' && <WinnerMedia contest={data} />}
                </Box>
              ) : (
                <Grid templateColumns={gridTemplateColumns} gap={4} px={2} mt={'-30px'} h={isMobile ? 'auto' : 'calc(100vh - 450px)'} mb={isMobile ? 6 : 0} zIndex={1} position={'relative'}>
                  <Box h={'100%'}>
                    <Flex justifyContent="space-evenly" h="100%" direction="column">
                      <Box>
                        <Text as="h1" fontSize="16px" mb={2} color="yellowBob">
                          Modalités de participation
                        </Text>
                        <Text fontSize="13px">{formatTextWithLineBreaks(data.participationModalities)}</Text>
                      </Box>
                      <Box>
                        <Text as="h1" fontSize="16px" mb={2} color="yellowBob">
                          Critère de jugement
                        </Text>
                        <Text fontSize="13px">{formatTextWithLineBreaks(data.judgmentCriterion)}</Text>
                      </Box>
                      <Box>
                        <Text as="h1" fontSize="16px" mb={2} color="yellowBob">
                          A gagner
                        </Text>
                        <Text fontSize="13px">{formatTextWithLineBreaks(data.toWin)}</Text>
                      </Box>
                    </Flex>
                  </Box>
                  <Box />
                  <Box>
                    <ContestParticipationButton contest={data} setParticipationClick={setParticipationClick} />
                  </Box>
                </Grid>
              )}
            </>
          ) : (
            <Participation contest={data} setParticipationClick={setParticipationClick} />
          )}
        </>
      )}
    </PageLayout>
  );
};

export default Contest;
