import React, { useEffect, useState } from 'react';
import { Box, Flex, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { Event } from '../../../../types/Events';
import { formatingDate } from '../../../../utils/dates';
import { S3_URL } from '../../../../utils/constants';
import { capitalizeFirstLetter, getRandomNumber, getTypeEventColor, getTypeString, isUrl } from '../../../../utils/helpers';
import { customColors } from '../../../../theme';
import { formatUri } from '../../../../utils/api/helpers';
import { EVENTS_CREATE_PATH, EVENTS_DETAIL_PATH } from '../../../../routing/constants';
import { useNavigate } from 'react-router-dom';
import UserStringAvatar from '../../../../components/StringAvatar';
import Linker from '../../../../components/Linker';
import ImageLoader from '../../../../components/ImageLoader';
import ImageLoaderWithGlitch from '../../../../components/ImageLoaderWithGlitch';

interface EventCarouselProps {
  events: Event[];
}

const Events: React.FC<EventCarouselProps> = ({ events }) => {
  const [isDisplay, setIsDisplay] = useState(window.innerWidth >= 940);

  useEffect(() => {
    const handleResize = () => {
      setIsDisplay(window.innerWidth >= 940);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const navigate = useNavigate();
  return (
    <Box>
      <Text as="h1" mb={2}>
        Prochains événements
      </Text>
      <Flex direction={{ base: 'column', md: 'row' }} alignItems="start">
        <Table variant="unstyled" size="sm" mb={{ base: 4, md: 0 }}>
          <Thead>
            <Tr pb={2}>
              <Th textTransform="none">
                <Text>Organisateur</Text>
              </Th>
              <Th textTransform="none">
                <Text>Événement</Text>
              </Th>
              <Th textTransform="none">
                <Text>Date</Text>
              </Th>
              <Th textTransform="none">
                <Text>Participants</Text>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {events.slice(0, 4).map((event, index) => {
              const shuffledParticipants = [...event.participants].sort(() => 0.5 - Math.random());
              const extraParticipantsCount = event.participants.length - 3;
              const borderBottomStyle = index === events.length - 1 ? '' : `1px solid ${customColors.greyChupacabra}`;

              return (
                <Tr
                  key={`event-${event.id}`}
                  borderBottom={borderBottomStyle}
                  onClick={() => navigate(formatUri({ slug: event.slug, type: getTypeString(event.entityType) }, EVENTS_DETAIL_PATH))}
                  cursor="pointer"
                >
                  <Td>
                    <Flex alignItems="center">
                      <UserStringAvatar withBigBorder name={event.userId.username} media={event.userId.media} styles={{ size: 'md' }} />
                      <Text ml={2}>{event.userId.username}</Text>
                    </Flex>
                  </Td>
                  <Td>
                    <Flex alignItems="center">
                      <ImageLoader
                        src={isUrl(event.image) ? event.image : `${S3_URL}/events/${event.image}`}
                        imageName="Événement"
                        styles={{ h: '40px', w: '85px', objectFit: 'cover', borderRadius: '8px' }}
                      />
                      <Box ml={2}>
                        <Text color={getTypeEventColor(event.entityType)}> {capitalizeFirstLetter(getTypeString(event.entityType, true))}</Text>
                        <Text>{event.name}</Text>
                      </Box>
                    </Flex>
                  </Td>
                  <Td>
                    <Text>{formatingDate(event.scheduledStartTime)}</Text>
                  </Td>
                  <Td>
                    <Flex alignItems="center">
                      {shuffledParticipants.slice(0, 3).map((participant, index) => (
                        <UserStringAvatar
                          withTooltip
                          key={participant.id}
                          name={participant.username}
                          media={participant.media}
                          styles={{ size: 'md', marginRight: index !== event.participants.length - 1 ? '-30px' : '0' }}
                          withBorder={true}
                        />
                      ))}
                      {extraParticipantsCount > 0 && <Text ml={2}>+{extraParticipantsCount}</Text>}
                    </Flex>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <Box mt={{ base: 4, md: 0 }} ml={{ base: 0, md: 5 }} style={{ display: isDisplay ? 'block' : 'none', position: 'relative' }} borderRadius={21}>
          <Linker to={EVENTS_CREATE_PATH}>
            <ImageLoaderWithGlitch src={`/assets/event-home-button-${getRandomNumber(1, 4)}.png`} imageName="Crée ton événement" styles={{ minW: '216px', maxH: '330px' }} />
          </Linker>
        </Box>
      </Flex>
    </Box>
  );
};

export default Events;
