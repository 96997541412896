import { User, UserPublic } from '../types/user';
import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Image as ChakraUi, Text } from '@chakra-ui/react';
import { defaultCoverImage, isUrl } from '../utils/helpers';
import { S3_URL } from '../utils/constants';
import UserAvatar from './UserAvatar';
import { customColors } from '../theme';
import { getAge } from '../utils/dates';
import { formatUri } from '../utils/api/helpers';
import { MESSAGES_CONVERSATIONS, PROFILE_PATH } from '../routing/constants';
import { useNavigate } from 'react-router-dom';

interface UserWithCoverCardProps {
  user: User | UserPublic;
  height?: string;
  gradiant?: string;
  overideContent?: JSX.Element;
}

export const UserWithCoverCard: React.FC<UserWithCoverCardProps> = ({ overideContent, user, height = '120px', gradiant = `linear(90deg, rgba(43, 44, 44, 0.9) 29%, rgba(43, 44, 44, 0) 90%)` }) => {
  const { mediaCover } = user;
  const navigate = useNavigate();
  const [isValidImageCover, setIsValidImageCover] = useState(true);

  useEffect(() => {
    const imageCover = new Image();
    imageCover.onload = () => setIsValidImageCover(true);
    imageCover.onerror = () => setIsValidImageCover(false);
    // Ici, vous pouvez ajouter une logique supplémentaire pour savoir quelle URL vérifier
    imageCover.src = mediaCover && isUrl(mediaCover) ? mediaCover : `${S3_URL}/users/covers/${mediaCover}`;
  }, [mediaCover]);

  const handleIconClick = (e: any, path: string) => {
    e.stopPropagation();
    navigate(path);
  };

  return (
    <Box
      as="div"
      bg={`url(${isValidImageCover ? (mediaCover && isUrl(mediaCover) ? mediaCover : `${S3_URL}/users/covers/${mediaCover}`) : defaultCoverImage}) center/cover no-repeat`}
      position="relative"
      width="100%"
      borderRadius={21}
      height={height}
      cursor="pointer"
      _hover={{ transform: 'translateY(-4px)', boxShadow: 'lg' }}
      onClick={() => navigate(formatUri({ username: user.username }, PROFILE_PATH))}
      transition="transform 0.2s, box-shadow 0.2s"
    >
      <Box borderRadius={21} position="absolute" top="0" left="-1px" w="100%" h="100%" bgGradient={gradiant} />
      <Flex alignItems="center">
        <Flex flexDirection="column" h={height}>
          <Button variant="buttonCoverCard" onClick={e => handleIconClick(e, formatUri({ username: user.username }, PROFILE_PATH))}>
            <ChakraUi src="/assets/icons/eye.svg" alt={'Profile'} />
          </Button>

          <Button
            _hover={{ zIndex: 'popover', boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.5)', transform: 'scale(1.05)' }}
            variant="buttonCoverCard"
            borderRadius={'0 0 0 21px'}
            onClick={e => handleIconClick(e, `${MESSAGES_CONVERSATIONS}?username=${user.username}`)}
          >
            <ChakraUi src="/assets/icons/chat.svg" alt={'Profile'} />
          </Button>
        </Flex>
        <Flex position="relative" alignItems="center" height="100%" ml={4}>
          <Box mr={10}>
            <Flex align="left">
              <Box>
                <UserAvatar withBorder user={user} styles={{ size: 'lg' }} />
              </Box>
              <Box color={customColors.whiteWalter} ml={4}>
                <Text as="h2">{user.username}</Text>
                <Text fontWeight="bold">{getAge(user.bornDate)} ans</Text>
                {overideContent ? (
                  overideContent
                ) : (
                  <Text fontWeight="bold">
                    {user.location.country} {user.location.city ? `, ${user.location.city}` : null}
                  </Text>
                )}
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export default UserWithCoverCard;
