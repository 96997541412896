import React, { useState } from 'react';
import { Box, Image } from '@chakra-ui/react';
import { defaultImage } from '../../utils/helpers';

interface EventImageProps {
  image: string;
  imageName: string;
  fullRadius?: boolean;
}

const ImageCard: React.FC<EventImageProps> = ({ image, imageName, fullRadius = false }) => {
  const [opacity, setOpacity] = useState(0);

  const handleImageLoaded = () => {
    setOpacity(1);
  };

  const handleImageError = (e: any) => {
    e.target.src = defaultImage;
  };

  return (
    <Box
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        borderRadius: fullRadius ? '21px' : '21px 21px 0 0',
      }}
    >
      <Image
        borderRadius={fullRadius ? '21px' : '21px 21px 0 0'}
        src={image}
        alt={imageName}
        objectFit="cover"
        w="100%"
        h="100%"
        onError={handleImageError}
        onLoad={handleImageLoaded}
        style={{
          opacity: opacity,
          transition: 'opacity 0.3s ease-in-out',
        }}
      />
    </Box>
  );
};

export default ImageCard;
