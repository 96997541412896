import React from 'react';
import { Box, Button, Flex, Grid, Text, useBreakpointValue, useMediaQuery } from '@chakra-ui/react';
import { User } from '../../../types/user';
import { useUpdateUser } from '../../../hooks/users/useUpdateUser';
import { useAppContext } from '../../../context/AppContext';
import ColorPicker from '../../../components/forms/ColorPicker';
import AffiliateDiscord from '../affiliateDiscord/AffiliateDiscord';
import { AiOutlineSound } from 'react-icons/ai';
import { customColors } from '../../../theme';
import { IoMdNotificationsOutline } from 'react-icons/io';

interface ParameterTabProps {
  user: User;
}

const Accessibility: React.FC<ParameterTabProps> = ({ user }) => {
  const { setUser } = useAppContext();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const updateUser = useUpdateUser();
  const [isLessThan768] = useMediaQuery('(max-width: 952px)');
  const templateColumns = !isLessThan768 ? '1fr 3fr' : '1fr';

  const updateMessageSound = async () => {
    if (user) {
      const update = await updateUser.mutateAsync({ id: user.id, data: { isSoundMessage: !user.isSoundMessage } });
      setUser(update);
    }
  };

  const updateVisitSound = async () => {
    if (user) {
      const update = await updateUser.mutateAsync({ id: user.id, data: { isSoundVisit: !user.isSoundVisit } });
      setUser(update);
    }
  };

  const updateNotificationMobile = async () => {
    if (user) {
      const update = await updateUser.mutateAsync({ id: user.id, data: { notificationMobile: !user.notificationMobile } });
      setUser(update);
    }
  };

  return (
    <Box w={'100%'} display="flex" flexDirection="column" mt={-4} h={isMobile ? 'auto' : 'calc(100vh - 500px)'} justifyContent="space-between">
      <Grid templateColumns={templateColumns} gap={4} mt={4} flex={1}>
        <Box>
          <Text fontWeight="bold" color="yellowBob">
            Volumes
          </Text>
        </Box>
        <Box>
          <Box>
            <Text>Désactivez le bruit ambiant, d’une taverne animée. 🍺🎲</Text>
            <Flex>
              <Box mt={4}>
                <Text>Son des messages</Text>
                <Flex alignItems="center" verticalAlign="center" mt={2}>
                  <Button p={4} mt={2} variant={user.isSoundMessage ? 'navGreen' : 'navRed'} onClick={updateMessageSound}>
                    {user.isSoundMessage ? 'Activer' : 'Désactiver'}
                  </Button>
                  <AiOutlineSound size={30} style={{ marginLeft: '5px', marginTop: '6px' }} color={user.isSoundMessage ? customColors.greenToxic : customColors.redRanger} />
                </Flex>
              </Box>
              <Box mt={4} ml={10}>
                <Text>Son des visites</Text>
                <Flex alignItems="center" verticalAlign="center" mt={2}>
                  <Button p={4} mt={2} variant={user.isSoundVisit ? 'navGreen' : 'navRed'} onClick={updateVisitSound}>
                    {user.isSoundVisit ? 'Activer' : 'Désactiver'}
                  </Button>
                  <AiOutlineSound size={30} style={{ marginLeft: '5px', marginTop: '6px' }} color={user.isSoundVisit ? customColors.greenToxic : customColors.redRanger} />
                </Flex>
              </Box>
            </Flex>
          </Box>
        </Box>
      </Grid>

      <Grid templateColumns={templateColumns} gap={4} mt={10} flex={1}>
        <Box>
          <Text fontWeight="bold" color="yellowBob">
            Notifications mobile
          </Text>
        </Box>
        <Box>
          <Text>Notifications messages et visites</Text>
          <Flex alignItems="center" verticalAlign="center" mt={2}>
            <Button p={4} mt={2} variant={user.notificationMobile ? 'navGreen' : 'navRed'} onClick={updateNotificationMobile}>
              {user.notificationMobile ? 'Activer' : 'Désactiver'}
            </Button>
            <IoMdNotificationsOutline size={30} style={{ marginLeft: '5px', marginTop: '6px' }} color={user.notificationMobile ? customColors.greenToxic : customColors.redRanger} />
          </Flex>
        </Box>
      </Grid>

      <Grid templateColumns={templateColumns} gap={4} mt={10} flex={1}>
        <Box>
          <Text fontWeight="bold" color="yellowBob">
            Interface
          </Text>
        </Box>
        <Box>
          <Box>
            <Text>Changer la couleur de fond</Text>
            <Box mt={4}>
              <ColorPicker user={user} />
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid templateColumns={templateColumns} gap={4} mt={10} flex={1}>
        <Box>
          <Text fontWeight="bold" color="yellowBob">
            Discord
          </Text>
        </Box>
        <Box>
          <AffiliateDiscord withoutSpacing user={user} />
        </Box>
      </Grid>
    </Box>
  );
};

export default Accessibility;
