import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { useMessages } from '../../../hooks/discord/forum/useMessages';
import { Message } from '../../../types/Discord';
import MessageCard from '../../forum/messages/MessageCard';
import SendDiscordMessageSimple from '../../../components/sendDiscordMessage/SendDiscordMessageSimple';

interface NewCommentsProps {
  threadId: string;
}

const NewComments: React.FC<NewCommentsProps> = ({ threadId }) => {
  const { data, fetchNextPage, hasNextPage, isLoading } = useMessages(threadId);
  const observer = useRef<IntersectionObserver | null>(null);
  const lastMessageElementRef = useRef<HTMLDivElement | null>(null);
  const [hasFirstLoadCompleted, setHasFirstLoadCompleted] = useState(false);

  useEffect(() => {
    if (isLoading) return;

    if (!hasFirstLoadCompleted && data?.pages[0]?.length > 0) {
      setHasFirstLoadCompleted(true);
    }

    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasNextPage) {
        fetchNextPage();
      }
    });

    if (lastMessageElementRef.current) {
      observer.current.observe(lastMessageElementRef.current);
    }
  }, [isLoading, hasNextPage, data]);

  let messageCount = 0;

  return (
    <Box>
      <SendDiscordMessageSimple channelId={threadId} />
      {isLoading && <p>Loading...</p>}
      {!isLoading &&
        hasFirstLoadCompleted &&
        data?.pages.map((page, i) => {
          const messagesToRender = page.slice(0, -2);
          messageCount += messagesToRender.length;

          return (
            <Box key={`comments-news-${i}`}>
              {messagesToRender.map((message: Message) => (
                <MessageCard key={message.id} message={message} />
              ))}
            </Box>
          );
        })}
      {hasFirstLoadCompleted && messageCount === 0 && <p>Pas de messages dans ce fil.</p>}
      <Box ref={lastMessageElementRef}></Box>
    </Box>
  );
};

export default NewComments;
