import React, { useState } from 'react';
import { Contest } from '../../../../types/Contests';
import { Box, Button, Flex, useMediaQuery } from '@chakra-ui/react';
import HeaderStep from './HeaderStep';
import SendMedia from './SendMedia';
import { useUploadContestImage } from '../../../../hooks/contests/useUploadContestImage';
import { useUploadContestPDF } from '../../../../hooks/contests/useUploadContestPDF';
import SendPDF from './SendPDF';

interface Step2Props {
  contest: Contest;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}
const Step2: React.FC<Step2Props> = ({ contest, setStep }) => {
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const { mutate: uploadImage } = useUploadContestImage();

  const [title, setTitle] = useState<string | null>(null);
  const [selectedPDF, setSelectedPDF] = useState<File | null>(null);
  const { mutate: uploadPDF } = useUploadContestPDF();
  const [isLessThan1020] = useMediaQuery('(max-width: 1600px)');
  const [isLessThan837] = useMediaQuery('(max-width: 837px)');

  const sendMediaForm = async () => {
    if (selectedImage) {
      await uploadImage({ file: selectedImage, contestId: contest.id });
      setStep(3);
    }
  };

  const sendPDF = async () => {
    if (selectedPDF && title) {
      await uploadPDF({ file: selectedPDF, contestId: contest.id, title });
      setStep(3);
    }
  };

  return (
    <>
      {!isLessThan1020 && (
        <Flex justifyContent="center" alignItems="end">
          <Button variant="navYellow" onClick={() => setStep(1)}>
            Etape precedente
          </Button>
        </Flex>
      )}
      <Box mt={4}>
        {!isLessThan837 && <HeaderStep step={2} />}
        {contest.type === '2' && <SendPDF contest={contest} setTitle={setTitle} selectedPDF={selectedPDF} setSelectedPDF={setSelectedPDF} />}
        {contest.type === '3' && <SendMedia contest={contest} setSelectedImage={setSelectedImage} selectedImage={selectedImage} />}
      </Box>
      <Flex justifyContent={isLessThan1020 ? 'space-between' : 'center'} alignItems="end">
        {isLessThan1020 && (
          <Flex justifyContent="center" alignItems="end">
            <Button variant="navYellow" onClick={() => setStep(1)}>
              Etape precedente
            </Button>
          </Flex>
        )}
        {contest.type === '2' && (
          <Button variant="navGreen" isDisabled={!selectedPDF || !title} onClick={() => sendPDF()}>
            Confirmer {!isLessThan1020 && 'ma participation'}
          </Button>
        )}
        {contest.type === '3' && (
          <Button variant="navGreen" isDisabled={!selectedImage} onClick={() => sendMediaForm()}>
            Confirmer ma participation
          </Button>
        )}
      </Flex>
    </>
  );
};
export default Step2;
