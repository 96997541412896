import React from 'react';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Text } from '@chakra-ui/react';
import MarkDownStyled from '../../components/MarkDownStyled';
import { account, community, contests, discord, events, news, stream } from './constants';
import { customColors } from '../../theme';

const FaqContent: React.FC = () => {
  return (
    <Box>
      <Text as="h1" mb={4}>
        Foire aux questions
      </Text>
      <Accordion>
        <AccordionItem _first={{ borderTop: 'none' }}>
          <h2>
            <AccordionButton _expanded={{ bg: customColors.yellowBob, color: customColors.blackTegrite, fontWeight: 'bold' }}>
              <Box as="span" flex="1" my={3} textAlign="left">
                Compte
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <MarkDownStyled text={account} />
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton _expanded={{ bg: customColors.yellowBob, color: customColors.blackTegrite, fontWeight: 'bold' }}>
              <Box as="span" flex="1" my={3} textAlign="left">
                Evènements
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <MarkDownStyled text={events} />
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton _expanded={{ bg: customColors.yellowBob, color: customColors.blackTegrite, fontWeight: 'bold' }}>
              <Box as="span" flex="1" my={3} textAlign="left">
                Communauté
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <MarkDownStyled text={community} />
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton _expanded={{ bg: customColors.yellowBob, color: customColors.blackTegrite, fontWeight: 'bold' }}>
              <Box as="span" flex="1" my={3} textAlign="left">
                Discord
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <MarkDownStyled text={discord} />
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton _expanded={{ bg: customColors.yellowBob, color: customColors.blackTegrite, fontWeight: 'bold' }}>
              <Box as="span" flex="1" my={3} textAlign="left">
                Stream
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <MarkDownStyled text={stream} />
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton _expanded={{ bg: customColors.yellowBob, color: customColors.blackTegrite, fontWeight: 'bold' }}>
              <Box as="span" flex="1" my={3} textAlign="left">
                Concours
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <MarkDownStyled text={contests} />
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem _last={{ borderBottom: 'none' }}>
          <h2>
            <AccordionButton _expanded={{ bg: customColors.yellowBob, color: customColors.blackTegrite }}>
              <Box as="span" flex="1" my={3} textAlign="left">
                Actualités
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <MarkDownStyled text={news} />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default FaqContent;
