import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useUserStats } from '../../../hooks/events/useUserStats';
import PageLayout from '../../../layout/PageLayout';

interface StatsProps {
  userId: string;
}

const Stats: React.FC<StatsProps> = ({ userId }) => {
  const { data, isLoading } = useUserStats(userId);
  return (
    <PageLayout isLoading={isLoading}>
      <Flex justifyContent="space-between" flexDirection="column" alignItems="center" height="100%">
        <Box textAlign="center">
          <Text textStyle="barlowCondensed" fontSize="16px" fontWeight="bold">
            Nombre sortie crées
          </Text>
          <Box position="relative" display="inline-block" w="100%">
            <Box position="absolute" top={0} left={0} right={0} bottom={0} bgImage="url('/assets/profile/stat1.svg')" bgPosition="center" bgRepeat="no-repeat" bgSize="67px 72px" />
            <Text
              textStyle="barlowCondensed"
              mt={'-28px'}
              mb={'-22px'}
              fontSize="89px"
              color="redLanterne"
              fontWeight="bold"
              position="relative" // Nécessaire pour que le texte s'affiche par-dessus le fond
            >
              {data?.outingCreation}
            </Text>
          </Box>
        </Box>
        <Box textAlign="center">
          <Text textStyle="barlowCondensed" fontSize="16px" fontWeight="bold">
            Nombre game crées
          </Text>
          <Box position="relative" display="inline-block" w="100%">
            <Box position="absolute" top={0} left={0} right={0} bottom={0} bgImage="url('/assets/profile/stat2.svg')" bgPosition="center" bgRepeat="no-repeat" bgSize="67px 72px" />
            <Text
              textStyle="barlowCondensed"
              mt={'-30px'}
              mb={'-25px'}
              fontSize="89px"
              color="greenFrog"
              fontWeight="bold"
              position="relative" // Nécessaire pour que le texte s'affiche par-dessus le fond
            >
              {data?.gameCreation}
            </Text>
          </Box>
        </Box>
        <Box textAlign="center">
          <Text textStyle="barlowCondensed" fontSize="16px" fontWeight="bold">
            Participation sorties
          </Text>
          <Box position="relative" display="inline-block" w="100%">
            <Box position="absolute" top={0} left={0} right={0} bottom={0} bgImage="url('/assets/profile/stat3.svg')" bgPosition="center" bgRepeat="no-repeat" bgSize="82px 78px" />
            <Text
              textStyle="barlowCondensed"
              mt={'-28px'}
              mb={'-22px'}
              fontSize="89px"
              color="blueTardis"
              fontWeight="bold"
              position="relative" // Nécessaire pour que le texte s'affiche par-dessus le fond
            >
              {data?.outingParticipant}
            </Text>
          </Box>
        </Box>
        <Box textAlign="center">
          <Text textStyle="barlowCondensed" fontSize="16px" fontWeight="bold">
            Participation games
          </Text>
          <Box position="relative" display="inline-block" w="100%">
            <Box position="absolute" top={0} left={0} right={0} bottom={0} bgImage="url('/assets/profile/stat4.svg')" bgPosition="center" bgRepeat="no-repeat" bgSize="100px 82px" />
            <Text
              textStyle="barlowCondensed"
              mt={'-28px'}
              mb={'-22px'}
              fontSize="89px"
              color="lemonBritney"
              fontWeight="bold"
              position="relative" // Nécessaire pour que le texte s'affiche par-dessus le fond
            >
              {data?.gameParticipant}
            </Text>
          </Box>
        </Box>
      </Flex>
    </PageLayout>
  );
};

export default Stats;
