import React from 'react';
import { Box, Flex, Grid, GridItem, HStack, Text, useBreakpointValue, VStack } from '@chakra-ui/react';
import { formatUri } from '../../../utils/api/helpers';
import { THREAD_MESSAGES_PATH } from '../../../routing/constants';
import { useNavigate } from 'react-router-dom';
import PageLayout from '../../../layout/PageLayout';
import { formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';
import UserStringAvatar from '../../../components/StringAvatar';
import { Thread } from '../../../types/Discord';
import { capitalizeFirstLetter } from '../../../utils/helpers';

interface ThreadCardProps {
  threads: Thread[];
  isLoading: boolean;
}

const ThreadCard: React.FC<ThreadCardProps> = ({ threads, isLoading }) => {
  const navigate = useNavigate();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const gridTemplateColumns = isMobile ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)';

  return (
    <PageLayout margin={200} isLoading={isLoading}>
      <Grid templateColumns={gridTemplateColumns} gap={4} mb={isMobile ? 6 : 0}>
        {threads.map(channel => {
          const isTruncated = channel.name.length > 40;
          const truncatedTitle = isTruncated ? channel.name.slice(0, 40) + '...' : channel.name;

          return (
            <GridItem key={channel.id} w="100%" _hover={{ transform: 'translateY(-4px)', boxShadow: 'lg' }} transition="transform 0.2s, box-shadow 0.2s" borderRadius={21}>
              <Flex
                bg="whiteTegrite"
                overflow="hidden"
                p={4}
                w="100%"
                borderRadius={21}
                align="center"
                justify="space-between"
                cursor="pointer"
                onClick={() => navigate(formatUri({ channelId: channel.id }, THREAD_MESSAGES_PATH))}
              >
                <HStack spacing={4}>
                  <VStack align="start">
                    <Text as="h2" fontSize={'16px'}>
                      {capitalizeFirstLetter(truncatedTitle)}
                    </Text>
                    {channel.latestThread && (
                      <Box mt={1}>
                        <Text color="gray.500">
                          Dernier message {channel.latestThread.lastMessageDate && `- ${formatDistanceToNow(new Date(channel.latestThread.lastMessageDate), { addSuffix: true, locale: fr })}`}
                        </Text>
                        {channel.latestThread && (
                          <Box>
                            {channel.latestThread.author ? (
                              <Flex align="center" justify="left" h="full" mt={1}>
                                <UserStringAvatar withBigBorder media={channel.latestThread.author.avatar || ''} name={channel.latestThread.author.username} styles={{ size: 'sm' }} />
                                <Box ml={3}>
                                  <Text fontSize="11px">{channel.latestThread.author.username}</Text>
                                </Box>
                              </Flex>
                            ) : (
                              <Text mt={1}>{capitalizeFirstLetter(truncatedTitle)}</Text>
                            )}
                          </Box>
                        )}
                      </Box>
                    )}
                  </VStack>
                </HStack>
              </Flex>
            </GridItem>
          );
        })}
      </Grid>
    </PageLayout>
  );
};

export default ThreadCard;
