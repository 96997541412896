import React from 'react';

import { useContestBySlug } from '../../../../hooks/contests/useContestBySlug';
import { useParams } from 'react-router-dom';
import PageLayout from '../../../../layout/PageLayout';
import ContestDraw from './ContestDraw';
import ContestDrawMedia from './ContestDrawMedia';
import ContestDrawScript from './ContestDrawScript';

type ParamTypes = {
  slug: string;
};

const ContestSetWinner: React.FC = () => {
  const { slug } = useParams<ParamTypes>();
  const { data: contest, isLoading } = useContestBySlug(slug || '');

  return (
    <PageLayout isLoading={isLoading}>
      {contest && <>{contest.type === '1' && <ContestDraw contest={contest} />}</>}
      {contest && <>{contest.type === '2' && <ContestDrawScript contest={contest} />}</>}
      {contest && <>{contest.type === '3' && <ContestDrawMedia contest={contest} />}</>}
    </PageLayout>
  );
};

export default ContestSetWinner;
