import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import api from '../../utils/api/api';
import { API_GAME_ADD_ADMIN } from '../../utils/api/constants';
import { GameAddForm } from '../../pages/admin/games/constants';

export const useAddGames = (): UseMutationResult<any, Error, GameAddForm, unknown> => {
  const queryClient = useQueryClient();

  return useMutation<any, Error, GameAddForm>(
    (newGame: GameAddForm) =>
      api
        .post(API_GAME_ADD_ADMIN, newGame, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res => res.data),
    {
      onSuccess: () => {
        queryClient.refetchQueries('adminDiscordRoles');
      },
    },
  );
};
