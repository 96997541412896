import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import axios from 'axios';
import { DISCORD_CLIENT_ID, DISCORD_CLIENT_SECRET, DISCORD_REDIRECT_URI } from '../../utils/constants';

type UseDiscordAuth = { discordAuthMutation: UseMutationResult<{ user: any; token: any; refreshToken: any }, unknown, string, unknown>; openDiscordPopup: () => void };

export const useDiscordAuth = (): UseDiscordAuth => {
  const queryClient = useQueryClient();

  const discordAuthMutation = useMutation(
    async (code: string) => {
      const data = new URLSearchParams();
      data.append('client_id', DISCORD_CLIENT_ID);
      data.append('client_secret', DISCORD_CLIENT_SECRET);
      data.append('grant_type', 'authorization_code');
      data.append('code', code);
      data.append('redirect_uri', DISCORD_REDIRECT_URI);

      const response = await axios.post('https://discord.com/api/oauth2/token', data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      const { access_token, refresh_token } = response.data;

      const userResponse = await axios.get('https://discord.com/api/users/@me', {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });

      return {
        user: userResponse.data,
        token: access_token,
        refreshToken: refresh_token,
      };
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('someQueryKey');
      },
    },
  );

  const openDiscordPopup = (): void => {
    const discordLoginUrl = `https://discord.com/api/oauth2/authorize?client_id=${DISCORD_CLIENT_ID}&redirect_uri=${encodeURIComponent(
      DISCORD_REDIRECT_URI,
    )}&response_type=code&scope=identify%20email`;
    window.open(discordLoginUrl, 'discordAuthPopup', 'width=500,height=800');
    const listener = (event: MessageEvent): void => {
      if (event.origin !== window.location.origin) return;

      const { code } = event.data;
      if (code) {
        discordAuthMutation.mutate(code);
        window.removeEventListener('message', listener);
      }
    };

    window.addEventListener('message', listener);
  };

  return { discordAuthMutation, openDiscordPopup };
};
