import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Avatar, Box, Button, Image, Text } from '@chakra-ui/react';
import { UserUpdateYupSchema } from './yup/UserUpdateYupSchema';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { User } from '../../../../types/user';
import { useUpdateUser } from '../../../../hooks/users/useUpdateUser';
import { useAppContext } from '../../../../context/AppContext';
import { S3_URL } from '../../../../utils/constants';
import { UserAdminUpdateFormType } from '../../../userProfile/update/constants';
import { formatUri } from '../../../../utils/api/helpers';
import { PROFILE_PATH } from '../../../../routing/constants';
import FormImageUpload from '../../../../components/forms/formImageUpload/FormImageUpload';
import CityAutocomplete from '../../../../components/forms/CityAutocomplete';
import FormTextarea from '../../../../components/forms/FormTextarea';
import FormInput from '../../../../components/forms/FormInput';
import { useUserByUsername } from '../../../../hooks/users/useUserByUsername';
import { convertToBase64 } from '../../../../utils/helpers';
import { useDeleteUserMedia } from '../../../../hooks/users/useDeleteUserMedia';

interface UserUpdateFormProps {
  usernameParam: string;
}

const UserUpdateForm: React.FC<UserUpdateFormProps> = ({ usernameParam }) => {
  const { data: user, isLoading, isError } = useUserByUsername(usernameParam);
  if (isLoading) return <Text>Loading...</Text>;
  if (isError || !user) return <Text>Error loading user</Text>;

  return <Form user={user} />;
};

interface FormProps {
  user: User;
}

const Form: React.FC<FormProps> = ({ user }) => {
  const updateUser = useUpdateUser();
  const navigate = useNavigate();
  const { setUser } = useAppContext();
  const { mutate: deleteMedia } = useDeleteUserMedia();
  const [currentAvatar, setCurrentAvatar] = React.useState<string | undefined>(user.media ? `${S3_URL}/users/${user.media}` : undefined);
  const [currentCover, setCurrentCover] = React.useState<string | undefined>(user.mediaCover ? `${S3_URL}/users/covers/${user.mediaCover}` : undefined);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    trigger,
    getValues,
    watch,
  } = useForm<UserAdminUpdateFormType>({
    resolver: yupResolver(UserUpdateYupSchema),
    defaultValues: {
      location: {
        country: user.location.country,
        city: user.location.city,
        department: user.location.department,
        region: user.location.region,
        formattedAddress: user.location.formattedAddress,
      },
      media: user.media,
      userProfile: {
        gamesId: user.userProfile.gamesId,
        socialNetworks: user.userProfile.socialNetworks,
        site: user.userProfile.site,
        presentation: user.userProfile.presentation,
      },
    },
    mode: 'onChange',
  });
  const onSubmit: SubmitHandler<UserAdminUpdateFormType> = async data => {
    try {
      const newUser = await updateUser.mutateAsync({ id: user.id, data });
      setUser(newUser);
      toast.success('Profile mis à jour avec succès', {
        position: toast.POSITION.TOP_CENTER,
      });
      navigate(formatUri({ username: user.username }, PROFILE_PATH));
      window.scrollTo(0, 0);
    } catch (error) {
      toast.error("Une erreur s'est produite lors de la mise à jour du profile", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const watchedMedia = watch('media');
  useEffect(() => {
    if (watchedMedia && watchedMedia instanceof File) {
      trigger('media');
      convertToBase64(watchedMedia).then(base64 => {
        setCurrentAvatar(base64 as string);
      });
    }
  }, [watchedMedia]);

  const watchedMediaCover = watch('mediaCover');
  useEffect(() => {
    if (watchedMediaCover && watchedMediaCover instanceof File) {
      trigger('mediaCover');
      convertToBase64(watchedMediaCover).then(base64 => {
        setCurrentCover(base64 as string);
      });
    }
  }, [watchedMediaCover]);

  const handleDeleteMedia = (type: 'media' | 'cover') => {
    deleteMedia({ id: user.id, type });
    if (type === 'media') {
      setCurrentAvatar(undefined);
    } else {
      setCurrentCover(undefined);
    }
    toast.success('Image supprimé', {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  console.log(isValid, errors);
  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      <Box p={8} borderWidth={1} mb={4} borderRadius="md">
        {currentCover && <Image alt={user.username} src={currentCover} />}
        {user.mediaCover && (
          <Box cursor="pointer" onClick={() => handleDeleteMedia('cover')}>
            Supprimer l'image de couverture
          </Box>
        )}
        <FormImageUpload placeholder="Cover" name="mediaCover" cropHeight={266} cropWidth={1077} register={register} setValue={setValue} getValues={getValues} errors={errors} />
      </Box>
      <Box p={8} borderWidth={1} mb={4} borderRadius="md">
        {currentAvatar && <Avatar name={user.username} src={currentAvatar} size="xl" />}
        {user.media && (
          <Box cursor="pointer" onClick={() => handleDeleteMedia('media')}>
            Supprimer l'avatar
          </Box>
        )}
        <FormImageUpload placeholder="Avatar" name="media" cropHeight={300} cropWidth={300} register={register} setValue={setValue} getValues={getValues} errors={errors} />
      </Box>
      <Box p={8} borderWidth={1} mb={4} borderRadius="md">
        <CityAutocomplete label="Ville" placeholder="Ville" name="location" required setValue={setValue} getValues={getValues} register={register} errors={errors} />

        <FormTextarea label="Présentation" placeholder="Présentation" name="userProfile.presentation" maxLength={2000} errors={errors} register={register} getValues={getValues} setValue={setValue} />
      </Box>
      <Box p={8} borderWidth={1} mb={4} borderRadius="md">
        <FormInput label="Xbox ID" placeholder="Xbox ID" type="text" name="userProfile.gamesId.xbox" register={register} errors={errors} />
        <FormInput label="PSN ID" placeholder="PSN ID" type="text" name="userProfile.gamesId.psn" register={register} errors={errors} />
        <FormInput label="Steam ID" placeholder="Steam ID" type="text" name="userProfile.gamesId.steam" register={register} errors={errors} />
        <FormInput label="Nintendo ID" placeholder="Nintendo ID" type="text" name="userProfile.gamesId.nintendo" register={register} errors={errors} />
        <FormInput label="Blizzard" placeholder="Blizzard" type="text" name="userProfile.gamesId.blizzard" register={register} errors={errors} />
        <FormInput label="Riot" placeholder="Riot" type="text" name="userProfile.gamesId.leagueOfLegend" register={register} errors={errors} />
        <FormInput label="Origin" placeholder="Origin" type="text" name="userProfile.gamesId.origin" register={register} errors={errors} />
      </Box>
      <Box p={8} borderWidth={1} mb={4} borderRadius="md">
        <FormInput label="Site" placeholder="Site" type="text" name="userProfile.site" register={register} errors={errors} />
        <FormInput label="Instagram" placeholder="Instagram" type="text" name="userProfile.socialNetworks.instagram" register={register} errors={errors} />
        <FormInput label="X" placeholder="X" type="text" name="userProfile.socialNetworks.x" register={register} errors={errors} />
        <FormInput label="Youtube" placeholder="Youtube" type="text" name="userProfile.socialNetworks.youtube" register={register} errors={errors} />
        <FormInput label="Pinterest" placeholder="Pinterest" type="text" name="userProfile.socialNetworks.pinterest" register={register} errors={errors} />
        <FormInput label="Tiktok" placeholder="Tiktok" type="text" name="userProfile.socialNetworks.tiktok" register={register} errors={errors} />
      </Box>
      <Button type="submit" colorScheme="blue" mt={4} isLoading={updateUser.isLoading} isDisabled={!isValid}>
        Update
      </Button>
    </Box>
  );
};

export default UserUpdateForm;
