import { useMutation, UseMutationResult } from 'react-query';
import api from '../../utils/api/api';
import { API_MEDIA_ADD } from '../../utils/api/constants';

interface UploadFormData {
  file: File;
  url: string;
}

export const useAddMedia = (): UseMutationResult<void, Error, UploadFormData, unknown> => {
  return useMutation<void, Error, UploadFormData>(({ file, url }) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('url', url);

    return api
      .post(API_MEDIA_ADD, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(res => res.data);
  });
};
