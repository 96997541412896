import React from 'react';
import { IconButton, Td, Tr } from '@chakra-ui/react';
import { DiscordRole } from '../../../types/DiscordRole';
import { formatUri } from '../../../utils/api/helpers';
import { ADMIN_DISCORD_ROLE_CREATE_PATH, ADMIN_DISCORD_ROLE_EDIT_PATH } from '../../../routing/constants';
import { AddIcon, EditIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';

interface DiscordRolesTableProps {
  discordRole: DiscordRole;
}

const DiscordRolesTable: React.FC<DiscordRolesTableProps> = ({ discordRole }) => {
  const navigate = useNavigate();
  return (
    <Tr>
      <Td>{discordRole.id}</Td>
      <Td>{discordRole.name}</Td>
      <Td>{discordRole.roleId}</Td>
      <Td>{discordRole.emoji}</Td>
      <Td>
        <IconButton ml={4} aria-label="Ajouter" icon={<AddIcon />} onClick={() => navigate(ADMIN_DISCORD_ROLE_CREATE_PATH)} />
        <IconButton ml={4} aria-label="Modifier" icon={<EditIcon />} onClick={() => navigate(formatUri({ id: discordRole.id }, ADMIN_DISCORD_ROLE_EDIT_PATH))} />
      </Td>
    </Tr>
  );
};

export default DiscordRolesTable;
