export const cleanMarkdown = (markdownText: string): string => {
  let markdown = markdownText;
  markdown = markdown.replaceAll('[img]', '').replaceAll('[/img]', '');
  markdown = markdown.replaceAll('[url]', '').replaceAll('[/url]', '');
  markdown = markdown.replaceAll('[quote]', '').replaceAll('[/quote]', '');
  markdown = markdown.replaceAll('[list]', '').replaceAll('[/list]', '');
  markdown = markdown.replaceAll('[*]', '').replaceAll('[/*]', '');
  markdown = markdown.replaceAll('[youtube]', '').replaceAll('[/youtube]', '');
  markdown = markdown.replaceAll('[edit]', '').replaceAll('[/edit]', '');
  markdown = markdown.replaceAll('[bar]', '').replaceAll('[/bar]', '');
  markdown = markdown.replaceAll('[sup]', '').replaceAll('[/sup]', '');
  markdown = markdown.replaceAll('[sub]', '').replaceAll('[/sub]', '');
  markdown = markdown.replaceAll('[list=1]', '').replaceAll('[/list]', '');
  markdown = markdown.replaceAll('[size=1]', '').replaceAll('[/size]', '');
  markdown = markdown.replaceAll('[color=1]', '').replaceAll('[/color]', '');
  markdown = markdown.replaceAll('[font=1]', '').replaceAll('[/font]', '');
  markdown = markdown.replaceAll('[spoil]', '').replaceAll('[/spoil]', '');
  markdown = markdown.replaceAll('[attention]', '').replaceAll('[/attention]', '');
  markdown = markdown.replaceAll('[info]', '').replaceAll('[/info]', '');
  markdown = markdown.replaceAll('[question]', '').replaceAll('[/question]', '');
  markdown = markdown.replaceAll('[erreur]', '').replaceAll('[/erreur]', '');
  markdown = markdown.replaceAll('[dailymotion]', '').replaceAll('[/dailymotion]', '');
  markdown = markdown.replaceAll('[img_g]', '').replaceAll('[/img_g]', '');
  markdown = markdown.replaceAll('[img_d]', '').replaceAll('[/img_d]', '');
  markdown = markdown.replaceAll('[left]', '').replaceAll('[/left]', '');
  markdown = markdown.replaceAll('[center]', '').replaceAll('[/center]', '');
  markdown = markdown.replaceAll('[right]', '').replaceAll('[/right]', '');
  markdown = markdown.replaceAll('[color=red]', '').replaceAll('[/color]', '');
  markdown = markdown.replaceAll('[color=blue]', '').replaceAll('[/color]', '');
  markdown = markdown.replaceAll('[color=green]', '').replaceAll('[/color]', '');
  markdown = markdown.replaceAll('[color=pink]', '').replaceAll('[/color]', '');
  markdown = markdown.replaceAll('[color=orange]', '').replaceAll('[/color]', '');
  markdown = markdown.replaceAll('[secret]', '').replaceAll('[/secret]', '');
  markdown = markdown.replaceAll('[liste]', '').replaceAll('[/liste]', '');
  markdown = markdown.replaceAll('[puce]', '').replaceAll('[/puce]', '');
  markdown = markdown.replaceAll('[taille valeur=1]', '').replaceAll('[/taille]', '');
  markdown = markdown.replaceAll('[citation nom=1]', '').replaceAll('[/citation]', '');
  markdown = markdown.replaceAll('[size=2]', '').replaceAll('[size=1]', '').replaceAll('[size=3]', '');
  markdown = markdown.replaceAll('[font=Trebuchet MS]', '');
  markdown = markdown.replaceAll('[font=Arial]', '');
  markdown = markdown.replaceAll('[size=4]', '');

  return markdown;
};
