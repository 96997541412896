import React, { useEffect, useState } from 'react';
import { Button, Flex, Grid, GridItem, Image, Text, useBreakpointValue } from '@chakra-ui/react';
import { useFetchThreadsByChannelId } from '../../../hooks/discord/forum/useFetchThreadsByChannelId';
import { formatUri } from '../../../utils/api/helpers';
import { THREAD_CREATE_PATH } from '../../../routing/constants';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PageTitleUpdater from '../../../routing/PageTitleUpdater';
import Ariane from '../../../components/Ariane';
import { QueryContestParams } from '../../contest/constantes';
import ThreadCard from './ThreadCard';
import { blockedChannelId, capitalizeFirstLetter } from '../../../utils/helpers';
import PageLayout from '../../../layout/PageLayout';

interface ThreadByChannelIdCardProps {
  channelId: string;
}

const ThreadByChannelIdCard: React.FC<ThreadByChannelIdCardProps> = ({ channelId }) => {
  const navigate = useNavigate();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [searchParams, setSearchParams] = useSearchParams();
  const [channelName, setChannelName] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const skip = searchParams.get('skip') ? parseInt(searchParams.get('skip')!) : 0;
  const [queryParams, setQueryParams] = useState<QueryContestParams>({
    skip,
    limit: 8,
  });
  const { data, isLoading, refetch, isError, error } = useFetchThreadsByChannelId(channelId, queryParams);

  useEffect(() => {
    const newQueryParams = {
      skip: searchParams.get('skip') ? parseInt(searchParams.get('skip') as string) : 0,
      limit: 8,
    };

    setQueryParams(newQueryParams);
    refetch();
  }, [searchParams]);

  const hasPrevious = queryParams.skip > 0;
  const hasNext = queryParams.skip + queryParams.limit < (data?.total || 0);

  const updateSkip = (skip: number) => {
    const newSearchParams = new URLSearchParams();
    newSearchParams.set('skip', skip.toString());
    setSearchParams(newSearchParams);
  };

  useEffect(() => {
    if (data && data.threads.length > 0) {
      setChannelName(data.threads[0].channelName as string);
    }
  }, [data]);

  return (
    <PageLayout isError={isError} error={error} isLoading={isLoading}>
      {data && <PageTitleUpdater additionalTitle={`Communauté - Channel - ${capitalizeFirstLetter(channelName)}`} />}
      <Ariane>
        <Flex alignItems="center">
          <Image src="/assets/icons/blue.svg" alt="Communauté" width="30px" />
          <Text as="h1" textTransform="uppercase" fontSize="19px" ml={4}>
            Communauté - Channel {channelName && `- ${capitalizeFirstLetter(channelName)}`}
          </Text>
        </Flex>
      </Ariane>
      <Grid mb={4} mt={isMobile ? 0 : 20}>
        <GridItem colSpan={2}>
          <Flex alignItems="center" justifyContent="space-between">
            {!blockedChannelId.includes(channelId) && (
              <Button variant="navYellow" onClick={() => navigate(formatUri({ channelId }, THREAD_CREATE_PATH))} mr={2}>
                Créer un thread
              </Button>
            )}
            <Flex alignItems="center" justifyContent="right">
              <Button isDisabled={!hasPrevious} variant="navYellow" onClick={() => updateSkip(queryParams.skip - queryParams.limit)} mr={2}>
                Précédent
              </Button>
              <Button isDisabled={!hasNext} variant="navYellow" onClick={() => updateSkip(queryParams.skip + queryParams.limit)} mr={2}>
                Suivant
              </Button>
            </Flex>
          </Flex>
        </GridItem>
      </Grid>
      <ThreadCard threads={data?.threads || []} isLoading={isLoading} />
    </PageLayout>
  );
};

export default ThreadByChannelIdCard;
