import { useQuery, UseQueryResult } from 'react-query';
import api from '../../utils/api/api';
import { News } from '../../types/News';
import { API_NEWS_LATEST } from '../../utils/api/constants';

export const useLatestNews = (): UseQueryResult<News, unknown> => {
  return useQuery('latestNews', async () => {
    const { data } = await api.get(API_NEWS_LATEST);
    return data;
  });
};
