import { useQuery, UseQueryResult } from 'react-query';
import { Thread } from '../../../types/Discord';
import api from '../../../utils/api/api';
import { API_DISCORD_THREAD_BY_CHANNEL_ID } from '../../../utils/api/constants';
import { QueryThreadParams } from '../../../pages/forum/threads/constants';

interface ThreadsByChannelIdResponse {
  threads: Thread[];
  total: number;
}

export const useFetchThreadsByChannelId = (channelId: string, options: QueryThreadParams): UseQueryResult<ThreadsByChannelIdResponse, unknown> => {
  // Destructure options with default values to ensure they are not undefined
  const { skip = 0, limit = 6 } = options;

  return useQuery(
    ['threadsByChannelId', channelId, skip, limit],
    async () => {
      const queryParams = new URLSearchParams();

      if (skip) queryParams.append('skip', String(skip));
      if (limit) queryParams.append('limit', String(limit));

      const { data } = await api.get<ThreadsByChannelIdResponse>(`${API_DISCORD_THREAD_BY_CHANNEL_ID}/${channelId}?${queryParams.toString()}`);
      return data;
    },
    {
      retry: 0,
      staleTime: 0,
      cacheTime: 0,
      refetchOnMount: true,
    },
  );
};
