import React, { useState } from 'react';
import { Box, Table, Thead, Tr, Th, Tbody, Td, IconButton, Button, useDisclosure } from '@chakra-ui/react';
import { AiFillEye } from 'react-icons/ai';
import { formatUri } from '../../../../utils/api/helpers';
import { PROFILE_PATH, ADMIN_PROFILE_UPDATE_PATH } from '../../../../routing/constants';
import { DeleteIcon, EditIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { RoleSimple, RoleType, User } from '../../../../types/user';
import { useNavigate } from 'react-router-dom';
import UpdateRoleModal from './modals/UpdateRoleModal';
import { useUpdateUser } from '../../../../hooks/users/useUpdateUser';
import { UserUpdateFormType } from '../../../userProfile/update/constants';
import { toast } from 'react-toastify';
import { useDeleteUser } from '../../../../hooks/users/useDeleteUser';
import { useAppContext } from '../../../../context/AppContext';
import { isAdmin } from '../../../../utils/constants';
import DeleteAlertDialog from '../../../../components/dialogAlert/DeleteAlertDialog';

interface UsersTableProps {
  users: User[];
  total: number;
  goToNextPage: () => void;
  goToPrevPage: () => void;
  skip: number;
  limit: number;
}

const UsersTable: React.FC<UsersTableProps> = ({ users, total, goToNextPage, goToPrevPage, skip, limit }) => {
  const navigate = useNavigate();
  const isNextDisabled = skip + limit >= total;
  const isPrevDisabled = skip === 0;
  const { user: currentUser } = useAppContext();
  const updateUser = useUpdateUser();
  const deleteUserMutation = useDeleteUser();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [selectedRoles, setSelectedRoles] = useState<RoleType[]>([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState<User | null>(null);

  const openModal = (user: User) => {
    setSelectedUser(user);
    setSelectedRoles(user.roles);
    onOpen();
  };

  const openDeleteDialog = (user: User) => {
    setUserToDelete(user);
    setDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setUserToDelete(null);
    setDeleteDialogOpen(false);
  };

  const updateRoles = async (newRoles: RoleType[]) => {
    if (selectedUser) {
      try {
        const data = { roles: newRoles } as UserUpdateFormType;
        await updateUser.mutateAsync({ id: selectedUser.id, data });

        toast.success('Roles modifiés avec succès', {
          position: toast.POSITION.TOP_CENTER,
        });
      } catch (error: any) {
        toast.error("Une erreur s'est produite: " + error.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
    setSelectedRoles([]);
    setSelectedUser(null);
  };

  const handleDelete = async () => {
    try {
      if (userToDelete) {
        await deleteUserMutation.mutateAsync(userToDelete.id);
        closeDeleteDialog();
        toast.success('Membre supprimé', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error: any) {
      toast.error(`Une erreur c'est produite : ${error.response.data.message}`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    closeDeleteDialog();
  };

  return (
    <Box>
      {users.length !== 0 && (
        <Box mt={4}>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Username</Th>
                <Th>Email</Th>
                <Th>Roles</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {users.map(user => (
                <Tr key={user.id}>
                  <Td>{user.username}</Td>
                  <Td>{user.email}</Td>
                  <Td>{user.roles.map(roleKey => RoleSimple[roleKey]).join(', ')}</Td>
                  <Td>
                    <IconButton aria-label="Voir" icon={<AiFillEye />} onClick={() => navigate(formatUri({ username: user.username }, PROFILE_PATH))} />
                    <IconButton ml={4} aria-label="Modifier" icon={<EditIcon />} onClick={() => navigate(formatUri({ username: user.username }, ADMIN_PROFILE_UPDATE_PATH))} />
                    {isAdmin(currentUser) && <IconButton ml={4} aria-label="Changer le rôle" onClick={() => openModal(user)} icon={<ExternalLinkIcon />} />}
                    <IconButton ml={4} aria-label="Supprimer" icon={<DeleteIcon />} onClick={() => openDeleteDialog(user)} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Box mt={4}>
            {!isPrevDisabled && (
              <Button onClick={goToPrevPage} disabled={isPrevDisabled}>
                Page précédente
              </Button>
            )}
            {!isNextDisabled && (
              <Button ml={4} onClick={goToNextPage}>
                Page suivante
              </Button>
            )}
          </Box>
        </Box>
      )}
      {isAdmin(currentUser) && <UpdateRoleModal isOpen={isOpen} onClose={onClose} roles={selectedRoles} onUpdateRoles={updateRoles} />}
      <DeleteAlertDialog
        isOpen={deleteDialogOpen}
        onClose={closeDeleteDialog}
        onDelete={handleDelete}
        title="Supprimer l'utilisateur"
        description="Êtes-vous sûr de vouloir supprimer cet utilisateur ?"
      />
    </Box>
  );
};

export default UsersTable;
