import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { API_AUTH_CONNEXION_URI } from '../../utils/api/constants';
import api from '../../utils/api/api';
import { User } from '../../types/user';
import { ConnexionForm } from '../../types/forms/connexion';

const useConnexion = (): UseMutationResult<User, any, ConnexionForm, unknown> => {
  const queryClient = useQueryClient();

  return useMutation<User, any, ConnexionForm>((data: ConnexionForm) => api.post(API_AUTH_CONNEXION_URI, data), {
    onSuccess: () => {
      queryClient.invalidateQueries('user');
    },
  });
};

export default useConnexion;
