import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Flex, Image, SimpleGrid, Text, useBreakpointValue } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { eventSchema } from '../yup/events';
import { useCreateEvent } from '../../../hooks/events/useCreateEvent';
import { EventCreateForm } from './constants';
import { useAppContext } from '../../../context/AppContext';
import { isAccessGranted, isStreameur, S3_ASSETS_URL } from '../../../utils/constants';

import { useDiscordRoles } from '../../../hooks/discord/userDiscordRoles';
import { useNavigate } from 'react-router-dom';
import { formatUri } from '../../../utils/api/helpers';
import { EVENTS_DETAIL_PATH } from '../../../routing/constants';
import PageTitleUpdater from '../../../routing/PageTitleUpdater';
import { convertToBase64, getRandomNumber, getTypeString, isUrl } from '../../../utils/helpers';

import FetchInactiveUser from './FetchInactiveUser';
import { EventForm } from '../EventForm';
import { User } from '../../../types/user';
import ImageLoaderWithGlitch from '../../../components/ImageLoaderWithGlitch';
import Ariane from '../../../components/Ariane';
import InSavedChangesPrompt from '../../../components/InSavedChangesPrompt';
import { Event } from '../../../types/Events';

export const EventCreate: React.FC = () => {
  const { user } = useAppContext();
  const [currentAvatar, setCurrentAvatar] = React.useState<string | undefined>();
  const [eventTypeSelected, setEventTypeSelected] = React.useState<number | undefined>();
  const [eventCreated, setEventCreated] = React.useState<Event | undefined>();
  const mutation = useCreateEvent();
  const navigate = useNavigate();
  const { data: discordRoles } = useDiscordRoles();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    trigger,
    watch,
    formState: { errors, isValid, isDirty },
  } = useForm<EventCreateForm>({
    resolver: yupResolver(eventSchema),
  });

  const watchedMedia = watch('image');
  useEffect(() => {
    if (watchedMedia && watchedMedia instanceof File) {
      trigger('image');
      convertToBase64(watchedMedia).then(base64 => {
        setCurrentAvatar(base64 as string);
      });
    } else if (watchedMedia && isUrl(watchedMedia)) {
      setCurrentAvatar(watchedMedia);
    }
  }, [watchedMedia]);

  const entityType = watch('entityType');
  useEffect(() => {
    if (Number(entityType) === 2) {
      setValue('image', undefined);
      setCurrentAvatar(undefined);
    }
    if (Number(entityType) === 1) {
      setValue('game', undefined);
    }
    if (Number(entityType) === 3) {
      setValue('game', undefined);
    }
  }, [entityType]);

  const watchGame = watch('game');
  useEffect(() => {
    if (watchGame) {
      trigger('image');
      const role = discordRoles?.find(role => role.roleId === watchGame)?.name;
      if (role) {
        const avatar = `${S3_ASSETS_URL}/games/${encodeURIComponent(role)}.png`;
        setValue('image', avatar);
      }
    }
  }, [watchGame]);

  const onSubmit = async (data: EventCreateForm) => {
    try {
      const result = await mutation.mutateAsync(data);
      setEventCreated(result);
      toast.success('Événement créé avec succès', {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (error: any) {
      toast.error(`Échec de la création de l'événement : ${error.response.data.message}`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  useEffect(() => {
    if (eventCreated) {
      navigate(formatUri({ slug: eventCreated.slug, type: getTypeString(eventCreated.entityType) }, EVENTS_DETAIL_PATH));
    }
  }, [eventCreated]);

  const selectEventType = (type: number) => {
    setEventTypeSelected(type);
    setValue('entityType', type);
  };

  if (!isAccessGranted(user)) {
    return <FetchInactiveUser />;
  }

  if (!eventTypeSelected) {
    return (
      <>
        <PageTitleUpdater additionalTitle={`Evénements - Création`} />
        <Ariane>
          <Flex justifyContent="space-between" alignItems="center">
            <Flex alignItems="center">
              <Flex alignItems="center">
                <Image src="/assets/icons/green.svg" alt="Evénements" width="30px" />
                <Text as="h1" fontSize="19px" textTransform="uppercase" ml="4">
                  événements
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Ariane>
        <Box maxW={isMobile ? '100%' : '650px'}>
          <Text>Geek du monde, sors de ta caverne ! 🌍</Text>
          <Text mt={3}>Organise une sortie IRL pour rencontrer d'autres membres de la communauté.</Text>
          <Text>Partage ta passion pour les univers fantastiques, les comics, et bien plus.</Text>
          <Text mt={3}>Après avoir refait le monde, pourquoi ne pas créer une partie en ligne ? 🎮</Text>
          <Text>Invite tes nouveaux amis pour des sessions épiques et des batailles virtuelles mémorables. </Text>
          <Text>Qui sait, peut-être qu'un super-héros sommeille en chacun de nous ?</Text>
          <Text mt={3}>#GeekOutAndPlay 😉</Text>
        </Box>
        <SimpleGrid columns={{ base: 1, md: isStreameur(user) ? 3 : 2 }} justifyContent="center" spacing={10} mt={isMobile ? '20px' : '30px'}>
          <Flex justifyContent={isStreameur(user) ? 'center' : 'end'} onClick={() => selectEventType(2)} cursor="pointer">
            <ImageLoaderWithGlitch src={`/assets/buttons/game-${getRandomNumber(1, 4)}.png`} imageName="Game" />
          </Flex>
          <Flex justifyContent={isStreameur(user) ? 'center' : 'start'} onClick={() => selectEventType(3)} cursor="pointer">
            <ImageLoaderWithGlitch src={`/assets/buttons/sortie-${getRandomNumber(1, 4)}.png`} imageName="Sortie" />
          </Flex>
          {isStreameur(user) && (
            <Flex justifyContent="center" onClick={() => selectEventType(1)} cursor="pointer">
              <ImageLoaderWithGlitch src={`/assets/buttons/stream.png`} imageName="Stream" />
            </Flex>
          )}
        </SimpleGrid>
      </>
    );
  }

  return (
    <>
      <EventForm
        title="Evénements - Création"
        getValues={getValues}
        setValue={setValue}
        register={register}
        errors={errors}
        onSubmit={handleSubmit(onSubmit)}
        isLoading={mutation.isLoading}
        isValid={isValid}
        currentAvatar={currentAvatar}
        eventTypeSelected={eventTypeSelected}
        user={user as User}
      />
      {!eventCreated && <InSavedChangesPrompt isDirty={isDirty} />}
    </>
  );
};
