import React, { useEffect } from 'react';
import { Text, Image, Flex } from '@chakra-ui/react';
import GoogleAnalytics from 'react-ga4';
import { customColors } from '../../theme';
import { CookieConsent, Cookies } from 'react-cookie-consent';

const CookiesPopup: React.FC = () => {
  useEffect(() => {
    // Vérifiez si l'utilisateur a déjà donné son consentement
    const gaCookie = Cookies.get('CookieConsent');
    if (gaCookie === 'true') {
      GoogleAnalytics.initialize('G-2268RG58TL');
      GoogleAnalytics.send('pageview');
    }
  }, []);

  const handleAcceptCookie = () => {
    GoogleAnalytics.initialize('G-2268RG58TL');
    GoogleAnalytics.send('pageview');
  };

  return (
    <CookieConsent
      onAccept={handleAcceptCookie}
      enableDeclineButton
      declineButtonText="Refuser"
      buttonText="Accepter"
      style={{
        width: '100%',
        left: 'auto',
        right: '0',
        background: customColors.whiteTegrite,
        boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      buttonStyle={{ color: customColors.whiteWalter, backgroundColor: customColors.greenFrog, fontSize: '13px', borderRadius: '5px', paddingLeft: '20px', paddingRight: '20px' }}
      declineButtonStyle={{ color: customColors.whiteWalter, backgroundColor: customColors.blackTegrite, fontSize: '13px', paddingLeft: '20px', paddingRight: '20px', borderRadius: '5px' }}
    >
      <Flex alignItems="center">
        <Image src="/assets/cookies.svg" alt="Cookies" maxH="68px" />
        <Text ml={2}>
          Nous utilisons des cookies uniquement pour améliorer votre expérience sur notre site. Aucun cookie à des fins commerciales n'est utilisé et sont anonymes. Acceptez-vous ces cookies pour nous
          aider à optimiser votre navigation ?
        </Text>
      </Flex>
    </CookieConsent>
  );
};

export default CookiesPopup;
