import React, { useState } from 'react';
import { Box, Button, Spinner } from '@chakra-ui/react';
import { useSearchAdminUsers } from '../../../../hooks/users/useSearchAdminUsers';
import { SubmitHandler, useForm } from 'react-hook-form';
import { SearchUsersForm } from './constants';
import UsersTable from './UsersTable';
import FormInput from '../../../../components/forms/FormInput';
import FormSelect from '../../../../components/forms/FormSelect';
import PageTitleUpdater from '../../../../routing/PageTitleUpdater';

const roles = [
  { key: 'ROLE_USER', value: 'Membre' },
  { key: 'ROLE_ADMIN', value: 'Admin' },
  { key: 'ROLE_MODERATOR', value: 'Moderateur' },
  { key: 'ROLE_STREAM', value: 'Streameur' },
];

const SearchUsersContent: React.FC = () => {
  const [searchForm, setSearchForm] = useState<SearchUsersForm | null>(null);
  const [skip, setSkip] = useState(0);
  const [limit] = useState(10); // Ou toute autre valeur pour la taille de la page

  const { data, isLoading } = useSearchAdminUsers(searchForm, skip, limit);
  const users = data?.users || [];
  const total = data?.total || 0;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SearchUsersForm>();

  const onSubmit: SubmitHandler<SearchUsersForm> = async data => {
    setSkip(0);
    setSearchForm(data);
  };

  const goToNextPage = () => setSkip(prevSkip => prevSkip + limit);
  const goToPrevPage = () => setSkip(prevSkip => Math.max(prevSkip - limit, 0));

  return (
    <Box>
      <PageTitleUpdater additionalTitle="Admin Panel - Recherche de membres" />
      <Box as="form" onSubmit={handleSubmit(onSubmit)} p={8} borderWidth={1} borderRadius="md">
        <FormInput label="Pseudo" type="text" placeholder="Pseudo" name="username" register={register} errors={errors} />
        <FormInput label="Email" type="email" placeholder="Email" name="email" register={register} errors={errors} />
        <FormSelect label="Role" placeholder="Role" name="role" options={roles} register={register} errors={errors} />
        <Button type="submit" colorScheme="blue" mt={4}>
          Submit
        </Button>
      </Box>
      {isLoading ? <Spinner /> : <>{users && <UsersTable users={users} total={total} goToNextPage={goToNextPage} goToPrevPage={goToPrevPage} skip={skip} limit={limit} />}</>}
    </Box>
  );
};

export default SearchUsersContent;
