import { useQuery, UseQueryResult } from 'react-query';
import api from '../../utils/api/api';
import { API_VISITS } from '../../utils/api/constants';
import { QueryVisitsParams } from '../../pages/Visits/constants';
import { ProfileVisit } from '../../types/ProfileVisit';

interface VisitsResponse {
  visits: ProfileVisit[];
  total: number;
}

export const useUserVisits = (options: QueryVisitsParams): UseQueryResult<VisitsResponse, unknown> => {
  // Destructure options with default values to ensure they are not undefined
  const { skip = 0, limit = 6 } = options;

  return useQuery(
    ['userVisits', skip, limit],
    async () => {
      const queryParams = new URLSearchParams();

      if (skip) queryParams.append('skip', String(skip));
      if (limit) queryParams.append('limit', String(limit));

      const { data } = await api.get<VisitsResponse>(`${API_VISITS}?${queryParams.toString()}`);
      return data;
    },
    {
      refetchOnMount: true,
    },
  );
};
