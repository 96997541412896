import React, { useEffect, useRef, useState } from 'react';
import { useAppContext } from '../../context/AppContext';
import { Box, Button, Flex, Grid, Image, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useBreakpointValue, useMediaQuery } from '@chakra-ui/react';
import PageTitleUpdater from '../../routing/PageTitleUpdater';
import Ariane from '../../components/Ariane';
import UserProfileHeader from '../userProfile/UserProfileHeader';
import Stats from '../userProfile/components/Stats';
import { customColors } from '../../theme';
import GeneralTab from './generalTab/GeneralTab';
import ParameterTab from './parameterTab/ParameterTab';
import Accessibility from './accessibility/Accessibility';
import { PROFILE_PATH } from '../../routing/constants';
import { formatUri } from '../../utils/api/helpers';
import { useNavigate } from 'react-router-dom';
import RegisterSuccessModal from './RegisterSuccessModal';

const Account: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAppContext();
  const [boxHeight, setBoxHeight] = useState(null);
  const boxRef = useRef(null);
  const [isLessThan1480] = useMediaQuery('(max-width: 1480px)');
  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    if (boxRef && boxRef.current) {
      const current = (boxRef as any).current;
      if (current && current.offsetHeight) {
        setTimeout(() => {
          const initialHeight = current.offsetHeight;
          setBoxHeight(initialHeight);
        }, 500);
      }
    }
  }, []);

  useEffect(() => {
    const updateHeight = () => {
      if (boxRef && boxRef.current) {
        const current = (boxRef as any).current;
        if (current && current.offsetHeight && boxHeight) {
          const currentHeight = current.offsetHeight;
          setBoxHeight(currentHeight);
        }
      }
    };
    updateHeight();
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, [boxHeight]);

  if (!user) {
    return null;
  }

  return (
    <Box>
      <PageTitleUpdater additionalTitle={`Compte`} />
      <Ariane>
        <Flex alignItems="center" justifyContent="space-between" w="100%">
          <Flex flex={1} alignItems="center">
            <Image src="/assets/icons/purple.svg" alt="Mon compte" width="30px" />
            <Text as="h1" textTransform="uppercase" fontSize="19px" ml={4}>
              Mon compte
            </Text>
          </Flex>
          {!isMobile && (
            <Button variant="createEvent" onClick={() => navigate(formatUri({ username: user.username }, PROFILE_PATH))}>
              Voir mon profil
            </Button>
          )}
        </Flex>
      </Ariane>
      <UserProfileHeader isEdit user={user} />
      <Grid templateColumns={!isLessThan1480 ? '5fr 1fr' : '1fr'} gap={4} mt={4} h={isMobile ? 'auto' : 'calc(100vh - 1000px)'} flex={1} ref={boxRef}>
        <Box>
          <Tabs variant="enclosed" colorScheme="red">
            <TabList mb="1em" justifyContent="space-evenly">
              <Tab _selected={{ color: 'yellowBob', borderBottom: `1px solid ${customColors.yellowBob}` }}>Général</Tab>
              <Tab _selected={{ color: 'yellowBob', borderBottom: `1px solid ${customColors.yellowBob}` }}>Paramètres</Tab>
              <Tab _selected={{ color: 'yellowBob', borderBottom: `1px solid ${customColors.yellowBob}` }}>Accessibilité</Tab>
            </TabList>
            <TabPanels>
              <TabPanel pr={0} pl={0}>
                <GeneralTab user={user} />
              </TabPanel>
              <TabPanel>
                <ParameterTab user={user} />
              </TabPanel>
              <TabPanel>
                <Accessibility user={user} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
        {!isLessThan1480 && (
          <Box>
            <Stats userId={user.id} />
          </Box>
        )}
      </Grid>
      <RegisterSuccessModal />
    </Box>
  );
};

export default Account;
