import React, { useEffect, useRef, useState } from 'react';
import { Box, SimpleGrid } from '@chakra-ui/react';
import { customScrollBar } from '../../utils/helpers';

interface ScrollableBoxWithFadeProps {
  children: React.ReactNode;
  // Vous pouvez aussi ajouter une prop pour la hauteur par défaut si nécessaire
}

const ScrollableBoxWithFade: React.FC<ScrollableBoxWithFadeProps> = ({ children }) => {
  const [scrollTop, setScrollTop] = useState(0);
  const scrollContainerRef = useRef<any>();

  const onScroll = () => {
    if (scrollContainerRef.current) {
      setScrollTop(scrollContainerRef.current.scrollTop);
    }
  };

  useEffect(() => {
    const scrollContainerCurrent = scrollContainerRef.current;
    if (scrollContainerCurrent) {
      setScrollTop(scrollContainerCurrent.scrollTop);
    }
  }, []);

  // Style pour la transition douce des gradients
  const fadeStyle = {
    transition: 'opacity 0.3s ease-in-out', // Transition douce de l'opacité
  };

  return (
    <Box width="full" height="auto" position="relative">
      <Box ref={scrollContainerRef} onScroll={onScroll} css={customScrollBar} height="100%" overflowY="auto">
        <SimpleGrid columns={[1]} spacing={5} mt={2} pb="16px">
          {children}
        </SimpleGrid>
      </Box>

      <Box
        position="absolute"
        top="0"
        left="0"
        right="0"
        height="30px"
        background="linear-gradient(to bottom, rgba(43, 44, 44,1), transparent)"
        pointerEvents="none"
        opacity={scrollTop > 0 ? 1 : 0}
        style={fadeStyle} // Appliquer le style de transition ici
      />

      {/* Gradient flou en bas */}
      <Box
        position="absolute"
        bottom="0"
        left="0"
        right="0"
        height="30px"
        background="linear-gradient(to top, rgba(43, 44, 44,1), transparent)"
        pointerEvents="none"
        opacity={scrollTop < scrollContainerRef.current?.scrollHeight - scrollContainerRef.current?.clientHeight ? 1 : 0}
        style={fadeStyle} // Appliquer le style de transition ici
      />
    </Box>
  );
};

export default ScrollableBoxWithFade;
