import { Box, Text, Badge, Image, VStack, HStack } from '@chakra-ui/react';
import { Event } from '../../../types/Events';
import React from 'react';
import { formatTimestamp } from '../../../utils/dates';
import { S3_URL } from '../../../utils/constants';
import Markdown from 'react-markdown';
import { isUrl } from '../../../utils/helpers';

interface EventCardProps {
  event: Event;
  onClick?: () => void;
  withDescription?: boolean;
}

const removePrefix = (name: string) => {
  return name.replace(/^\[(Game|Sortie|Stream)\]\s*/, '');
};

const AdminEventCard: React.FC<EventCardProps> = ({ withDescription, event, onClick }) => {
  const { name, description, scheduledStartTime, scheduledEndTime, entityType, image, location, participants } = event;

  const getEntityType = (entityType: number) => {
    if (entityType === 1) return 'Stream';
    if (entityType === 2) return 'Game';
    if (entityType === 3) return 'Sortie';
  };

  const participantNames = participants.map(p => p.username).join(', ');
  const totalParticipants = participants.length;

  return (
    <Box p="4" w="100%" overflow="hidden">
      {image && <Image onClick={onClick} w="100%" objectFit="cover" src={isUrl(image) ? image : `${S3_URL}/events/${image}`} alt={name} />}
      <VStack align="start" spacing={2} onClick={onClick}>
        <Text fontWeight="bold">{removePrefix(name)}</Text>
        <Text fontSize="sm">{`Le ${formatTimestamp(scheduledStartTime)} jusqu'à ${formatTimestamp(scheduledEndTime)}`}</Text>
        {withDescription && (
          <Box className="markdown">
            <Markdown>{description}</Markdown>
          </Box>
        )}
        {location && <Text>Location: {location.formattedAddress}</Text>}
        <HStack spacing={2}>
          <Badge colorScheme="blue">{getEntityType(entityType)}</Badge>
        </HStack>
        <HStack spacing={2}>
          <Badge colorScheme="blue">{`Participants: ${totalParticipants}`}</Badge>
          <Text fontSize="sm">{participantNames}</Text>
        </HStack>
      </VStack>
    </Box>
  );
};

export default AdminEventCard;
