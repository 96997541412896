import React from 'react';
import { Contest } from '../../../../types/Contests';
import { Grid, useMediaQuery } from '@chakra-ui/react';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

interface ParticipationProps {
  contest: Contest;
  setParticipationClick: React.Dispatch<React.SetStateAction<boolean>>;
}

const Participation: React.FC<ParticipationProps> = ({ contest, setParticipationClick }) => {
  const [step, setStep] = React.useState(1);
  const [isLessThan1020] = useMediaQuery('(max-width: 1600px)');
  const gridTemplateColumns = isLessThan1020 ? '1fr' : '1fr 5fr 1fr';
  return (
    <Grid templateColumns={gridTemplateColumns} gap={4} px={2} mt={'-30px'} h="calc(100vh - 450px)" zIndex={1} position={'relative'}>
      {step === 1 && <Step1 contest={contest} setParticipationClick={setParticipationClick} setStep={setStep} />}
      {step === 2 && <Step2 contest={contest} setStep={setStep} />}
      {step === 3 && <Step3 contest={contest} setParticipationClick={setParticipationClick} />}
    </Grid>
  );
};

export default Participation;
