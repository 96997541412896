import { useMutation, UseMutationResult } from 'react-query';
import api from '../../utils/api/api';
import { API_PASSWORD_RESET_VALID } from '../../utils/api/constants';

type ResetPasswordParams = {
  token: string;
  newPassword: string;
};

export const useResetPassword = (): UseMutationResult<void, Error, ResetPasswordParams, unknown> => {
  return useMutation<void, Error, ResetPasswordParams>(({ token, newPassword }) => api.post(API_PASSWORD_RESET_VALID, { token, newPassword }).then(res => res.data));
};
