import React from 'react';
import { Event } from '../../types/Events';
import { useJoinOrLeaveEvent } from '../../hooks/events/useJoinOrLeaveEvent';
import { useAppContext } from '../../context/AppContext';
import { Button, Text } from '@chakra-ui/react';
import { customColors } from '../../theme';

interface JoinEventButtonProps {
  event: Event;
}

const JoinEventButton: React.FC<JoinEventButtonProps> = ({ event }) => {
  const { user } = useAppContext();
  const { joinEvent, leaveEvent } = useJoinOrLeaveEvent();

  const currentDate = new Date();
  const endDateTime = new Date(event.scheduledEndTime);
  const isEnded = currentDate > endDateTime;
  const isParticipant = event.participants.some(participant => participant.id === user?.id);

  const handleJoinOrLeave = () => {
    if (event) {
      if (!isEnded) {
        if (isParticipant) {
          leaveEvent.mutate(event.id);
        } else {
          joinEvent.mutate(event.id);
        }
      }
    }
  };

  const isDisplayParticipationEvent = (event: Event) => {
    const endTime = new Date(event.scheduledEndTime);
    return endTime > new Date();
  };

  if (!isDisplayParticipationEvent(event)) {
    return null;
  }

  if (isParticipant) {
    return (
      <Text border="none" cursor="pointer" color="white" textDecoration="underline" onClick={handleJoinOrLeave}>
        Quitter l'événement
      </Text>
    );
  }

  return (
    <Button variant="navYellowTotal" sx={{ _hover: { bg: customColors.yellowBob, color: customColors.blackTegrite } }} onClick={handleJoinOrLeave}>
      Rejoindre l'événement
    </Button>
  );
};

export default JoinEventButton;
