import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { renderersMarkdown } from '../utils/helpers';
import { css } from '@emotion/react';

const customStyles = css`
  h1 {
    font-size: 2em;
    margin-bottom: 20px;
    border-bottom: 1px solid black;
  }
  h2 {
    font-size: 1.5em;
    margin-top: 20px;
  }
  p {
    font-size: 1em;
    margin-bottom: 15px;
  }
`;

interface MarkDownStyledProps {
  text: string;
}

const MarkDownStyled: React.FC<MarkDownStyledProps> = ({ text }) => {
  const lines = text.split('@@').map((segment, index) => {
    return (
      <Box key={index}>
        <ReactMarkdown components={renderersMarkdown}>{segment}</ReactMarkdown>
      </Box>
    );
  });
  return <Text css={customStyles}>{lines}</Text>;
};

export default MarkDownStyled;
