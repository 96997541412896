import React, { useRef } from 'react';
import { Box, Container, Flex, useBreakpointValue, useMediaQuery } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { useOnScreen } from '../../../hooks/useOnScreen';
import { customColors } from '../../../theme';

const MotionBox = motion(Box);

const Bottom: React.FC = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  const [isLessThan1244] = useMediaQuery('(max-width: 1244px)');
  const ref = useRef<any>();
  const isVisible = useOnScreen(ref);

  return (
    // eslint-disable-next-line react/jsx-no-undef
    <Container maxW="container.2xl" mt={isMobile ? 50 : 200} data-nosnippet>
      <Flex direction={'column'} p={6} mt={10} pb={isMobile ? 100 : 200} borderRadius="xl" zIndex="1" maxW={isLessThan1244 ? '100%' : '900px'} ref={ref}>
        <AnimatePresence>
          {isVisible && (
            <>
              <MotionBox
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -30 }}
                transition={{ delay: 0.4, duration: 0.3, exit: { delay: 0, duration: 0.1 } }}
                style={{
                  marginBottom: '15px',
                  fontSize: isMobile ? '40px' : '55px',
                  lineHeight: isMobile ? '40px' : '66px',
                  letterSpacing: '0.2em',
                  fontFamily: 'Barlow Condensed',
                  fontWeight: '700',
                  color: customColors.blackTegrite,
                  textTransform: 'uppercase',
                  textAlign: 'center',
                }}
              >
                ENTRE-GEEKS décrypté
              </MotionBox>
              <MotionBox
                style={{
                  color: customColors.blackTegrite,
                  marginTop: '50px',
                }}
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -30 }}
                transition={{ delay: 0.4, duration: 0.5, exit: { delay: 0, duration: 0.1 } }}
              >
                Bienvenue sur EntreGeeks, le point de ralliement incontournable pour tous les passionnés de la culture geek.
              </MotionBox>
              <MotionBox
                style={{
                  color: customColors.blackTegrite,
                  marginTop: '30px',
                }}
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -30 }}
                transition={{ delay: 0.3, duration: 0.9, exit: { delay: 0, duration: 0.1 } }}
              >
                EntreGeeks rassemble les geeks de tous horizons, créant ainsi une communauté ouverte et dynamique. Ici, plongez-vous dans des discussions animées sur notre serveur Discord et notre
                chat. Organisez des sorties dans des bars, salons, rues, terres du milieu, et bien d'autres lieux pour partager vos passions avec d'autres membres enthousiastes.
              </MotionBox>
              <MotionBox
                style={{
                  color: customColors.blackTegrite,
                  marginTop: '30px',
                }}
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -30 }}
                transition={{ delay: 0.6, duration: 0.5, exit: { delay: 0, duration: 0.1 } }}
              >
                Grâce à notre communauté dévouée, participez à des sessions de jeux en ligne et suivez des émissions captivantes sur notre chaîne Twitch, orchestrée par nos modérateurs talentueux. Ne
                manquez pas l'occasion de rejoindre cette grande famille sur Entre-Geeks. Vous ne le regretterez pas. La culture geek vous attend avec impatience.
              </MotionBox>
            </>
          )}
        </AnimatePresence>
      </Flex>
    </Container>
  );
};

export default Bottom;
