import React, { useEffect } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Box, FormControl, FormLabel, FormErrorMessage, useTheme } from '@chakra-ui/react';
import { UseFormRegister, DeepMap, FieldError, UseFormSetValue, FieldValues } from 'react-hook-form';
import fr from 'date-fns/locale/fr';
import { format } from 'date-fns';

registerLocale('fr', fr);

export interface FormDatePickerProps {
  label?: string;
  name: string;
  register: UseFormRegister<any>;
  errors?: DeepMap<FieldValues, FieldError>;
  setValue: UseFormSetValue<any>;
  getValues: any;
  required?: boolean;
  minDate?: Date;
  maxDate?: Date;
  placeholder: string;
  showTime?: boolean;
  variant?: any;
  defaultDate?: string;
  isDateString?: boolean;
  sx?: any;
}

const FormDatePicker: React.FC<FormDatePickerProps> = ({
  isDateString = false,
  defaultDate,
  getValues,
  showTime,
  placeholder,
  label,
  name,
  register,
  setValue,
  errors,
  required,
  minDate,
  maxDate,
  variant = 'violet',
  sx,
}) => {
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);
  const dateFormat = showTime ? 'd MMMM yyyy, HH:mm' : 'd MMMM yyyy';
  const theme = useTheme();
  const violetStyle = variant === 'violet' ? theme.components.Select.variants.violet.field : theme.components.Select.variants.whiteBorder.field;

  const dateOptions: Intl.DateTimeFormatOptions = showTime
    ? { day: 'numeric', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' }
    : { day: 'numeric', month: 'long', year: 'numeric' };

  useEffect(() => {
    if (getValues && getValues(name)) {
      setSelectedDate(new Date(getValues(name)));
    }
  }, [getValues, name]);

  useEffect(() => {
    setSelectedDate(defaultDate ? new Date(defaultDate) : null);
  }, [defaultDate]);

  const setDate = (date: Date | null) => {
    setSelectedDate(date ? new Date(date) : null);

    if (date) {
      const formattedDate = isDateString ? format(date, 'yyyy-MM-dd') : date;
      setValue(name, formattedDate, { shouldValidate: true });
    } else {
      setValue(name, null, { shouldValidate: true });
    }
  };

  return (
    <FormControl isRequired={required} isInvalid={errors && !!errors[name]} mt={4} sx={sx}>
      {label && <FormLabel>{label}</FormLabel>}
      <Box
        {...violetStyle}
        w="100%"
        {...(register ? register(name) : {})}
        as={DatePicker}
        selected={selectedDate}
        onChange={(date: Date) => setDate(date)}
        minDate={minDate}
        maxDate={maxDate}
        locale="fr"
        showTimeSelect={showTime}
        dateFormat={dateFormat}
        placeholderText="Sélectionner une date"
        showPopperArrow={false}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        customInput={
          <Box
            as="button"
            type="button"
            sx={{
              color: `${selectedDate ? 'white' : '#939393'} !important`,
            }}
            border="10px solid white"
            borderColor="gray.300"
            borderRadius="md"
            p="2"
            w="100%"
            textAlign="left"
          >
            {selectedDate ? selectedDate.toLocaleDateString('fr-FR', dateOptions) : placeholder}
          </Box>
        }
      />
      <FormErrorMessage>{errors && errors[name] && errors[name].message}</FormErrorMessage>
    </FormControl>
  );
};

export default FormDatePicker;
