import React, { useEffect } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useInvalidAccounts } from '../../../hooks/users/useInvalidAccounts';
import { useInvalidEvents } from '../../../hooks/events/useInvalidEvents';
import { useUpdatedUsers } from '../../../hooks/users/useUpdatedUsers';
import { useMediaToValidate } from '../../../hooks/media/useMediaToValidate';
import { Link } from 'react-router-dom';
import { ADMIN_PATH } from '../../../routing/constants';
import { SiGodotengine } from 'react-icons/si';
import { customColors } from '../../../theme';

interface AdminIconProps {
  toggleContent?: () => void;
}

const AdminIcon: React.FC<AdminIconProps> = ({ toggleContent }) => {
  const { data } = useInvalidAccounts();
  const { data: events } = useInvalidEvents();
  const { data: dataUpdateUser } = useUpdatedUsers();
  const { data: mediaToValidate } = useMediaToValidate();
  const [alert, setAlert] = React.useState(false);

  useEffect(() => {
    if (data && data.length > 0) {
      setAlert(true);
    } else if (events && events.length > 0) {
      setAlert(true);
    } else if (dataUpdateUser && dataUpdateUser.length > 0) {
      setAlert(true);
    } else if (mediaToValidate && mediaToValidate.length > 0) {
      setAlert(true);
    } else {
      setAlert(false);
    }
  }, [data, events, dataUpdateUser, mediaToValidate]);

  return (
    <Box mx={2}>
      <Link to={ADMIN_PATH} onClick={() => (toggleContent ? toggleContent() : undefined)}>
        <Flex mt={1.5}>
          <SiGodotengine size={'35px'} color={alert ? customColors.redRanger : customColors.whiteWalter} />
        </Flex>
      </Link>
    </Box>
  );
};

export default AdminIcon;
