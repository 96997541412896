import React, { useEffect, useState } from 'react';
import { Box, Grid, Image, useBreakpointValue } from '@chakra-ui/react';
import { SocketContextProvider } from '../context/SocketContext';
import { useAppContext } from '../context/AppContext';

import '../indexAuth.css';

import LeftBar from './left/LeftBar';
import Content from './content/Content';
import RightBar from './right/RightBar';
import GlobalStyles from '../GlobalStyles';
import Header from './header/Header';
import { Outlet, useNavigate } from 'react-router-dom';
import { HOME_PATH, MAINTENANCE, PUBLIC_CONTESTS, PUBLIC_GAMES, PUBLIC_NEWS, PUBLIC_OUTING, PUBLIC_STREAMS } from '../routing/constants';
import { formatUri } from '../utils/api/helpers';
import { IS_MAINTENANCE } from '../utils/constants';
import useWindowZoom from '../hooks/useWindowZoom';
import ScrollToTop from '../components/ScrollToTop';
import PageTitleUpdater from '../routing/PageTitleUpdater';

const Layout: React.FC = () => {
  useWindowZoom(2000);
  const { user, isAppLoading } = useAppContext();
  const [isInRange, setIsInRange] = useState(false);
  const navigate = useNavigate();
  const isMobile = useBreakpointValue({ base: 'none', md: 'initial' });

  // Configuration conditionnelle des colonnes et de l'affichage
  const gridProps =
    isMobile === 'none'
      ? { display: 'block', py: 1, px: 1 }
      : {
          templateColumns: isInRange ? '1.5fr 7.5fr' : { base: '1fr', md: '1.5fr 6fr 2fr', lg: '1.5fr 6fr 2fr' },
          gap: 5,
          p: 4,
          minHeight: 'calc(100vh - 82px)',
        };

  if (location.pathname !== MAINTENANCE && IS_MAINTENANCE === 'true') {
    navigate(MAINTENANCE);
  }

  if (!isAppLoading && !user && IS_MAINTENANCE === 'false') {
    const path = window.location.pathname;
    const slug = path.split('/').pop();

    if (location.pathname.startsWith('/evenements/sorties/')) {
      navigate(formatUri({ slug }, PUBLIC_OUTING));
    } else if (location.pathname.startsWith('/evenements/games/')) {
      navigate(formatUri({ slug }, PUBLIC_GAMES));
    } else if (location.pathname.startsWith('/evenements/streams/')) {
      navigate(formatUri({ slug }, PUBLIC_STREAMS));
    } else if (location.pathname.startsWith('/actus/')) {
      navigate(formatUri({ slug }, PUBLIC_NEWS));
    } else if (location.pathname.startsWith('/concours/')) {
      navigate(formatUri({ slug }, PUBLIC_CONTESTS));
    } else {
      navigate(HOME_PATH);
    }
  }

  useEffect(() => {
    const updateWidth = () => {
      const currentWidth = window.innerWidth;
      setIsInRange(currentWidth > 767 && currentWidth < 1341);
    };

    updateWidth();

    window.addEventListener('resize', updateWidth);

    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  if (!user) return null;

  return (
    <SocketContextProvider>
      <ScrollToTop />
      <PageTitleUpdater />
      <GlobalStyles />
      {isMobile !== 'none' && (
        <Box zIndex={-1} position="absolute" right="350px" width="30%" display={isInRange ? 'none' : 'block'}>
          <Image src="/assets/background-img.png" align="center" />
        </Box>
      )}

      <Header user={user} />
      <Grid {...gridProps}>
        <LeftBar />
        <Content>
          <Outlet />
        </Content>
        {!isInRange && isMobile !== 'none' && <RightBar />}
      </Grid>
    </SocketContextProvider>
  );
};

export default Layout;
