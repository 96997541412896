import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import api from '../../utils/api/api';
import { API_MEDIA_VALIDATION } from '../../utils/api/constants';

interface ValidationMedia {
  medias: {
    id: number;
    url: string;
  }[];
  action: 'validate' | 'reject';
}

export const useMediaValidation = (): UseMutationResult<void, Error, ValidationMedia, unknown> => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, ValidationMedia>(
    data => {
      return api.post(API_MEDIA_VALIDATION, data).then(res => res.data);
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries('mediaToValidate');
      },
    },
  );
};
