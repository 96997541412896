import React from 'react';
import { useAppContext } from '../../../context/AppContext';
import { Box, Button, Flex, Grid, Image, Text, useMediaQuery } from '@chakra-ui/react';
import { useUpdateUser } from '../../../hooks/users/useUpdateUser';
import { User } from '../../../types/user';
import { toast } from 'react-toastify';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UserUpdateParameterYupSchema } from './yup/UserUpdateGeneralYupSchema';
import FormInput from '../../../components/forms/FormInput';
import { UserParameterUpdateFormType } from '../constants';
import CityAutocomplete from '../../../components/forms/CityAutocomplete';
import UserAvatarProfile from '../../../components/UserAvatarProfile';
import FormImageUpload from '../../../components/forms/formImageUpload/FormImageUpload';
import UpdateHeaderCover from '../updateHead/UpdateHeaderCover';
import { useDeleteUserMedia } from '../../../hooks/users/useDeleteUserMedia';

interface AccountInfosProps {
  user: User;
}

const AccountInfos: React.FC<AccountInfosProps> = ({ user }) => {
  const [isLessThan952] = useMediaQuery('(max-width: 952px)');
  const { mutate: deleteMedia } = useDeleteUserMedia();
  const updateUser = useUpdateUser();
  const { setUser } = useAppContext();

  const templateColumns = !isLessThan952 ? '1fr 3fr' : '1fr';

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    getValues,
  } = useForm<UserParameterUpdateFormType>({
    resolver: yupResolver(UserUpdateParameterYupSchema),
    defaultValues: {
      location: {
        country: user.location.country,
        city: user.location.city,
        department: user.location.department,
        region: user.location.region,
        formattedAddress: user.location.formattedAddress,
      },
      userProfile: {
        site: user.userProfile.site,
      },
      media: user.media,
    },
    mode: 'onChange',
  });

  const onSubmit: SubmitHandler<UserParameterUpdateFormType> = async data => {
    try {
      const newUser = await updateUser.mutateAsync({ id: user.id, data });
      setUser(newUser);
      toast.success('Profile mis à jour avec succès', {
        position: toast.POSITION.TOP_CENTER,
      });
      window.scrollTo(0, 0);
    } catch (error) {
      toast.error("Une erreur s'est produite lors de la mise à jour du profile", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const handleDeleteMedia = () => {
    deleteMedia({ id: user.id, type: 'media' });
  };

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      <Grid templateColumns={templateColumns} gap={4} mt={4} flex={1}>
        <Box>
          <Text fontWeight="bold" color="yellowBob">
            Infos du compte
          </Text>
        </Box>
        <Box>
          <Text mb={4}>Portrait de ton personnage</Text>
          <Flex alignItems={'top'} flexDirection={isLessThan952 ? 'column' : 'row'} w="100%">
            <Box textAlign="center" position="relative">
              <UserAvatarProfile withBorder username={user.username} media={user.media} styles={{ width: '140px', height: '140px' }} />
              {user.media && (
                <Box position="absolute" top="29px" right="-8px">
                  <Image cursor="pointer" src={`/assets/icons/delete.svg`} alt="Discord" width="30px" onClick={handleDeleteMedia} />
                </Box>
              )}
              <FormImageUpload placeholder="Modifier la photo" name="media" cropWidth={300} cropHeight={300} register={register} setValue={setValue} getValues={getValues} errors={errors} />
              {isLessThan952 && <UpdateHeaderCover user={user} />}
            </Box>

            <Box ml={isLessThan952 ? 0 : 6} width="100%">
              <CityAutocomplete variant="whiteBorderNoBg" label="Ville" placeholder="Ville" name="location" required setValue={setValue} getValues={getValues} register={register} errors={errors} />
              <Box mt={4}>
                <FormInput variant="whiteBorderNoBg" label="Site" placeholder="Site" type="text" name="userProfile.site" register={register} errors={errors} />
              </Box>
              <Flex justifyContent="flex-end" mt={4}>
                <Button type="submit" isLoading={updateUser.isLoading} isDisabled={!isValid} variant="navGreen">
                  Sauvegarder les changements
                </Button>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Grid>
    </Box>
  );
};

export default AccountInfos;
