/* eslint-disable max-len */
import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import MarkDownStyled from '../../../components/MarkDownStyled';
import PageLayout from '../../../layout/noAuth/wrappers/PageLayout';
import { textCGU } from './constants';
import PageTitleUpdater from '../../../routing/PageTitleUpdater';

const Cgu: React.FC = () => {
  return (
    <PageLayout>
      <PageTitleUpdater additionalTitle="CGU" />
      <Box mb={20}>
        <Text as="h1" mb={4}>
          Conditions générales d'utilisation
        </Text>
        <MarkDownStyled text={textCGU} />
      </Box>
    </PageLayout>
  );
};

export default Cgu;
