import { useParams } from 'react-router-dom';
import React from 'react';
import ThreadByTagCard from './ThreadByTagCard';
import ThreadByChannelIdCard from './ThreadByChannelIdCard';

type ParamTypes = {
  tagId: string;
  channelId: string;
};

const Threads: React.FC = () => {
  const { tagId, channelId } = useParams<ParamTypes>();
  return (
    <>
      {tagId && <ThreadByTagCard tagId={tagId} />}
      {channelId && <ThreadByChannelIdCard channelId={channelId} />}
    </>
  );
};

export default Threads;
