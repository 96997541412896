import { useMutation, useQueryClient, UseMutationResult } from 'react-query';
import api from '../../utils/api/api';
import { Contest } from '../../types/Contests';
import { API_CONTEST_DRAW_ADMIN } from '../../utils/api/constants';

export const useDrawContest = (): UseMutationResult<Contest, Error, { id: string; winners: string[]; text: string }, unknown> => {
  const queryClient = useQueryClient();

  return useMutation<Contest, Error, { id: string; winners: string[]; text: string }>(({ id, winners, text }) => api.put(`${API_CONTEST_DRAW_ADMIN}/${id}`, { winners, text }).then(res => res.data), {
    onSuccess: () => {
      queryClient.invalidateQueries('contests');
    },
  });
};
