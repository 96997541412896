import React from 'react';
import { Box, Container, Flex, Link as ChakraLink, Text, useBreakpointValue } from '@chakra-ui/react';
import { FaTwitch } from 'react-icons/fa';
import { RiTwitterXLine } from 'react-icons/ri';
import { BsFacebook, BsYoutube } from 'react-icons/bs';
import { Link as RouterLink } from 'react-router-dom';
import FooterColumn from './footerColumn';
import { ABOUT_US_PATH, CGU, CONTACT_PATH, COOKIES_POLICY_PATH, FAQ_PATH, LEGAL_NOTICE_PATH } from '../../../../routing/constants';

const Footer: React.FC = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Box bg="blackTegrite" color="whiteWalter" position="relative" zIndex="2">
      <Container maxW="container.xl">
        <Flex direction="column" alignItems="center" py={10}>
          <Flex wrap="wrap" flexDirection={isMobile ? 'column' : 'row'} justifyContent="center">
            <FooterColumn title="À propos">
              <ChakraLink as={RouterLink} to={ABOUT_US_PATH}>
                <Text fontSize="sm">Qui sommes-nous</Text>
              </ChakraLink>
              <ChakraLink as={RouterLink} to={CONTACT_PATH}>
                <Text fontSize="sm">Contactez-nous</Text>
              </ChakraLink>
            </FooterColumn>

            <FooterColumn title="Confidentialité">
              <ChakraLink as={RouterLink} to={CGU} mr={4}>
                <Text fontSize="sm">Conditions générales d'utilisation</Text>
              </ChakraLink>
              <ChakraLink as={RouterLink} to={LEGAL_NOTICE_PATH}>
                <Text fontSize="sm">Mentions légales</Text>
              </ChakraLink>
              <ChakraLink as={RouterLink} to={COOKIES_POLICY_PATH}>
                <Text fontSize="sm">Politique de Cookies</Text>
              </ChakraLink>
            </FooterColumn>

            <FooterColumn title="Support">
              <ChakraLink as={RouterLink} to={FAQ_PATH}>
                <Text fontSize="sm">FAQ</Text>
              </ChakraLink>
              <ChakraLink as={RouterLink} to="https://discord.gg/0VJbJI6szxQG87LO" target="_blank">
                <Text fontSize="sm">DISCORD</Text>
              </ChakraLink>
            </FooterColumn>
          </Flex>

          {/* Icônes */}
          <Flex mt={6}>
            <Box mx={4}>
              <ChakraLink as={RouterLink} to="https://www.twitch.tv/entregeeksgaming" target="_blank">
                <FaTwitch size={40} />
              </ChakraLink>
            </Box>
            <Box mx={4}>
              <ChakraLink as={RouterLink} to="https://twitter.com/EntreGeeks" target="_blank">
                <RiTwitterXLine size={40} />
              </ChakraLink>
            </Box>
            <Box mx={4}>
              <ChakraLink as={RouterLink} to="https://www.facebook.com/EntreGeeksCom" target="_blank">
                <BsFacebook size={40} />
              </ChakraLink>
            </Box>
            <Box mx={4}>
              <ChakraLink as={RouterLink} to="https://www.youtube.com/@Entre-geeks" target="_blank">
                <BsYoutube size={40} />
              </ChakraLink>
            </Box>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};

export default Footer;
