import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { ContactFormSchema } from './yup/contacts';
import FormInput from '../../../../components/forms/FormInput';
import { useSendContactMessage } from '../../../../hooks/users/useSendContactMessage';
import { toast } from 'react-toastify';
import FormTextareaSimple from '../../../../components/forms/FormTextareaSimple';

interface ContactFormData {
  name: string;
  email: string;
  message: string;
}

export const ContactForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm<ContactFormData>({
    resolver: yupResolver(ContactFormSchema),
    mode: 'onBlur',
  });

  const sendMessage = useSendContactMessage();

  const onSubmit: SubmitHandler<ContactFormData> = async data => {
    try {
      await sendMessage.mutateAsync(data);
      reset();
      toast.success(`Votre message a bien été envoyé, nous vous répondrons dans les plus brefs délais.`, {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (error: any) {
      toast.error(`Erreur d'envoi : ${error.response.data.message}`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <Box minW="400px">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Text fontSize="4xl" color="#ffffff" mb={50}>
          Nous contacter
        </Text>

        <Flex direction="column" minH="200px">
          <Flex align="center" my={4}>
            <FormInput variant="whiteBorderNoBg" type="text" placeholder="Votre nom" name="name" required register={register} errors={errors} />
          </Flex>

          <Flex align="center" my={4}>
            <FormInput variant="whiteBorderNoBg" type="email" placeholder="Votre email" name="email" required register={register} errors={errors} />
          </Flex>

          <Flex align="center" my={4}>
            <FormTextareaSimple variant="whiteBorderNoBg" placeholder="Votre message" name="message" required errors={errors} register={register} getValues={getValues} setValue={setValue} />
          </Flex>
        </Flex>

        <Button mt={4} variant="primary-dark" w="100%" fontSize="sm" colorScheme="teal" type="submit" isLoading={sendMessage.isLoading} isDisabled={!isValid}>
          Envoyer le message
        </Button>
      </form>
    </Box>
  );
};

export default ContactForm;
