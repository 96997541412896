import React from 'react';
import { useDiscordRoles } from '../../../hooks/discord/useDiscordRoles';
import PageLayout from '../../../layout/PageLayout';
import { Box, Table, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import DiscordRolesTable from './DiscordRolesTable';
import PageTitleUpdater from '../../../routing/PageTitleUpdater';

export const DiscordRoles: React.FC = () => {
  const { data, isLoading, isError, error } = useDiscordRoles();

  return (
    <>
      <PageTitleUpdater additionalTitle="Admin Panel - Liste roles discord" />
      <PageLayout isLoading={isLoading} isError={isError} error={error}>
        {data && data.length !== 0 && (
          <Box mt={4}>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Id</Th>
                  <Th>Nom</Th>
                  <Th>Role Id</Th>
                  <Th>Emoji</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data.map(discordRole => (
                  <DiscordRolesTable key={discordRole.id} discordRole={discordRole} />
                ))}
              </Tbody>
            </Table>
          </Box>
        )}
      </PageLayout>
    </>
  );
};
