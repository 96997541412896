import { useQuery, UseQueryResult } from 'react-query';
import api from '../../utils/api/api';
import { API_DISCORD_DISCUSSIONS_LATEST } from '../../utils/api/constants';
import { LastThread } from '../../types/Discord';

// Fonction pour obtenir les dernières discussions
const fetchLatestDiscussions = async (): Promise<LastThread[]> => {
  return api.get(API_DISCORD_DISCUSSIONS_LATEST).then(res => res.data);
};

export const useLatestDiscussions = (): UseQueryResult<LastThread[], Error> => {
  return useQuery('latestDiscussions', fetchLatestDiscussions, {
    refetchInterval: 300000, // Relance toutes les 5 minutes
  });
};
