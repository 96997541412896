import React from 'react';
import { Text, Flex, Image } from '@chakra-ui/react';

import { useDiscordRoles } from '../../../hooks/discord/userDiscordRoles';
import { DiscordRole } from '../../../types/DiscordRole';

interface GameRoleProps {
  game: string;
}

export function getNameById(games: DiscordRole[], id: string): string | null {
  const game = games.find(game => game.roleId === id);

  if (!game) return null;

  return game.name.replace('_game', '');
}

const GameRole: React.FC<GameRoleProps> = ({ game }) => {
  const { data: discordRoles } = useDiscordRoles();

  return (
    <Flex alignItems="center" mb={3}>
      <Image src="/assets/icons/sword.svg" alt="Tarif" width="27px" ml="-2px" />
      {discordRoles && <Text ml={2}>{getNameById(discordRoles, game)}</Text>}
    </Flex>
  );
};

export default GameRole;
