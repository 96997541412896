import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import api from '../../utils/api/api';
import { API_USERS_BLOCK_URI } from '../../utils/api/constants';

export const useBlockOrUnblockUser = (): {
  blockUser: UseMutationResult<void, Error, { userId: string; reason: string }>;
  unblockUser: UseMutationResult<void, Error, string>;
} => {
  const queryClient = useQueryClient();

  const blockUser = useMutation<void, Error, { userId: string; reason: string }>(({ userId, reason }) => api.post(`${API_USERS_BLOCK_URI}/${userId}`, { reason }).then(res => res.data), {
    onSuccess: () => {
      queryClient.invalidateQueries('users');
      queryClient.invalidateQueries('user');
    },
  });

  const unblockUser = useMutation<void, Error, string>((userId: string) => api.delete(`${API_USERS_BLOCK_URI}/${userId}`).then(res => res.data), {
    onSuccess: () => {
      queryClient.invalidateQueries('users');
      queryClient.invalidateQueries('user');
    },
  });

  return {
    blockUser,
    unblockUser,
  };
};
