import React, { useRef } from 'react';
import { AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, Button } from '@chakra-ui/react';
import { formatTextWithLineBreaks } from '../../utils/helpers';

type DeleteAlertDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  title: string;
  description: string;
};

const DeleteAlertDialog: React.FC<DeleteAlertDialogProps> = ({ isOpen, onClose, onDelete, title, description }) => {
  const cancelRef = useRef(null);

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>
          <AlertDialogBody>{formatTextWithLineBreaks(description)}</AlertDialogBody>
          <AlertDialogFooter>
            <Button variant="navRed" border="none" color="white" textDecoration="underline" onClick={onDelete}>
              Oui je supprime mon compte
            </Button>
            <Button variant="navYellow" ml={3} ref={cancelRef} onClick={onClose}>
              Non je garde mon compte
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default DeleteAlertDialog;
