export const API_USERS_URI = '/users';
export const API_USERS_ONLINE = '/users/last-connected';
export const API_USERS_REGISTER = '/users/last-register';
export const API_USERS_PUBLIC = '/users/public';
export const API_USERS_ME_URI = `${API_USERS_URI}/me`;
export const API_USERS_CHECK_EMAIL_URI = `${API_USERS_URI}/checkEmail`;
export const API_USERS_BLOCK_URI = `${API_USERS_URI}/block`;
export const API_USERS_CHECK_USERNAME_URI = `${API_USERS_URI}/checkUsername`;
export const API_USER_BY_USERNAME_URI = `${API_USERS_URI}/profile`;
export const API_STAFF_USERS_URI = `${API_USERS_URI}/staff/list`;
export const API_USERS_SEARCH_URI = `${API_USERS_URI}/search`;
export const API_USERS_LOCATIONS_URI = `${API_USERS_URI}/search/location`;

export const API_AUTH_URI = '/auth';
export const API_AUTH_CONNEXION_URI = `${API_AUTH_URI}/login`;
export const API_AUTH_DISCORD_CONNEXION_URI = `${API_AUTH_URI}/discord/login`;
export const API_REFRESH_TOKEN_URI = `${API_AUTH_URI}/refresh`;
export const API_LOGOUT_URI = `${API_AUTH_URI}/logout`;
export const API_CHECK_STREAM_STATUS_URI = `/twitch/stream-status`;
export const API_DISCORD = `/discord`;
export const API_DISCORD_CHANNELS = `${API_DISCORD}/channels`;
export const API_DISCORD_CHANNELS_ALL = `${API_DISCORD}/channels/all`;
export const API_DISCORD_THREAD_CREATE = `${API_DISCORD}/threads/create`;
export const API_DISCORD_THREAD_BY_TAG = `${API_DISCORD}/threads/tag`;
export const API_DISCORD_THREAD_BY_CHANNEL_ID = `${API_DISCORD}/threads/channelId`;
export const API_DISCORD_MESSAGES = `${API_DISCORD}/messages`;
export const API_DISCORD_SEND_DISCORD_MESSAGE = `${API_DISCORD}/forum/send/message`;
export const API_DISCORD_DISCUSSIONS_LATEST = `${API_DISCORD}/discussions/latest`;
export const API_DISCORD_STATS = `${API_DISCORD}/stats`;
export const API_DISCORD_ROLES = `${API_DISCORD}/roles`;
export const API_CONTESTS = `/contests`;
export const API_CONTESTS_PUBLIC = `/contests/public`;

export const API_CONTEST_SEND_MEDIA = `/contests/send-media`;
export const API_MEDIA_ADD = `/medias/add`;
export const API_MEDIA_VALIDATION = `/medias/validation`;
export const API_MEDIA_TO_VALIDATE = `/medias/to-validate`;

export const API_EVENTS = `/events`;
export const API_EVENTS_CREATE = `${API_EVENTS}/create`;
export const API_EVENTS_STATS = `${API_EVENTS}/users/stats`;

export const API_NEWS = `/news`;
export const API_NEWS_CREATE = `${API_NEWS}/create`;
export const API_NEWS_UPDATE = `${API_NEWS}/update`;
export const API_NEWS_LATEST = `${API_NEWS}/latest`;

export const API_ADMIN_USERS_CONNECTED = `${API_USERS_URI}/admin/connected`;
export const API_ADMIN_USERS_SEARCH_URI = `${API_USERS_URI}/admin/search`;
export const API_ADMIN_INVALID_USER_ACCOUNTS_URI = `${API_USERS_URI}/admin/invalid-accounts`;
export const API_ADMIN_VALID_ACCOUNT_URI = `${API_USERS_URI}/admin/validate-accounts`;
export const API_ADMIN_INVALID_EVENTS_URI = `${API_EVENTS}/admin/invalid-events`;
export const API_ADMIN_VALID_EVENTS_URI = `${API_EVENTS}/admin/validate-events`;
export const API_USERS_INSCRIPTION_LAST_7DAYS = `${API_USERS_URI}/admin/inscriptions-last-7-days`;
export const API_USERS_UPDATED = `${API_USERS_URI}/admin/updated`;
export const API_USERS_ADMIN_DELETE = `${API_USERS_URI}/admin/ban`;
export const API_USERS_UPDATE_VALIDATE = `${API_USERS_URI}/admin/validate-updated`;
export const API_CONTESTS_ADMIN = `/admin/contests`;
export const API_CONTEST_CREATE_ADMIN = `/admin/contests/create`;
export const API_GAME_ADD_ADMIN = `/admin/game/add`;
export const API_DISCORD_ROLE_ADD_ADMIN = `/discord/roles/add`;
export const API_BOT_MESSAGE_ADMIN = `/admin/bot/message`;
export const API_DISCORD_ROLES_ADMIN = `/discord/admin/roles`;
export const API_CONTEST_UPDATE_ADMIN = `/admin/contests/update`;
export const API_CONTEST_DRAW_ADMIN = `/admin/contests/draw`;

export const API_FETCH_METADATA = `/metadata/fetch`;

export const API_MESSAGES = `/messages`;
export const API_MESSAGES_CONVERSATIONS_LAST_SEVEN = `${API_MESSAGES}/admin/count-last-seven-days`;

export const API_VISITS = `/profile-visits`;
export const API_VISITS_READ = `${API_VISITS}/markAsRead`;
export const API_PASSWORD_RESET = `${API_USERS_URI}/password-reset-request`;
export const API_PASSWORD_RESET_VALID = `${API_USERS_URI}/password-reset`;
export const API_PASSWORD_PROFILE_RESET_VALID = `${API_USERS_URI}/password-profile-reset`;

export const API_CONTACT_SEND = `${API_USERS_URI}/contact/sendMessage`;
export const API_AUTH_CONTACT_SEND = `${API_USERS_URI}/contact/auth/sendMessage`;

// PUBLIC ROUTES
export const API_PUBLIC_EVENTS = `/public/events`;
export const API_PUBLIC_NEWS = `/public/news`;

export const API_HOME_STATS = `users/admin/home/stats`;
