import { useQuery, UseQueryResult } from 'react-query';
import { API_ADMIN_USERS_CONNECTED } from '../../utils/api/constants';
import { UserPublic } from '../../types/user';
import api from '../../utils/api/api';

const fetchConnectedMembers = async (): Promise<UserPublic[]> => {
  return api.get(API_ADMIN_USERS_CONNECTED).then(res => res.data);
};

export const useConnectedMembers = (): UseQueryResult<UserPublic[], Error> => {
  return useQuery('useConnectedMembers', fetchConnectedMembers, {
    retry: 0,
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  });
};
