import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link as ChakraLink } from '@chakra-ui/react';

interface LinkerProps {
  to: string;
  color?: string;
  textDecoration?: string;
  children: React.ReactNode;
}

const Linker: React.FC<LinkerProps> = ({ children, to, color, textDecoration = 'underline' }) => {
  return (
    <ChakraLink as={RouterLink} to={to} color={color} _hover={{ textDecoration: textDecoration }} _active={{ textDecoration: textDecoration }} _visited={{ textDecoration: textDecoration }}>
      {children}
    </ChakraLink>
  );
};

export default Linker;
