import React, { useRef } from 'react';

import { User } from '../../types/user';
import { useDiscordRoles } from '../../hooks/discord/userDiscordRoles';
import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  useBreakpointValue,
  VStack,
  Image,
  Text,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
  AlertDialogHeader,
} from '@chakra-ui/react';
import PageTitleUpdater from '../../routing/PageTitleUpdater';
import { customColors } from '../../theme';
import Ariane from '../../components/Ariane';
import FormImageUpload from '../../components/forms/formImageUpload/FormImageUpload';
import FormTextareaSimple from '../../components/forms/FormTextareaSimple';
import { capitalizeFirstLetter, getTypeEventColor, getTypeString } from '../../utils/helpers';
import FormInput from '../../components/forms/FormInput';
import { formatDateRange } from '../../utils/dates';
import FormDatePicker from '../../components/forms/FormDatePicker';
import { getGameRoles, getTypes, today, todayAddOneHour } from './create/constants';
import CityAutocomplete from '../../components/forms/CityAutocomplete';
import { getNameById } from './event/GameRole';
import FormSelect from '../../components/forms/FormSelect';
import mdToText from 'markdown-to-text';
import { useNavigate } from 'react-router-dom';
import { EVENTS_PATH } from '../../routing/constants';
import UserStringAvatar from '../../components/StringAvatar';
import { useAppContext } from '../../context/AppContext';

interface EventFormProps {
  user: User;
  eventTypeSelected: number;
  onSubmit: any;
  title: string;
  register: any;
  getValues: any;
  setValue: any;
  errors: any;
  isValid: any;
  isLoading: any;
  currentAvatar: any;
}

export const EventForm: React.FC<EventFormProps> = ({ currentAvatar, title, eventTypeSelected, isLoading, onSubmit, errors, setValue, getValues, register }) => {
  const [isPreview, setIsPreview] = React.useState<boolean>(false);
  const { user } = useAppContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: discordRoles } = useDiscordRoles();
  const cancelRef = useRef<any>();
  const navigate = useNavigate();

  const isMobile = useBreakpointValue({ base: 'none', md: 'initial' });
  const gridTemplateColumns =
    isMobile === 'none'
      ? 'repeat(auto-fill, minmax(100%, 1fr))' // Pleine largeur sur mobile lorsque la scrollbar est masquée
      : ['1fr', '1fr', '2fr 1.5fr'];

  const handleConfirmCancel = () => {
    onClose();
    navigate(EVENTS_PATH);
  };

  return (
    <>
      <PageTitleUpdater additionalTitle={title} />
      <Box as="form" onSubmit={onSubmit} h="calc(100% - 330px)">
        <Box h="330px">
          <Box position="absolute" width="100%" height="338px" ml={-7} mt={-5}>
            {/* Image comme fond d'une Box */}
            <Box as="div" bg={currentAvatar ? `url(${currentAvatar}) center/cover no-repeat` : customColors.greyChupacabra} position="relative" width="100%" height="100%">
              {/* Gradient */}
              <Box position="absolute" top="0" left="0" w="100%" h="100%" bgGradient="linear(92deg, #2C2C2B 0%, rgba(43, 44, 44, 0) 100%)" />

              {/* Contenu */}
              <Box position="relative" bottom="4" left="4" py="4" height="calc(100% - 8px)" mr={10}>
                <Ariane>
                  <Flex justifyContent="space-between" alignItems="center" pt={4}>
                    <Flex alignItems="center">
                      <Flex alignItems="center" ml="4">
                        <Image src="/assets/icons/green.svg" alt="EVENEMENTS" width="30px" />
                        <Text as="h1" fontSize="19px" textTransform="uppercase" ml="4">
                          événements
                        </Text>
                      </Flex>
                    </Flex>
                    {!isPreview && (
                      <Box>
                        <FormImageUpload
                          placeholder={currentAvatar ? "Modifier l'image" : 'Ajouter une image'}
                          name="image"
                          register={register}
                          setValue={setValue}
                          getValues={getValues}
                          errors={errors}
                        />
                      </Box>
                    )}
                  </Flex>
                </Ariane>
                <VStack zIndex={2} position="relative" justifyContent="space-between" alignItems="flex-start" ml="4" w={'400px'}>
                  <VStack alignItems="start" spacing="2">
                    <Flex alignItems={'center'}>
                      <Text color={getTypeEventColor(eventTypeSelected)}>{capitalizeFirstLetter(getTypeString(eventTypeSelected, true))}</Text>
                      {Number(eventTypeSelected) === 3 && isPreview && <Text ml={1}>- {getValues('type')}</Text>}
                    </Flex>
                    <Box>
                      {!isPreview && (
                        <Flex alignItems={'center'} mb={2}>
                          <Box>
                            <FormInput variant="whiteBorder" type="text" placeholder="Titre de l'event *" name="name" required register={register} errors={errors} />
                          </Box>
                        </Flex>
                      )}
                      {isPreview && (
                        <Flex alignItems="center" mb={1} mt={-3}>
                          <Text as="h1" fontWeight="bold" color="white">
                            {getValues('name')}
                          </Text>
                        </Flex>
                      )}
                      {isPreview ? (
                        <Text color="white" fontSize="13px">
                          {getValues('description')}
                        </Text>
                      ) : (
                        <Box mt={4} minW="400px">
                          <FormTextareaSimple
                            variant="whiteBorder"
                            height={150}
                            placeholder="Description courte *"
                            name="description"
                            maxLength={300}
                            required
                            errors={errors}
                            register={register}
                            getValues={getValues}
                            setValue={setValue}
                          />
                        </Box>
                      )}
                    </Box>
                  </VStack>
                </VStack>
              </Box>
            </Box>
          </Box>
        </Box>

        <Grid templateColumns={gridTemplateColumns} gap={4} px={2} zIndex={1} position={'relative'} h="100%">
          <Box pr={20}>
            {isPreview && user && (
              <Flex alignItems={'center'} mb={3} ml="-5px">
                <UserStringAvatar withBigBorder name={user.username} media={user.media} styles={{ size: 'xs' }} />
                <Text ml="2">{user.username}</Text>
              </Flex>
            )}
            {!isPreview && (
              <Text as="h1" mb={2} mt={2} color="yellowBob">
                Informations
              </Text>
            )}
            <Flex alignItems="center" mb={2}>
              <Image src="/assets/icons/calendar.svg" alt="Calendrier" width="27px" ml="-2px" />
              {isPreview ? (
                <Text ml={2}>{formatDateRange(getValues('scheduledStartTime'), getValues('scheduledEndTime'))}</Text>
              ) : (
                <>
                  <FormDatePicker
                    variant="whiteBorder"
                    placeholder="Date de début *"
                    name="scheduledStartTime"
                    minDate={today}
                    showTime={true}
                    required={true}
                    errors={errors}
                    register={register}
                    getValues={getValues}
                    setValue={setValue}
                    sx={{ ml: 2, mt: 0 }}
                    defaultDate={getValues('scheduledStartTime') ? getValues('scheduledStartTime') : undefined}
                  />
                  <FormDatePicker
                    variant="whiteBorder"
                    placeholder="Date de fin *"
                    name="scheduledEndTime"
                    minDate={getValues('scheduledStartTime') ? getValues('scheduledStartTime') : todayAddOneHour}
                    showTime={true}
                    required={true}
                    errors={errors}
                    register={register}
                    getValues={getValues}
                    setValue={setValue}
                    sx={{ ml: 2, mt: 0 }}
                    defaultDate={getValues('scheduledEndTime') ? getValues('scheduledEndTime') : undefined}
                  />
                </>
              )}
            </Flex>

            {Number(eventTypeSelected) === 3 && !isPreview && (
              <Flex alignItems="center" mb={2}>
                <Image src="/assets/icons/type.svg" alt="Type" width="27px" ml="-2px" />
                <FormSelect sx={{ ml: 2, mt: 0 }} variant="whiteBorder" placeholder="Selectionne le type" name="type" register={register} errors={errors} getValues={getValues} options={getTypes()} />
              </Flex>
            )}

            {Number(eventTypeSelected) === 3 && (
              <Flex alignItems="center" mb={2}>
                <Image src="/assets/icons/mapPin.svg" alt="Localisation" width="27px" ml="-2px" />
                {isPreview ? (
                  <>{getValues('location') && getValues('location.formattedAddress') && eventTypeSelected === 3 && <Text ml={2}>{getValues('location.formattedAddress')}</Text>}</>
                ) : (
                  <CityAutocomplete
                    sx={{ ml: 2 }}
                    variant="whiteBorder"
                    placeholder="Localisation *"
                    type="address"
                    name="location"
                    required
                    setValue={setValue}
                    getValues={getValues}
                    register={register}
                    errors={errors}
                  />
                )}
              </Flex>
            )}

            {Number(eventTypeSelected) === 2 && discordRoles && (
              <Flex alignItems="center" mb={2}>
                <Image src="/assets/icons/coins.svg" alt="Tarif" width="27px" ml="-2px" />
                {isPreview ? (
                  <>{getValues('game') && <Text ml={2}>{getNameById(discordRoles, getValues('game') as string)}</Text>}</>
                ) : (
                  <FormSelect
                    sx={{ ml: 2 }}
                    variant="whiteBorder"
                    placeholder="Selectionne ton jeu *"
                    name="game"
                    register={register}
                    errors={errors}
                    getValues={getValues}
                    options={getGameRoles(discordRoles)}
                  />
                )}
              </Flex>
            )}

            {Number(eventTypeSelected) === 1 && (
              <Flex alignItems="center" mb={2}>
                <Image src="/assets/icons/mapPin.svg" alt="Localisation" width="27px" ml="-2px" />
                {isPreview ? (
                  <Link href={getValues('twitch')} isExternal ml={2} style={{ textDecoration: 'underline' }}>
                    {getValues('twitch')}
                  </Link>
                ) : (
                  <FormInput
                    sx={{ ml: 2 }}
                    variant="whiteBorder"
                    type="text"
                    placeholder="Chaine Twitch"
                    name="twitch"
                    required
                    defaultValue={'https://www.twitch.tv/entregeeksgaming'}
                    register={register}
                    errors={errors}
                  />
                )}
              </Flex>
            )}

            <>
              {isPreview && getValues('amount') && eventTypeSelected === 3 && (
                <Flex alignItems="center" mb={2}>
                  <Image src="/assets/icons/coins.svg" alt="Tarif" width="27px" ml="-2px" />
                  <Text ml={2}>{getValues('amount')}€</Text>
                </Flex>
              )}

              {!isPreview && eventTypeSelected === 3 && (
                <Flex alignItems="center" mb={2}>
                  <Image src="/assets/icons/coins.svg" alt="Tarif" width="27px" ml="-2px" />
                  <FormInput sx={{ ml: 2 }} variant="whiteBorder" type="number" placeholder="Tarif (Nombre uniquement)" name="amount" register={register} errors={errors} />
                </Flex>
              )}
            </>
            <>
              {isPreview && getValues('url') && eventTypeSelected === 3 && (
                <Flex alignItems="center" mb={2}>
                  <Image src="/assets/icons/globeHemisphereEast.svg" alt="Url" width="27px" ml="-2px" />
                  <Link href={getValues('url')} isExternal ml={2} style={{ textDecoration: 'underline' }}>
                    {getValues('url')}
                  </Link>
                </Flex>
              )}

              {!isPreview && eventTypeSelected === 3 && (
                <Flex alignItems="center" mb={2}>
                  <Image src="/assets/icons/globeHemisphereEast.svg" alt="Url" width="27px" ml="-2px" />
                  <FormInput sx={{ ml: 2 }} variant="whiteBorder" type="url" placeholder="Lien" name="url" register={register} errors={errors} />
                </Flex>
              )}
            </>

            <>
              <Text as="h1" mb={2} mt={4} color="yellowBob">
                Toujours plus d'infos
              </Text>
              {isPreview && <Text>{getValues('descriptionMore') ? mdToText(getValues('descriptionMore') as string) : 'Ah bas non :/'}</Text>}

              {!isPreview && (
                <FormTextareaSimple
                  variant="whiteBorder"
                  placeholder="Infos supplémentaire"
                  name="descriptionMore"
                  maxLength={300}
                  errors={errors}
                  register={register}
                  getValues={getValues}
                  setValue={setValue}
                />
              )}
            </>
          </Box>
          <Flex justifyContent="space-between" flexDirection="column" p={4} height="100%">
            {eventTypeSelected === 3 && (
              <Box h="100%">
                <Text as="h1" color="yellowBob">
                  Bienvenue sur la création d'une sortie !
                </Text>

                <Text mt={4}>Les sorties EG, c'est la crème de la crème, le cœur battant de la convivialité !</Text>
                <Text mt={4}>Elles sont ton billet d'or pour passer du monde virtuel du forum à la réalité en chair et en os ! Et quand tu es aux commandes, tu es le roi du monde !</Text>
                <Text mt={4}>Alors, déballe tous les détails croustillants pour captiver les curieux.</Text>
                <Text mt={4}>Et si tu te grattes la tête avec une question, n'hésite pas à interpeller un membre du staff. Nous sommes toujours là, prêts à bondir à la rescousse !</Text>
              </Box>
            )}

            {eventTypeSelected === 2 && (
              <Box h="100%">
                <Text as="h1" color="yellowBob">
                  Bienvenue sur la création d'une Game !
                </Text>

                <Text mt={4}>
                  Tu peux organiser des parties en ligne sur des jeux multi-joueurs, qui seront automatiquement partagées sur Discord. Cela alerte une armée de joueurs de se joindre à toi - plus on
                  est de fous, plus on rit !
                </Text>
                <Text mt={4}>Le top du top ? Planifie ta partie au moins une heure à l'avance. Cela donne le temps aux gamers de chauffer leurs manettes et de rappliquer !</Text>
                <Text mt={4}>
                  Tu disposes d'une liste de jeux liée à Discord. Ton jeu fétiche n'est pas là ? Pas de panique, sélectionne le jeu 'Autre'. Et pour la cerise sur le gâteau, une image de couverture
                  est générée automatiquement pour ta partie. Tu n'as même pas à lever le petit doigt pour en chercher une ! Bien sûr, si l'envie te prend de la personnaliser, libre à toi de jouer les
                  artistes !
                </Text>
              </Box>
            )}

            {eventTypeSelected === 1 && (
              <Box h="100%">
                <Text as="h1" color="yellowBob">
                  Bienvenue sur la création d'un stream !
                </Text>

                <Text mt={4}>N'oublie pas de bien étoffer la description de ton stream !</Text>
                <Text mt={4}>
                  Et bonne nouvelle : pas besoin de jouer au technicien en activant ton stream. Notre site est équipé d'une sorte de sixième sens numérique ! Il détecte automatiquement si ton stream
                  est en ligne et le présente aux membres comme par magie. Alors, concentre-toi sur ton show, on s'occupe du reste.
                </Text>
              </Box>
            )}
            <Flex justifyContent="end">
              <Button variant="navRed" border="none" color="whiteWalter" onClick={onOpen}>
                Annuler
              </Button>
              <Button ml={3} variant="navYellow" onClick={() => setIsPreview(!isPreview)}>
                Aperçu
              </Button>
              <Button variant="navGreen" ml={3} type="submit" isLoading={isLoading}>
                Valider
              </Button>
            </Flex>
          </Flex>
        </Grid>
      </Box>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" border="none" color="whiteWalter" fontWeight="bold">
              Annuler la création
            </AlertDialogHeader>

            <AlertDialogBody>Êtes-vous sûr de vouloir annuler ?</AlertDialogBody>

            <AlertDialogFooter>
              <Button variant="navRed" border="none" color="white" textDecoration="underline" onClick={handleConfirmCancel}>
                Oui, quitter la création
              </Button>
              <Button ml={3} variant="navYellow" ref={cancelRef} onClick={onClose}>
                Non
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
