/* eslint-disable max-len */
import React from 'react';
import MarkDownStyled from '../../../components/MarkDownStyled';
import PageLayout from '../../../layout/noAuth/wrappers/PageLayout';
import PageTitleUpdater from '../../../routing/PageTitleUpdater';

const text = `# Mentions légales

## Mentions légales

Le site Entre-geeks.com est édité par : Maxime Champeval

Le site Entre-geeks.com est hébergé par : Digital Ocean

## Les images uploadées sur le site

Toutes les images uploadées sur le site via la fonction interne sont obligatoirement modérées par un modérateur.
Toutes images uploads sur le site sont hébergées par : Amazon S3

## Concours Entre Geeks

Les concours sur entre geeks sont sans obligation d'achat et n'entraine aucun engagement de votre part.
`;
const LegalNotice: React.FC = () => {
  return (
    <PageLayout>
      <PageTitleUpdater additionalTitle="Mentions légales" />
      <MarkDownStyled text={text} />
    </PageLayout>
  );
};

export default LegalNotice;
