import React from 'react';
import { Box, HStack, IconButton } from '@chakra-ui/react';
import { AiFillCheckCircle, AiFillDelete } from 'react-icons/ai';
import { Event } from '../../../../types/Events';
import AdminEventCard from '../AdminEventCard';
import { formatUri } from '../../../../utils/api/helpers';
import { EVENTS_UPDATE_PATH } from '../../../../routing/constants';
import { useNavigate } from 'react-router-dom';
import { BiEdit } from 'react-icons/bi';

interface EventAminCardProps {
  event: Event;
  onValidate: () => void;
  onDelete: () => void;
}

const EventAminCard: React.FC<EventAminCardProps> = ({ event, onValidate, onDelete }) => {
  const navigate = useNavigate();
  return (
    <Box borderWidth="1px" borderRadius="lg" p={4} m={2} height="100%">
      <HStack justifyContent="space-between">
        <HStack>
          <IconButton aria-label="Valider" icon={<AiFillCheckCircle />} onClick={onValidate} />
          <IconButton aria-label="Editer" icon={<BiEdit />} onClick={() => navigate(formatUri({ slug: event.slug }, EVENTS_UPDATE_PATH))} />
          <IconButton aria-label="Supprimer" icon={<AiFillDelete />} onClick={onDelete} />
        </HStack>
      </HStack>
      <AdminEventCard event={event} />
    </Box>
  );
};

export default EventAminCard;
