import React from 'react';
import { Box, Flex, Text, useBreakpointValue } from '@chakra-ui/react';
import { useTimer } from 'react-timer-hook';

interface TimerProps {
  startDate: string;
  endDate: string;
}

const CountdownTimer: React.FC<TimerProps> = ({ endDate }) => {
  const { seconds, minutes, hours, days } = useTimer({ expiryTimestamp: new Date(endDate), onExpire: () => console.warn('Timer expired!') });
  const isMobile = useBreakpointValue({ base: true, md: false });
  const formatNumber = (num: number) => num.toString().padStart(2, '0');

  return (
    <Flex align="left" justify="left" gap="2" mt={4}>
      {/* Jours */}
      <Box px={2} textAlign="center" minW={'65px'}>
        <Flex justifyContent="center">
          <Text fontSize={isMobile ? '30px' : '50px'} fontWeight="bold" lineHeight="45px">
            {formatNumber(days)}
          </Text>
        </Flex>
        <Text fontSize={isMobile ? '13px' : '16px'} fontWeight="bold" color="greyChupacabra">
          JOURS
        </Text>
      </Box>

      {/* Heures */}
      <Box px={2} textAlign="center" minW={'65px'}>
        <Flex justifyContent="center">
          <Text fontSize={isMobile ? '30px' : '50px'} fontWeight="bold" lineHeight="45px">
            {formatNumber(hours)}
          </Text>
        </Flex>
        <Text fontSize={isMobile ? '13px' : '16px'} fontWeight="bold" color="greyChupacabra">
          HEURES
        </Text>
      </Box>

      {/* Minutes */}
      <Box px={2} textAlign="center" minW={'65px'}>
        <Flex justifyContent="center">
          <Text fontSize={isMobile ? '30px' : '50px'} fontWeight="bold" lineHeight="45px">
            {formatNumber(minutes)}
          </Text>
        </Flex>
        <Text fontSize={isMobile ? '13px' : '16px'} fontWeight="bold" color="greyChupacabra">
          MINUTES
        </Text>
      </Box>

      {/* Secondes */}
      <Box px={2} textAlign="center" minW={'80px'}>
        <Flex justifyContent="center">
          <Text fontSize={isMobile ? '30px' : '50px'} fontWeight="bold" lineHeight="45px">
            {formatNumber(seconds)}
          </Text>
        </Flex>
        <Text fontSize={isMobile ? '13px' : '16px'} fontWeight="bold" color="greyChupacabra">
          SECONDES
        </Text>
      </Box>
    </Flex>
  );
};

export default CountdownTimer;
