import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { Passions } from '../../../types/userProfile';
import PassionsElement from './PassionsElement';

interface PassionsComponentProps {
  passions: Passions;
}

const PassionsComponent: React.FC<PassionsComponentProps> = ({ passions }) => {
  return (
    <Box>
      <Text as="h3" mb={2} color="yellowBob">
        Passions
      </Text>
      <Flex flexWrap="wrap" justifyContent="flex-start">
        <PassionsElement name="Animes" value={passions.animes} />
        <PassionsElement name="Jeux Consoles" value={passions.gameConsole} />
        <PassionsElement name="Manga" value={passions.mangas} />
        <PassionsElement name="Jeux de cartes" value={passions.gameCard} />
        <PassionsElement name="Culture Jap" value={passions.japCulture} />
        <PassionsElement name="Programmation" value={passions.programmation} />
        <PassionsElement name="FPS" value={passions.fps} />
        <PassionsElement name="Jeux de role IRL" value={passions.jdrIrl} />
        <PassionsElement name="MEUPORG" value={passions.mmo} />
        <PassionsElement name="Jeux sur table" value={passions.jdrTable} />
        <PassionsElement name="Jeux PC" value={passions.gamePc} />
        <PassionsElement name="Comics" value={passions.comics} />
        <PassionsElement name="Scans" value={passions.scans} />
        <PassionsElement name="Cinéma" value={passions.cinema} />
      </Flex>
    </Box>
  );
};

export default PassionsComponent;
