import React from 'react';
import { Box, HStack, IconButton } from '@chakra-ui/react';
import { AiFillCheckCircle, AiOutlineEye } from 'react-icons/ai';
import { User } from '../../../../types/user';
import UsernameFormatted from '../../../../components/UsernameFormatted';
import AdminTableUser from '../../AdminTableUser';
import { formatUri } from '../../../../utils/api/helpers';
import { ADMIN_PROFILE_UPDATE_PATH, PROFILE_PATH } from '../../../../routing/constants';
import { FaRegEdit } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

interface UserAminCardProps {
  user: User;
  onValidate: () => void;
}

const UpdateUserAdminCard: React.FC<UserAminCardProps> = ({ user, onValidate }) => {
  const navigate = useNavigate();
  const handleOpenNewTab = () => {
    window.open(formatUri({ username: user.username }, PROFILE_PATH), '_blank');
  };
  return (
    <Box borderWidth="1px" borderRadius="lg" p={4} m={2} height="100%">
      <HStack justifyContent="space-between">
        <UsernameFormatted user={user} styles={{ fontSize: 'xl', textAlign: 'center' }} />
        <Box>
          <IconButton aria-label="Show" icon={<AiOutlineEye />} onClick={handleOpenNewTab} />
          <IconButton ml={4} aria-label="Valider" icon={<AiFillCheckCircle />} onClick={onValidate} />
          <IconButton ml={4} aria-label="Edit" icon={<FaRegEdit />} onClick={() => navigate(formatUri({ username: user.username }, ADMIN_PROFILE_UPDATE_PATH))} />
        </Box>
      </HStack>
      <AdminTableUser user={user} />
    </Box>
  );
};

export default UpdateUserAdminCard;
