import React from 'react';

import { useConnectedMembers } from '../../../hooks/users/useConnectedMembers';
import PageLayout from '../../../layout/PageLayout';
import UserWithCoverCard from '../../../components/UserWithCoverCard';
import { Flex, Image, SimpleGrid, Text, useMediaQuery } from '@chakra-ui/react';
import PageTitleUpdater from '../../../routing/PageTitleUpdater';
import Ariane from '../../../components/Ariane';

const Connected: React.FC = () => {
  const { data, isError, error, isLoading } = useConnectedMembers();
  const [isLessThan1050] = useMediaQuery('(max-width: 1050px)');

  return (
    <PageLayout isLoading={isLoading} error={error} isError={isError}>
      <PageTitleUpdater additionalTitle={`Admin Panel - Connectés`} />
      <Ariane>
        <Flex alignItems="center">
          <Image src="/assets/icons/green-2.svg" alt="Membres" width="30px" />
          <Text as="h1" textTransform="uppercase" fontSize="19px" ml={4}>
            Membres en ligne
          </Text>
        </Flex>
      </Ariane>
      <SimpleGrid columns={isLessThan1050 ? [1] : [1, 2]} spacingX={5} spacingY={4} mb={isLessThan1050 ? 6 : 0}>
        {data && data.map(user => <UserWithCoverCard user={user} key={user.id} />)}
      </SimpleGrid>
    </PageLayout>
  );
};

export default Connected;
