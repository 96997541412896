import { useQuery, UseQueryResult } from 'react-query';
import api from '../../utils/api/api';
import { User } from '../../types/user';
import { API_USERS_SEARCH_URI } from '../../utils/api/constants';
import { QueryUserParams } from '../../pages/users/search/constants';

interface UserSearchResponse {
  users: User[];
  total: number;
}

export const useSearchUsers = (options: QueryUserParams): UseQueryResult<UserSearchResponse, unknown> => {
  const { username = '', skip = 0, limit = 8, location } = options;

  return useQuery(
    ['events', username, JSON.stringify(location), skip, limit], // Utiliser JSON.stringify pour éviter des problèmes de cache quand location est un objet
    async () => {
      const queryParams = new URLSearchParams();

      if (username) queryParams.append('username', username);
      if (location) {
        if (location.country) queryParams.append('country', location.country);
        if (location.region) queryParams.append('region', location.region);
        if (location.department) queryParams.append('department', location.department);
      }
      if (skip) queryParams.append('skip', String(skip));
      if (limit) queryParams.append('limit', String(limit));

      const { data } = await api.get<UserSearchResponse>(`${API_USERS_SEARCH_URI}?${queryParams.toString()}`);
      return data;
    },
    {
      keepPreviousData: true,
    },
  );
};
