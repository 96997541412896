import React from 'react';
import { Box, Button } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useSendDiscordMessage } from '../../hooks/discord/forum/useSendDiscordMessage';
import { DiscordMessageType } from './constants';
import MarkdownTextarea from '../forms/MarkdownTextarea';

interface SendDiscordMessageSimpleProps {
  channelId: string;
}

const SendDiscordMessageSimple: React.FC<SendDiscordMessageSimpleProps> = ({ channelId }) => {
  const sendMessage = useSendDiscordMessage();

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    getValues,
  } = useForm<DiscordMessageType>({
    mode: 'onChange',
  });

  const sendMessageHandler = () => {
    try {
      sendMessage.mutate({ channelId, message: getValues('message') });
      reset();
    } catch (error: any) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <Box as="form" onSubmit={handleSubmit(sendMessageHandler)} mb={4}>
      <MarkdownTextarea errors={errors} required={true} placeholder="Entrez pour envoyez, Maj + Entrée pour ajouter une nouvelle ligne" name="message" getValues={getValues} setValue={setValue} />
      <Button display="none" id="submit-button" type="submit" colorScheme="blue">
        Envoyer
      </Button>
    </Box>
  );
};

export default SendDiscordMessageSimple;
