import { useQuery, UseQueryResult } from 'react-query';
import api from '../../utils/api/api';
import { User } from '../../types/user';
import { API_USERS_PUBLIC } from '../../utils/api/constants';
import { useState } from 'react';

export const useGetUserByUsername = (username: string): UseQueryResult<User, unknown> => {
  return useQuery(['userMessage', username], () => api.get(`${API_USERS_PUBLIC}/${username}`).then(res => res.data), {
    refetchOnWindowFocus: false,
    retry: 0,
  });
};
type UseGetUserByUsernameTempReturn = UseQueryResult<User, unknown> & {
  setUsername: React.Dispatch<React.SetStateAction<string | null>>;
};

export const useGetUserByUsernameTemp = (): UseGetUserByUsernameTempReturn => {
  const [username, setUsername] = useState<string | null>(null);

  const queryInfo = useQuery<User, unknown>(['userMessage', username], () => api.get(`${API_USERS_PUBLIC}/${username}`).then(res => res.data), {
    enabled: username !== null,
    refetchOnWindowFocus: false,
    retry: 0,
  });

  return { ...queryInfo, setUsername };
};
