import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { API_AUTH_DISCORD_CONNEXION_URI } from '../../utils/api/constants';
import api from '../../utils/api/api';
import { User } from '../../types/user';

type DiscordConnexionForm = {
  userId: any;
  token: string;
  refreshToken: string;
};

const useDiscordConnexion = (): UseMutationResult<User, any, DiscordConnexionForm, unknown> => {
  const queryClient = useQueryClient();

  return useMutation<User, any, DiscordConnexionForm>((data: DiscordConnexionForm) => api.post(API_AUTH_DISCORD_CONNEXION_URI, data), {
    onSuccess: () => {
      queryClient.invalidateQueries('user');
    },
  });
};

export default useDiscordConnexion;
