import React, { useState, useEffect, useRef } from 'react';
import { User } from '../../../types/user';
import { useGetMessagesBetweenUsers } from '../../../hooks/message/useGetMessages';
import { Box, Button, Text, useBreakpointValue } from '@chakra-ui/react';
import { useSocketContext } from '../../../context/SocketContext';
import MessageCard from './MessageCard';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { messageSchema } from '../yup/messageSchema';
import MarkdownTextarea from '../../../components/forms/MarkdownTextarea';
import { customScrollBar, smoothScrollToTop } from '../../../utils/helpers';
import { useGetUserByUsername } from '../../../hooks/users/useGetUserByUsername';

import PageLayout from '../../../layout/PageLayout';
import MessageInfosUser from '../list/MessageInfosUser';
import { toast } from 'react-toastify';
import InSavedChangesPrompt from '../../../components/InSavedChangesPrompt';

type MessageComponentProps = {
  username: string;
  currentUser: User;
};

const MessageComponent: React.FC<MessageComponentProps> = ({ username, currentUser }) => {
  const { data: targetUser, isLoading, isError, error } = useGetUserByUsername(username);
  const isMobileView = useBreakpointValue({ base: true, md: false });

  const [lastSent, setLastSent] = useState<number | null>(null);
  const [messageLimitTime, setMessageLimitTime] = useState<boolean>(false);
  const { sendMessage, updateMessageRead } = useSocketContext();

  const isCurrentUserBlocked = currentUser.blockedUsers?.some(blockedUser => blockedUser.id === targetUser?.id) || false;
  const isUserBlocked = targetUser?.blockedUsers?.some(blockedUser => blockedUser.id === currentUser.id) || false;

  const lastMessageElementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (targetUser) {
      updateMessageRead(targetUser.id);
    }
  }, [targetUser]);

  const {
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm<{ content: string }>({
    resolver: yupResolver(messageSchema),
  });

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (messageLimitTime) {
      timer = setTimeout(() => {
        setMessageLimitTime(false);
      }, 5000);
    }

    return () => clearTimeout(timer);
  }, [messageLimitTime]);

  const { data: messages } = useGetMessagesBetweenUsers(targetUser?.id);

  const sendMessageHandler = () => {
    if (!targetUser) {
      return;
    }
    const now = Date.now();
    if (lastSent && now - lastSent < 10000) {
      setMessageLimitTime(true);
      return;
    }

    sendMessage({ receiverId: targetUser.id, content: getValues('content') }, success => {
      if (success) {
        toast.success(`Message bien énvoyé !`, {
          position: toast.POSITION.TOP_CENTER,
        });
        reset();
        setLastSent(now);
        if (lastMessageElementRef.current) {
          smoothScrollToTop(lastMessageElementRef.current, 500); // 500ms pour le défilement
        }
      } else {
        toast.error(`Une erreur c'est produite lors de l'envoi du message.`, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  return (
    <PageLayout isLoading={isLoading} isError={isError} error={error}>
      <Box position="relative" mb={isMobileView ? 6 : 0}>
        {targetUser && <MessageInfosUser currentUser={currentUser} targetUser={targetUser} />}
        <Box w={'100%'} display="flex" flexDirection="column" mt={-4} h={isMobileView ? 'auto' : 'calc(100vh - 450px)'} justifyContent="space-between">
          {!isUserBlocked && !isCurrentUserBlocked && isMobileView && (
            <Box as="form" onSubmit={handleSubmit(sendMessageHandler)} mb={4}>
              <MarkdownTextarea
                errors={errors}
                required={true}
                placeholder="Entrez pour envoyez, Maj + Entrée pour ajouter une nouvelle ligne"
                name="content"
                getValues={getValues}
                setValue={setValue}
              />
              <Button display="none" id="submit-button" type="submit" colorScheme="blue">
                Envoyer
              </Button>
            </Box>
          )}
          <PageLayout isLoading={false}>
            <Box ref={lastMessageElementRef} css={customScrollBar} overflowY="auto" mb={4} pr={2}>
              {messageLimitTime && (
                <Box p={2} borderRadius={10} bg="orangeMecanique" mx={4} mb={4}>
                  <Text>Veuillez attendre 10 secondes entre chaque envoi de message.</Text>
                </Box>
              )}
              {messages?.map((message, index) => (
                <Box key={index}>{targetUser && <MessageCard message={message} currentUser={currentUser} targetUser={targetUser} />}</Box>
              ))}
            </Box>
          </PageLayout>

          {!isUserBlocked && !isCurrentUserBlocked && !isMobileView && (
            <Box as="form" onSubmit={handleSubmit(sendMessageHandler)}>
              {/* Contenu du formulaire */}
              <MarkdownTextarea
                errors={errors}
                required={true}
                placeholder="Entrez pour envoyez, Maj + Entrée pour ajouter une nouvelle ligne"
                name="content"
                getValues={getValues}
                setValue={setValue}
              />
              <Button display="none" id="submit-button" type="submit" colorScheme="blue">
                Envoyer
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      <InSavedChangesPrompt isDirty={!!(getValues('content') && getValues('content').length !== 0)} />
    </PageLayout>
  );
};

export default MessageComponent;
