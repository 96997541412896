import React from 'react';
import { Box, Text, useBreakpointValue } from '@chakra-ui/react';

interface FooterColumnProps {
  title: string;
  children: React.ReactNode;
}

const FooterColumn: React.FC<FooterColumnProps> = ({ title, children }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Box mr={isMobile ? 4 : 20} mb={6}>
      <Text fontWeight="bold" mb={2}>
        {title}
      </Text>
      <Box display="flex" flexDirection="column">
        {children}
      </Box>
    </Box>
  );
};

export default FooterColumn;
