import { Box, Text, Image, VStack } from '@chakra-ui/react';
import { S3_URL } from '../../../../utils/constants';
import React from 'react';
import { News } from '../../../../types/News';
import PageTitleUpdater from '../../../../routing/PageTitleUpdater';
import { removeMarkdown } from '../../../../utils/helpers';
import Markdown from 'react-markdown';

interface PublicNewsCardProps {
  news: News;
}

const PublicNewsCard: React.FC<PublicNewsCardProps> = ({ news }) => {
  return (
    <Box borderWidth="1px" borderRadius="lg" p="4" w="100%">
      <PageTitleUpdater additionalTitle={`Events - ${news.title}`} additionalDescription={removeMarkdown(news.text)} additionalOgImage={`${S3_URL}/news/${news.media}`} />
      {news.media && <Image w="100%" objectFit="cover" src={`${S3_URL}/news/${news.media}`} alt={news.title} />}
      <VStack align="start" spacing={2}>
        <Text as="h1">{news.title}</Text>
        <Box style={{ whiteSpace: 'pre-line' }} className="markdown">
          <Markdown>{news.text}</Markdown>
        </Box>
        {news?.link && (
          <Text>
            Lien:
            <a href={news?.link} target="_blank" rel="noreferrer">
              {news?.link}
            </a>
          </Text>
        )}
      </VStack>
    </Box>
  );
};

export default PublicNewsCard;
