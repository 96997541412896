import React, { createContext, useContext, useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { BACKEND_URL } from '../utils/constants';
import { useAppContext } from './AppContext';
import { queryClient } from '../utils/reactQuery/config';
import soundFileVisit from '../sound/visit.mp3';
import soundFileMessage from '../sound/message.mp3';
import { toast } from 'react-toastify';

interface MessagePayload {
  receiverId: string;
  content: string;
}

interface UnreadData {
  visits: number;
  messages: number;
}

interface SocketContextProps {
  onlineUsers: number;
  unreadData: UnreadData;
  sendMessage: (payload: MessagePayload, callback: (success: boolean) => void) => void;
  recordVisit: (username: string) => void;
  updateMessageRead: (targetUserId: string) => void;
  updateVisitsRead: () => void;
}

export const SocketContext = createContext<SocketContextProps | null>(null);

interface SocketContextProviderProps {
  children: React.ReactNode;
}

export const SocketContextProvider: React.FC<SocketContextProviderProps> = ({ children }) => {
  const [onlineUsers, setOnlineUsers] = useState<number>(0);
  const [unreadData, setUnreadData] = useState<UnreadData>({ messages: 0, visits: 0 });
  const [socket, setSocket] = useState<Socket | null>(null);
  const { user } = useAppContext();

  useEffect(() => {
    const newSocket: Socket = io(BACKEND_URL, { withCredentials: true });
    setSocket(newSocket);

    newSocket.on('updatedUnreadMessages', data => {
      setUnreadData(prevState => ({ ...prevState, messages: data }));
    });

    newSocket.on('updatedUnreadVisits', data => {
      setUnreadData(prevState => ({ ...prevState, visits: data }));
    });

    newSocket.on('onlineUsers', (data: number) => {
      setOnlineUsers(data);
    });

    newSocket.on('unreadCounts', ({ unreadMessages, unreadVisits }) => {
      setUnreadData({ messages: unreadMessages, visits: unreadVisits });
    });

    newSocket.on(`newMessageTo:${user?.id}`, ({ newMessage, unreadMessagesCount }) => {
      if (!newMessage.isRead) {
        queryClient.invalidateQueries(['messagesBetween', newMessage.receiverId]);
        queryClient.invalidateQueries(['messagesBetween', newMessage.senderId]);
        queryClient.invalidateQueries('conversations');
        setUnreadData(prevState => ({ ...prevState, messages: unreadMessagesCount }));
        toast.info('Tu as reçu un nouveau message !', {
          position: toast.POSITION.TOP_CENTER,
        });
        if (user?.isSoundMessage) {
          const audio = new Audio(soundFileMessage);
          audio.play().catch(e => console.error('Erreur lors de la lecture du son:', e));
        }
      }
    });

    newSocket.on(`newVisitTo:${user?.id}`, ({ visitorId, unreadVisitsCount }) => {
      setUnreadData(prevState => ({ ...prevState, visits: unreadVisitsCount }));
      queryClient.invalidateQueries('userVisits');
      if (visitorId) {
        toast.info("Quelqu'un a visité ton profil !", {
          position: toast.POSITION.TOP_CENTER,
        });
        if (user?.isSoundVisit) {
          const audio = new Audio(soundFileVisit);
          audio.play().catch(e => console.error('Erreur lors de la lecture du son:', e));
        }
      }
    });

    return () => {
      newSocket.disconnect();
    };
  }, [user?.id]);

  const sendMessage = (payload: MessagePayload, callback: (success: boolean) => void) => {
    socket?.emit('sendMessage', payload, (response: { success: boolean }) => {
      if (response.success) {
        queryClient.invalidateQueries(['messagesBetween', payload.receiverId]);
        queryClient.invalidateQueries(['messagesBetween', user?.id]);
      }
      callback(response.success);
    });
  };

  const recordVisit = (username: string) => {
    socket?.emit('recordVisit', { username });
  };

  const updateMessageRead = (targetUserId: string) => {
    socket?.emit('markAsReadMessage', { targetUserId });
    queryClient.invalidateQueries('conversations');
  };

  const updateVisitsRead = () => {
    socket?.emit('markVisitsAsRead', {});
  };

  return <SocketContext.Provider value={{ onlineUsers, sendMessage, recordVisit, unreadData, updateMessageRead, updateVisitsRead }}>{children}</SocketContext.Provider>;
};

export const useSocketContext = () => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error('useSocketContext must be used within an SocketContextProvider');
  }
  return context;
};
