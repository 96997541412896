import { useQuery, UseQueryResult } from 'react-query';
import api from '../../utils/api/api';
import { User } from '../../types/user';
import { API_ADMIN_INVALID_USER_ACCOUNTS_URI } from '../../utils/api/constants';

export const useInvalidAccounts = (): UseQueryResult<User[], unknown> => {
  return useQuery<User[]>(
    ['invalidAccounts'],
    async () => {
      const { data } = await api.get(API_ADMIN_INVALID_USER_ACCOUNTS_URI);
      return data;
    },
    {
      refetchInterval: 5 * 60 * 1000, // 15 minutes en millisecondes
    },
  );
};
