import React, { useRef } from 'react';
import { Box, Container, Flex, Image, useBreakpointValue, useMediaQuery, Link as ChakraLink } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useOnScreen } from '../../../hooks/useOnScreen';
import { Link as RouterLink } from 'react-router-dom';

const MotionBox = motion(Box);

const Bottom: React.FC = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [isLessThan1160] = useMediaQuery('(max-width: 1160px)');
  const ref = useRef<any>();
  const isVisible = useOnScreen(ref);
  return (
    <Container maxW="container.2xl" pt="100px" zIndex="1">
      <Box
        position="relative"
        color="whiteTrue"
        px={5}
        py={isLessThan1160 ? 10 : 0}
        bgColor="blackTegrite"
        zIndex="1"
        borderRadius={21}
        overflow="hidden"
        flex={1}
        mx={isMobile ? 0 : 4}
        mb={isMobile ? 4 : 0}
      >
        <Flex alignItems="center" justifyContent="space-between" ref={ref}>
          {!isLessThan1160 && (
            <Flex flex="1" justifyContent="left" alignItems="center" ml="-20px">
              {isVisible && (
                <MotionBox
                  initial={{ opacity: 0, y: 30 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 30, delay: 0, duration: 0.5 }}
                  transition={{ type: 'spring', damping: 10, stiffness: 100, delay: 0.4, duration: 0.8 }}
                >
                  <Image src="/assets/homeNoAuth/phone-girl.png" alt="Mobile" maxH="700px" />
                </MotionBox>
              )}
            </Flex>
          )}
          <Flex flex="1" flexDirection="column" justifyContent="center" alignItems="start" paddingLeft="20px">
            <Box maxW="580px">
              {isVisible && (
                <>
                  <MotionBox
                    initial={{ opacity: 0, y: 30 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -30 }}
                    transition={{ delay: 0.4, duration: 0.3, exit: { delay: 0, duration: 0.1 } }}
                    style={{
                      marginBottom: '15px',
                      fontSize: isLessThan1160 ? '30px' : '55px',
                      lineHeight: isMobile ? '40px' : '66px',
                      letterSpacing: '0.2em',
                      fontFamily: 'Barlow Condensed',
                      fontWeight: '700',
                      color: 'white',
                      textTransform: 'uppercase',
                    }}
                  >
                    Revolutionne ton experience avec L’application ENTRE-GEEKS
                  </MotionBox>
                  <MotionBox
                    style={{
                      marginTop: '30px',
                    }}
                    initial={{ opacity: 0, y: 30 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -30 }}
                    transition={{ delay: 0.3, duration: 0.9, exit: { delay: 0, duration: 0.1 } }}
                  >
                    L'heure est venue de révolutionner votre expérience geek ! Téléchargez notre application sur Google Play, profitez des outils de communication mis à disposition par la plateforme
                    Entre-Geeks. Transformez votre smartphone en portail ultime vers notre univers geek.
                  </MotionBox>
                </>
              )}
              <ChakraLink as={RouterLink} to="https://play.google.com/store/apps/details?id=com.izoka.entregeeks" target="_blank">
                <Image src="/assets/googleplay.svg" alt="Google Play Store" maxW="260px" mt={10} />
              </ChakraLink>
            </Box>
          </Flex>
        </Flex>
      </Box>
    </Container>
  );
};

export default Bottom;
