import React, { useEffect, useMemo, useState } from 'react';
import { ButtonGroup, Button, Box, useDisclosure, useBreakpointValue, Flex, Text, SimpleGrid, GridItem, useMediaQuery, Grid } from '@chakra-ui/react';
import { useEvents } from '../../../hooks/events/useEvents';
import { EVENTS_DETAIL_PATH } from '../../../routing/constants';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Event } from '../../../types/Events';
import { SearchIcon } from '@chakra-ui/icons';
import PageLayout from '../../../layout/PageLayout';
import HeaderEvent from '../HeaderEvent';
import { customColors } from '../../../theme';
import { SearchEventsModal } from '../event/SearchEventsModal';
import { formatUri } from '../../../utils/api/helpers';
import { capitalizeFirstLetter, getTypeEventColor, getTypeString, isUrl } from '../../../utils/helpers';
import ScrollableBoxWithFadeMobile from '../../../components/events/ScrollableBoxWithFadeMobile';
import CardImageWithBox from '../../../components/card/CardImageWithBox';
import { formatDateRange, formatDateRangeFormatted } from '../../../utils/dates';
import { S3_URL } from '../../../utils/constants';
import PageTitleUpdater from '../../../routing/PageTitleUpdater';

export interface FormValues {
  name?: string;
  old: boolean;
  entityType?: number;
  date?: string;
  type?: string;
}
export interface QueryParams {
  name?: string;
  old?: boolean;
  skip: number;
  limit: number;
  entityType?: number;
  date?: string;
  type?: string;
}

const EventsList: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const isMobile = useBreakpointValue({ base: 'none', md: 'initial' });
  const [isLessThan1220] = useMediaQuery('(max-width: 1220px)');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const skip = searchParams.get('skip') ? parseInt(searchParams.get('skip')!) : 0;

  const queryParamsFromUrl = useMemo(() => {
    return {
      name: searchParams.get('name') || undefined,
      old: searchParams.get('old') === 'true',
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      entityType: searchParams.get('entityType') ? parseInt(searchParams.get('entityType')!) : undefined,
      date: searchParams.get('date') || undefined,
      type: searchParams.get('type') || undefined,
      limit: 6,
      skip,
    };
  }, [searchParams]);

  const [queryParams, setQueryParams] = useState<QueryParams>(queryParamsFromUrl);

  const { data, isLoading, refetch } = useEvents(queryParams);

  const { data: activeEvent } = useEvents({ skip: 0, limit: 20 });

  useEffect(() => {
    if (data && data.events.length === 0 && !selectedEvent && !isLoading) {
      setQueryParams({
        ...queryParams,
        name: undefined,
        old: false,
        entityType: undefined,
        date: undefined,
        type: undefined,
      });
    }
  }, [data]);

  useEffect(() => {
    const newQueryParams = {
      name: searchParams.get('name') || undefined,
      old: searchParams.get('old') === 'true',
      entityType: searchParams.get('entityType') ? parseInt(searchParams.get('entityType') as string) : undefined,
      date: searchParams.get('date') || undefined,
      type: searchParams.get('type') || undefined,
      limit: 6,
      skip: searchParams.get('skip') ? parseInt(searchParams.get('skip') as string) : 0,
    };

    if (JSON.stringify(newQueryParams) !== JSON.stringify(queryParams)) {
      setQueryParams(newQueryParams);
      refetch();
    }
  }, [searchParams, queryParams, refetch]);

  const [hover, setHover] = useState<boolean>(false);

  const onSubmit = (data: FormValues) => {
    const newSearchParams = new URLSearchParams();
    newSearchParams.set('skip', '0');
    if (data.entityType) newSearchParams.set('entityType', data.entityType.toString());
    if (data.old) newSearchParams.set('old', data.old.toString());
    if (data.date) newSearchParams.set('date', data.date.toString());
    if (data.name) newSearchParams.set('name', data.name.toString());
    if (data.type) newSearchParams.set('type', data.type.toString());
    setSearchParams(newSearchParams);
    onClose();
  };

  const countFilterSearch = () => {
    const queryKeys = ['name', 'old', 'entityType', 'date', 'type'];
    return queryKeys.reduce((count, key) => (queryParams[key as keyof QueryParams] ? count + 1 : count), 0);
  };

  const hasPrevious = queryParams.skip > 0;
  const hasNext = queryParams.skip + queryParams.limit < (data?.total || 0);
  const updateSkip = (skip: number) => {
    const newSearchParams = new URLSearchParams();
    newSearchParams.set('skip', skip.toString());
    if (queryParams.entityType) newSearchParams.set('entityType', queryParams.entityType.toString());
    if (queryParams.old) newSearchParams.set('old', queryParams.old.toString());
    if (queryParams.date) newSearchParams.set('date', queryParams.date.toString());
    if (queryParams.name) newSearchParams.set('name', queryParams.name.toString());
    if (queryParams.type) newSearchParams.set('type', queryParams.type.toString());
    setSearchParams(newSearchParams);
  };

  const updateType = (entityType: number | null) => {
    const newSearchParams = new URLSearchParams();
    newSearchParams.set('skip', '0');
    if (entityType) newSearchParams.set('entityType', entityType.toString());
    if (queryParams.old) newSearchParams.set('old', queryParams.old.toString());
    if (queryParams.date) newSearchParams.set('date', queryParams.date.toString());
    if (queryParams.name) newSearchParams.set('name', queryParams.name.toString());
    if (queryParams.type) newSearchParams.set('type', queryParams.type.toString());
    setSearchParams(newSearchParams);
  };

  function getRandomEvent(event: Event[]): Event {
    const randomIndex = Math.floor(Math.random() * event.length);
    return event[randomIndex];
  }

  useEffect(() => {
    if (activeEvent && activeEvent.events.length > 0) {
      setSelectedEvent(getRandomEvent(activeEvent.events));
    }
  }, [activeEvent]);

  return (
    <PageLayout isLoading={isLoading}>
      <PageTitleUpdater additionalTitle={`Evénements`} />
      {selectedEvent && isMobile !== 'none' && <HeaderEvent event={selectedEvent} isEventList />}
      {/*<EventFilterForm onSubmit={onSubmit} params={queryParams} />*/}
      <Box mt={isMobile !== 'none' ? 4 : 2} zIndex={0} position="relative">
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <>
            {isMobile !== 'none' && (
              <ButtonGroup variant="outline" spacing="1" flexWrap="wrap">
                <Button
                  variant="navYellow"
                  color={!queryParams.entityType ? customColors.blackTegrite : undefined}
                  bg={!queryParams.entityType ? customColors.yellowBob : undefined}
                  onClick={() => updateType(null)}
                >
                  Tous
                </Button>
                <Button
                  variant="navBlue"
                  color={queryParams.entityType === 3 ? customColors.blackTegrite : undefined}
                  bg={queryParams.entityType === 3 ? customColors.blueTardis : undefined}
                  onClick={() => updateType(3)}
                >
                  Sorties
                </Button>
                <Button
                  variant="navPurple"
                  color={queryParams.entityType === 2 ? customColors.blackTegrite : undefined}
                  bg={queryParams.entityType === 2 ? customColors.purpleThanos : undefined}
                  onClick={() => updateType(2)}
                >
                  Games
                </Button>
                <Button
                  variant="navGreen"
                  color={queryParams.entityType === 1 ? customColors.blackTegrite : undefined}
                  bg={queryParams.entityType === 1 ? customColors.greenToxic : undefined}
                  onClick={() => updateType(1)}
                >
                  Streams
                </Button>
                <Button
                  variant="navYellow"
                  pr={'8px'}
                  pl={'6px'}
                  color={isOpen ? customColors.blackTegrite : undefined}
                  bg={isOpen ? customColors.yellowBob : undefined}
                  onClick={onOpen}
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                >
                  <SearchIcon stroke={hover ? customColors.blackTegrite : customColors.yellowBob} width="20px" />
                  Filtrer {countFilterSearch() > 0 && `+${countFilterSearch()}`}
                </Button>
              </ButtonGroup>
            )}
            <GridItem colSpan={2}>
              <Flex alignItems="center" justifyContent="right" mr={2}>
                <Button isDisabled={!hasPrevious} variant="navYellow" onClick={() => updateSkip(queryParams.skip - queryParams.limit)} mr={2}>
                  Précédent
                </Button>
                <Button isDisabled={!hasNext} variant="navYellow" onClick={() => updateSkip(queryParams.skip + queryParams.limit)} mr={2}>
                  Suivant
                </Button>
              </Flex>
            </GridItem>
          </>
        </Flex>
      </Box>

      {isMobile !== 'none' ? (
        <>
          {data && data.total === 0 ? (
            <Grid templateColumns="repeat(1, 1fr)" alignContent="space-around" gap={7} mt={2} w="100%" h={isMobile === 'none' ? 'auto' : 'calc(100vh - 558px)'}>
              <Text textAlign="center" w="100%">
                Aucun événement trouvé
              </Text>
            </Grid>
          ) : (
            <SimpleGrid columns={isLessThan1220 ? [1, 2] : [1, 2, 3]} spacingX={5} spacingY={2}>
              {data?.events?.map(event => (
                <Box mt={2} key={event.id} onClick={() => navigate(formatUri({ slug: event.slug, type: getTypeString(event.entityType) }, EVENTS_DETAIL_PATH))}>
                  <CardImageWithBox
                    height={130}
                    imageName={event.name}
                    image={isUrl(event.image) ? event.image : `${S3_URL}/events/${event.image}`}
                    user={event.userId}
                    contentInCard={<>{formatDateRangeFormatted(event.scheduledStartTime, event.scheduledEndTime, true)}</>}
                    contentRight={
                      <Text mr={-2} mt={1} color={getTypeEventColor(event.entityType)}>
                        {capitalizeFirstLetter(getTypeString(event.entityType, true))}
                      </Text>
                    }
                    contentFooter={
                      <>
                        <Flex justifyContent="space-between" flexDirection={'row'} alignItems="center" h="100%" mt={5}>
                          <Text as="h2" fontSize={'13px'}>
                            {event.name}
                          </Text>
                        </Flex>
                      </>
                    }
                  />
                </Box>
              ))}
            </SimpleGrid>
          )}
        </>
      ) : (
        <Box mt={2}>
          <ScrollableBoxWithFadeMobile>
            {data && data.total === 0 && (
              <Grid templateColumns="repeat(1, 1fr)" alignContent="space-around" gap={7} mt={2} w="100%" h={isMobile ? 'auto' : 'calc(100vh - 375px)'}>
                <Text textAlign="center" w="100%">
                  Aucune actualité trouvée
                </Text>
              </Grid>
            )}
            {data?.events?.map(event => (
              <Box key={event.id} onClick={() => navigate(formatUri({ slug: event.slug, type: getTypeString(event.entityType) }, EVENTS_DETAIL_PATH))}>
                <CardImageWithBox
                  imageName={event.name}
                  image={isUrl(event.image) ? event.image : `${S3_URL}/events/${event.image}`}
                  user={event.userId}
                  contentFooter={
                    <>
                      <Flex justifyContent="space-between" flexDirection={'column'} alignItems="center" h="100%" mt={5}>
                        <Text as="h2" fontSize={'13px'}>
                          {event.name}
                        </Text>
                        <Text fontSize="11px">{formatDateRange(event.scheduledStartTime, event.scheduledEndTime, true)}</Text>
                      </Flex>
                    </>
                  }
                />
              </Box>
            ))}
          </ScrollableBoxWithFadeMobile>
        </Box>
      )}

      <SearchEventsModal isOpen={isOpen} onClose={onClose} onSubmit={onSubmit} />
    </PageLayout>
  );
};

export default React.memo(EventsList);
