import { Center, Divider, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import Linker from '../../components/Linker';
import { ACCOUNT_PATH, ADMIN_CREATE_NEWS_PATH, CGU_AUTH, CONTACT_AUTH } from '../../routing/constants';
import { isStaff } from '../../utils/constants';
import { User } from '../../types/user';
import { AiOutlineIdcard } from 'react-icons/ai';
import { SiGodotengine } from 'react-icons/si';
import LogoutButton from '../../components/buttons/LogoutButton';
import { HiOutlineEnvelope } from 'react-icons/hi2';
import { FaQuestion } from 'react-icons/fa';

interface UserMenuProps {
  user: User;
  toggleContent?: () => void;
}

const UserMenuContent: React.FC<UserMenuProps> = ({ user, toggleContent }) => {
  return (
    <>
      <Linker to={ACCOUNT_PATH} textDecoration="none">
        <Flex alignItems="center" p={2} onClick={() => (toggleContent ? toggleContent() : undefined)}>
          <AiOutlineIdcard size={20} />
          <Text ml={2}>Mon Compte</Text>
        </Flex>
      </Linker>
      {!isStaff(user) && user.roles.includes('ROLE_NEWS') && (
        <Linker to={ADMIN_CREATE_NEWS_PATH} textDecoration="none">
          <Flex alignItems="center" p={2} onClick={() => (toggleContent ? toggleContent() : undefined)}>
            <SiGodotengine size={20} />
            <Text ml={2}>Créer une news</Text>
          </Flex>
        </Linker>
      )}

      <Linker to={CGU_AUTH} textDecoration="none">
        <Flex alignItems="center" p={2} onClick={() => (toggleContent ? toggleContent() : undefined)}>
          <FaQuestion size={20} />
          <Text ml={2}>FAQ/CGU</Text>
        </Flex>
      </Linker>

      <Linker to={CONTACT_AUTH} textDecoration="none">
        <Flex alignItems="center" p={2} onClick={() => (toggleContent ? toggleContent() : undefined)}>
          <HiOutlineEnvelope size={20} color="white" />
          <Text ml={2}>Nous contacter</Text>
        </Flex>
      </Linker>
      <Center>
        <Divider borderColor="greyChupacabra" maxW="90%" />
      </Center>
      <LogoutButton />
    </>
  );
};

export default UserMenuContent;
