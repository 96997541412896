// context/AppContext.tsx
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useFetchCurrentUser } from '../hooks/users/useFetchCurrentUser';
import { User } from '../types/user';
import { customColors } from '../theme';
interface AppContextProps {
  user: User | null;
  isLoading: boolean;
  isAppLoading: boolean;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
}

const AppContext = createContext<AppContextProps>({
  user: null,
  isLoading: false,
  isAppLoading: false,
  setUser: () => {},
});

interface AppProviderProps {
  children: React.ReactNode;
}

const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isAppLoading, setIsAppLoading] = useState(true);
  const { data, isLoading } = useFetchCurrentUser();

  useEffect(() => {
    if (data) {
      document.body.style.backgroundColor = data.bgColor ? data.bgColor : customColors.yellowBob;
      setUser(data);
    }
    if (!isLoading) {
      setIsAppLoading(false);
    }
  }, [data, isLoading]);

  return <AppContext.Provider value={{ user, isLoading, isAppLoading, setUser }}>{children}</AppContext.Provider>;
};

const useAppContext = (): AppContextProps => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error('useAppContext must be used within a AppProvider');
  }
  return context;
};

export { AppProvider, useAppContext };
