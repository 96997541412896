import { RoleType } from '../../../types/user';
import { DiscordRole } from '../../../types/DiscordRole';

export interface EventCreateForm {
  name: string;
  description: string;
  descriptionMore?: string;
  scheduledStartTime: string;
  scheduledEndTime: string;
  entityType: number;
  location?: any;
  type?: any;
  url?: any;
  amount?: any;
  image: any;
  twitch?: string;
  game?: string;
}

export const getEventTypes = (userRoles: RoleType[]): { key: string; value: string }[] => {
  const eventTypes = [
    { key: '2', value: 'Game' },
    { key: '3', value: 'Sortie' },
  ];

  if (userRoles.includes('ROLE_STREAM')) {
    eventTypes.push({ key: '1', value: 'Stream' });
  }

  return eventTypes;
};

export const getTypes = (): { key: string; value: string }[] => {
  return [
    { key: 'Autres', value: 'Autres' },
    { key: 'Salon', value: 'Salon' },
    { key: 'Festival', value: 'Festival' },
    { key: 'Lan', value: 'Lan' },
    { key: 'Soirée', value: 'Soirée' },
    { key: 'Resto/Bar', value: 'Resto/Bar' },
    { key: 'Concert', value: 'Concert' },
    { key: 'Sport', value: 'Sport' },
    { key: 'Cosplay', value: 'Cosplay' },
    { key: 'Musée', value: 'Musée' },
    { key: 'JdR', value: 'JdR' },
    { key: 'Plein Air', value: 'Plein Air' },
    { key: 'Cinéma', value: 'Cinéma' },
    { key: 'Bowling', value: 'Bowling' },
    { key: "Parc d'Attraction", value: "Parc d'Attraction" },
    { key: 'Théatre', value: 'Théatre' },
  ];
};

export const getColorTypes = (value: string): string | null => {
  const type = [
    { key: 'greenToxic', value: 'Autres' },
    { key: 'greenToxic', value: 'Salon' },
    { key: 'greenToxic', value: 'Festival' },
    { key: 'greenToxic', value: 'Lan' },
    { key: 'greenToxic', value: 'Soirée' },
    { key: 'greenToxic', value: 'Resto/Bar' },
    { key: 'greenToxic', value: 'Concert' },
    { key: 'greenToxic', value: 'Sport' },
    { key: 'greenToxic', value: 'Cosplay' },
    { key: 'greenToxic', value: 'Musée' },
    { key: 'greenToxic', value: 'JdR' },
    { key: 'greenToxic', value: 'Plein Air' },
    { key: 'greenToxic', value: 'Cinéma' },
    { key: 'greenToxic', value: 'Bowling' },
    { key: 'greenToxic', value: "Parc d'Attraction" },
    { key: 'greenToxic', value: 'Théatre' },
  ];

  const obj = type.find(item => item.value === value);
  return obj ? obj.key : null;
};

export const today: Date = new Date();
export const todayAddOneHour: Date = new Date(today.setHours(today.getHours() + 1));

export const getGameRoles = (discordRoles: DiscordRole[]): { key: string; value: string }[] => {
  return discordRoles.map(role => ({
    key: role.roleId,
    value: role.name.replace('_game', ''),
  }));
};
