import React from 'react';

import { useParams } from 'react-router-dom';

import { useContestBySlug } from '../../../../hooks/contests/useContestBySlug';
import { ContestUpdateForm } from './ContestUpdateForm';

type ParamTypes = {
  slug: string;
};

export const ContestUpdate: React.FC = () => {
  const { slug } = useParams<ParamTypes>();
  const { data, error, isLoading } = useContestBySlug(slug || '');

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>An error occurred: {(error as any).message}</p>;
  }

  if (!data) {
    return <p>No contest found.</p>;
  }

  return <ContestUpdateForm contest={data} />;
};
