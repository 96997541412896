import React from 'react';
import { Box, Text, Flex, Thead, Tr, Th, Tbody, Table, Td } from '@chakra-ui/react';
import UserStringAvatar from '../../../../components/StringAvatar';
import { getAge } from '../../../../utils/dates';
import { UserPublic } from '../../../../types/user';
import { useNavigate } from 'react-router-dom';
import { formatUri } from '../../../../utils/api/helpers';
import { PROFILE_PATH } from '../../../../routing/constants';

interface EventCarouselProps {
  users: UserPublic[];
}

const LastRegister: React.FC<EventCarouselProps> = ({ users }) => {
  const navigate = useNavigate();
  return (
    <Box overflow="hidden" position="relative">
      <Text as="h1" mb={2}>
        Derniers inscrits
      </Text>
      <Table variant="unstyled" size="sm" width="100%">
        <Thead>
          <Tr pb={2}>
            <Th paddingLeft={0}>Membre</Th>
            <Th>Age</Th>
            <Th>Lieu</Th>
          </Tr>
        </Thead>
        <Tbody>
          {users.map(user => (
            <Tr cursor="pointer" key={`last-register-${user.username}`} onClick={() => navigate(formatUri({ username: user.username }, PROFILE_PATH))}>
              <Td paddingLeft={0}>
                <Flex alignItems="center">
                  <UserStringAvatar name={user.username} media={user.media} styles={{ size: 'sm' }} withBorder />
                  <Text ml={2}>{user.username}</Text>
                </Flex>
              </Td>
              <Td>
                <Text color="greyChupacabra">{getAge(user.bornDate)}</Text>
              </Td>
              <Td>{user.location.city && <Text color="greyChupacabra">{user.location.city.length > 12 ? `${user.location.city.substring(0, 12)}...` : user.location.city}</Text>}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default LastRegister;
