import React from 'react';
import AdminCard from './AdminCard';
import { AiFillIdcard, AiOutlineAppstoreAdd, AiOutlineReddit, AiOutlineTeam, AiOutlineSolution } from 'react-icons/ai';
import { Box, Flex } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import {
  ADMIN_BOT_MESSAGE_PATH,
  ADMIN_CONNECTED,
  ADMIN_CONTEST_CREATE_PATH,
  ADMIN_CONTESTS_LIST_PATH,
  ADMIN_CREATE_NEWS_PATH,
  ADMIN_DISCORD_ROLES_PATH,
  ADMIN_EVENTS_VALIDATE_PATH,
  ADMIN_GAME_ADD_PATH,
  ADMIN_MEDIA_TO_VALIDATE,
  ADMIN_USERS_PATH,
  ADMIN_USERS_VALIDATE_ACCOUNT_PATH,
  ADMIN_USERS_VALIDATE_UPDATE_PATH,
} from '../../routing/constants';
import { useInvalidAccounts } from '../../hooks/users/useInvalidAccounts';
import { useSocketContext } from '../../context/SocketContext';
import MessagesCountChart from './charts/MessagesCountChart';
import InscriptionCountChart from './charts/InscriptionCountChart';
import { useUpdatedUsers } from '../../hooks/users/useUpdatedUsers';
import { useInvalidEvents } from '../../hooks/events/useInvalidEvents';
import { RiCalendarEventLine } from 'react-icons/ri';
import { GiPodiumWinner } from 'react-icons/gi';
import { BiGame } from 'react-icons/bi';
import PageTitleUpdater from '../../routing/PageTitleUpdater';
import { useMediaToValidate } from '../../hooks/media/useMediaToValidate';
import { FaGamepad, FaRobot } from 'react-icons/fa';
import { IoLogoDiscord } from 'react-icons/io5';

const AdminPanel: React.FC = () => {
  const navigate = useNavigate();
  const { data } = useInvalidAccounts();
  const { data: events } = useInvalidEvents();
  const { data: dataUpdateUser } = useUpdatedUsers();
  const { data: mediaToValidate } = useMediaToValidate();
  const { onlineUsers } = useSocketContext();

  return (
    <Box>
      <PageTitleUpdater additionalTitle="Admin Panel" />
      <Flex wrap="wrap" justify="center">
        <AdminCard title={`${onlineUsers} en ligne`} icon={<AiOutlineTeam size={50} />} onClick={() => navigate(ADMIN_CONNECTED)} />
        <AdminCard
          title={`Nouveaux membres ${data ? data.length : ''}`}
          alert={!!(data && data.length > 0)}
          icon={<AiOutlineReddit size={50} />}
          onClick={() => navigate(ADMIN_USERS_VALIDATE_ACCOUNT_PATH)}
        />
        <AdminCard
          title={`Medias ${mediaToValidate ? mediaToValidate.length : ''}`}
          alert={!!(mediaToValidate && mediaToValidate.length > 0)}
          icon={<AiOutlineSolution size={50} />}
          onClick={() => navigate(ADMIN_MEDIA_TO_VALIDATE)}
        />
        <AdminCard
          title={`Profile update ${dataUpdateUser ? dataUpdateUser.length : ''}`}
          alert={!!(dataUpdateUser && dataUpdateUser.length > 0)}
          icon={<AiOutlineSolution size={50} />}
          onClick={() => navigate(ADMIN_USERS_VALIDATE_UPDATE_PATH)}
        />
        <AdminCard
          title={`Nouvelles Events ${events ? events.length : ''}`}
          alert={!!(events && events.length > 0)}
          icon={<RiCalendarEventLine size={50} />}
          onClick={() => navigate(ADMIN_EVENTS_VALIDATE_PATH)}
        />
        <AdminCard title={`Ajouter un concours`} icon={<BiGame size={50} />} onClick={() => navigate(ADMIN_CONTEST_CREATE_PATH)} />
        <AdminCard title={`Liste des concours`} icon={<GiPodiumWinner size={50} />} onClick={() => navigate(ADMIN_CONTESTS_LIST_PATH)} />
        <AdminCard title="Gestion des utilisateurs" icon={<AiFillIdcard size={50} />} onClick={() => navigate(ADMIN_USERS_PATH)} />
        <AdminCard title="Ajout d'une news" icon={<AiOutlineAppstoreAdd size={50} />} onClick={() => navigate(ADMIN_CREATE_NEWS_PATH)} />
        <AdminCard title="Ajout d'un jeu" icon={<FaGamepad size={50} />} onClick={() => navigate(ADMIN_GAME_ADD_PATH)} />
        <AdminCard title="Envoi message bot" icon={<FaRobot size={50} />} onClick={() => navigate(ADMIN_BOT_MESSAGE_PATH)} />
        <AdminCard title="Gestion role discord" icon={<IoLogoDiscord size={50} />} onClick={() => navigate(ADMIN_DISCORD_ROLES_PATH)} />
      </Flex>
      <MessagesCountChart />
      <InscriptionCountChart />
    </Box>
  );
};

export default AdminPanel;
