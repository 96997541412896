import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import Router from './routing/Router';
import { QueryClientProvider } from 'react-query';
import { queryClient } from './utils/reactQuery/config';
import { AppProvider } from './context/AppContext';
import { ToastContainer } from 'react-toastify';
import theme from './theme';

const App: React.FC = () => {
  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <AppProvider>
          <ToastContainer />
          <Router />
        </AppProvider>
      </QueryClientProvider>
    </ChakraProvider>
  );
};

export default App;
