import React from 'react';
import ContactForm from './ContactForm';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import Ariane from '../../../../components/Ariane';
import { useStaffUser } from '../../../../hooks/users/useStaffUser';
import PageLayout from '../../../../layout/PageLayout';
import PageTitleUpdater from '../../../../routing/PageTitleUpdater';

const ContactAuth: React.FC = () => {
  const { data: staff, isLoading } = useStaffUser();
  return (
    <Box>
      <PageTitleUpdater additionalTitle="Contact" />
      <Ariane>
        <Flex alignItems="center">
          <Image src="/assets/icons/eg.svg" alt="Contact" width="30px" />
          <Text as="h1" textTransform="uppercase" fontSize="19px" ml={4}>
            Contact
          </Text>
        </Flex>
      </Ariane>
      <PageLayout isLoading={isLoading}>{staff && <ContactForm staff={staff} />}</PageLayout>
    </Box>
  );
};

export default ContactAuth;
