import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton } from '@chakra-ui/react';

import { QueryUserParams } from './constants';
import UsersSearchForm from './UsersSearchForm';

interface SearchUsersModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: QueryUserParams) => void;
}

export const SearchUsersModal: React.FC<SearchUsersModalProps> = ({ isOpen, onClose, onSubmit }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Recherche</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <UsersSearchForm setSearchForm={onSubmit} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
