/* eslint-disable max-len */
export const account = `Dans la partie mon compte vous pourrez gérer toutes informations vous concernant.
 
Vos données personnelles telles que votre date de naissance ou votre mail ne seront en aucun cas communiqués !
 
De plus seul les personnes ayant un compte et étant connecté pourront accéder à votre fiche.

N'hésitez pas à étoffer celle-ci afin de partager avec les autres membres vos passions, vos goûts, votre quotidien de geek !

Montrez le coté geek de la force !`;

export const events = `
Vous pouvez créer/rejoindre deux types d'événements : Une sortie ou une partie en ligne.

Alors oui vous allez me dire, mais depuis quand les geeks sortent de chez eux ? Et bien depuis que nous avons créé ce site !

Vous pourrez donc créer une sortie pour aller voir un film, aller boire un verre, se retrouver dans un salon, etc...

Pour les parties en ligne, vous pourrez créer une partie pour jouer à un jeu en ligne avec d'autres membres du site. Le tout étant lier directement à discord, votre evenement sera visible par tous !
 
Par la suite les membres pourront s'y inscrire et discuter pour vous organiser au mieux.`;

export const community = `
Vous retrouvez ici tous les topics de discussion de la communauté discord. 

Vous pouvez poster directement depuis le site ou depuis discord, les deux sont liés ! 

Sachez qu'en postant depuis le site, notre gentil Bot va automatiquement poster votre message sur discord en y renseignant votre pseudo EntreGeeks.

Si vous souhaitez créer un topic, vous pouvez le faire depuis le site ou depuis discord.
`;

export const news = `
Vous retrouvez ici toutes les actualités du site et de la communauté ainsi que quelques actualités geek.

Si vous désirez participer à la rédation d'article, n'hésitez pas à nous contacter sur discord ou via le formulaire de contact du site.
`;

export const contests = `
Chaque semaines, nous organisons un concours pour gagner 2 jeux steams.

Vous pouvez participer à tout moment au tirage au sort en cliquant sur le bouton "Participer" sur la page du concours et doubler vos chances en ajoutant un émote sur le poste discord du même concours.

Vous n'avez aucune obligation d'achat pour participer, il vous suffit simplement d'avoir un compte discord et d'être membre d'Entre-Geeks.

Les gagnants sont tirés au sort et annoncés sur discord et sur le site.

Il est possible que pour certaines occasion nous organisions des concours avec des lots plus importants, restez à l'affut !
`;

export const stream = `
Vous pouvez retrouver notre équipe de stream sur twitch tout au long de la semaine.

Vous pourrez retrouver des streams de jeux vidéos, de dessins, de musique, de programmation, etc...

Vous pouvez suivre le planning des streams sur le site dans les events ou sur discord.

N'hésitez pas à nous rejoindre sur twitch et sur discord pour discuter avec nous en direct.
`;

export const discord = `
Le site et le discord sont très liés, vous pouvez donc retrouver les événements et discussions du site sur discord et inversement.

Si vous désirez bénéficier de toutes les fonctionnalités, pensez à lier votre compte discord à votre compte Entre-Geeks dans la rubrique "mon compte".
`;
