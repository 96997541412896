import { useQuery, UseQueryResult } from 'react-query';
import api from '../../utils/api/api';
import { API_CONTESTS_PUBLIC } from '../../utils/api/constants';

import { ContestPublic } from '../../types/Contests';

export const useContestBySlugPublic = (slug: string): UseQueryResult<ContestPublic | null, unknown> => {
  return useQuery(
    ['contestPublic', slug],
    async () => {
      const { data } = await api.get(`${API_CONTESTS_PUBLIC}/${slug}`);
      return data;
    },
    {
      enabled: slug !== '',
    },
  );
};
