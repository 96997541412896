import { useMutation, useQueryClient, UseMutationResult } from 'react-query';
import api from '../../utils/api/api';
import { API_NEWS_UPDATE } from '../../utils/api/constants'; // Assurez-vous que cette constante existe et est correcte
import { News } from '../../types/News';
import { NewsForm } from '../../pages/admin/news/constants';

// Cette fonction pourrait être différente selon votre implémentation de l'API
export const useUpdateNews = (): UseMutationResult<News, Error, { newsId: string; data: NewsForm }, unknown> => {
  const queryClient = useQueryClient();

  return useMutation<News, Error, { newsId: string; data: NewsForm }>(
    ({ newsId, data }) =>
      api
        .put(`${API_NEWS_UPDATE}/${newsId}`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res => res.data),
    {
      onSuccess: () => {
        queryClient.refetchQueries('newsList');
        queryClient.refetchQueries('latestNews');
        queryClient.invalidateQueries('news');
      },
    },
  );
};
