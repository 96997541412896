import * as yup from 'yup';

const ResetPasswordSchema = yup.object().shape({
  password: yup.string().required('Le mot de passe est requis').min(8, 'Le mot de passe doit avoir au moins 8 caractères'),
  confirmPassword: yup
    .string()
    .required('La confirmation du mot de passe est requise')
    .oneOf([yup.ref('password')], 'Les mots de passe doivent correspondre'),
});

export default ResetPasswordSchema;
