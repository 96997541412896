import React from 'react';
import { Box, Button, Flex, Image, Link, Text, useBreakpointValue, useMediaQuery } from '@chakra-ui/react';
import { Contest } from '../../../../types/Contests';
import HeaderStep from './HeaderStep';
import { S3_URL } from '../../../../utils/constants';
import { useAppContext } from '../../../../context/AppContext';

interface Step3Props {
  contest: Contest;
  setParticipationClick: React.Dispatch<React.SetStateAction<boolean>>;
}
const Step3: React.FC<Step3Props> = ({ contest, setParticipationClick }) => {
  const { user } = useAppContext();
  const [isLessThan1020] = useMediaQuery('(max-width: 1100px)');
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [isLessThan837] = useMediaQuery('(max-width: 837px)');

  const getImageParticipation = () => {
    const mediaSend = contest.mediaSend.find(media => media.userId === user?.id);
    return `${S3_URL}/contests/${contest.slug}/${mediaSend?.media}`;
  };
  return (
    <>
      <Flex justifyContent="center" alignItems="end" />
      <Box mt={4}>
        {!isLessThan837 && <HeaderStep step={3} />}
        <Flex justifyContent="center" h={isLessThan1020 ? 'auto' : 'calc(100% - 100px)'} direction="column">
          <Text as="h1" fontSize="16px" mb={2} color="yellowBob">
            Félicitations, héros !
          </Text>
          <Text>Ton chef-d'œuvre est maintenant dans la course pour le concours</Text>
          <Text fontWeight="bold"> {contest.name}</Text>
          <Text mt={2}>
            Reste connecté pour les résultats, c'est là que la magie opère ! Ton {contest.type === '2' ? 'texte' : 'image'} a bien été partagé sur Discord mais ne t'inquiète pas, ça reste en anonyme,
            laisse planer le mystère jusqu'à la fin !
          </Text>
          <Flex justifyContent="center" flexDirection={isLessThan1020 ? 'column' : 'row'} alignItems="center" verticalAlign="center" w={'100%'} mt={6}>
            {contest.type === '3' ? (
              <Box boxSize="sm" maxH={'150px'} display={isMobile ? 'contents' : 'relative'}>
                <Image src={getImageParticipation()} border="1px dashed white" maxH={'150px'} w="auto" alt="Ton image" />
              </Box>
            ) : (
              <Flex
                alignItems="center"
                justifyContent="center" // Ajouté pour l'alignement horizontal
                textAlign="center"
                boxSize="sm"
                h={'100px'}
                w={'100px'}
                border="1px dashed white"
              >
                <Link href={getImageParticipation()} isExternal>
                  Voir le PDF
                </Link>
              </Flex>
            )}
            <Button variant="navGreen" mt={isLessThan1020 ? 4 : 0} ml={isLessThan1020 ? 0 : 10} onClick={() => setParticipationClick(false)}>
              Retour à la page du concours
            </Button>
          </Flex>
        </Flex>
      </Box>
      <Flex justifyContent="center" alignItems="end" />
    </>
  );
};

export default Step3;
