import { useQuery, UseQueryResult } from 'react-query';
import api from '../../utils/api/api';
import { API_MEDIA_TO_VALIDATE } from '../../utils/api/constants';
import { Media } from '../../types/Medias';

const fetchMediaToValidate = async (): Promise<boolean> => {
  return api.get(API_MEDIA_TO_VALIDATE).then(res => res.data);
};

export const useMediaToValidate = (): UseQueryResult<Media[], Error> => {
  return useQuery('mediaToValidate', fetchMediaToValidate, {
    retry: 0,
    staleTime: 0,
    cacheTime: 0,
    refetchInterval: 5 * 60 * 1000,
  });
};
