import { SubmitHandler, useForm } from 'react-hook-form';
import { UserCreateForm } from './constants';
import { UserCreateYupSchema } from './yup/users';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCreateUser } from '../../hooks/users/useCreateUser';
import { useAppContext } from '../../context/AppContext';
import { useNavigate } from 'react-router-dom';
import { ACCOUNT_PATH, CGU, CONNEXION_PATH } from '../../routing/constants';
import { Box, Button, Flex, Text, Link as ChakraLink, useBreakpointValue } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import FormImageUpload from '../../components/forms/formImageUpload/FormImageUpload';
import FormInput from '../../components/forms/FormInput';
import CityAutocomplete from '../../components/forms/CityAutocomplete';
import FormDatePicker from '../../components/forms/FormDatePicker';
import Linker from '../../components/Linker';
import { toast } from 'react-toastify';
import { base64ToFile, convertToBase64 } from '../../utils/helpers';
import UserAvatarProfile from '../../components/UserAvatarProfile';
import FormCheckbox from '../../components/forms/FormCheckbox';
import { Link as RouterLink } from 'react-router-dom';
import { customColors } from '../../theme';

interface InscriptionFormProps {
  discordData?: any;
}

const today: Date = new Date();
const sixteenYearsBack: Date = new Date(today.setFullYear(today.getFullYear() - 16));
const minYearsBack: Date = new Date(today.setFullYear(today.getFullYear() - 100));

export const InscriptionForm: React.FC<InscriptionFormProps> = ({ discordData }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    getValues,
    watch,
    trigger,
  } = useForm<UserCreateForm>({
    resolver: yupResolver(UserCreateYupSchema),
    mode: 'onBlur',
  });
  const createUser = useCreateUser();
  const { setUser } = useAppContext();
  const navigate = useNavigate();
  const [currentAvatar, setCurrentAvatar] = React.useState<string | undefined>();
  const isMobile = useBreakpointValue({ base: true, md: false });

  async function convertImageToBase64(url: string) {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  useEffect(() => {
    if (discordData) {
      setValue(
        'discordData',
        {
          refreshToken: discordData.refreshToken,
          token: discordData.token,
          userId: discordData.user.id,
        },
        { shouldValidate: true },
      );
      setValue('username', discordData.user.global_name, { shouldValidate: true });
      setValue('email', discordData.user.email, { shouldValidate: true });
      convertImageToBase64(`https://cdn.discordapp.com/avatars/${discordData.user.id}/${discordData.user.avatar}.png`).then(base64 => {
        const discordImg = base64ToFile(base64 as string, 'croppedImage.png');
        setValue('media', discordImg, { shouldValidate: true });
      });
    }
  }, [discordData]);

  const onSubmit: SubmitHandler<UserCreateForm> = async data => {
    createUser.mutate(data, {
      onSuccess: data => {
        setUser(data.user);
        navigate(ACCOUNT_PATH, { state: { registerSuccess: true } });
        document.body.style.backgroundColor = customColors.yellowBob;
      },
      onError: (error: any) => {
        toast.error(`Erreur inscription : ${error.response.data.message}`, {
          position: toast.POSITION.TOP_CENTER,
        });
      },
    });
  };

  const watchedMedia = watch('media');
  useEffect(() => {
    if (watchedMedia && watchedMedia instanceof File) {
      trigger('media');
      convertToBase64(watchedMedia).then(base64 => {
        setCurrentAvatar(base64 as string);
      });
    }
  }, [watchedMedia]);

  return (
    <Box px={isMobile ? 4 : 0} mt={isMobile ? 10 : 20}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {isMobile && (
          <Text fontSize={isMobile ? '2xl' : '4xl'} color="#ffffff" mb={30} minW={isMobile ? 'auto' : '400px'} textAlign="center">
            Rejoins les vrais Geeks !
          </Text>
        )}
        <Flex flexDirection={isMobile ? 'column' : 'row'}>
          <Flex direction="column" justifyContent={isMobile ? 'center' : 'flex-start'} textAlign="center" alignItems="center" h="100%" mr={6} mb={isMobile ? 4 : 0}>
            <UserAvatarProfile withBorder username={getValues('username') ? getValues('username') : 'Photo'} media={currentAvatar ? currentAvatar : ''} styles={{ width: '70px', height: '70px' }} />
            <FormImageUpload
              variant="whiteBorderNoBg"
              placeholder="Photo de profil"
              name="media"
              cropWidth={300}
              cropHeight={300}
              register={register}
              setValue={setValue}
              getValues={getValues}
              errors={errors}
            />
          </Flex>
          <Flex direction="column" justifyContent="flex-start" minW={isMobile ? 'auto' : '400px'}>
            {!isMobile && (
              <Text position="absolute" mt="-100px" fontSize={isMobile ? '2xl' : '4xl'} color="#ffffff" mb={50} minW={isMobile ? 'auto' : '400px'} textAlign="center">
                Rejoins les vrais Geeks !
              </Text>
            )}
            <Flex direction="column" flex="1" justifyContent="flex-end" mb={4}>
              <FormInput variant="whiteBorderNoBg" type="text" placeholder="Ton pseudo" name="username" required register={register} errors={errors} />
            </Flex>
            <Flex align="center" mb={4}>
              <FormInput variant="whiteBorderNoBg" type="email" placeholder="Ton email" name="email" required register={register} errors={errors} />
            </Flex>
            {!getValues('discordData') && (
              <Flex align="center" mb={4}>
                <FormInput variant="whiteBorderNoBg" type="password" placeholder="Mot de passe" name="password" required register={register} errors={errors} />
              </Flex>
            )}
            <Flex align="center" mb={4} color="whiteWalter">
              <CityAutocomplete variant="whiteBorderNoBg" placeholder="Ta ville" name="location" required setValue={setValue} getValues={getValues} register={register} errors={errors} />
            </Flex>
            <Flex align="center" mb={4}>
              <FormDatePicker
                sx={{ mt: 0, mb: 0 }}
                variant="whiteBorderNoBg"
                placeholder="Date de naissance"
                name="bornDate"
                minDate={minYearsBack}
                maxDate={sixteenYearsBack}
                required={true}
                errors={errors}
                register={register}
                getValues={getValues}
                setValue={setValue}
              />
            </Flex>
            <Flex justifyContent="flex-start" my={4}>
              <FormCheckbox name="cgu" variant="whiteBorder" register={register} errors={errors}>
                <Text variant="whiteBorderNoBg" color="white" fontSize="sm">
                  Je confirme avoir lu et accepté les{' '}
                  <Text as="span">
                    <ChakraLink as={RouterLink} to={CGU} target="_blank" color="yellowBob">
                      conditions générales d'utilisation
                    </ChakraLink>
                  </Text>
                </Text>
              </FormCheckbox>
            </Flex>
            <Button mt={4} variant="primary-dark" w="100%" fontSize="sm" colorScheme="teal" type="submit" isLoading={createUser.isLoading} isDisabled={!isValid}>
              S'inscrire
            </Button>
            <Flex align="center" justifyContent="center" my={4}>
              <Text fontSize="sm" color="white">
                Tu as déjà un compte ?{' '}
                <Linker to={CONNEXION_PATH} color="yellowBob">
                  Connecte-toi
                </Linker>
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </form>
    </Box>
  );
};
