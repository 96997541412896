import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, Button, Flex } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { usePasswordReset } from '../../hooks/users/usePasswordReset';
import FormInput from '../../components/forms/FormInput';
import { toast } from 'react-toastify';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

interface PasswordResetForm {
  email: string;
}

const PasswordResetModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PasswordResetForm>();
  const passwordResetMutation = usePasswordReset();

  const onSubmit: SubmitHandler<PasswordResetForm> = data => {
    passwordResetMutation.mutate(data.email, {
      onSuccess: () => {
        toast.info(`Réinitialisation du mot de passe. Un mail de réinitialisation vous a été envoyé.`, {
          position: toast.POSITION.TOP_CENTER,
        });
        onClose();
      },
      onError: (error: any) => {
        toast.error(`Erreur. ${error.response.data.message}`, {
          position: toast.POSITION.TOP_CENTER,
        });
      },
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader>Réinitialisation du mot de passe</ModalHeader>
          <ModalBody>
            <FormInput variant="whiteBorderNoBg" type="email" placeholder="Votre email" name="email" register={register} errors={errors} />
          </ModalBody>
          <ModalFooter>
            <Flex flexDir="row" justifyContent="flex-end" mt={4}>
              <Button variant="navRed" border="none" color="white" textDecoration="underline" type="button" onClick={onClose}>
                Fermer
              </Button>
              <Button ml={4} variant="navYellow" type="submit" isLoading={passwordResetMutation.isLoading}>
                Envoyer
              </Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default PasswordResetModal;
