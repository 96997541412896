import React from 'react';
import { useParams } from 'react-router-dom';
import { useNewsBySlug } from '../../../../hooks/news/useNewsBySlug';
import UpdateNewsForm from './UpdateNewsForm';

type ParamTypes = {
  slug: string;
};

const UpdateNews: React.FC = () => {
  const { slug } = useParams<ParamTypes>();
  const { data: news } = useNewsBySlug(slug || '');

  if (!news) return null;

  return <UpdateNewsForm news={news} />;
};

export default UpdateNews;
