import axios from 'axios';
import { API_URL } from '../constants';
import { API_REFRESH_TOKEN_URI } from './constants';

const api = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

let retryCount = 0;

api.interceptors.response.use(
  response => response,
  error => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      retryCount += 1;
      if (retryCount > 1) {
        retryCount = 0;
        return Promise.reject(error);
      }
      return api.post(API_URL + API_REFRESH_TOKEN_URI).then(res => {
        if (res.status === 201) {
          document.cookie = `access_token=${res.data.access_token}`;
          return axios(originalRequest);
        }
      });
    }
    return Promise.reject(error);
  },
);

export default api;
