import { REDIRECT_DISCORD_URI } from '../routing/constants';
import { User } from '../types/user';

const getValue = (placeholder: string, envVar: string | undefined): string | undefined => {
  return placeholder === placeholder.toUpperCase() ? envVar : placeholder;
};

export const BACKEND_URL = getValue('REACT_APP_API_URL_PLACEHOLDER', process.env.REACT_APP_API_URL) || '';
export const APP_URL_TWITCH = getValue('REACT_APP_APP_URL_TWITCH_PLACEHOLDER', process.env.REACT_APP_APP_URL_TWITCH) || '';
export const API_URL = `${BACKEND_URL}/api`;
export const APP_URL = `https://entre-geeks.com`;

export const NAME_BOT = 'EntreGeeksMasterBot';
export const DISCORD_CLIENT_ID = getValue('REACT_APP_DISCORD_CLIENT_ID_PLACEHOLDER', process.env.REACT_APP_DISCORD_CLIENT_ID) || '1193143243953688587';
export const DISCORD_CLIENT_SECRET = getValue('REACT_APP_DISCORD_CLIENT_SECRET_PLACEHOLDER', process.env.REACT_APP_DISCORD_CLIENT_SECRET) || '';
export const DISCORD_REDIRECT_URI = getValue('REACT_APP_APP_URL_PLACEHOLDER', process.env.REACT_APP_APP_URL) + REDIRECT_DISCORD_URI;
export const S3_URL = getValue('REACT_APP_S3_URL_PLACEHOLDER', process.env.REACT_APP_S3_URL) || '';
export const GOOGLE_API_KEY = getValue('REACT_APP_GOOGLE_API_KEY_PLACEHOLDER', process.env.REACT_APP_GOOGLE_API_KEY) || '';
export const PUPPETEER_URL = getValue('REACT_APP_PUPPETEER_URL_PLACEHOLDER', process.env.REACT_APP_PUPPETEER_URL) || '';
export const GOOGLE_ANALYTICS = getValue('REACT_APP_GOOGLE_ANALYTICS_PLACEHOLDER', process.env.REACT_APP_GOOGLE_ANALYTICS) || '';
export const IS_MAINTENANCE = getValue('REACT_APP_MAINTENANCE_PLACEHOLDER', process.env.REACT_APP_MAINTENANCE) || false;

export const IMGUR_CLIENT_ID = getValue('REACT_APP_IMGUR_CLIENT_ID_PLACEHOLDER', process.env.REACT_APP_IMGUR_CLIENT_ID) || false;
export const S3_ASSETS_URL = `${S3_URL}/assets`;

export const isAdmin = (user: User | null): boolean => {
  if (user && user.roles) {
    return user.roles.includes('ROLE_ADMIN');
  }

  return false;
};

export const isStaff = (user: User | null): boolean => {
  if (user && user.roles) {
    return user.roles.includes('ROLE_MODERATOR') || user.roles.includes('ROLE_ADMIN');
  }

  return false;
};

export const isStreameur = (user: User | null): boolean => {
  if (user && user.roles) {
    return user.roles.includes('ROLE_STREAM') || user.roles.includes('ROLE_MODERATOR') || user.roles.includes('ROLE_ADMIN');
  }

  return false;
};

export const isAccessGranted = (user: User | null): boolean => {
  if (!user) {
    return false;
  }

  return user.isAccountValid;
};

export function isImageDataUri(str: string | File): boolean {
  if (str instanceof File) {
    return true;
  }

  // Si c'est une chaîne, vérifier si elle commence par les préfixes spécifiés
  return str.startsWith('data:image/') || str.startsWith('/assets/');
}

export const convertUrlsToMarkdown = (text: string): string => {
  const urlRegex = /(?<!\]\()https?:\/\/[^\s]+(?<!\)|\]\()/g;
  return text.replace(urlRegex, url => `[${url}](${url})`);
};
