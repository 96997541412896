import React from 'react';
import { useAdminContests } from '../../../../hooks/contests/useAdminContests';
import { Box } from '@chakra-ui/react';
import ContestsTable from './ContestsTable';
import PageTitleUpdater from '../../../../routing/PageTitleUpdater';

const ContestList: React.FC = () => {
  const { data, isLoading, isError } = useAdminContests();

  if (isLoading) return <p>Loading...</p>;
  if (isError || !data) return <p>An error occurred</p>;

  return (
    <Box>
      <PageTitleUpdater additionalTitle="Admin Panel - Liste concours" />
      <ContestsTable contests={data} />
    </Box>
  );
};

export default ContestList;
