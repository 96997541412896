import * as yup from 'yup';

export const UserUpdateParameterYupSchema = yup.object().shape({
  location: yup
    .object()
    .shape({
      country: yup.string().required('Le pays est requis'),
      city: yup.string(),
    })
    .required('La localisation est requise'),
  userProfile: yup.object().shape({
    site: yup.string().required('Le site est requis'),
  }),
});

export const UserUpdatePasswordParameterYupSchema = yup.object().shape({
  password: yup
    .string()
    .test('password-validation', 'Minimum 8 caractères ou requis si pas de token Discord', function (value) {
      if (!value || value.length < 8) {
        return new yup.ValidationError('Minimum 8 caractères requis', null, 'password');
      }
      return true;
    })
    .required('Mot de passe requis'),
  oldPassword: yup
    .string()
    .test('password-validation', 'Minimum 8 caractères ou requis si pas de token Discord', function (value) {
      if (!value || value.length < 8) {
        return new yup.ValidationError('Minimum 8 caractères requis', null, 'password');
      }
      return true;
    })
    .required('Mot de passe requis'),
});
