import React, { useRef, useState } from 'react';
import { Box, Button, HStack, VStack, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, SimpleGrid } from '@chakra-ui/react';

import { useInvalidEvents } from '../../../../hooks/events/useInvalidEvents';
import { useValidateEvents } from '../../../../hooks/events/useValidateEvents';
import { useDeleteEvent } from '../../../../hooks/events/useDeleteEvent';
import PageLoader from '../../../../components/PageLoader';
import EventAdminCard from './EventAdminCard';
import PageTitleUpdater from '../../../../routing/PageTitleUpdater';

const EventsValidation: React.FC = () => {
  const { data, isLoading } = useInvalidEvents();
  const validateEventsMutation = useValidateEvents();
  const deleteEventMutation = useDeleteEvent();

  const [isOpen, setIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const onClose = () => setIsOpen(false);
  const cancelRef = useRef(null);

  const handleValidateAll = () => {
    if (data) {
      const ids = data.map(user => user.id);
      validateEventsMutation.mutate(ids);
    }
  };

  const handleValidate = (id: string) => {
    validateEventsMutation.mutate([id]);
  };

  const handleDelete = (id: string) => {
    setDeleteId(id);
    setIsOpen(true);
  };

  const confirmDelete = () => {
    if (deleteId) {
      deleteEventMutation.mutateAsync(deleteId);
      setDeleteId(null);
    }
    onClose();
  };

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <PageTitleUpdater additionalTitle="Admin Panel - Validation events" />
      <VStack spacing={4}>
        {data && data.length === 0 ? (
          <Box>Aucun Events à valider</Box>
        ) : (
          <>
            <Box alignSelf="flex-end">
              <Button onClick={handleValidateAll}>Valider tous</Button>
            </Box>
            <Box width="100%">
              <HStack wrap="wrap" justify="center" alignItems="stretch">
                <SimpleGrid columns={[1, 2]} spacing={10}>
                  {data && data.map(event => <EventAdminCard key={event.id} event={event} onValidate={() => handleValidate(event.id)} onDelete={() => handleDelete(event.id)} />)}
                </SimpleGrid>
              </HStack>
            </Box>
          </>
        )}
      </VStack>

      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Supprimer un event
            </AlertDialogHeader>

            <AlertDialogBody>Êtes-vous sûr ? Cette action est irréversible.</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Annuler
              </Button>
              <Button colorScheme="red" onClick={confirmDelete} ml={3}>
                Supprimer
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default EventsValidation;
