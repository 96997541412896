import React from 'react';
import PageLayout from '../../../../layout/noAuth/wrappers/PageLayout';
import { useNavigate, useParams } from 'react-router-dom';
import { CONNEXION_PATH, HOME_PATH } from '../../../../routing/constants';

import { Button, Center } from '@chakra-ui/react';
import { useContestBySlugPublic } from '../../../../hooks/contests/useContestBySlugPublic';
import PublicContestCard from './PublicContestCard';

type ParamTypes = {
  slug: string;
};

const PublicContest: React.FC = () => {
  const { slug } = useParams<ParamTypes>();
  const { data, error, isLoading } = useContestBySlugPublic(slug || '');
  const navigate = useNavigate();

  if (error) {
    navigate(HOME_PATH);
  }

  return (
    <PageLayout>
      {isLoading && <p>Loading...</p>}
      {data && <PublicContestCard contest={data} />}
      <Center my={10}>
        <Button variant="navYellow" fontSize="20px" p={5} onClick={() => navigate(CONNEXION_PATH)}>
          Connexion
        </Button>
      </Center>
    </PageLayout>
  );
};

export default PublicContest;
