import { Box, Text, Image, VStack } from '@chakra-ui/react';
import { S3_URL } from '../../../../utils/constants';
import React from 'react';
import PageTitleUpdater from '../../../../routing/PageTitleUpdater';
import { removeMarkdown } from '../../../../utils/helpers';
import { ContestPublic } from '../../../../types/Contests';
import CountdownTimer from '../../../../components/CountdownTimer';

interface PublicContestsCardProps {
  contest: ContestPublic;
}

const PublicContestsCard: React.FC<PublicContestsCardProps> = ({ contest }) => {
  const currentDate = new Date();
  const endDateTime = new Date(contest.endDate);
  const isEnded = currentDate > endDateTime;
  return (
    <Box borderWidth="1px" borderRadius="lg" p="4" w="100%">
      <PageTitleUpdater additionalTitle={`Concours - ${contest.name}`} additionalDescription={removeMarkdown(contest.description)} additionalOgImage={`${S3_URL}/contests/${contest.media}`} />
      {contest.media && <Image w="100%" objectFit="cover" src={`${S3_URL}/contests/${contest.media}`} alt={contest.name} />}
      <VStack align="start" spacing={2}>
        <Text as="h1">{contest.name}</Text>
        {!isEnded && <CountdownTimer startDate={contest.createdAt} endDate={contest.endDate} />}
        {isEnded && (
          <Text as="h1" color="greenToxic" fontSize={'30px'}>
            TERMINER
          </Text>
        )}
        <Box style={{ whiteSpace: 'pre-line' }}>
          <Text>{contest.description}</Text>
        </Box>
      </VStack>
    </Box>
  );
};

export default PublicContestsCard;
