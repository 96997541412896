import React, { useState } from 'react';
import { FormControl, FormLabel, Textarea, FormErrorMessage, Text } from '@chakra-ui/react';
import { UseFormRegister, DeepMap, FieldError } from 'react-hook-form';
import { customColors } from '../../theme';

export interface FormTextareaSimpleProps {
  label?: string;
  placeholder: string;
  name: string;
  required?: boolean;
  getValues: any;
  setValue: any;
  register: UseFormRegister<any>;
  errors?: DeepMap<any, FieldError>;
  maxLength?: number;
  variant?: any;
  height?: number;
}

const FormTextareaSimple: React.FC<FormTextareaSimpleProps> = ({ height, variant = 'violet', getValues, label, placeholder, name, register, errors, required, maxLength }) => {
  const [charCount, setCharCount] = useState(0);
  const registerProps = register ? register(name) : {};
  let registerOnChange: ((e: any) => void) | undefined;
  let restRegisterProps;

  if (registerProps && typeof registerProps === 'object' && 'onChange' in registerProps) {
    ({ onChange: registerOnChange, ...restRegisterProps } = registerProps as any);
  }

  const handleTextChange = (e: any) => {
    const newValue = e.target.value;
    setCharCount(newValue.length);
    if (registerOnChange) {
      registerOnChange(e);
    }
  };

  return (
    <FormControl isRequired={required} isInvalid={errors && !!errors[name]}>
      {label && <FormLabel>{label}</FormLabel>}
      <Textarea
        height={height ? `${height}px` : 'auto'}
        variant={variant}
        sx={
          variant === 'violet'
            ? { backgroundColor: '#4D3650', color: 'white', '::placeholder': { color: customColors.greyChupacabra } }
            : { backgroundColor: customColors.blackTegrite, color: 'white', borderColor: 'white', border: '1px solid', '::placeholder': { color: customColors.greyChupacabra } }
        }
        placeholder={placeholder}
        maxLength={maxLength}
        value={getValues(name) ? getValues(name) : ''}
        onChange={handleTextChange}
        {...(restRegisterProps || {})}
      />
      {maxLength && (
        <Text fontSize="sm" textAlign="right" mt={2}>
          {charCount}/{maxLength} caractères
        </Text>
      )}
      <FormErrorMessage>{errors && errors[name] && errors[name].message}</FormErrorMessage>
    </FormControl>
  );
};

export default FormTextareaSimple;
