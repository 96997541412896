import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { convertToBase64 } from '../../../utils/helpers';
import PageTitleUpdater from '../../../routing/PageTitleUpdater';
import { Box, Button, Image } from '@chakra-ui/react';
import FormImageUpload from '../../../components/forms/formImageUpload/FormImageUpload';
import FormInput from '../../../components/forms/FormInput';
import { useAddGames } from '../../../hooks/games/useAddGames';
import { GameAddForm } from './constants';
import { gameSchema } from './yup/game';
import FormTextarea from '../../../components/forms/FormTextarea';

export const GameAdd: React.FC = () => {
  const mutation = useAddGames();
  const [currentAvatar, setCurrentAvatar] = React.useState<string | undefined>();
  const {
    handleSubmit,
    register,
    trigger,
    setValue,
    watch,
    getValues,
    formState: { errors, isValid },
  } = useForm<GameAddForm>({
    resolver: yupResolver(gameSchema),
  });

  const onSubmit = async (data: GameAddForm) => {
    try {
      await mutation.mutateAsync(data);
      toast.success('Jeu ajouté avec succès', {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (error: any) {
      toast.error(`Échec d'ajout du Jeu : ${error.response.data.message}`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const watchedMedia = watch('media');
  useEffect(() => {
    if (watchedMedia && watchedMedia instanceof File) {
      trigger('media');
      convertToBase64(watchedMedia).then(base64 => {
        setCurrentAvatar(base64 as string);
      });
    }
  }, [watchedMedia]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PageTitleUpdater additionalTitle="Admin Panel - Ajout d'un jeu" />

      {currentAvatar && <Image src={currentAvatar} />}
      <Box mt={4}>
        <FormImageUpload variant="whiteBorderNoBg" placeholder="Image" name="media" required register={register} setValue={setValue} getValues={getValues} errors={errors} />
      </Box>
      <Box mt={4}>
        <FormInput variant="whiteBorderNoBg" label={'Nom du role'} placeholder="Nom du role" type="text" name="name" required register={register} errors={errors} />
      </Box>
      <Box mt={4}>
        <FormTextarea variant="whiteBorderNoBg" label={'Emoji'} placeholder="Emoji" name="emoji" required register={register} errors={errors} getValues={getValues} setValue={setValue} />
      </Box>
      <Box my={4} textAlign="right">
        <Button variant="navYellow" type="submit" isLoading={mutation.isLoading} isDisabled={!isValid}>
          Enregistrer
        </Button>
      </Box>
    </form>
  );
};
