import * as yup from 'yup';

export const eventSchema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string().required(),
  scheduledStartTime: yup.string().required('Veuillez ajouter la date de début'),
  scheduledEndTime: yup
    .string()
    .required('Veuillez ajouter la date de fin')
    .test('is-greater', 'La date de fin doit être supérieur à la date de début', function (value) {
      const { scheduledStartTime } = this.parent;
      return new Date(value).getTime() > new Date(scheduledStartTime).getTime();
    }),
  entityType: yup.number().required(),
  location: yup.mixed().test('location-validation', 'Ajoutez la localisation', function (value) {
    const { entityType } = this.parent;
    if (entityType === 3 && !value) {
      return new yup.ValidationError('Ajoutez la localisation', null, 'location');
    }
    return true;
  }),
  type: yup.string().test('type-validation', 'Ajoutez le type', function (value) {
    const { entityType } = this.parent;
    if (entityType === 3 && !value) {
      return new yup.ValidationError('Ajoutez le type', null, 'type');
    }
    return true;
  }),
  twitch: yup.string().test('twitch-validation', 'Chaine obligatoire', function (value) {
    const { entityType } = this.parent;
    if (entityType === 1 && !value) {
      return new yup.ValidationError('Chaine obligatoire', null, 'twitch');
    }
    return true;
  }),
  game: yup.string().test('game-validation', 'Choisissez un jeu', function (value) {
    const { entityType } = this.parent;
    if (entityType === 2 && !value) {
      return new yup.ValidationError('Choisissez un jeu', null, 'game');
    }
    return true;
  }),
  image: yup.mixed().required('Veuillez ajouter une image'),
});
