import React, { useEffect, useState } from 'react';
import { Avatar, AvatarProps, Box, Flex, Text } from '@chakra-ui/react';
import { isImageDataUri, S3_URL } from '../utils/constants';
import { customColors } from '../theme';

interface UserAvatarProps {
  username: string;
  media: string;
  styles?: AvatarProps;
  withBorder?: boolean;
  isDisplayIcon?: boolean;
  color?: string;
}

const UserCardStringAvatar: React.FC<UserAvatarProps> = ({ color = 'whiteTegrite', username, media, styles, withBorder = false }) => {
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpacity(1);
    }, 50);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Box position={'absolute'} bottom={-7} zIndex={10}>
      <Flex alignItems={'center'} justifyContent={'center'}>
        <Box p="6px" bg={color} borderRadius="full" display="inline-flex" alignItems="center" justifyContent="center">
          <Box
            p="4px" // Ajustez selon vos besoins
            borderRadius="full" // Pour garder la forme circulaire
            display="inline-block" // Pour empêcher le conteneur de prendre toute la largeur disponible
            border={withBorder ? `2px solid ${customColors.greyRoswel}` : 'none'}
            style={{
              opacity: opacity,
              transition: 'opacity 0.3s ease-in-out',
            }}
          >
            <Avatar name={username} src={media ? (isImageDataUri(media) ? media : `${S3_URL}/users/${media}`) : ''} {...styles} />
          </Box>
        </Box>
        <Box bg={color} ml={-1} borderRadius="0 21px  0 0" px={4}>
          <Text
            ml={-2}
            mt={1}
            style={{
              opacity: opacity,
              transition: 'opacity 0.3s ease-in-out',
            }}
          >
            {username}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default UserCardStringAvatar;
