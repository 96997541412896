import React from 'react';
import { Avatar, Box, Flex, Grid, SimpleGrid, Text, useBreakpointValue } from '@chakra-ui/react';
import { useMessages } from '../../../hooks/discord/forum/useMessages';
import { Message } from '../../../types/Discord';
import PageLayout from '../../../layout/PageLayout';
import mdToText from 'markdown-to-text';
import { removeAndExtractPrefix } from '../../../utils/helpers';
import ScrollableBoxWithFade from './ScrollableBoxWithFade';
import ScrollableBoxWithFadeMobile from './ScrollableBoxWithFadeMobile';
import { THREAD_MESSAGES_PATH } from '../../../routing/constants';
import Linker from '../../../components/Linker';
import { formatUri } from '../../../utils/api/helpers';
import { NAME_BOT } from '../../../utils/constants';

interface EventCommentsProps {
  threadId: string;
}

function formatDate(date: number) {
  return new Date(date).toLocaleDateString('fr-FR', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  });
}

const EventComments: React.FC<EventCommentsProps> = ({ threadId }) => {
  const isMobile = useBreakpointValue({ base: 'none', md: 'initial' });
  const { data, isLoading } = useMessages(threadId);

  let messageCount = 0;

  return (
    <PageLayout isLoading={isLoading}>
      <Flex justify="space-between" align="center" m="auto">
        <Text as="h1" mt={4} mb={2}>
          Commentaires
        </Text>
        {threadId && (
          <Linker color="greyChupacabra" to={formatUri({ channelId: threadId }, THREAD_MESSAGES_PATH)}>
            Voir/commenter
          </Linker>
        )}
      </Flex>
      {!threadId && <Text>Les commentaires ne sont pas disponible pour cet événement</Text>}
      {data?.pages.map((page, i) => {
        const messagesToRender = page.slice(0, -2);
        messageCount += messagesToRender.length;

        if (isMobile !== 'none') {
          return (
            <ScrollableBoxWithFade key={i}>
              <SimpleGrid columns={[1]} spacing={5} pr="20px">
                {messagesToRender.slice(0, 5).map((message: Message) => {
                  const { cleanMessage, originalAuthor } = removeAndExtractPrefix(message.content);

                  return (
                    <Grid key={message.id} templateColumns="auto 1fr auto" gap={4} mb={2} alignItems="start">
                      {message.author.avatar ? <Avatar src={message.author.avatar} size="sm" mr="2" /> : <Avatar size="sm" name={message.author.username} mr="2" />}
                      <Box>
                        <Text fontWeight="bold">{message.author.username}</Text>
                        {originalAuthor && message.author.username === NAME_BOT && (
                          <Text fontSize="11px" color="gray.500">
                            Auteur original : {originalAuthor}
                          </Text>
                        )}
                        <Text>{mdToText(cleanMessage)}</Text>
                      </Box>
                      <Box textAlign="right">
                        <Text textStyle="subHeader">{formatDate(message.createdAt)}</Text>
                      </Box>
                    </Grid>
                  );
                })}
              </SimpleGrid>
              {messageCount === 0 && <p>Pas de messages dans ce fil.</p>}
            </ScrollableBoxWithFade>
          );
        } else {
          return (
            <ScrollableBoxWithFadeMobile key={i}>
              <SimpleGrid columns={[1]} spacing={5} pr="20px">
                {messagesToRender.slice(0, 5).map((message: Message) => {
                  const { cleanMessage } = removeAndExtractPrefix(message.content);

                  return (
                    <Grid key={message.id} templateColumns="auto 1fr auto" gap={4} mb={2} alignItems="start">
                      {message.author.avatar ? <Avatar src={message.author.avatar} size="sm" mr="2" /> : <Avatar size="sm" name={message.author.username} mr="2" />}
                      <Box>
                        <Text fontWeight="bold">{message.author.username}</Text>
                        <Text>{mdToText(cleanMessage)}</Text>
                      </Box>
                      <Box textAlign="right">
                        <Text textStyle="subHeader">{formatDate(message.createdAt)}</Text>
                      </Box>
                    </Grid>
                  );
                })}
              </SimpleGrid>
              {messageCount === 0 && <p>Pas de messages dans ce fil.</p>}
            </ScrollableBoxWithFadeMobile>
          );
        }
      })}
    </PageLayout>
  );
};

export default EventComments;
