import React from 'react';
import { Box, Button, HStack, VStack } from '@chakra-ui/react';
import UpdateUserAdminCard from './UpdateUserAdminCard';
import { useValidateUpdatedUsers } from '../../../../hooks/users/useValidateUpdatedUsers';
import { useUpdatedUsers } from '../../../../hooks/users/useUpdatedUsers';
import PageLoader from '../../../../components/PageLoader';
import PageTitleUpdater from '../../../../routing/PageTitleUpdater';

const UpdatedValidation: React.FC = () => {
  const { data, isLoading } = useUpdatedUsers();
  const validateUpdatedUsers = useValidateUpdatedUsers();

  const handleValidateAll = () => {
    if (data) {
      const ids = data.map(user => user.id);
      validateUpdatedUsers.mutate(ids);
    }
  };

  const handleValidate = (id: string) => {
    validateUpdatedUsers.mutate([id]);
  };

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <PageTitleUpdater additionalTitle="Admin Panel - Validation update de membres" />
      <VStack spacing={4}>
        {data && data.length === 0 ? (
          <Box>Aucun utilisateur à valider</Box>
        ) : (
          <>
            <Box alignSelf="flex-end">
              <Button onClick={handleValidateAll}>Valider tous</Button>
            </Box>
            <Box width="100%">
              <HStack wrap="wrap" justify="center" alignItems="stretch">
                {data && data.map(user => <UpdateUserAdminCard key={user.id} user={user} onValidate={() => handleValidate(user.id)} />)}
              </HStack>
            </Box>
          </>
        )}
      </VStack>
    </>
  );
};

export default UpdatedValidation;
