import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Image, Text, useBreakpointValue } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { CONNEXION_PATH, INSCRIPTION_PATH } from '../../routing/constants';

const Menu: React.FC = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const navigate = useNavigate();
  const [headerBg, setHeaderBg] = useState('linear-gradient(180deg, #2B2B2B 0%, rgba(0, 0, 0, 0) 100%)');
  const [videoEnd, setVideoEnd] = useState(isMobile ? 200 : 800);

  useEffect(() => {
    setVideoEnd(isMobile ? 200 : 800);
  }, [isMobile]);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setHeaderBg(position > videoEnd ? 'yellowBob' : 'linear-gradient(180deg, #2B2B2B 0%, rgba(0, 0, 0, 0) 100%)');
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <Flex as="header" position="fixed" top="0" width="100%" zIndex="popover" background={headerBg} p="4" align="center" justify="space-between" transition="background 0.2s ease-in-out">
      <Image src={`/assets/logos/logo-white.svg`} align="center" maxHeight="50px" />
      <Flex alignItems="center" justifyContent="center" mr={6}>
        <Box onClick={() => navigate(CONNEXION_PATH)} cursor="pointer">
          <Text textDecoration="underline" color={headerBg === 'yellowBob' ? 'blackTegrite' : 'whiteWalter'}>
            Connexion
          </Text>
        </Box>
        {!isMobile && (
          <Flex onClick={() => navigate(INSCRIPTION_PATH)} cursor="pointer" ml={6}>
            <Button variant={headerBg === 'yellowBob' ? 'navTegrite' : 'navYellow'}>Rejoins-nous !</Button>
            <Image src="/assets/homeNoAuth/pilule.svg" alt="matrix" ml={2} />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default Menu;
