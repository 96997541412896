import React, { useEffect, useRef } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { motion, useAnimation } from 'framer-motion';
import { MenuItems } from '../../pages/home/noAuth/covers/constants';

const MotionBox = motion(Box);

interface MenuHomeNoAuthContentProps {
  currentIndex: number | null;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number | null>>;
}

const MenuHomeNoAuthContent: React.FC<MenuHomeNoAuthContentProps> = ({ currentIndex, setCurrentIndex }) => {
  const controls = useAnimation();
  const refs = useRef<any>([]);

  useEffect(() => {
    if (currentIndex !== null) {
      const currentRef = refs.current[currentIndex - 1];
      if (currentRef) {
        controls.start({
          left: currentRef.offsetLeft,
          width: currentRef.offsetWidth,
          opacity: 1, // Assurez-vous que la barre est visible lorsque l'élément est sélectionné
          transition: { duration: 0.3 }, // Ajustez selon vos besoins pour la fluidité
        });
      }
    } else {
      // Si currentIndex est null, faites disparaître la barre
      controls.start({
        opacity: 0, // Rend la barre invisible
        transition: { duration: 0.2 }, // Animation plus rapide pour la disparition
      });
    }
  }, [currentIndex, controls]);

  const handleClick = (index: number) => {
    // Mise à jour de l'index courant
    setCurrentIndex(index);

    // Scroll vers le haut de la page
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Pour un effet de scroll doux
    });
  };

  return (
    <Flex align="center" position="relative">
      {MenuItems.map(item => (
        <Box key={item.id} ref={el => (refs.current[item.id - 1] = el)} onClick={() => handleClick(item.id)} cursor="pointer" ml={item.id > 1 ? 6 : 0}>
          <Text className={'title_menu_home'}>{item.label}</Text>
        </Box>
      ))}
      <MotionBox position="absolute" bottom="-5px" height="2px" bgColor="yellow.400" animate={controls} initial={{ opacity: 0 }} exit={{ opacity: 0 }} />
    </Flex>
  );
};

export default MenuHomeNoAuthContent;
