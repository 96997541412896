import React, { useState } from 'react';
import { useAppContext } from '../../../context/AppContext';
import { Box, Button, useMediaQuery } from '@chakra-ui/react';

import { toast } from 'react-toastify';

import { useDeleteUser } from '../../../hooks/users/useDeleteUser';
import DeleteAlertDialog from '../../../components/dialogAlert/DeleteAlertDialog';

const DeleteAccount: React.FC = () => {
  const { user } = useAppContext();
  const deleteUserMutation = useDeleteUser();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isLessThan768] = useMediaQuery('(max-width: 952px)');

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleDelete = async () => {
    if (!user) return;
    try {
      await deleteUserMutation.mutateAsync(user.id);
      window.location.reload();
    } catch (error: any) {
      toast.error(`Une erreur c'est produite : ${error.response.data.message}`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    closeDeleteDialog();
  };

  return (
    <Box textAlign={isLessThan768 ? 'center' : 'left'}>
      <Button variant="navRed" mt={isLessThan768 ? 6 : 0} onClick={() => setDeleteDialogOpen(true)}>
        Suppression du compte
      </Button>
      <DeleteAlertDialog
        isOpen={deleteDialogOpen}
        onClose={closeDeleteDialog}
        onDelete={handleDelete}
        title="Suppression du compte"
        description={`Êtes-vous sûr de vouloir supprimer votre compte Entre Geeks ?\n\nCela effacera toutes vos informations.\n\nCette action est irreversible !`}
      />
    </Box>
  );
};

export default DeleteAccount;
