import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { eventSchema } from '../yup/events';
import { EventCreateForm } from '../create/constants';
import { S3_URL } from '../../../utils/constants';
import { useUpdateEvent } from '../../../hooks/events/useUpdateEvent';
import { useNavigate } from 'react-router-dom';
import { formatUri } from '../../../utils/api/helpers';
import { EVENTS_DETAIL_PATH } from '../../../routing/constants';
import { Event } from '../../../types/Events';
import { User } from '../../../types/user';
import { convertToBase64, getTypeString, isUrl } from '../../../utils/helpers';
import { EventForm } from '../EventForm';

interface EventUpdateFormProps {
  event: Event;
  user: User;
}

export const EventUpdateForm: React.FC<EventUpdateFormProps> = ({ event, user }) => {
  const [currentAvatar, setCurrentAvatar] = React.useState<string | undefined>(isUrl(event.image) ? event.image : `${S3_URL}/events/${event.image}`);
  const mutationUpdate = useUpdateEvent();
  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    trigger,
    watch,
    formState: { errors, isValid },
  } = useForm<EventCreateForm>({
    resolver: yupResolver(eventSchema),
    defaultValues: {
      description: event.description,
      entityType: event.entityType,
      game: event.game,
      type: event.type,
      location: event.location,
      scheduledEndTime: event.scheduledEndTime,
      scheduledStartTime: event.scheduledStartTime,
      twitch: event.eventURL,
      amount: event.amount,
      url: event.url,
      name: event.name.replace(/^\[(Game|Sortie|Stream)\]\s*/, ''),
    },
  });

  const watchedMedia = watch('image');
  useEffect(() => {
    if (watchedMedia && watchedMedia instanceof File) {
      trigger('image');
      convertToBase64(watchedMedia).then(base64 => {
        setCurrentAvatar(base64 as string);
      });
    } else if (watchedMedia && isUrl(watchedMedia)) {
      setCurrentAvatar(watchedMedia);
    }
  }, [watchedMedia]);

  const entityType = watch('entityType');
  useEffect(() => {
    if (Number(entityType) === 2) {
      setCurrentAvatar(undefined);
    }
    if (Number(entityType) === 1) {
      setValue('game', undefined);
    }
    if (Number(entityType) === 3) {
      setValue('game', undefined);
    }
  }, [entityType]);

  useEffect(() => {
    setValue('image', isUrl(event.image) ? event.image : `${S3_URL}/events/${event.image}`);
  }, [setValue]);

  const onSubmit = async (data: EventCreateForm) => {
    try {
      if (event) {
        const result = await mutationUpdate.mutateAsync({
          data,
          id: event.id,
        });
        toast.success('Événement modifié avec succès', {
          position: toast.POSITION.TOP_CENTER,
        });
        navigate(formatUri({ slug: result.slug, type: getTypeString(result.entityType) }, EVENTS_DETAIL_PATH));
      }
    } catch (error: any) {
      toast.error(`Échec de la mise à jour de l'événement : ${error.response.data.message}`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <EventForm
      title="Evenement - Update"
      getValues={getValues}
      setValue={setValue}
      register={register}
      errors={errors}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={mutationUpdate.isLoading}
      isValid={isValid}
      currentAvatar={currentAvatar}
      eventTypeSelected={event.entityType}
      user={user as User}
    />
  );
};
