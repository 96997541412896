import { useQuery, UseQueryResult } from 'react-query';
import { Contest } from '../../types/Contests';
import api from '../../utils/api/api';
import { API_CONTESTS } from '../../utils/api/constants';
import { QueryContestParams } from '../../pages/contest/constantes';

interface EventResponse {
  contests: Contest[];
  total: number;
}

export const useContests = (options: QueryContestParams): UseQueryResult<EventResponse, unknown> => {
  // Destructure options with default values to ensure they are not undefined
  const { skip = 0, limit = 6 } = options;

  return useQuery(
    ['contests', skip, limit],
    async () => {
      const queryParams = new URLSearchParams();

      if (skip) queryParams.append('skip', String(skip));
      if (limit) queryParams.append('limit', String(limit));

      const { data } = await api.get<EventResponse>(`${API_CONTESTS}?${queryParams.toString()}`);
      return data;
    },
    {
      // This option will keep the previous data while fetching the new one on refetch
      keepPreviousData: true,
    },
  );
};
