import { UserProfile } from './userProfile';

export enum Role {
  ROLE_USER = 'USER',
  ROLE_ADMIN = 'ADMIN',
  ROLE_MODERATOR = 'MODERATOR',
  ROLE_STREAM = 'ROLE_STREAM',
  ROLE_NEWS = 'ROLE_NEWS',
}

export enum RoleSimple {
  ROLE_USER = 'User',
  ROLE_ADMIN = 'Admin',
  ROLE_MODERATOR = 'Moderateur',
  ROLE_STREAM = 'Streameur',
  ROLE_NEWS = 'Newser',
}

export type RoleType = keyof typeof Role;

export type UserLocation = {
  country: string;
  city?: string;
  department?: string;
  region?: string;
  formattedAddress?: string;
};

export type Discord = {
  token: string;
  refreshToken: string;
  userId: string;
};

export type BlockedUser = {
  id: string;
};

export type User = {
  id: string;
  roles: RoleType[];
  username: string;
  email: string;
  location: UserLocation;
  isAccountValid: boolean;
  isSoundMessage: boolean;
  isSoundVisit: boolean;
  bornDate: string;
  bgColor?: string;
  ip?: string;
  isOnline: boolean;
  media: string;
  mediaCover: string;
  userProfile: UserProfile;
  inscriptionDate: string;
  lastConnexion: string;
  notificationToken: string;
  notificationMobile: boolean;
  discordData?: {
    userId?: string;
  };
  blockedUsers?: BlockedUser[];
};

export type UserPublic = {
  id: string;
  roles: RoleType[];
  username: string;
  location: UserLocation;
  bornDate: string;
  isOnline: boolean;
  media: string;
  mediaCover?: string;
};
