import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import api from '../../utils/api/api';
import { API_LOGOUT_URI } from '../../utils/api/constants';
import { AxiosResponse } from 'axios';

const useLogout = (onSuccess: () => void): UseMutationResult<AxiosResponse<any, any>, unknown, void, unknown> => {
  const queryClient = useQueryClient();

  return useMutation(() => api.post(API_LOGOUT_URI), {
    onSuccess: () => {
      queryClient.invalidateQueries();
      onSuccess();
    },
  });
};

export default useLogout;
