import React from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';

import AdminRoute from './AdminRoute';
import {
  ABOUT_US_PATH,
  ACCOUNT_PATH,
  ADMIN_CONTEST_CREATE_PATH,
  ADMIN_CONTEST_UPDATE_PATH,
  ADMIN_CONTESTS_LIST_PATH,
  ADMIN_CREATE_NEWS_PATH,
  ADMIN_EVENTS_VALIDATE_PATH,
  ADMIN_PATH,
  ADMIN_UPDATE_NEWS_PATH,
  ADMIN_USERS_PATH,
  ADMIN_USERS_VALIDATE_ACCOUNT_PATH,
  ADMIN_USERS_VALIDATE_UPDATE_PATH,
  CGU,
  CGU_AUTH,
  CHANNELS_PATH,
  CONNEXION_PATH,
  CONTACT_AUTH,
  CONTACT_PATH,
  CONTESTS_DETAILS_PATH,
  CONTESTS_PATH,
  COOKIES_POLICY_PATH,
  EVENTS_CREATE_PATH,
  EVENTS_DETAIL_PATH,
  EVENTS_PATH,
  EVENTS_UPDATE_PATH,
  FAQ_PATH,
  HOME_BOARD,
  HOME_PATH,
  INSCRIPTION_PATH,
  LEGAL_NOTICE_PATH,
  MAINTENANCE,
  MESSAGES_CONVERSATIONS,
  NEWS_LIST_PATH,
  NEWS_PATH,
  PROFILE_PATH,
  ADMIN_PROFILE_UPDATE_PATH,
  PUBLIC_GAMES,
  PUBLIC_NEWS,
  PUBLIC_OUTING,
  PUBLIC_STREAMS,
  REDIRECT_DISCORD_URI,
  RESET_PASSWORD_PATH,
  THREAD_CHANNEL_PATH,
  THREAD_CREATE_PATH,
  THREAD_MESSAGES_PATH,
  THREAD_TAG_PATH,
  USERS_SEARCH_PATH,
  VISITS,
  ADMIN_CONTESTS_SET_WINNER_PATH,
  ADMIN_MEDIA_TO_VALIDATE,
  PUBLIC_CONTESTS,
  ADMIN_CONNECTED,
  ADMIN_GAME_ADD_PATH,
  ADMIN_BOT_MESSAGE_PATH,
  ADMIN_DISCORD_ROLES_PATH,
  ADMIN_DISCORD_ROLE_CREATE_PATH,
  ADMIN_DISCORD_ROLE_EDIT_PATH,
} from './constants';
import NoHautRoute from './NoHautRoute';
import Inscription from '../pages/inscription/inscription';
import Connexion from '../pages/connexion/Connexion';
import PublicRoute from './PublicRoute';
import DiscordRedirect from '../components/DiscordRedirect';
import Layout from '../layout/Layout';
import PrivateRoute from './PrivateRoute';
import Account from '../pages/account/Account';
import Channels from '../pages/forum/channels/Channels';
import Threads from '../pages/forum/threads/threads';
import UserProfile from '../pages/userProfile/UserProfile';
import ContestsList from '../pages/contest/list/ContestsList';
import Messages from '../pages/forum/messages/Messages';
import EventsList from '../pages/events/eventsList/EventsList';
import NewsList from '../pages/news/newsList/NewsList';
import AdminPanel from '../pages/admin/AdminPanel';
import CreateNews from '../pages/admin/news/CreateNews';
import SearchUsers from '../pages/admin/users/search/SearchUsers';
import AccountValidation from '../pages/admin/users/AccountValidation/AccountValidation';
import HomeAuth from '../pages/home/auth/HomeAuth';
import HomeNoAuth from '../pages/home/noAuth/HomeNoAuth';
import News from '../pages/news/news/News';
import Event from '../pages/events/event/Event';
import CreateThread from '../pages/forum/threads/create/CreateThread';
import MessageList from '../pages/messages/list/MessageList';
import Visits from '../pages/Visits/Visits';
import UpdatedValidation from '../pages/admin/users/updated/UpdatedValidation';
import Cgu from '../pages/misc/noAuth/Cgu';
import AboutUs from '../pages/misc/noAuth/AboutUs';
import Contact from '../pages/misc/noAuth/contact/Contact';
import CookiesPolicy from '../pages/misc/noAuth/CookiesPolicy';
import Faq from '../pages/misc/noAuth/Faq';
import LegalNotice from '../pages/misc/noAuth/LegalNotice';
import ResetPassword from '../pages/misc/noAuth/resetPassword/ResetPassword';
import EventsValidation from '../pages/admin/events/eventsValidation/EventsValidation';
import { EventCreate } from '../pages/events/create/EventCreate';
import { EventUpdate } from '../pages/events/update/EventUpdate';
import { ContestCreate } from '../pages/admin/contests/create/ContestCreate';
import Contest from '../pages/contest/contest/Contest';
import ContestList from '../pages/admin/contests/list/ContestList';
import { ContestUpdate } from '../pages/admin/contests/update/ContestUpdate';
import UsersList from '../pages/users/list/UsersList';
import PublicEvent from '../pages/misc/noAuth/events/PublicEvent';
import PublicNews from '../pages/misc/noAuth/news/PublicNews';
import Maintenance from '../pages/misc/Maintenance';
import UpdateNews from '../pages/admin/news/update/UpdateNews';
import NewserRoute from './NewserRoute';
import ContactAuth from '../pages/misc/auth/contact/ContactAuth';
import CguFaq from '../pages/misc/auth/CguFaq';
import UserAdminUpdate from '../pages/admin/users/update/UserAdminUpdate';
import ContestSetWinner from '../pages/admin/contests/draw/ContestSetWinner';
import ValidateMedia from '../pages/admin/medias/ValidateMedia';
import PublicContest from '../pages/misc/noAuth/contests/PublicContest';
import LayoutNoAuth from '../layout/LayoutNoAuth';
import Connected from '../pages/admin/users/Connected';
import { GameAdd } from '../pages/admin/games/GameAdd';
import { BotMessage } from '../pages/admin/botMessage/BotMessage';
import { DiscordRoles } from '../pages/admin/discordRoles/DiscordRoles';
import DiscordRoleCreate from '../pages/admin/discordRoles/create/DiscordRoleCreate';
import DiscordRoleEdit from '../pages/admin/discordRoles/edit/DiscordRoleEdit';

const router = createBrowserRouter([
  {
    path: '/',
    element: <LayoutNoAuth />,
    children: [
      {
        path: PUBLIC_OUTING,
        element: (
          <PublicRoute>
            <PublicEvent />
          </PublicRoute>
        ),
      },
      {
        path: MAINTENANCE,
        element: (
          <PublicRoute>
            <Maintenance />
          </PublicRoute>
        ),
      },
      {
        path: PUBLIC_STREAMS,
        element: (
          <PublicRoute>
            <PublicEvent />
          </PublicRoute>
        ),
      },
      {
        path: PUBLIC_GAMES,
        element: (
          <PublicRoute>
            <PublicEvent />
          </PublicRoute>
        ),
      },
      {
        path: PUBLIC_NEWS,
        element: (
          <PublicRoute>
            <PublicNews />
          </PublicRoute>
        ),
      },
      {
        path: PUBLIC_CONTESTS,
        element: (
          <PublicRoute>
            <PublicContest />
          </PublicRoute>
        ),
      },
      {
        path: HOME_PATH,
        element: (
          <PublicRoute>
            <HomeNoAuth />
          </PublicRoute>
        ),
      },
      {
        path: INSCRIPTION_PATH,
        element: (
          <NoHautRoute>
            <Inscription />
          </NoHautRoute>
        ),
      },
      {
        path: CONNEXION_PATH,
        element: (
          <NoHautRoute>
            <Connexion />
          </NoHautRoute>
        ),
      },
      {
        path: CGU,
        element: (
          <NoHautRoute>
            <Cgu />
          </NoHautRoute>
        ),
      },
      {
        path: LEGAL_NOTICE_PATH,
        element: (
          <NoHautRoute>
            <LegalNotice />
          </NoHautRoute>
        ),
      },
      {
        path: RESET_PASSWORD_PATH,
        element: (
          <NoHautRoute>
            <ResetPassword />
          </NoHautRoute>
        ),
      },
      {
        path: ABOUT_US_PATH,
        element: (
          <NoHautRoute>
            <AboutUs />
          </NoHautRoute>
        ),
      },
      {
        path: CONTACT_PATH,
        element: (
          <NoHautRoute>
            <Contact />
          </NoHautRoute>
        ),
      },
      {
        path: COOKIES_POLICY_PATH,
        element: (
          <NoHautRoute>
            <CookiesPolicy />
          </NoHautRoute>
        ),
      },
      {
        path: FAQ_PATH,
        element: (
          <NoHautRoute>
            <Faq />
          </NoHautRoute>
        ),
      },
    ],
  } /**/,
  {
    path: REDIRECT_DISCORD_URI,
    element: <DiscordRedirect />,
  },
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: HOME_BOARD,
        element: (
          <PrivateRoute>
            <HomeAuth />
          </PrivateRoute>
        ),
      },
      {
        path: USERS_SEARCH_PATH,
        element: (
          <PrivateRoute>
            <UsersList />
          </PrivateRoute>
        ),
      },
      {
        path: CONTACT_AUTH,
        element: (
          <PrivateRoute>
            <ContactAuth />
          </PrivateRoute>
        ),
      },
      {
        path: CGU_AUTH,
        element: (
          <PrivateRoute>
            <CguFaq />
          </PrivateRoute>
        ),
      },
      {
        path: ACCOUNT_PATH,
        element: (
          <PrivateRoute>
            <Account />
          </PrivateRoute>
        ),
      },
      {
        path: CHANNELS_PATH,
        element: (
          <PrivateRoute>
            <Channels />
          </PrivateRoute>
        ),
      },
      {
        path: THREAD_TAG_PATH,
        element: (
          <PrivateRoute>
            <Threads />
          </PrivateRoute>
        ),
      },
      {
        path: THREAD_CREATE_PATH,
        element: (
          <PrivateRoute>
            <CreateThread />
          </PrivateRoute>
        ),
      },
      {
        path: PROFILE_PATH,
        element: (
          <PrivateRoute>
            <UserProfile />
          </PrivateRoute>
        ),
      },
      {
        path: CONTESTS_PATH,
        element: (
          <PrivateRoute>
            <ContestsList />
          </PrivateRoute>
        ),
      },
      {
        path: CONTESTS_DETAILS_PATH,
        element: (
          <PrivateRoute>
            <Contest />
          </PrivateRoute>
        ),
      },
      {
        path: THREAD_CHANNEL_PATH,
        element: (
          <PrivateRoute>
            <Threads />
          </PrivateRoute>
        ),
      },
      {
        path: THREAD_MESSAGES_PATH,
        element: (
          <PrivateRoute>
            <Messages />
          </PrivateRoute>
        ),
      },
      {
        path: EVENTS_PATH,
        element: (
          <PrivateRoute>
            <EventsList />
          </PrivateRoute>
        ),
      },
      {
        path: EVENTS_CREATE_PATH,
        element: (
          <PrivateRoute>
            <EventCreate />
          </PrivateRoute>
        ),
      },
      {
        path: EVENTS_UPDATE_PATH,
        element: (
          <PrivateRoute>
            <EventUpdate />
          </PrivateRoute>
        ),
      },
      {
        path: EVENTS_DETAIL_PATH,
        element: (
          <PrivateRoute>
            <Event />
          </PrivateRoute>
        ),
      },
      {
        path: NEWS_PATH,
        element: (
          <PrivateRoute>
            <News />
          </PrivateRoute>
        ),
      },
      {
        path: NEWS_LIST_PATH,
        element: (
          <PrivateRoute>
            <NewsList />
          </PrivateRoute>
        ),
      },
      {
        path: MESSAGES_CONVERSATIONS,
        element: (
          <PrivateRoute>
            <MessageList />
          </PrivateRoute>
        ),
      },
      {
        path: VISITS,
        element: (
          <PrivateRoute>
            <Visits />
          </PrivateRoute>
        ),
      },
      {
        path: ADMIN_PATH,
        element: (
          <AdminRoute>
            <AdminPanel />
          </AdminRoute>
        ),
      },
      {
        path: ADMIN_CREATE_NEWS_PATH,
        element: (
          <NewserRoute>
            <CreateNews />
          </NewserRoute>
        ),
      },
      {
        path: ADMIN_MEDIA_TO_VALIDATE,
        element: (
          <AdminRoute>
            <ValidateMedia />
          </AdminRoute>
        ),
      },
      {
        path: ADMIN_PROFILE_UPDATE_PATH,
        element: (
          <AdminRoute>
            <UserAdminUpdate />
          </AdminRoute>
        ),
      },
      {
        path: ADMIN_GAME_ADD_PATH,
        element: (
          <AdminRoute>
            <GameAdd />
          </AdminRoute>
        ),
      },
      {
        path: ADMIN_BOT_MESSAGE_PATH,
        element: (
          <AdminRoute>
            <BotMessage />
          </AdminRoute>
        ),
      },
      {
        path: ADMIN_DISCORD_ROLES_PATH,
        element: (
          <AdminRoute>
            <DiscordRoles />
          </AdminRoute>
        ),
      },
      {
        path: ADMIN_DISCORD_ROLE_CREATE_PATH,
        element: (
          <AdminRoute>
            <DiscordRoleCreate />
          </AdminRoute>
        ),
      },
      {
        path: ADMIN_DISCORD_ROLE_EDIT_PATH,
        element: (
          <AdminRoute>
            <DiscordRoleEdit />
          </AdminRoute>
        ),
      },
      {
        path: ADMIN_CONNECTED,
        element: (
          <AdminRoute>
            <Connected />
          </AdminRoute>
        ),
      },
      {
        path: ADMIN_USERS_PATH,
        element: (
          <AdminRoute>
            <SearchUsers />
          </AdminRoute>
        ),
      },
      {
        path: ADMIN_USERS_VALIDATE_ACCOUNT_PATH,
        element: (
          <AdminRoute>
            <AccountValidation />
          </AdminRoute>
        ),
      },
      {
        path: ADMIN_UPDATE_NEWS_PATH,
        element: (
          <NewserRoute>
            <UpdateNews />
          </NewserRoute>
        ),
      },
      {
        path: ADMIN_USERS_VALIDATE_UPDATE_PATH,
        element: (
          <AdminRoute>
            <UpdatedValidation />
          </AdminRoute>
        ),
      },
      {
        path: ADMIN_EVENTS_VALIDATE_PATH,
        element: (
          <AdminRoute>
            <EventsValidation />
          </AdminRoute>
        ),
      },
      {
        path: ADMIN_CONTEST_CREATE_PATH,
        element: (
          <AdminRoute>
            <ContestCreate />
          </AdminRoute>
        ),
      },
      {
        path: ADMIN_CONTESTS_LIST_PATH,
        element: (
          <AdminRoute>
            <ContestList />
          </AdminRoute>
        ),
      },
      {
        path: ADMIN_CONTESTS_SET_WINNER_PATH,
        element: (
          <AdminRoute>
            <ContestSetWinner />
          </AdminRoute>
        ),
      },
      {
        path: ADMIN_CONTEST_UPDATE_PATH,
        element: (
          <AdminRoute>
            <ContestUpdate />
          </AdminRoute>
        ),
      },
    ],
  },
  { path: '*', element: <Navigate to={HOME_PATH} /> },
]);

const Router: React.FC = () => {
  return <RouterProvider router={router} />;
};

export default Router;
