import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { S3_URL } from '../utils/constants';
import { defaultCoverImage, isUrl } from '../utils/helpers';

interface UserCoverProps {
  media?: string;
  children: React.ReactNode;
  height?: string;
  gradiant?: string;
}

const UserCover: React.FC<UserCoverProps> = ({ children, media, height, gradiant = `linear(90deg, rgba(43, 44, 44, 0.9) 10%, rgba(43, 44, 44, 0) 80%)` }) => {
  const [isValidImage, setIsValidImage] = useState(true);

  useEffect(() => {
    const image = new Image();
    image.onload = () => setIsValidImage(true);
    image.onerror = () => setIsValidImage(false);

    // Ici, vous pouvez ajouter une logique supplémentaire pour savoir quelle URL vérifier
    image.src = media && isUrl(media) ? media : `${S3_URL}/users/covers/${media}`;
  }, [media]);

  return (
    <Box
      as="div"
      bg={`url(${isValidImage ? (media && isUrl(media) ? media : `${S3_URL}/users/covers/${media}`) : defaultCoverImage}) center/cover no-repeat`}
      position="relative"
      width="100%"
      borderRadius={21}
      height={height ? height : '100%'}
    >
      <Box borderRadius={21} position="absolute" top="0" left="-1px" w="100%" h="100%" bgGradient={gradiant} />

      {children}
    </Box>
  );
};

export default UserCover;
