import React from 'react';
import { Box, Table, Thead, Tr, Th, Tbody, Td, Heading, Divider, IconButton, Text } from '@chakra-ui/react';
import { Contest } from '../../../../types/Contests';
import { formatTimestamp } from '../../../../utils/dates';
import { formatUri } from '../../../../utils/api/helpers';
import { ADMIN_CONTEST_UPDATE_PATH, ADMIN_CONTESTS_SET_WINNER_PATH } from '../../../../routing/constants';
import { useNavigate } from 'react-router-dom';
import { BiEdit } from 'react-icons/bi';
import { GiPodiumWinner } from 'react-icons/gi';
import { getContestContestColor, getContestTypeString } from '../../../../utils/helpers';

interface ContestsTableProps {
  contests: Contest[];
}

const isEndDatePast = (endDate: string) => {
  const now = new Date();
  const end = new Date(endDate);
  return end < now;
};

const ContestsTable: React.FC<ContestsTableProps> = ({ contests }) => {
  const navigate = useNavigate();

  return (
    <Box>
      <Heading as="h1" size="xl" mb={4}>
        Concours
      </Heading>
      <Divider />
      {contests.length !== 0 && (
        <Box mt={4}>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Nom</Th>
                <Th>Type</Th>
                <Th>Date début</Th>
                <Th>Date fin</Th>
                <Th>Gagnant</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {contests.map(contest => (
                <Tr key={contest.id}>
                  <Td>{contest.name}</Td>
                  <Td>
                    {' '}
                    <Text color={getContestContestColor(contest.type)}>{getContestTypeString(contest.type)}</Text>
                  </Td>
                  <Td>{formatTimestamp(contest.createdAt)}</Td>
                  <Td>{formatTimestamp(contest.endDate)}</Td>
                  <Td>{contest.winner.length !== 0 ? contest.winner.map(winner => winner.username) : contest.winnerDiscord[0]}</Td>
                  <Td>
                    {contest.winner?.length === 0 && contest.winnerDiscord?.length === 0 && isEndDatePast(contest.endDate) && (
                      <IconButton aria-label="Selectionner le gagnant" icon={<GiPodiumWinner />} onClick={() => navigate(formatUri({ slug: contest.slug }, ADMIN_CONTESTS_SET_WINNER_PATH))} />
                    )}
                    <IconButton aria-label="Modifier" ml={4} icon={<BiEdit />} onClick={() => navigate(formatUri({ slug: contest.slug }, ADMIN_CONTEST_UPDATE_PATH))} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}
    </Box>
  );
};

export default ContestsTable;
