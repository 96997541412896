import React, { useEffect, useState } from 'react';
import { User, UserPublic } from '../types/user';
import { Avatar, AvatarProps, Box } from '@chakra-ui/react';
import { S3_URL } from '../utils/constants';
import { AiOutlineDown } from 'react-icons/ai';
import { customColors } from '../theme';

interface UserAvatarProps {
  user: User | UserPublic;
  styles?: AvatarProps;
  withBorder?: boolean;
  isDisplayIcon?: boolean;
}

const UserAvatar: React.FC<UserAvatarProps> = ({ isDisplayIcon, user, styles, withBorder = false }) => {
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpacity(1);
    }, 50);

    return () => clearTimeout(timer);
  }, []);
  return (
    <Box
      p="4px"
      borderRadius="full"
      display="inline-block"
      border={withBorder ? `2px solid ${customColors.greyRoswel}` : 'none'}
      style={{
        position: 'relative',
        opacity: opacity,
        transition: 'opacity 0.3s ease-in-out',
      }}
    >
      <Avatar name={user.username} src={user.media ? `${S3_URL}/users/${user.media}` : ''} {...styles} />
      {isDisplayIcon && (
        <Box
          style={{
            position: 'absolute', // Positionnement absolu pour l'icône
            bottom: 0,
            right: 0,
            border: '1px solid white', // Bordure de l'icône (modifiable selon le besoin)
            transform: 'translate( 0%, 20%)', // Centrer l'icône sur le bord de l'avatar
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: customColors.blackTegrite,
            justifyContent: 'center',
            width: '15px', // Taille de l'icône
            height: '15px',
          }}
        >
          <AiOutlineDown size={11} /> {/* L'icône utilisée */}
        </Box>
      )}
    </Box>
  );
};

export default UserAvatar;
